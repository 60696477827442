import React, { useEffect, useState } from "react";
import ReportsLayout from "../../../components/reports/reports-layout";
import { gstr2AReportAction } from "../../../redux/actions/report-action";
import { useDispatch } from "react-redux";
import moment from "moment";

const Gstr2aReport = () => {
  const [dateRangeFilter, setDateRangeFilterValue] = useState({
    from: null,
    to: null,
  });
const [group_by, setGroupBy ] = useState()
  const [filters, setFilters] = useState([])
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState(tableHeading);
  const [tableData, setTableData] = useState([]);
  const [dateRange, setDateRange] = useState("");

  const init = {
    selectedColumns: selectedColumns.map((column) => tableColoumsn[column]),
    filters: [],
    filter_by: "",
    group_by: "",
    from: null,
    to: null,
  };
  useEffect(() => {
    const selectedColumnValues = selectedColumns.map(
      (column) => tableColoumsn[column]
    );
    const requestData = {
      selectedColumns: selectedColumnValues,
      filters: [],
      filter_by: dateRange,
      group_by: "",
      from: dateRangeFilter?.from,
      to: dateRangeFilter?.to,
    };
    dispatch(gstr2AReportAction(requestData, onSuccess, onError));
    setLoading(true);
  }, [selectedColumns, filters, dateRange]);

  const isIso8601Date = (dateString) => {
    const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    return iso8601Regex.test(dateString);
  };
  const formatDate = (value) => {
    return moment(value).format("DD-MM-YYYY");
  };
  const transformData = (data) => {
    return data.map((item) => {
      let transformedItem = {};
      for (let [key, value] of Object.entries(tableColoumsn)) {
        const rawValue = item[value.split(".").pop()];
        transformedItem[key] = isIso8601Date(rawValue)
          ? formatDate(rawValue)
          : rawValue;
      }
      return transformedItem;
    });
  };

  const onSuccess = (data) => {
    const transformedData = transformData(data?.data?.result);
    setTableData(transformedData);
    setLoading(false);
  };
  const onError = (data) => {
    setLoading(false);
  };

  return (
    <ReportsLayout
    setGroupBy={setGroupBy}
    setFilters={setFilters}
      init={init}
      reqData={{
        selectedColumns: selectedColumns.map((column) => tableColoumsn[column]),
        filters: [],
        filter_by: dateRange,
        group_by: "",
        from: null,
        to: dateRangeFilter?.to,
      }}
      reportType={"gst"}
      title={"GSTR-2A (InWards Supplies)"}
      tableHeading={selectedColumns}
      tableData={tableData}
      availableColumns={tableHeading}
      selectedColumns={selectedColumns}
      setSelectedColumns={setSelectedColumns}
      loading={loading}
      setDateRange={setDateRange}
      setDateRangeFilterValue={setDateRangeFilterValue}
    />
  );
};

const tableHeading = [
  "GSTIN of Supplier", // New entry
  "Item Name", // New entry
  "Item Code", // New entry
  "Invoice Number", // Already present
  "Invoice Date", // Already present
  "Invoice Value (Total Value)", // Could replace "Invoice Total"
  "Taxable Value", // Already present
  "IGST Amount", // Already present
  "CGST Amount", // Already present
  "SGST Amount", // Already present
  "Place of Supply", // Already present
  "Total Tax amount", // New entry
  // "CESS Amount", // Already present
  // "Date of Filing by Supplier", // New entry
  // "Invoice Type", // New entry
  // "Return Period", // New entry
  // "ITC Eligibility", // New entry
  // "ITC Claimed in GSTR-3B", // New entry
  // "Difference (ITC Mismatch)", // New entry
];

const tableColoumsn = {
  "GSTIN of Supplier": "c.cmr_gstin", // New entry
  "Item Name": "c.item_name", // New entry
  "Item Code": "c.item_code", // New entry
  "Invoice Number": "so.poit_invoice_number", // Already present
  "Invoice Date": "so.created_date", // Already present
  "Invoice Value (Total Value)": "so.poit_total_amount", // Could replace "Invoice Total"
  "Taxable Value": "so.item_price_without_tax", // Already present
  "IGST Amount": "so.poit_igst_amount", // Already present
  "CGST Amount": "so.item_cgst", // Already present
  "SGST Amount": "so.item_sgst", // Already present
  "Place of Supply": "so.cmr_state", // Already present
  "Total Tax amount": "so.poit_total_gst", // New entry
  // "CESS Amount": "so.cess_amount", // Already present
  // "Date of Filing by Supplier": "si.date_of_filing", // New entry
  // "Invoice Type": "so.invoice_type", // New entry
  // "Return Period": "so.return_period", // New entry
  // "ITC Eligibility": "so.ITC Eligibility", // New entry
  // "ITC Claimed in GSTR-3B": "so.ITC Claimed in GSTR-3B", // New entry
  // "Difference (ITC Mismatch)": "so.Difference (ITC Mismatch)", // New entry
};

export default Gstr2aReport;
