const reportsData = [
  {
    title: "Sales Reports",
    path: "/reports",
    childrens: [
      {
        title: "Overall Sales Report",
        desc: "Overview of total sales revenue, number of transactions, and average transaction value.",
        path: "/reports/sales/over-all",
        module_name: "sales_report",
      },
      // {
      //   title: "Daily Basis Sales Report",
      //   desc: "Overview of total sales revenue, number of transactions, and average transaction value.",
      //   path: "/reports/sales/daily-billing",
      //   module_name: "sales_report",
      // },
      // {
      //   title: "Branch Wise Sales Report",
      //   desc: "Sales performance comparison across multiple branches or departments, Identification of high-performing branches and strategies to replicate their success.",
      //   path: "/reports/sales/branch-wise",
      //   module_name: "sales_report",
      // },
      // {
      //   title: "Product Wise Sales Report",
      //   desc: "Detailed sales data for individual products, including quantity sold, total revenue, and average price.",
      //   path: "/reports/sales/product-wise",
      //   module_name: "sales_report",
      // },
      // {
      //   title: "Doctor Wise Sales Report",
      //   desc: "Number of transactions linked to each doctor's prescriptions.",
      //   path: "/reports/sales/doctor-wise",
      //   module_name: "sales_report",
      // },
      // {
      //   title: "Party Wise Sales Report",
      //   desc: "Analysis of customer demographics and preferences.",
      //   path: "/reports/sales/party-wise",
      //   module_name: "sales_report",
      // },
      {
        title: "Payment Mode Wise Sales Report",
        desc: "Total sales revenue categorized by each payment mode (e.g., cash, credit card, debit card, digital wallets, insurance).",
        path: "/reports/sales/payment-mode-wise",
        module_name: "sales_report",
      },
      {
        title: "Product Purchase and Return",
        desc: "The information about like purchase and return of a product",
        path: "/reports/sales/product-purchase-return",
        module_name: "sales_report",
      },
    ],
  },
  {
    title: "Financial & Revenue Reports",
    path: "/reports",
    childrens: [
      // {
      //   title: "Discount Analysis Report",
      //   desc: "Analysis of discounts, returns, and refunds",
      //   path: "/reports/financial/discount-analysis",
      //   module_name: "sales_report",
      // },
      {
        title: "GSTR-1 (Outwards Supplies)",
        desc: "Analysis of discounts, returns, and refunds",
        path: "/reports/gstr-1",
        module_name: "sales_report",
      },
      {
        title: "GSTR-2A (Inwards Supplies)",
        desc: "Analysis of discounts, returns, and refunds",
        path: "/reports/gstr-2a",
        module_name: "sales_report",
      },
      // {
      //   title: "HSN Code Summary",
      //   desc: "Analysis of discounts, returns, and refunds",
      //   path: "/reports/hsn-code-summary",
      //   module_name: "sales_report",
      // },
      // {
      //   title: "GSTR-3B (Summary)",
      //   desc: "Analysis of discounts, returns, and refunds",
      //   path: "/reports/gstr-3b",
      //   module_name: "sales_report",
      // },
      // {
      //   title: "GSTR-9 (Annual Return)",
      //   desc: "Consolidated annual return.",
      //   path: "/reports/gstr-9",
      //   module_name: "sales_report",
      // },

      // {
      //   title: "GSTR-9c (Reconciliation)",
      //   desc: "Audit report and reconciliation with financials.",
      //   path: "/reports/gstr-9c",
      //   module_name: "sales_report",
      // },
      // {
      //   title: "ITC Mismatch Report",
      //   desc: "Reconciles ITC claimed vs. GSTR-2A data.",
      //   path: "/reports/gst-itc-mismatch",
      //   module_name: "sales_report",
      // },
      // {
      //   title: "E-Way Bill Report",
      //   desc: "Tracks the movement of goods requiring e-way bills.",
      //   path: "/reports/gst-e-way-bill",
      //   module_name: "sales_report",
      // },
      // {
      //   title: "GST Payment Summary Report",
      //   desc: "Overview of GST payments made.",
      //   path: "/reports/gst-payment-summary",
      //   module_name: "sales_report",
      // },
      // {
      //   title: "ITC Aging Report",
      //   desc: "Tracks aging of input tax credit.",
      //   path: "/reports/gst-itc-aging",
      //   module_name: "sales_report",
      // },
    ],
  },
  // {
  //   title: "Sales Return Reports",
  //   path: "/reports",
  //   childrens: [
  //     {
  //       title: "Sales Return Report",
  //       desc: "Gain detailed insights into returned products and helping you manage inventory efficiently, and improve customer satisfaction",
  //       path: "/reports/sales-return/sales-return",
  //       module_name: "sales_report",
  //     },
  //   ],
  // },
  // {
  //   title: "Customer Reports",
  //   path: "/reports",
  //   childrens: [
  //     {
  //       title: "Customer Purchase Frequency",
  //       desc: "Analysis of customer demographics and preferences",
  //       path: "/reports/customer/purchase-frequency",
  //       module_name: "sales_report",
  //     },
  //     {
  //       title: "Customer Purchase Behavior",
  //       desc: "Identification of repeat customers and their purchase patterns",
  //       path: "/reports/customer/purchase-behavior",
  //       module_name: "sales_report",
  //     },
  //   ],
  // },
  {
  title: "Inventory and Stock Reports",
    path: "/reports",
    childrens: [
      // {
      //   title: "Inventory Report",
      //   desc: "Analysis of customer demographics and preferences",
      //   path: "/reports/inventory",
      //   module_name: "sales_report",
      // },
      {
        title: "Bounce Report",
        desc: "Analysis of customer demographics and preferences",
        path: "/reports/inventory/bounce",
        module_name: "sales_report",
      },
      {
        title: "Stock & Sales Report",
        desc: "Analysis of customer demographics and preferences",
        path: "/reports/inventory/stock-sales",
        module_name: "sales_report",
      },
      // {
      //   title: "Scheduled H Drug Reg Report",
      //   desc: "Analysis of customer demographics and preferences",
      //   path: "/reports/scheduled-hdrug-reg",
      //   module_name: "sales_report",
      // },
      // {
      //   title: "Expiry Loss Analysis",
      //   desc: "Analysis of customer demographics and preferences",
      //   path: "/reports/expiry-loss",
      //   module_name: "sales_report",
      // },
      // {
      //   title: "Slow and Fast Moving Product Analysis",
      //   desc: "Analysis of customer demographics and preferences",
      //   path: "/reports/slow-fast-moving-products",
      //   module_name: "sales_report",
      // },
    ],
  },
];

export default reportsData;
