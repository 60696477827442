import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import KibabMenu from "../../../../assets/icons/kibab-menu";
import Select from 'react-select';
import { useDispatch } from "react-redux";
import { addItemGroupAction, deleteItemGroupAction, itemGroupTableListAction, uomDropDownAction, updateItemGroupAction } from "../../../../redux/actions/settings-action";
import errorToast from "../../../helper-components/error-toast";
import DeleteIcon from "../../../../assets/icons/delete-icon";
import successToast from "../../../helper-components/success-toast";
import AddButton from "../../../buttons/button";
import LoadingSpinner from "../../../helper-components/loading-spinner";

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        border: 'none',
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
    }),
};

const ItemGroupTable = ({ searchValue }) => {
    const tableHeading = ["Group Code", "Group Name", "Default UOM", ""];
    const [disableArrayFields, setDisableArrayFields] = useState(false);
    const [disableNewFields, setDisableNewFields] = useState(false);
    const [selectedDOtIndex, setSelectedDOtIndex] = useState(null);
    const [uomLists, setUomLists] = useState([]);
    const [initialData, setGroupListData] = useState([]);
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch();

    const handleFieldChange = (index, field, value, setFieldValue) => {
        setFieldValue(`${index}.${field}`, value);
    };

    useEffect(() => {
        dispatch(itemGroupTableListAction({}, onFetchItemTypeSuccess, onFetchItemTypeError));
        setLoading(true)
    }, []);

    useEffect(() => {
        dispatch(itemGroupTableListAction({ searchValue }, onFetchItemTypeSuccess, onFetchItemTypeError));
        setLoading(true)
    }, [searchValue]);

    const reloadData = () => {
        dispatch(itemGroupTableListAction({}, onFetchItemTypeSuccess, onFetchItemTypeError));
        setLoading(true)
    }

    const onFetchItemTypeSuccess = (data) => {
        setGroupListData(data?.data?.itemGroupList);
        setLoading(false)
    }

    const onFetchItemTypeError = (data) => {
        
        setGroupListData([]);
        errorToast(data?.message)
        setLoading(false)
    }

    const initialValues = {
        data: initialData,
        newLine: {
            code: "",
            name: "",
            default_uom_id: ""
        },
    };

    const onSubmit = (values, { setSubmitting }) => {
        const changedObjects = [];
        let newLineObject = null

        // Collect changed array data
        initialData.forEach((item, index) => {
            if (JSON.stringify(values.data[index]) !== JSON.stringify(item)) {
                const { id, code, name, default_uom_id } = values.data[index];
                const pushingObj = {
                    id, code, name, default_uom_id
                }
                changedObjects.push(pushingObj);
            }
        });

        // Collect new line data if it is altered
        if (JSON.stringify(values.newLine) !== JSON.stringify(initialValues.newLine)) {
            newLineObject = values.newLine
        }

        if (newLineObject !== null) {
            const dispatchingObj = {
                code: newLineObject.code,
                name: newLineObject.name,
                default_uom_id: newLineObject.default_uom_id.value
            }
            dispatch(addItemGroupAction(dispatchingObj, onCreateSuccess, onCreateError))
        }

        if (changedObjects.length > 0) {
            dispatch(updateItemGroupAction({ updatingData: changedObjects }, onUpdateSuccess, onUpdateError))
        }
       
        setSubmitting(false);
    }

    const onCreateSuccess = (data) => {
        reloadData();
        setDisableArrayFields(false);
        setDisableNewFields(false);
        successToast(data?.message);
    }
    const onCreateError = (data) => {
        
        setDisableArrayFields(false);
        setDisableNewFields(false);
        errorToast(data?.message);
    }

    const onUpdateSuccess = (data) => {
        reloadData();
        setDisableArrayFields(false);
        setDisableNewFields(false);
        successToast(data?.message);
    }
    const onUpdateError = (data) => {
        
        setDisableArrayFields(false);
        setDisableNewFields(false);
        errorToast(data?.message);
    }

    const componentRef = useRef(null);

    const handleClickOutside = (event) => {
        if (componentRef.current && !componentRef.current.contains(event.target)) {
            setSelectedDOtIndex(null);
        }
    };

    useEffect(() => {
        dispatch(uomDropDownAction({}, onSuccessUsers, onErrorUsers));
    }, [dispatch]);

    const onSuccessUsers = (data) => {
        setUomLists(
            data?.data?.map((item) => ({
                label: item?.name,
                value: item?.uom_id,
            }))
        );
    };

    const onErrorUsers = (data) => {
        
    };

    const onClickDelete = (id) => {
        dispatch(deleteItemGroupAction({ id }, onDeletSuccess, onError))
    }
    const onDeletSuccess = (data) => {
        reloadData();
        successToast(data?.message);
        selectedDOtIndex(null)
    }

    const onError = (data) => {
        
        errorToast(data?.message);
        selectedDOtIndex(null)
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {({ values, setFieldValue, dirty, handleSubmit }) => (
                <Form className="pt-1">
                    <div
                        onClick={handleClickOutside}
                        className="flex flex-col justify-between"
                        style={{ height: "80vh" }}
                    >
                        {loading ? (
                            <LoadingSpinner />
                        ) : (
                            <div className="overflow-auto h-full">
                                <table className="mt-2 w-full">
                                    <thead className="sticky top-0 z-10 bg-F2F4F5">
                                        <tr>
                                            {tableHeading.map((heading, index) => (
                                                <th
                                                    className={`tab-header whitespace-nowrap text-xs fw_500 text-475467 bg-F2F4F5 text-start`}
                                                    key={heading}
                                                >
                                                    {heading}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody style={{ position: "relative" }}>
                                        {values.data.map((item, index) => (
                                            <tr key={index}>
                                                <td
                                                    className={`tab-cell text-sm fw_400 text-secondary`}
                                                >
                                                    <Field
                                                        name={`data[${index}].code`}
                                                        placeholder="Code"
                                                        disabled={disableArrayFields}
                                                        onChange={(e) =>
                                                            handleFieldChange(
                                                                `data[${index}]`,
                                                                "code",
                                                                e.target.value,
                                                                setFieldValue
                                                            )
                                                        }
                                                        className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded px-1 py-2 w-28"
                                                    />
                                                </td>
                                                <td
                                                    className={`tab-cell text-sm fw_400 text-secondary`}
                                                >
                                                    <Field
                                                        name={`data[${index}].name`}
                                                        placeholder="Name"
                                                        disabled={disableArrayFields}
                                                        onChange={(e) =>
                                                            handleFieldChange(
                                                                `data[${index}]`,
                                                                "name",
                                                                e.target.value,
                                                                setFieldValue
                                                            )
                                                        }
                                                        className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2"
                                                    />
                                                </td>
                                                <td
                                                    className={`tab-cell text-sm fw_400 text-secondary`}
                                                >
                                                    <Select
                                                        options={uomLists}
                                                        name={`data[${index}].default_uom_id`}
                                                        placeholder="Select a default UOM"
                                                        value={uomLists.find(uom => uom.value === item.default_uom_id)}
                                                        onChange={(e) => handleFieldChange(
                                                            `data[${index}]`,
                                                            "default_uom_id",
                                                            e.value,
                                                            setFieldValue
                                                        )}
                                                        className="w-52"
                                                        styles={customStyles}
                                                    />
                                                </td>
                                                <td className="tab-cell  w-[5rem] text-sm fw_400 text-secondary w-50 p-8">
                                                    <button onClick={() => setSelectedDOtIndex(index)}>
                                                        <KibabMenu />
                                                    </button>
                                                    {selectedDOtIndex === index && (
                                                        <div
                                                            ref={componentRef}
                                                            style={{ position: "absolute", right: '5rem', boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                                                            className='bg-white rounded w-38'
                                                        >
                                                            <div className='w-full p-3'>
                                                                <button className='w-full flex align-middle gap-2 px-2 py-1' onClick={() => onClickDelete(item?.id)}>
                                                                    <div className="mt-1">
                                                                        <DeleteIcon />
                                                                    </div>
                                                                    <span className='text-sm'>Delete</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td
                                                className={`tab-cell text-sm fw_400 text-secondary`}
                                            >
                                                <Field
                                                    name="newLine.code"
                                                    placeholder="Code"
                                                    disabled={disableNewFields}
                                                    onChange={(e) =>
                                                        handleFieldChange(
                                                            "newLine",
                                                            "code",
                                                            e.target.value,
                                                            setFieldValue
                                                        )
                                                    }
                                                    className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2 w-28"
                                                    autoFocus={searchValue ? false : true}
                                                />
                                            </td>
                                            <td
                                                className={`tab-cell text-sm fw_400 text-secondary`}
                                            >
                                                <Field
                                                    name="newLine.name"
                                                    placeholder="Name"
                                                    disabled={disableNewFields}
                                                    onChange={(e) =>
                                                        handleFieldChange(
                                                            "newLine",
                                                            "name",
                                                            e.target.value,
                                                            setFieldValue
                                                        )
                                                    }
                                                    className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2"
                                                />
                                            </td>
                                            <td
                                                className={`tab-cell text-sm fw_400 text-secondary`}
                                            >
                                                <Select
                                                    options={uomLists}
                                                    name="newLine.default_uom_id"
                                                    placeholder="Select a default UOM"
                                                    value={values.newLine.default_uom_id}
                                                    onChange={(e) => handleFieldChange(
                                                        "newLine",
                                                        "default_uom_id",
                                                        e,
                                                        setFieldValue
                                                    )}
                                                    className="w-52"
                                                    styles={customStyles}
                                                />
                                            </td>
                                            <td
                                                className={`tab-cell text-sm fw_400 text-secondary`}
                                            ></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}

                        <div className="flex justify-end w-full bg-white py-4 px-6 invoice-bottom-container">
                            <AddButton
                                onclkFn={handleSubmit}
                                prefixIcon={false}
                                text="Save"
                                type="submit"
                            />
                        </div>

                    </div>


                </Form>
            )}
        </Formik>
    );
};

export default ItemGroupTable;
