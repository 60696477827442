import {
  ALL_DISCOUNT_GROUP,
  DIS_ITEMS_CREATE,
  DIS_ITEMS_FETCH,
  DIS_ITEM_GROUP_CREATE,
  DIS_MANUFAC_GROUP_CREATE,
  PERIOD_VOLUME_ADD,
  PERIOD_VOLUME_CREATE,
  PERIOD_VOLUME_GET,
  PER_DIS_ITEMS_LIST,
  PER_VOL_PRICE_LIST_ITEMS,
  PRICE_LIST_CREATE,
  PRICE_LIST_DELETE,
  PRICE_LIST_EDIT,
  PRICE_LIST_ITEMS,
  PRICE_LIST_ITEM_EDIT,
  PRICE_LIST_TABLE,
  PRICE_LIST_VIEW,
} from "../types";

export const priceListTableAction = (data, onSuccess, onError) => {
  return {
    type: PRICE_LIST_TABLE,
    data,
    onSuccess,
    onError,
  };
};
export const priceListCreateAction = (data, onSuccess, onError) => {
  return {
    type: PRICE_LIST_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const priceListDeleteAction = (data, onSuccess, onError) => {
  return {
    type: PRICE_LIST_DELETE,
    data,
    onSuccess,
    onError,
  };
};
export const priceListItemsAction = (data, onSuccess, onError) => {
  return {
    type: PRICE_LIST_ITEMS,
    data,
    onSuccess,
    onError,
  };
};
export const priceListViewAction = (data, onSuccess, onError) => {
  return {
    type: PRICE_LIST_VIEW,
    data,
    onSuccess,
    onError,
  };
};
export const priceListEditAction = (data, onSuccess, onError) => {
  return {
    type: PRICE_LIST_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const priceListItemEditAction = (data, onSuccess, onError) => {
  return {
    type: PRICE_LIST_ITEM_EDIT,
    data,
    onSuccess,
    onError,
  };
};

export const allDisGroupAction = (data, onSuccess, onError) => {
  return {
    type: ALL_DISCOUNT_GROUP,
    data,
    onSuccess,
    onError,
  };
};

export const disItemGroupCreateAction = (data, onSuccess, onError) => {
  return {
    type: DIS_ITEM_GROUP_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const disManufacGroupCreateAction = (data, onSuccess, onError) => {
  return {
    type: DIS_MANUFAC_GROUP_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const disItemsCreateAction = (data, onSuccess, onError) => {
  return {
    type: DIS_ITEMS_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const disItemsFetchAction = (data, onSuccess, onError) => {
  return {
    type: DIS_ITEMS_FETCH,
    data,
    onSuccess,
    onError,
  };
};

export const perDisItemsListAction = (data, onSuccess, onError) => {
  return {
    type: PER_DIS_ITEMS_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const perVolumePriceListItemsAction = (data, onSuccess, onError) => {
  return {
    type: PER_VOL_PRICE_LIST_ITEMS,
    data,
    onSuccess,
    onError,
  };
};
export const perVolCreateAction = (data, onSuccess, onError) => {
  return {
    type: PERIOD_VOLUME_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const periodVolumeAddAction = (data, onSuccess, onError) => {
  return {
    type: PERIOD_VOLUME_ADD,
    data,
    onSuccess,
    onError,
  };
};
export const periodVolumeGetAction = (data, onSuccess, onError) => {
  return {
    type: PERIOD_VOLUME_GET,
    data,
    onSuccess,
    onError,
  };
};
