import {
  INVOICE_ALTERNATIVE,
  INVOICE_CLEAR,
  INVOICE_CREATE,
  INVOICE_DELETE,
  INVOICE_DISCOUNT,
  INVOICE_DRAFT_CREATE,
  INVOICE_EDIT,
  INVOICE_NUMBER,
  INVOICE_PAST,
  INVOICE_ROW,
  INVOICE_SCANNED_ITEM,
  INVOICE_TABLE,
  INVOICE_VIEW,
  REPEAT_ORDER,
  RETURN_BILL_ADD,
  RETURN_BILL_ITEMS_GET,
  RETURN_BILL_TABLE,
  RETURN_BILL_VIEW,
  STORE_DETAILS,
} from "../types";

export const invoiceCreateAction = (data, onSuccess, onError) => {
  return {
    type: INVOICE_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const invoiceDeleteAction = (data, onSuccess, onError) => {
  return {
    type: INVOICE_DELETE,
    data,
    onSuccess,
    onError,
  };
};
export const invoiceEditAction = (data, onSuccess, onError) => {
  return {
    type: INVOICE_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const invoiceDraftCreateAction = (data, onSuccess, onError) => {
  return {
    type: INVOICE_DRAFT_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const invoiceTableAction = (data, onSuccess, onError) => {
  return {
    type: INVOICE_TABLE,
    data,
    onSuccess,
    onError,
  };
};
export const invoicePastAction = (data, onSuccess, onError) => {
  return {
    type: INVOICE_PAST,
    data,
    onSuccess,
    onError,
  };
};
export const repeatOrderAction = (data, onSuccess, onError) => {
  return {
    type: REPEAT_ORDER,
    data,
    onSuccess,
    onError,
  };
};
export const invoiceNumberAction = (data, onSuccess, onError) => {
  return {
    type: INVOICE_NUMBER,
    data,
    onSuccess,
    onError,
  };
};
export const invoiceDiscountAction = (data, onSuccess, onError) => {
  return {
    type: INVOICE_DISCOUNT,
    data,
    onSuccess,
    onError,
  };
};

export const invoiceViewAction = (data, onSuccess, onError) => {
  return {
    type: INVOICE_VIEW,
    data,
    onSuccess,
    onError,
  };
};
export const invoiceAlternativeAction = (data, onSuccess, onError) => {
  return {
    type: INVOICE_ALTERNATIVE,
    data,
    onSuccess,
    onError,
  };
};
export const invoiceRowAction = (data) => {
  return {
    type: INVOICE_ROW,
    data,
  };
};
export const invoiceRowClearAction = (data) => {
  return {
    type: INVOICE_CLEAR,
  };
};
export const storeDetailsAction = (onSuccess, onError) => {    
  return {
    type: STORE_DETAILS,
    onSuccess,
    onError,
  };
};
export const invoiceScannedItemAction = (data, onSuccess, onError) => {
  return {
    type: INVOICE_SCANNED_ITEM,
    data,
    onSuccess,
    onError,
  };
};

//RETURN BILL

export const returnBillTableAction = (data, onSuccess, onError) => {
  return {
    type: RETURN_BILL_TABLE,
    data,
    onSuccess,
    onError,
  };
};
export const returnBillViewAction = (data, onSuccess, onError) => {
  return {
    type: RETURN_BILL_VIEW,
    data,
    onSuccess,
    onError,
  };
};
export const returnBillCreateAction = (data, onSuccess, onError) => {
  return {
    type: RETURN_BILL_ADD,
    data,
    onSuccess,
    onError,
  };
};
export const returnBillItemsGetAction = (data, onSuccess, onError) => {
  return {
    type: RETURN_BILL_ITEMS_GET,
    data,
    onSuccess,
    onError,
  };
};
