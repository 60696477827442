import React, { createContext, useState } from "react";
import { Outlet } from "react-router-dom";
import "./layout.css";
import Sidebar from "./side-bar";
import usePermissions from "../helper-components/usePermission-checker";
import { useHotkeys } from "react-hotkeys-hook";
import { useNavigate } from "react-router-dom";

export const DataContext = createContext();
const MainLayout = () => {
  const [isExpanded, setExpanded] = useState(false);
  const { permissionFn } = usePermissions();

  const navigate = useNavigate();

  window.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      const focusedElement = document.activeElement;
      if (focusedElement) {
        focusedElement.blur();
      }
    }
  });
  useHotkeys("/", () => {
    const searchInput = document.querySelector(".table_search_input");
    if (searchInput) {
      setTimeout(() => {
        searchInput.focus();
      }, 0);
    }
  });

  // useHotkeys("s", () => {
  //   navigate("/settings");
  // });

  useHotkeys("n", () => {
    const addButton = document.getElementById("new-button");
    if (addButton) {
      addButton.click();
    }
  });

  useHotkeys("shift+b", () => {
    navigate("/bills");
  });

  useHotkeys("ctrl+i", () => {
    navigate("/add-invoice");
  });

  useHotkeys("shift+s", () => {
    navigate("/sales");
  });

  useHotkeys("ctrl+s", () => {
    navigate("/add-sales/%5B%5D/order");
  });

  useHotkeys("shift+p", () => {
    navigate("/purchase");
  });

  useHotkeys("ctrl+p", () => {
    navigate("/add-purchase/%5B%5D/purchase");
  });

  useHotkeys("shift+p", () => {
    navigate("/purchase");
  });

  useHotkeys("ctrl+p", () => {
    navigate("/add-purchase/%5B%5D/purchase");
  });

  useHotkeys("d", () => {
    const draftButton = document.getElementById("draft");
    if (draftButton) {
      draftButton.click();
    }
  });

  useHotkeys("alt+s", () => {
    const saveButton = document.getElementById("save-and-print-button");
    if (saveButton) {
      saveButton.click();
    }
  });

  useHotkeys("e", () => {
    const saveButton = document.getElementById("edit-button");
    if (saveButton) {
      saveButton.click();
    }
  });

  useHotkeys("p", () => {
    const printButton = document.getElementById("print-button");
    if (printButton) {
      if (window.confirm("Are you sure you want to print?")) {
        printButton.click();
      }
    }
  });

  useHotkeys("alt+shift+d", () => {
    const Button = document.getElementById("delete-button");
    if (Button) {
      if (window.confirm("Are you sure you want to delete?")) {
        Button.click();
      }
    }
  });

  useHotkeys("alt+a", () => {
    const checkboxes = document.querySelectorAll(".custom-checkbox");
    checkboxes.forEach((checkbox) => {
      if (!checkbox.disabled) {
        if (!checkbox.checked) {
          checkbox.checked = true;
        } else {
          checkbox.checked = false;
        }
      }
    });

    const checkboxes2 = document.getElementById("myCheckbox");
    if (checkboxes2) {
      checkboxes2.click();
    }
  });

  const handleSelectRadioButton = (id) => {
    const radioButton = document.getElementById(`alt-${id - 1}`);
    if (radioButton) {
      radioButton.click();
    }
  };

  useHotkeys("alt+¡", () => handleSelectRadioButton(1));
  useHotkeys("alt+2", () => handleSelectRadioButton(2));
  useHotkeys("alt+3", () => handleSelectRadioButton(3));
  useHotkeys("alt+4", () => handleSelectRadioButton(4));
  useHotkeys("alt+5", () => handleSelectRadioButton(5));
  useHotkeys("alt+6", () => handleSelectRadioButton(6));
  useHotkeys("alt+7", () => handleSelectRadioButton(7));
  useHotkeys("alt+8", () => handleSelectRadioButton(8));
  useHotkeys("alt+9", () => handleSelectRadioButton(9));

  useHotkeys("alt+0", () => {
    const alternativesButton = document.getElementById("alternatives");
    if (alternativesButton) {
      alternativesButton.click();
    }
  });

  useHotkeys("meta+{", () => {
    navigate(-1);
  });

  useHotkeys("meta+}", () => {
    navigate(1);
  });

  useHotkeys("alt+.", () => {
    const sidebarToggleButton = document.getElementById(
      "sidebar-toggle-button"
    );
    if (sidebarToggleButton) {
      sidebarToggleButton.click();
    }
  });

  useHotkeys("alt+p", () => {
    const pastOrdersButton = document.getElementById("view-past-orders");
    if (pastOrdersButton) {
      pastOrdersButton.click();
    }
  });
  return (
    <DataContext.Provider value={{ permissionFn: permissionFn }}>
      <div className="flex h-screen w-100 ">
        <Sidebar
          permissionFn={permissionFn}
          isExpanded={isExpanded}
          setExpanded={setExpanded}
        />
        <div
          style={{
            overflowY: "auto",
          }}
          className={`h-full ${
            isExpanded ? "main_layout_container" : "main_container_not_exp"
          }`}
        >
          <Outlet />
        </div>
      </div>
    </DataContext.Provider>
  );
};

export default MainLayout;
