import React, { useRef, useState } from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import SelectField from "../../components/forms/select-field";
import AsyncCreateSelect from "../../components/forms/async-create-select-field";
import { useDispatch } from "react-redux";
import { warehouseListAction } from "../../redux/actions/inventory-action";

function BinLocationManagement() {
  const initialValues = {
    warehouseCode: "",
    warehouseName: "",
    taxLocation: "",
    location: "",
    binLocation: false,
  };

  const validationSchema = Yup.object().shape({
    warehouseCode: Yup.string().required("warehouse code is required"),
    warehouseName: Yup.string().matches("warehouse name is required "),
    taxLocation: Yup.string().required("tax location is required"),
    location: Yup.string().required("location is required"),
  });

  const onSubmit = (values) => {};

  const searchRef = useRef(null);
  const dispatch = useDispatch();
  const loadOptions = (inputValue) => {
    return new Promise((resolve) => {
      const onSuccess = (data) => {
        const options = [
          ...data.data.map((item) => ({
            value: item.warehouse_id,
            label: `${item?.warehouse_code} - ${item?.warehouse_name}`,
          })),
        ];
        resolve(options);
      };

      const onError = (error) => {
        resolve([]);
      };

      if (inputValue?.length > 0) {
        dispatch(
          warehouseListAction(
            {
              code: inputValue,
            },
            onSuccess,
            onError
          )
        );
      } else {
        resolve([]);
      }
    });
  };

  const [stateSubLevel, setStateSubLevel] = useState([{ from: "", to: "" }]);

  return (
    <InnerLayout
      onclkFn={"/binlocation-management"}
      title="Bin Location Management"
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, isSubmitting }) => {
          return (
            <Form className="h-full pt-5">
              <div className="flex flex-col justify-between h-full ">
                {" "}
                <div>
                  <div className="flex flex-wrap">
                    <div className="w-full 2xl:w-[43%] lg:w-[50%] px-5 xl:px-10">
                      <SelectField
                        placeholder="select management task"
                        options={managementTaskList}
                        label="Management Task"
                        id="managementTask"
                        name="managementTask"
                        isSearchable={true}
                        isRequired={true}
                        labelWidth="9rem"
                      />
                      <AsyncCreateSelect
                        isValidNewOption={true}
                        name={"warehouse"}
                        autoFocus={true}
                        label={"Warehouse"}
                        placeholder="Enter warehouse code"
                        loadOptions={loadOptions}
                        onChange={(selectedOption) => {
                          setFieldValue("warehouse", selectedOption);

                          if (searchRef && searchRef.current) {
                            searchRef.current.focus();
                            // searchRef.current.select();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="mt-5 ml-10">
                    <div className="grid grid-cols-7 gap-10">
                      <div className=" col-span-1"></div>
                      <div className=" col-span-2">
                        <p>form</p>
                      </div>
                      <div className=" col-span-2">to</div>
                      <div className=" col-span-2"></div>
                    </div>

                    {stateSubLevel.map((item, index) => (
                      <div
                        key={index}
                        className="grid grid-cols-7 gap-10 mt-5 items-center"
                      >
                        <div className=" col-span-1">Sub level {index + 1}</div>
                        <div className=" col-span-2">
                          <input
                            type="text"
                            name="from"
                            value={item.from}
                            onChange={(e) => {
                              const { value } = e.target;
                              const list = [...stateSubLevel];
                              list[index].from = value;
                              setStateSubLevel(list);
                            }}
                            className="w-full border border-787878 rounded-md p-2"
                          />
                        </div>
                        <div className=" col-span-2">
                          <input
                            type="text"
                            name="to"
                            value={item.to}
                            onChange={(e) => {
                              const { value } = e.target;
                              const list = [...stateSubLevel];
                              list[index].to = value;
                              setStateSubLevel(list);
                            }}
                            className="w-full border border-787878 rounded-md p-2"
                          />
                        </div>
                        <div className=" col-span-2">
                          <button
                            type="button"
                            onClick={() => {
                              setStateSubLevel([
                                ...stateSubLevel,
                                { from: "", to: "" },
                              ]);
                            }}
                            className="bg-blue-500 text-white rounded-md p-2"
                          >
                            Add sub level
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </InnerLayout>
  );
}

export default BinLocationManagement;

const managementTaskList = [
  { value: "generateBinLocations", label: "Generate Bin Locations" },
  { value: "warehouse", label: "Warehouse" },
  { value: "bin-location", label: "Bin Location" },
];