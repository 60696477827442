import { call, takeLatest } from "redux-saga/effects";
import {
  ALL_DISCOUNT_GROUP,
  DIS_ITEMS_CREATE,
  DIS_ITEMS_FETCH,
  DIS_ITEM_GROUP_CREATE,
  DIS_MANUFAC_GROUP_CREATE,
  PERIOD_VOLUME_ADD,
  PERIOD_VOLUME_CREATE,
  PERIOD_VOLUME_GET,
  PER_DIS_ITEMS_LIST,
  PER_VOL_PRICE_LIST_ITEMS,
  PRICE_LIST_CREATE,
  PRICE_LIST_DELETE,
  PRICE_LIST_EDIT,
  PRICE_LIST_ITEMS,
  PRICE_LIST_ITEM_EDIT,
  PRICE_LIST_TABLE,
  PRICE_LIST_VIEW,
} from "../types";
import {
  allDisGroupApi,
  disItemGroupCreateApi,
  disItemsCreateApi,
  disItemsFetchApi,
  disManufacGroupCreateApi,
  perDisItemsListApi,
  perVolCreateApi,
  perVolumePriceListItemsApi,
  periodVolumeAddApi,
  periodVolumeGetApi,
  priceListCreateApi,
  priceListDeleteApi,
  priceListEditApi,
  priceListItemEditApi,
  priceListItemsApi,
  priceListTableApi,
  priceListViewApi,
} from "../apis/discount-api";

export function* priceListTableWorker(action) {
  try {
    const res = yield call(priceListTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* priceListCreateWorker(action) {
  try {
    const res = yield call(priceListCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* priceListDeleteWorker(action) {
  try {
    const res = yield call(priceListDeleteApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* priceListItemsWorker(action) {
  try {
    const res = yield call(priceListItemsApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* priceListViewWorker(action) {
  try {
    const res = yield call(priceListViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* priceListEditWorker(action) {
  try {
    const res = yield call(priceListEditApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* priceListItemEditWorker(action) {
  try {
    const res = yield call(priceListItemEditApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* allDisGroupWorker(action) {
  try {
    const res = yield call(allDisGroupApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* disItemGroupCreateWorker(action) {
  try {
    const res = yield call(disItemGroupCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* disManufacGroupCreateWorker(action) {
  try {
    const res = yield call(disManufacGroupCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* disItemsCreateWorker(action) {
  try {
    const res = yield call(disItemsCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* disItemsFetchWorker(action) {
  try {
    const res = yield call(disItemsFetchApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* perDisItemsListWorker(action) {
  try {
    const res = yield call(perDisItemsListApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* perVolumePriceListItemsWorker(action) {
  try {
    const res = yield call(perVolumePriceListItemsApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* perVolCreateWorker(action) {
  try {
    const res = yield call(perVolCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* periodVolumeAddWorker(action) {
  try {
    const res = yield call(periodVolumeAddApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* periodVolumeGetWorker(action) {
  try {
    const res = yield call(periodVolumeGetApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* periodVolumeGetWatcher() {
  yield takeLatest(PERIOD_VOLUME_GET, periodVolumeGetWorker);
}
export function* periodVolumeAddWatcher() {
  yield takeLatest(PERIOD_VOLUME_ADD, periodVolumeAddWorker);
}
export function* perVolCreateWatcher() {
  yield takeLatest(PERIOD_VOLUME_CREATE, perVolCreateWorker);
}
export function* perVolumePriceListItemsWatcher() {
  yield takeLatest(PER_VOL_PRICE_LIST_ITEMS, perVolumePriceListItemsWorker);
}
export function* perDisItemsListWatcher() {
  yield takeLatest(PER_DIS_ITEMS_LIST, perDisItemsListWorker);
}

export function* disItemsFetchWatcher() {
  yield takeLatest(DIS_ITEMS_FETCH, disItemsFetchWorker);
}
export function* allDisGroupWatcher() {
  yield takeLatest(ALL_DISCOUNT_GROUP, allDisGroupWorker);
}
export function* disItemGroupCreateWatcher() {
  yield takeLatest(DIS_ITEM_GROUP_CREATE, disItemGroupCreateWorker);
}
export function* disManufacGroupCreateWatcher() {
  yield takeLatest(DIS_MANUFAC_GROUP_CREATE, disManufacGroupCreateWorker);
}

export function* disItemsCreateWatcher() {
  yield takeLatest(DIS_ITEMS_CREATE, disItemsCreateWorker);
}

export function* priceListItemEditWatcher() {
  yield takeLatest(PRICE_LIST_ITEM_EDIT, priceListItemEditWorker);
}
export function* priceListEditWatcher() {
  yield takeLatest(PRICE_LIST_EDIT, priceListEditWorker);
}
export function* priceListViewWatcher() {
  yield takeLatest(PRICE_LIST_VIEW, priceListViewWorker);
}
export function* priceListItemsWatcher() {
  yield takeLatest(PRICE_LIST_ITEMS, priceListItemsWorker);
}
export function* priceListCreateWatcher() {
  yield takeLatest(PRICE_LIST_CREATE, priceListCreateWorker);
}
export function* priceListTableWatcher() {
  yield takeLatest(PRICE_LIST_TABLE, priceListTableWorker);
}

export function* priceListDeleteWatcher() {
  yield takeLatest(PRICE_LIST_DELETE, priceListDeleteWorker);
}
