import React, { useState } from "react";
import InnerLayout from "../layouts/inner-layout";
import FilterIcon from "../../assets/icons/filter-icon";
import Select from "react-select";
import { PlusIcon } from "@heroicons/react/24/outline";
import AddButton from "../buttons/button";
import exportFromJSON from "export-from-json";
import ColoumnsModal from "../modals/reports/coloumn-modal";
import ColoumnsButton from "../../assets/icons/coloumnButton";
import ClearIcon from "../../assets/icons/clear-icon";
import ToggleColoumnsModal from "../modals/reports/update-column-modal";
import WerehouseCreation from "../../assets/icons/warehouse-creation";
const ReportLayout = ({
  allcolumns = [],
  children,
  title = "",
  setOpenMoreFilter,
  filters = {},
  setFilters,
  openMoreFilter,
  handleApply = () => {},
  groupByOptions,
  tableData,
  setTableData,
  initFilter,
  selectedColumns = [],
  availableColumns = [],
  setSelectedColumns = () => {},
  displayFiletrs,
  savedReportColumns,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [favName, setFavName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isApply, setIsApply] = useState(false);
  const exportOptions = [{ label: "CSV", value: "csv" }];
  const fileName = title ? title : "report";
  const exportType = exportFromJSON.types.csv;
  const onClickExportCsv = () => {
    exportFromJSON({ data: tableData, fileName, exportType });
  };

  const filterDateRange = [
    { label: "This Month", value: "this_month" },
    { label: "This Year", value: "this_year" },
    { label: "All", value: "" },
    ...(title === "Daily Sales Report" ||
    title === "Overall Sales Report" ||
    title === "Stock and Sales Report" ||
    title === "Payment Mode Wise Sales Report" ||
    title === "Product Wise Purchase and Return Report"
      ? [
          { label: "This Week", value: "this_week" },
          { label: "Today", value: "today" },
        ]
      : []),
    ...(title !== "Daily Sales Report"
      ? [{ label: "Custom Date", value: "custom_date" }]
      : []),
  ];

  return (
    <InnerLayout backBtn={true} list={true} title={title}>
      <div className="report-header">
        {displayFiletrs !== false && (
          <div className=" flex justify-between mx-6 flex-wrap ">
            <div className="relative flex items-center gap-3 pt-2">
              <button
                style={{
                  height: "2.6rem",
                }}
                onClick={() => null}
                className="flex items-center whitespace-nowrap text-sm rounded-md px-3 gap-2 text-344054 fw_600"
              >
                <FilterIcon />
                {"Filters"}
              </button>
              <div
                style={{ maxWidth: "16rem", height: "2.6rem" }}
                className={`w-full flex items-center rounded-md input_border `}
              >
                <span className="mx-2 whitespace-nowrap text-xs  text-344054 fw_600">
                  Date Range:
                </span>
                <Select
                  className="border-0 text-xs placeholder:text-xs"
                  menuPortalTarget={document.body}
                  styles={customStylesForDateRange}
                  value={filterDateRange.find(
                    (item) => item.value === filters?.filter_by
                  )}
                  options={filterDateRange}
                  onChange={(selectedOption) => {
                    setFilters({
                      ...filters,
                      filter_by: selectedOption.value,
                      from: null,
                      to: null,
                    });
                  }}
                />
              </div>

              {filters?.filter_by === "custom_date" && (
                <div
                  style={{
                    width: "fit-content",
                    height: "2.6rem",
                  }}
                  className="input_border rounded-md text-344054 fw_600 flex items-center md:flex-wrap "
                >
                  <div className="px-2 flex items-center">
                    <span className="text-xs">From:</span>
                    <input
                      style={{
                        width: "7rem",
                      }}
                      placeholder="DD-MM-YYYY"
                      className=" table_search_input w-full rounded-md mr-4 ml-1 text-xs placeholder:text-text-sm"
                      type="date"
                      value={filters?.from}
                      onChange={(e) => {
                        setFilters({
                          ...filters,
                          from: e.target.value,
                        });
                      }}
                    />
                    <span className="text-xs">To:</span>
                    <input
                      style={{
                        width: "7rem",
                      }}
                      placeholder="DD-MM-YYYY"
                      className=" table_search_input w-full rounded-md ml-1 text-xs placeholder:text-text-sm"
                      type="date"
                      value={filters?.to}
                      onChange={(e) => {
                        setFilters({
                          ...filters,
                          to: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              )}

              <div
                onClick={() => setOpenMoreFilter(!openMoreFilter)}
                style={{ maxWidth: "8rem", height: "2.6rem" }}
                className={`w-full px-2 cursor-pointer flex align-middle items-center rounded-md input_border`}
              >
                <PlusIcon
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className={`w-4 text-primary`}
                />
                <span className="mx-2 whitespace-nowrap text-sm  text-344054 fw_600">
                  More Filter
                </span>
              </div>
              <div>
                <AddButton
                  onclkFn={() => {
                    handleApply(filters);
                    setIsApply(true);
                  }}
                  prefixIcon={false}
                  text="Apply"
                  type="submit"
                />
              </div>
            </div>

            <div className="flex items-center gap-3 pt-2">
              <div
                style={{ maxWidth: "16rem", height: "2.6rem" }}
                className={`w-full flex items-center rounded-md input_border`}
              >
                <span className="mx-2 whitespace-nowrap text-xs  text-344054 fw_600">
                  Group By:
                </span>
                <Select
                  className="border-0 text-xs placeholder:text-xs"
                  menuPortalTarget={document.body}
                  styles={customStyles}
                  value={groupByOptions.find(
                    (item) => item.value === filters?.group_by
                  )}
                  options={groupByOptions}
                  onChange={(sel) => {
                    setFilters({ ...filters, group_by: sel?.value });
                  }}
                />
              </div>
              <div
                style={{ maxWidth: "10rem", height: "2.6rem" }}
                className={`w-full flex items-center py-3 rounded-md input_border `}
              >
                <span className="mx-2 whitespace-nowrap text-xs  text-344054 fw_600">
                  Export
                </span>
                <Select
                  className="border-0 text-xs placeholder:text-xs"
                  menuPortalTarget={document.body}
                  styles={customStylesForExports}
                  options={exportOptions}
                  onChange={onClickExportCsv}
                  placeholder=""
                />
              </div>
              <div
                style={{ maxWidth: "8rem", height: "2.6rem" }}
                className={`w-full flex items-center py-3 rounded-md cursor-pointer`}
                onClick={() => {
                  setIsDrawerOpen(true);
                }}
              >
                <ColoumnsButton />
              </div>
              <button
                style={{
                  height: "2.6rem",
                }}
                onClick={() => {
                  setFilters(initFilter);

                  handleApply(initFilter);
                }}
                className={`px-4 whitespace-nowrap text-sm  fw_600 rounded-md input_border flex items-center gap-2  h-full
                ${
                  JSON.stringify(filters) === JSON.stringify(initFilter) &&
                  "bg-disabled-color"
                }
                `}
              >
                <ClearIcon />
                Clear All
              </button>
              <button
                className={`px-4 whitespace-nowrap text-sm fw_600 rounded-md input_border flex items-center gap-2 h-full
              ${
                JSON.stringify(filters) === JSON.stringify(initFilter) &&
                (tableData.length > 0 && allcolumns.length > 0
                  ? Object.keys(allcolumns[0]).length ===
                    Object.keys(tableData[0]).length
                  : true)
                  ? "bg-disabled-color cursor-not-allowed"
                  : "bg-primary cursor-pointer text-white"
              }
            `}
                disabled={
                  JSON.stringify(filters) === JSON.stringify(initFilter) &&
                  (tableData.length > 0 && allcolumns.length > 0
                    ? Object.keys(allcolumns[0]).length ===
                      Object.keys(tableData[0]).length
                    : true)
                }
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                Add To Fav
              </button>
              {isModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center min-h-screen justify-center">
                  <div
                    className="fixed inset-0 bg-black opacity-60 min-h-screen"
                    onClick={() => setIsModalOpen(false)} // Close modal on background click
                  ></div>

                  {/* Modal Content */}
                  <div className="relative z-10 bg-white p-6 rounded-lg shadow-lg w-80">
                    <h2 className="text-lg font-bold mb-4">Add to Favorites</h2>
                    <input
                      type="text"
                      placeholder="Enter name"
                      value={favName}
                      onChange={(e) => setFavName(e.target.value)}
                      className="w-full px-3 py-2 mb-4 border rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                    />
                    <div className="flex justify-end gap-2">
                      <button
                        className="px-4 whitespace-nowrap text-sm  fw_600 rounded-md input_border flex items-center gap-2 "
                        onClick={() => setIsModalOpen(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="px-4 py-2 text-sm text-white bg-primary rounded-md"
                        onClick={() => {
                          handleApply({
                            ...filters,
                            save: "true",
                            name: favName,
                          });
                          setIsModalOpen(false);
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {displayFiletrs === false && (
            <div
              style={{
                maxWidth: "10rem",
                height: "2.6rem",
                marginTop: "10px",
                marginRight: "20px",
              }}
              className="w-full flex items-center py-3 rounded-md input_border"
            >
              <span className="mx-2 whitespace-nowrap text-xs text-344054 fw_600">
                Export
              </span>
              <Select
                className="border-0 text-xs placeholder:text-xs"
                menuPortalTarget={document.body}
                styles={customStylesForExports}
                options={exportOptions}
                onChange={onClickExportCsv}
                placeholder=""
              />
            </div>
          )}
        </div>
      </div>

      {children}

      {title === "Payment Mode Wise Sales Report" ? (
        <ColoumnsModal
          tableData={tableData}
          setTableData={setTableData}
          filters={filters}
          setFilters={setFilters}
          isModalOpen={isDrawerOpen}
          setIsModalOpen={() => setIsDrawerOpen(!isDrawerOpen)}
          selectedColumns={selectedColumns}
          availableColumns={availableColumns}
          setSelectedColumns={setSelectedColumns}
        />
      ) : (
        <ToggleColoumnsModal
          allcolumns={allcolumns}
          tableData={tableData}
          setTableData={setTableData}
          filters={filters}
          setFilters={setFilters}
          isModalOpen={isDrawerOpen}
          setIsModalOpen={() => setIsDrawerOpen(!isDrawerOpen)}
        />
      )}
    </InnerLayout>
  );
};

export default ReportLayout;

export const customStylesForDateRange = {
  control: (provided, state) => ({
    ...provided,
    // fontSize: "12px",
    fontWeight: "600",
    color: "#344054",
    width: "9rem",
    boxShadow: "none", // Remove box shadow
    border: "none",
    borderRadius: "6px",
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    color: "gray",
    fontFamily: "Inter, sans-serif",
    // fontSize: "12px",
    fontWeight: "600",
    opacity: "1",
    position: "absolute",
    whiteSpace: "nowrap",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none", // Hide indicator separator
  }),
  singleValue: (base) => ({
    ...base,
    fontWeight: "600",
    color: "#344054",
    // fontSize: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    ...(state.data.color ? { color: state.data.color } : { color: "black" }),
    backgroundColor: state.isSelected ? "#e5ecffb2" : null,
    borderBottom: "1px solid #F5F5F5",
    fontSize: "12px",
    fontFamily: "Inter, sans-serif",
    "&:hover": {
      backgroundColor: state.isFocused ? "#0000002E" : "#0000002E",
    },
    "&::after": {
      content: state.isSelected ? '"✓"' : '""',
      color: "#007bff", // Blue color
      fontWeight: "normal",
      fontSize: "20px", // Larger size
      position: "absolute",
      right: "0.8rem",
      marginBottom: "22px",
    },
  }),
};

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    // fontSize: "12px",
    fontWeight: "600",
    color: "#344054",
    width: "10rem",
    boxShadow: "none", // Remove box shadow
    border: "none",
    borderRadius: "6px",
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    color: "gray",
    fontFamily: "Inter, sans-serif",
    // fontSize: "12px",
    fontWeight: "600",
    opacity: "1",
    position: "absolute",
    whiteSpace: "nowrap",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none", // Hide indicator separator
  }),
  singleValue: (base) => ({
    ...base,
    fontWeight: "600",
    color: "#344054",
    // fontSize: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    ...(state.data.color ? { color: state.data.color } : { color: "black" }),
    backgroundColor: state.isSelected ? "#e5ecffb2" : null,
    borderBottom: "1px solid #F5F5F5",
    fontSize: "12px",
    fontFamily: "Inter, sans-serif",
    "&:hover": {
      backgroundColor: state.isFocused ? "#0000002E" : "#0000002E",
    },
    "&::after": {
      content: state.isSelected ? '"✓"' : '""',
      color: "#007bff", // Blue color
      fontWeight: "normal",
      fontSize: "20px", // Larger size
      position: "absolute",
      right: "0.8rem",
      marginBottom: "22px",
    },
  }),
};

export const customStylesForExports = {
  control: (provided, state) => ({
    ...provided,
    // fontSize: "12px",
    fontWeight: "600",
    color: "#344054",
    width: "6rem",
    boxShadow: "none", // Remove box shadow
    border: "none",
    borderRadius: "6px",
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    color: "black",
    fontFamily: "Inter, sans-serif",
    // fontSize: "12px",
    fontWeight: "600",
    opacity: "1",
    position: "absolute",
    whiteSpace: "nowrap",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none", // Hide indicator separator
  }),
  singleValue: (base) => ({
    ...base,
    fontWeight: "600",
    color: "#344054",
    // fontSize: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    ...(state.data.color ? { color: state.data.color } : { color: "black" }),
    backgroundColor: state.isSelected ? "#e5ecffb2" : null,
    borderBottom: "1px solid #F5F5F5",
    fontSize: "12px",
    fontFamily: "Inter, sans-serif",
    "&:hover": {
      backgroundColor: state.isFocused ? "#0000002E" : "#0000002E",
    },
  }),
};
