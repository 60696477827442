import { api } from "./api";

export const productListApi = (params) => {
  return api.get(`/product/get?field=${params?.field}&value=${params?.value}`);
};
export const phoneListApi = (params) => {
  return api.get(
    `/customer/getByPhoneNumber?cmr_phone_number=${params?.phone}`
  );
};
var headers = new Headers();
headers.append(
  "X-CSCAPI-KEY",
  "VTU5Q2RWNkM1Z1BkbXZCa2JnTFp1MXBXN3htRVNkYkF3Zk90QmprTQ=="
);
var requestOptions = {
  headers: headers,
  redirect: "follow",
};
export const stateListApi = () => {
  return fetch(
    `https://api.countrystatecity.in/v1/countries/IN/states`,
    requestOptions
  );
};

export const doctorListApi = () => {
  return api.get(`/doctor/get`);
};
export const storeIdApi = () => {
  return api.get(`/storeId/get`);
};

export const paymentMethodListApi = () => {
  return api.get(`/paymentsModes/get`);
};

export const catListApi = () => {
  return api.get(`/settings/itemgcategory/dropdown`);
};
export const uomListApi = () => {
  return api.get(`/settings/uoms`);
};
export const priceListApi = () => {
  return api.get(`/discount/pricelist/get`);
};
export const itemGroupListApi = () => {
  return api.get(`/settings/itemgroup/dropdown`);
};
export const itemTypeListApi = () => {
  return api.get(`/settings/itemgtype/dropdown`);
};
export const taxCatListApi = () => {
  return api.get(`/settings/tax-combination-item/dropdown`);
};
export const manufacturesListApi = () => {
  return api.get(`/settings/manufacturers`);
};
export const shippingTypeListApi = () => {
  return api.get(`/settings/shipping-types`);
};
export const sectorListApi = () => {
  return api.get(`/sector/get`);
};
export const schListApi = () => {
  return api.get(`/productScheduleList/get`);
};
export const vendorListApi = () => {
  return api.get(`/preferred_vendor/get`);
};
export const valListApi = () => {
  return api.get(`/valuationMethod/get`);
};

export const uomGroupListApi = (params) => {
  return api.get(`/settings/uom-groups`, JSON.stringify(params));
};
export const uomGroupFindOneApi = (params) => {
  return api.get(`/settings/uom-group-items?group_id=${params}`);
};
export const imageUploadApi = (params) => {
  return api.post(`/image/upload`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const adminRolesApi = () => {
  return api.get(`/user/permission/get`);
};
export const departmentListApi = () => {
  return api.get(`/department/get`);
};
export const RolesListApi = () => {
  return api.get(`/roles/get`);
};
export const UsersListApi = (data) => {
  return api.get(`/user/getUsers?role=${data}`);
};

export const cusTypeListApi = () => {
  return api.get(`/api/cpos/customerType/get`);
};
export const acqSrcListApi = () => {
  return api.get(`/api/cpos/acquiredSource/get`);
};
export const buisnessTypeListApi = () => {
  return api.get(`/api/cpos/bussinessType/get`);
};
export const bnkNameListApi = () => {
  return api.get(`/api/cpos/bank/list`);
};
export const disCusListApi = () => {
  return api.get(`/customerGroup/get`);
};
export const disCusGrpListApi = () => {
  return api.get(`/discountType/get`);
};

export const customerListApi = (params) => {
  // return api.get(`/vendor/get?field=${params?.field}&value=${params?.value}`);
  return api.get(
    `/customer/getByType?field=${params?.field}&value=${params?.value}&sortfield=is_vendor_cmr&sortValue=${params?.isVendor}`
  );
};
export const pincodeListApi = (params) => {
  return fetch(`https://api.postalpincode.in/pincode/${parseInt(params)}`);
};
export const binLocationListApi = () => {
  return api.get(`/warehouse/getAllBinLocation`);
};
export const wareHouseListApi = () => {
  return api.get(`/stock/transfer/warehouselist`);
};
export const storeListApi = () => {
  return api.get(`/storelist/getdropDown`);
};
export const transactionShareApi = (params) => {
  return api.post(`/order/invoice/share`, JSON.stringify(params));
};

export const transactionPrintApi = (params) => {
  if (params?.transaction_type === "Billing") {
    return api.get(`/order/getOrderByIdAsPdf?sot_id=${params?.transaction_id}`);
  } else if (params?.transaction_type === "invoice_credit_note") {
    return api.get(
      `/order/invoice_credit_note/getOrderByIdHTML?icn_id=${params?.transaction_id}`
    );
  } else if (params?.transaction_type === "sales_order") {
    return api.get(`/saleorder/getByIdHTML?sott_id=${params?.transaction_id}`);
  } else if (params?.transaction_type === "sales_delivery") {
    return api.get(
      `/saleorder/delivery/getOrderByIdHTML?sodtt_id=${params?.transaction_id}`
    );
  } else if (params?.transaction_type === "sales_invoice") {
    return api.get(
      `/saleorder/invoice/getByIdHTML?soit_id=${params?.transaction_id}`
    );
  } else if (params?.transaction_type === "sales_credit_note") {
    return api.get(
      `/saleorder/creditNote/getByIdHTML?sct_id=${params?.transaction_id}`
    );
  } else if (params?.transaction_type === "sales_return") {
    return api.get(
      `/saleorder/returnInvoice/getByIdHTML?srt_id=${params?.transaction_id}`
    );
  } else if (params?.transaction_type === "purchase_order") {
    return api.get(
      `/purchaseorder/getOrderByIdHTML?pot_id=${params?.transaction_id}`
    );
  } else if (params?.transaction_type === "goods_receipt_note") {
    return api.get(
      `/purchaseorder/gop/getOrderByIdHTML?gort_id=${params?.transaction_id}`
    );
  } else if (params?.transaction_type === "purchase_invoice") {
    return api.get(
      `/purchaseorder/invoice/getOrderByIdHTML?poit_id=${params?.transaction_id}`
    );
  } else if (params?.transaction_type === "purchase_credit_note") {
    return api.get(
      `/purchaseorder/creditNote/getOrderByIdHTML?pct_id=${params?.transaction_id}`
    );
  } else if (params?.transaction_type === "purchase_return") {
    return api.get(
      `/purchaseorder/return/getOrderByIdHTML?prt_id=${params?.transaction_id}`
    );
  }
};
