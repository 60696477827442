import React, { useEffect, useState } from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import { Form, Formik } from "formik";
import AddButton, { DraftButton } from "../../components/buttons/button";
import * as Yup from "yup";
import SelectField from "../../components/forms/select-field";
import { useDispatch } from "react-redux";
import {
  financialAccAllocUpdateAction,
  financialAccListAction,
  financialAllAccountAction,
  financialPeriodSelectionAction,
} from "../../redux/actions/financial-action";
import TabsComponent from "../../components/helper-components/tabs-component";
import { formatString } from "../../components/helper-components/helper-function";
import SearchAddFeild from "../../components/forms/search-add-field";
import errorToast from "../../components/helper-components/error-toast";
import LoadingSpinner from "../../components/helper-components/loading-spinner";
import successToast from "../../components/helper-components/success-toast";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

const GlAccountAllocation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [yearList, setYearList] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [currentTab, setCurrentTab] = useState("General");
  const [localData, setLocalData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async (inputValue, callback, field, values) => {
    const onSuccess = (data) => {
      const options =
        data?.data === null
          ? []
          : data.data
              .map((item) => ({
                value: item.account_id,
                label:
                  field === "account_code"
                    ? item?.account_code
                    : item?.account_name,
              }))
              .filter(
                (option) =>
                  !values?.data?.some((item) => item?.item_id === option.value)
              );
      setLocalData(data.data);
      callback(options);
    };
    const onError = (data) => {
      callback([]);
    };
    if (inputValue?.length > 0) {
      dispatch(
        financialAccListAction(
          {
            field: field,
            value: inputValue,
          },
          onSuccess,
          onError
        )
      );
    }
  };

  useEffect(() => {
    dispatch(financialPeriodSelectionAction(onSuccessYears, onErrorYears));
  }, []);

  const onSuccessYears = (data) => {
    setYearList(
      data?.data.map((year) => ({
        label: year?.period_category,
        value: year?.gcct_id,
      }))
    );
  };
  const onErrorYears = (data) => {};
  const initialValues = {
    gcct_id: accountList?.[0]?.gcct_id ?? "",
    data: accountList,
  };
  const validationSchema = Yup.object().shape({});

  function fetchAllAccFn(id, currentTab) {
    setLoading(true);
    dispatch(
      financialAllAccountAction(
        {
          gcct_id: id,
          tab: currentTab,
        },
        (data) => {
          setAccountList(data?.data);
          setLoading(false);
        },
        onError
      )
    );
  }

  const onError = (data) => {
    setLoading(false);
    errorToast(data?.message);
    setAccountList([]);
  };

  const onSubmit = (values) => {
    const mergedData = values.data.reduce((acc, item) => {
      // Destructure gcct_id and the rest of the properties
      const { gcct_id, account_code, account_name, ...rest } = item;
      // Merge properties into the accumulator object
      return {
        ...acc,
        ...rest,
        tab:
          currentTab === "General"
            ? "general_tab"
            : currentTab === "Sales"
            ? "sale_tab"
            : currentTab === "Purchasing"
            ? "purchase_tab"
            : "inventory_tab",
      };
    }, {});

    dispatch(
      financialAccAllocUpdateAction(
        { gcct_id: values?.gcct_id, ...mergedData },

        (data) => {
          successToast(data?.data?.message);
          fetchAllAccFn(
            values?.gcct_id,
            currentTab === "General"
              ? "general_tab"
              : currentTab === "Sales"
              ? "sale_tab"
              : currentTab === "Purchasing"
              ? "purchase_tab"
              : "inventory_tab"
          );
        },
        onSubmitError
      )
    );
  };

  const onSubmitError = (data) => {};

  return (
    <InnerLayout title="G/L Account Allocation">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form className="h-full pt-5">
              <div className="pl-4 pb-5 flex items-center gap-2">
                <button
                  className="flex items-center gap-2"
                  onClick={() => {
                    navigate("/settings");
                  }}
                >
                  <ChevronLeftIcon className="h-5 w-5 inline-block" />
                  Settings /
                </button>
                <span className="cursor-pointer">
                  {" " + "Account Allocation"}
                </span>
              </div>
              <div className="flex flex-col justify-between h-full ">
                <div>
                  <div className="2xl:w-[35%] xl:w-[60%] lg:w-[70%] w-full px-10">
                    <SelectField
                      handleSelected={(selected) => {
                        fetchAllAccFn(
                          selected?.value,
                          currentTab === "General"
                            ? "general_tab"
                            : currentTab === "Sales"
                            ? "sale_tab"
                            : currentTab === "Purchasing"
                            ? "purchase_tab"
                            : "inventory_tab"
                        );
                      }}
                      placeholder="Select item"
                      options={yearList}
                      label="Period Selection"
                      id="gcct_id"
                      name="gcct_id"
                      isSearchable={true}
                      errMsgContainerClass="ps-2"
                      isRequired={true}
                      labelWidth="10rem"
                    />
                  </div>
                  <TabsComponent
                    handleClickfn={(item) => {
                      setCurrentTab(item);
                      fetchAllAccFn(
                        values?.gcct_id,
                        item === "General"
                          ? "general_tab"
                          : item === "Sales"
                          ? "sale_tab"
                          : item === "Purchasing"
                          ? "purchase_tab"
                          : "inventory_tab"
                      );
                    }}
                    itemTabs={itemTabs}
                    setCurrentTab={setCurrentTab}
                    currentTab={currentTab}
                  />
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <div className="overflow-x-auto overflow-y-hidden">
                      <table className="price-my-tab h-full">
                        <thead>
                          <tr>
                            {tableHeading.map((heading, index) => (
                              <th
                                key={index}
                                className={`${"price-tab-header"} ${
                                  heading?.label === "Action" &&
                                  "flex justify-end pe-10"
                                } whitespace-nowrap fs_12 fw_500 text-475467 bg-F2F4F5`}
                              >
                                {heading?.label}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="h-full">
                          {values?.data?.length > 0 ? (
                            <>
                              {values?.data?.map((acc, index) => (
                                <tr>
                                  <td
                                    className={`price-tab-cell w-[30%] text-sm fw_700 text-secondary whitespace-nowrap`}
                                  >
                                    {formatString(
                                      Object.keys(acc).find(
                                        (key) =>
                                          key !== "account_name" &&
                                          key !== "account_code" &&
                                          key !== "gcct_id"
                                      )
                                    )}
                                  </td>
                                  <td
                                    className={`price-tab-cell  w-[37%] text-sm fw_400 text-secondary whitespace-nowrap`}
                                  >
                                    <div
                                      style={{
                                        maxWidth: "15rem",
                                      }}
                                    >
                                      <SearchAddFeild
                                        placeholder="Enter Code"
                                        values={values}
                                        // searchRef={searchRef}
                                        // isDisabled={
                                        //   values?.data?.length - 1 !== index ||
                                        //   disabled
                                        //     ? true
                                        //     : false
                                        // }
                                        errorClass="hidden"
                                        isRequired={false}
                                        value={{
                                          label: values?.data[index]
                                            ?.account_code ?? (
                                            <span className="opacity-50">
                                              Account code
                                            </span>
                                          ),
                                          value: "",
                                        }}
                                        name={`data[${index}].account_code`}
                                        field={"account_code"}
                                        isLabelReq={false}
                                        defaultSelectClass=""
                                        fetchData={fetchData}
                                        onChange={async (selectedOption) => {
                                          const filteredData = localData?.find(
                                            (item) =>
                                              item?.account_id ===
                                              selectedOption?.value
                                          );
                                          setFieldValue(
                                            `data[${index}].account_code`,
                                            selectedOption?.label
                                          );
                                          setFieldValue(
                                            `data[${index}].account_name`,
                                            filteredData?.account_name
                                          );
                                          setFieldValue(
                                            `data[${index}].${Object.keys(
                                              acc
                                            ).find(
                                              (key) =>
                                                key !== "account_name" &&
                                                key !== "account_code" &&
                                                key !== "gcct_id"
                                            )}`,
                                            selectedOption?.value
                                          );
                                        }}
                                      />
                                    </div>
                                  </td>
                                  <td
                                    className={`price-tab-cell  w-[33%] text-sm fw_400 text-secondary whitespace-nowrap`}
                                  >
                                    <div
                                      style={{
                                        maxWidth: "15rem",
                                      }}
                                    >
                                      <SearchAddFeild
                                        placeholder="Enter Code"
                                        values={values}
                                        errorClass="hidden"
                                        isRequired={false}
                                        value={{
                                          label: values?.data[index]
                                            ?.account_name ?? (
                                            <span className="opacity-50">
                                              Account name
                                            </span>
                                          ),
                                          value: "",
                                        }}
                                        name={`data[${index}].account_name`}
                                        field={"account_name"}
                                        isLabelReq={false}
                                        defaultSelectClass=""
                                        fetchData={fetchData}
                                        onChange={async (selectedOption) => {
                                          const filteredData = localData?.find(
                                            (item) =>
                                              item?.account_id ===
                                              selectedOption?.value
                                          );
                                          setFieldValue(
                                            `data[${index}].account_name`,
                                            selectedOption?.label
                                          );
                                          setFieldValue(
                                            `data[${index}].account_code`,
                                            filteredData?.account_code
                                          );
                                          setFieldValue(
                                            `data[${index}].${Object.keys(
                                              acc
                                            ).find(
                                              (key) =>
                                                key !== "account_name" &&
                                                key !== "account_code" &&
                                                key !== "gcct_id"
                                            )}`,
                                            selectedOption?.value
                                          );
                                        }}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <tr
                              style={{
                                height: "20rem",
                              }}
                            >
                              <td
                                colSpan={tableHeading.length + 1}
                                className="text-center text-primary"
                              >
                                No Items found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    bottom: "0",
                  }}
                  className="flex justify-between px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
                >
                  <DraftButton type="reset" text="Cancel" />
                  <AddButton prefixIcon={false} text="Save" type="submit" />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </InnerLayout>
  );
};

export default GlAccountAllocation;

const itemTabs = ["General", "Sales", "Purchasing", "Inventory"];
const tableHeading = [
  {
    label: "TYPE OF ACCOUNT",
  },
  {
    label: "ACCOUNT CODE",
  },
  {
    label: "ACCOUNT NAME",
  },
];
