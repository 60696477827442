import React from 'react';
export function SidebarItem({ title, icon: IconComponent, isActive, onClick }) {
  return (
    <li 
      className={`flex items-center gap-2 px-4 py-2 cursor-pointer hover:bg-f3f8ff rounded-md transition-colors
        ${isActive ? 'bg-blue-50' : ''}`}
      onClick={onClick}
    >
      {IconComponent && <IconComponent className="w-4 h-4 text-667085" />}
      <span className="text-gray-700">{title}</span>
    </li>
  );
}