import { Formik } from "formik";
import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import SelectField from "../forms/select-field";
import InputField from "../forms/input-field";
import AddButton from "../buttons/button";
import {
  adminCustomerBnkDetailsAction,
  adminCustomerUpdateBnkDetailsAction,
  adminCustomerUpdateContactPersonAction,
} from "../../redux/actions/admin-customer-action";
import successToast from "../helper-components/success-toast";
import { useDispatch } from "react-redux";
import { bnkNameListAction } from "../../redux/actions/general-action";

const BankDetailsTab = ({
  setCurrentTab,
  customerGeneralData,
  customerId,
  setReload,
  reload,
  customerDetails,
  isDisabled = false,
}) => {
  const dispatch = useDispatch();
  const [bankList, setBankList] = useState([]);

  useEffect(() => {
    dispatch(bnkNameListAction(onSuccessBnk, onErrorBnk));
    // dispatch(buisnessTypeListAction(onSuccessBuisness, onErrorBuisness));
    // dispatch(acqSrcListAction(onSuccessAcqSrc, onErrorSrc));
    // dispatch(cusTypeListAction(onSuccessAcqSrc, onErrorSrc));
  }, []);

  const onSuccessBnk = (data) => {
    console.log(data, "sggfsdgsdgsgsgdgsd");
    setBankList(data?.data?.customerType);
  };
  const onErrorBnk = (data) => {};
  const initialValues = {
    cmr_id: customerId,
    cmr_bank_name: customerDetails.cmr_bank_name || "",
    cmr_account_no: customerDetails.cmr_account_no || "",
    cmr_bank_branch: customerDetails.cmr_bank_branch || "",
    cmr_ifsc_code: customerDetails.cmr_ifsc_code || "",
  };

  const validationSchema = Yup.object().shape({
    cmr_bank_name: Yup.string().required("Bank name is required"),
    cmr_account_no: Yup.string()
      .matches(/^[0-9]{9,18}$/, "Account number must be 9 to 18 digits")
      .required("Account number is required"),
    cmr_bank_branch: Yup.string().required("Branch name is required"),
    cmr_ifsc_code: Yup.string().required("IFSC code is required"),
  });
  const innerOnSubmit = (values) => {
    let actionType = adminCustomerBnkDetailsAction;
    if (customerDetails?.cmr_bank_name) {
      actionType = adminCustomerUpdateBnkDetailsAction;
    }

    dispatch(
      actionType(
        {
          customerGeneralData: customerGeneralData,
          customerBankData: values,
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = (data) => {
    successToast(data?.message);
    setCurrentTab("Bank Details");
    setReload(!reload);
  };
  const onError = (data) => {};

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={innerOnSubmit}
    >
      {({ values, handleSubmit, errors }) => {
        return (
          <>
            <div className="flex flex-wrap flex-1">
              <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10">
                <SelectField
                  isDisabled={isDisabled}
                  placeholder="Select Bank"
                  options={bankList}
                  label="Bank Name"
                  id="cmr_bank_name"
                  name="cmr_bank_name"
                  isSearchable={true}
                  errMsgContainerClass="ps-2"
                  isRequired={true}
                  labelWidth="10rem"
                />
                <InputField
                  isDisabled={isDisabled}
                  type="number"
                  name="cmr_account_no"
                  placeholder="xxxxxxxxx"
                  label="Account Number"
                  isRequired={true}
                  errMsgContainerClass="ps-2"
                  labelWidth="10rem"
                />
                <SelectField
                  isDisabled={isDisabled}
                  placeholder="Select Branch"
                  options={BranchList}
                  label="Branch Name"
                  id="cmr_bank_branch"
                  name="cmr_bank_branch"
                  isSearchable={true}
                  errMsgContainerClass="ps-2"
                  isRequired={true}
                  labelWidth="10rem"
                />
                <InputField
                  isDisabled={isDisabled}
                  type="text"
                  name="cmr_ifsc_code"
                  placeholder="xxxxxx"
                  label="IFSC Code"
                  isRequired={true}
                  errMsgContainerClass="ps-2"
                  labelWidth="10rem"
                />
              </div>
            </div>

            {!isDisabled && (
              <div
                style={{
                  bottom: "0",
                }}
                className="flex w-full justify-end px-10 mb-5x sticky bg-white py-4 invoice-bottom-container mt-auto"
              >
                <AddButton
                  onclkFn={handleSubmit}
                  prefixIcon={false}
                  text="Save"
                  type="submit"
                />
              </div>
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default BankDetailsTab;

const BankList = [
  { value: "SBI", label: "SBI" },
  { value: "HDFC", label: "HDFC" },
  { value: "ICICI", label: "ICICI" },
  { value: "AXIS", label: "AXIS" },
  { value: "PNB", label: "PNB" },
  { value: "BOB", label: "BOB" },
  { value: "IDBI", label: "IDBI" },
  { value: "KOTAK", label: "KOTAK" },
];

const BranchList = [
  { value: "Vijayawada", label: "vijayawada" },
  { value: "Hyderabad", label: "Hyderabad" },
];
