import React, { useEffect, useState } from "react";
import {
  customStylesForDateRange,
  customStylesForExports,
} from "../../../components/reports/reports-layout";
import { gstr3bReportAction } from "../../../redux/actions/report-action";
import { useDispatch } from "react-redux";
import moment from "moment";
import InnerLayout from "../../../components/layouts/inner-layout";
import LoadingSpinner from "../../../components/helper-components/loading-spinner";
import FilterIcon from "../../../assets/icons/filter-icon";
import Select from "react-select";
import AddButton from "../../../components/buttons/button";
import exportFromJSON from "export-from-json";
import ClearIcon from "../../../assets/icons/clear-icon";
import {
  formatString,
  hexToRgba,
} from "../../../components/helper-components/helper-function";
import html2pdf from "html2pdf.js";
const Gstr3bReport = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [heightOfFirstDiv, setHeightOfFirstDiv] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState({
    filter_by: "",
    from: null,
    to: null,
  });
  const initFilter = {
    filter_by: "",
    from: null,
    to: null,
  };
  useEffect(() => {
    handleApply();
  }, []);

  const isIso8601Date = (dateString) => {
    const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    return iso8601Regex.test(dateString);
  };
  const formatDate = (value) => {
    return moment(value).format("DD-MM-YYYY");
  };
  const transformData = (data) => {
    return data.map((item) => {
      let transformedItem = {};
      for (let [key, value] of Object.entries(tableColoumsn)) {
        const rawValue = item[value.split(".").pop()];
        transformedItem[key] = isIso8601Date(rawValue)
          ? formatDate(rawValue)
          : rawValue;
      }
      return transformedItem;
    });
  };
  const onSuccess = (data) => {
    setTableData(data?.data);
    const transformedData = transformData(data?.data);
    setLoading(false);
  };
  const onError = (data) => {
    setLoading(false);
  };

  const filterDateRange = [
    { label: "First Quarter", value: "first_quarter" },
    { label: "Second Quarter", value: "second_quarter" },
    { label: "Third Quarter", value: "third_quarter" },
    { label: "Fourth Quarter", value: "fourth_quarter" },
    { label: "Custom Date", value: "custom_date" },
    { label: "This Month", value: "this_month" },
    { label: "This Year", value: "this_year" },
    { label: "All", value: "" },
  ];

  const handleApply = () => {
    dispatch(gstr3bReportAction(filters, onSuccess, onError));
    setLoading(true);
  };

  const fileName = "report";
  const exportType = exportFromJSON.types.csv;
  const header = "detailsofOutwardSupplies3_1";

  const onClickExportCsv = (opt) => {
    if (opt?.value === "csv") {
      const columns = Object.keys(tableData.detailsofOutwardSupplies3_1[0]);
      const csvData = [
        {
          "3.1 Details of Outward Supplies and inward supplies liable to reverse charge":
            "",
        }, // Title row
      ];
      tableData.detailsofOutwardSupplies3_1.forEach((row) => {
        csvData.push(Object.fromEntries(columns.map((col) => [col, row[col]])));
      });

      exportFromJSON({
        data: csvData,
        fileName: "outward_supplies",
        exportType: "csv",
      });
    } 
    else if (opt?.value === "pdf") {
      const element = document.querySelector(".pdf-content");
      const hiddenElements = document.querySelectorAll(".hidden");
      hiddenElements.forEach((el) => {
        el.style.display = "block"; // or `el.style.visibility = 'visible';`
      });
      const options = {
        margin: [0.5, 0.5, 0.5, 0.5], // Margins in inches
        filename: "gstr3b_report.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      };
      html2pdf()
        .set(options)
        .from(element)
        .save()
        .then(() => {
          hiddenElements.forEach((el) => {
            el.style.display = "none"; // or `el.style.visibility = 'hidden';`
          });
        });
    }
  };

  useEffect(() => {
    const updateHeight = () => {
      const firstDivHeight =
        document.querySelector(".report-3b-header")?.clientHeight;
      setHeightOfFirstDiv(firstDivHeight);
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const getBackgroundColor = (isActive) => {
    const primaryHex = getComputedStyle(document.documentElement)
      .getPropertyValue("--primary")
      .trim();
    return hexToRgba(primaryHex, 0.15);
  };

  return (
    <InnerLayout
      backBtn={true}
      module="invoice"
      list={true}
      onclkFn={() => {}}
      title={"GSTR 3B Report"}
    >
      <div className=" flex justify-between mx-6 flex-wrap report-3b-header">
        <div className="relative flex gap-3 pt-2">
          <button
            style={{
              height: "2.6rem",
            }}
            onClick={() => null}
            className="flex items-center whitespace-nowrap fs_12 rounded-md px-3 gap-2 text-344054 fw_600"
          >
            <FilterIcon />
            {"Filters"}
          </button>
          <div
            style={{ maxWidth: "16rem", height: "2.6rem" }}
            className={`w-full flex items-center rounded-md input_border`}
          >
            <span className="mx-2 whitespace-nowrap fs_12  text-344054 fw_600">
              Date Range:
            </span>
            <Select
              className="border-0"
              menuPortalTarget={document.body}
              styles={customStylesForDateRange}
              value={filterDateRange.find(
                (item) => item.value === filters?.filter_by
              )}
              options={filterDateRange}
              onChange={(selectedOption) => {
                setFilters({
                  ...filters,
                  filter_by: selectedOption?.value,
                });
              }}
            />
          </div>

          {filters?.filter_by === "custom_date" && (
            <div
              style={{
                width: "fit-content",
                height: "2.6rem",
              }}
              className="input_border rounded-md text-344054 fw_600 flex items-center md:flex-wrap"
            >
              <div className="px-1 flex items-center">
                <span className="fs_12">From:</span>
                <input
                  style={{
                    width: "7rem",
                  }}
                  placeholder="DD-MM-YYYY"
                  className=" table_search_input w-full rounded-md mr-4 ml-1 fs_12 placeholder:text-fs_12"
                  type="date"
                  value={filters?.from}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      from: e.target.value,
                    });
                  }}
                />
                <span className="fs_12">To:</span>
                <input
                  style={{
                    width: "7rem",
                  }}
                  placeholder="DD-MM-YYYY"
                  className=" table_search_input w-full rounded-md ml-1 fs_12 placeholder:text-fs_12"
                  type="date"
                  value={filters?.to}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      to: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          )}

          <div>
            <AddButton
              onclkFn={handleApply}
              prefixIcon={false}
              text="Apply"
              type="submit"
            />
          </div>
        </div>
        <div className="flex gap-3 pt-2">
          <div
            style={{ minWidth: "11.5rem", height: "2.6rem" }}
            className={`w-full flex items-center py-3 rounded-md input_border `}
          >
            <span className="mx-2 whitespace-nowrap fs_12   text-344054 fw_600">
              Export
            </span>
            <Select
              className="w-full text-nowrap"
              menuPortalTarget={document.body}
              styles={customStylesForExports}
              options={exportOptions}
              onChange={onClickExportCsv}
              placeholder=""
            />
          </div>
          <button
            style={{
              height: "2.6rem",
            }}
            onClick={() => {
              setFilters(initFilter);
            }}
            className={`px-4 whitespace-nowrap fs_12  fw_600 rounded-md input_border flex items-center gap-2  h-full
            ${
              JSON.stringify(filters) === JSON.stringify(initFilter) &&
              "bg-disabled-color"
            }
            `}
          >
            <ClearIcon />
            Clear All
          </button>
        </div>
      </div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div
            className="px-5 pdf-content"
            style={{
              maxWidth: "100%",
              overflowX: "auto",
              overflowY: "auto",
              maxHeight: `calc(100% - ${heightOfFirstDiv}px)`,
              height: `calc(100% - ${heightOfFirstDiv}px)`,
            }}
          >
            <div className="w-full mt-4">
              <div className="w-full flex justify-center ">
                <div className="flex justify-center flex-col">
                  <h1 className="text-center fw_600">GSTR-3B-Summary</h1>
                  {filters?.filter_by === "custom_date" && (
                    <p className="fw_300 mt-2">
                      From: {filters?.from} {"  "} To: {filters?.to}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <h1 className="mt-5 text-000000 fs_18 fw_300">
              3.1 Details of Outward Supplies and inward supplies liable to
              reverse charge
            </h1>
            <table className="w-full  rounded-lg mt-3">
              <thead>
                <tr className="bg-F2F4F5">
                  {tableData?.detailsofOutwardSupplies3_1?.length > 0 &&
                    Object.keys(tableData.detailsofOutwardSupplies3_1[0]).map(
                      (key) => (
                        <th
                          key={key}
                          className="py-2 px-3 text-left text-475467 fw_400 fs_12 border-EAECF0 border"
                        >
                          {formatString(key)}
                        </th>
                      )
                    )}
                </tr>
                <tr
                  style={{
                    backgroundColor: getBackgroundColor(true),
                  }}
                  className="bg-primary "
                >
                  {tableData?.detailsofOutwardSupplies3_1?.length > 0 &&
                    Object.keys(tableData.detailsofOutwardSupplies3_1[0]).map(
                      (key, index) => (
                        <th
                          key={key}
                          className="py-2 px-3 text-left text-475467 fw_400 fs_12 border-EAECF0 border"
                        >
                          {index + 1}
                        </th>
                      )
                    )}
                </tr>
              </thead>
              <tbody>
                {tableData?.detailsofOutwardSupplies3_1?.map((row, index) => (
                  <tr key={index} className="bg-white border-EAECF0 border">
                    {Object.values(row).map((value, colIndex) => (
                      <td
                        key={`${index}-${colIndex}`}
                        className="py-2 px-3 text-left text-475467 fw_400 fs_12 border-EAECF0 border"
                      >
                        {value}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <h1 className="mt-10 text-000000 fs_18 fw_300 ">
              3.1.1 Details of Supplies under sub-section(5) of section 9 of the
              central Goods and Services Tax Act
            </h1>
            <table className="w-full  rounded-lg mt-3">
              <thead>
                <tr className="bg-F2F4F5">
                  {tableData?.detailsofOutwardSupplies3_2?.length > 0 &&
                    Object.keys(tableData.detailsofOutwardSupplies3_2[0]).map(
                      (key) => (
                        <th
                          key={key}
                          className="py-2 px-3 text-left text-475467 fw_400 fs_12 border-EAECF0 border"
                        >
                          {formatString(key)}
                        </th>
                      )
                    )}
                </tr>
                <tr
                  style={{
                    backgroundColor: getBackgroundColor(true),
                  }}
                  className="bg-primary "
                >
                  {tableData?.detailsofOutwardSupplies3_2?.length > 0 &&
                    Object.keys(tableData.detailsofOutwardSupplies3_2[0]).map(
                      (key, index) => (
                        <th
                          key={key}
                          className="py-2 px-3 text-left text-475467 fw_400 fs_12 border-EAECF0 border"
                        >
                          {index + 1}
                        </th>
                      )
                    )}
                </tr>
              </thead>
              <tbody>
                {tableData?.detailsofOutwardSupplies3_2?.map((row, index) => (
                  <tr key={index} className="bg-white border-EAECF0 border">
                    {Object.values(row).map((value, colIndex) => (
                      <td
                        key={`${index}-${colIndex}`}
                        className="py-2 px-3 text-left text-475467 fw_400 fs_12 border-EAECF0 border"
                      >
                        {value}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <h1 className="mt-10 text-000000 fs_18 fw_300 new-section">
              3.2 of the Supplies shown in 3.1 (a) above, details of inter-state
              supplies made to unregistered persons, composition taxable persons
              and UIN holders
            </h1>
            <table className="w-full  rounded-lg mt-3">
              <thead>
                <tr className="bg-F2F4F5">
                  {tableData?.detailsofOutwardSupplies3_3?.length > 0 &&
                    Object.keys(tableData.detailsofOutwardSupplies3_3[0]).map(
                      (key) => (
                        <th
                          key={key}
                          className="py-2 px-3 text-left text-475467 fw_400 fs_12 border-EAECF0 border"
                        >
                          {formatString(key)}
                        </th>
                      )
                    )}
                </tr>
                <tr
                  style={{
                    backgroundColor: getBackgroundColor(true),
                  }}
                  className="bg-primary "
                >
                  {tableData?.detailsofOutwardSupplies3_3?.length > 0 &&
                    Object.keys(tableData.detailsofOutwardSupplies3_3[0]).map(
                      (key, index) => (
                        <th
                          key={key}
                          className="py-2 px-3 text-left text-475467 fw_400 fs_12 border-EAECF0 border"
                        >
                          {index + 1}
                        </th>
                      )
                    )}
                </tr>
              </thead>
              <tbody>
                {tableData?.detailsofOutwardSupplies3_3?.map((row, index) => (
                  <tr key={index} className="bg-white border-EAECF0 border">
                    {Object.values(row).map((value, colIndex) => (
                      <td
                        key={`${index}-${colIndex}`}
                        className="py-2 px-3 text-left text-475467 fw_400 fs_12 border-EAECF0 border"
                      >
                        {value}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <h1 className="mt-10 text-000000 fs_18 fw_300">4.Eligible ITC</h1>
            <table className="w-full  rounded-lg mt-3">
              <thead>
                <tr className="bg-F2F4F5">
                  {tableData?.detailsofOutwardSupplies4?.length > 0 &&
                    Object.keys(tableData.detailsofOutwardSupplies4[0]).map(
                      (key) => (
                        <th
                          key={key}
                          className="py-2 px-3 text-left text-475467 fw_400 fs_12 border-EAECF0 border"
                        >
                          {formatString(key)}
                        </th>
                      )
                    )}
                </tr>
                <tr
                  style={{
                    backgroundColor: getBackgroundColor(true),
                  }}
                  className="bg-primary "
                >
                  {tableData?.detailsofOutwardSupplies4?.length > 0 &&
                    Object.keys(tableData.detailsofOutwardSupplies4[0]).map(
                      (key, index) => (
                        <th
                          key={key}
                          className="py-2 px-3 text-left text-475467 fw_400 fs_12 border-EAECF0 border"
                        >
                          {index + 1}
                        </th>
                      )
                    )}
                </tr>
              </thead>
              <tbody>
                {tableData?.detailsofOutwardSupplies4?.map((row, index) => {
                  if (row !== null) {
                    return (
                      <tr key={index} className="bg-white border-EAECF0 border">
                        {Object.values(row).map((value, colIndex) => (
                          <td
                            key={`${index}-${colIndex}`}
                            className="py-2 px-3 text-left text-475467 fw_400 fs_12 border-EAECF0 border"
                          >
                            {value}
                          </td>
                        ))}
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
            <h1 className="mt-10 text-000000 fs_18 fw_300">
              5. Values of exempt, nil-rated and non-Gst inwars supplies
            </h1>
            <table className="w-full  rounded-lg mt-3">
              <thead>
                <tr className="bg-F2F4F5">
                  {tableData?.detailsofOutwardSupplies5?.length > 0 &&
                    Object.keys(tableData.detailsofOutwardSupplies5[0]).map(
                      (key) => (
                        <th
                          key={key}
                          className="py-2 px-3 text-left text-475467 fw_400 fs_12 border-EAECF0 border"
                        >
                          {formatString(key)}
                        </th>
                      )
                    )}
                </tr>
                <tr
                  style={{
                    backgroundColor: getBackgroundColor(true),
                  }}
                  className="bg-primary "
                >
                  {tableData?.detailsofOutwardSupplies5?.length > 0 &&
                    Object.keys(tableData.detailsofOutwardSupplies5[0]).map(
                      (key, index) => (
                        <th
                          key={key}
                          className="py-2 px-3 text-left text-475467 fw_400 fs_12 border-EAECF0 border"
                        >
                          {index + 1}
                        </th>
                      )
                    )}
                </tr>
              </thead>
              <tbody>
                {tableData?.detailsofOutwardSupplies5?.map((row, index) => {
                  if (row !== null) {
                    return (
                      <tr key={index} className="bg-white border-EAECF0 border">
                        {Object.values(row).map((value, colIndex) => (
                          <td
                            key={`${index}-${colIndex}`}
                            className="py-2 px-3 text-left text-475467 fw_400 fs_12 border-EAECF0 border"
                          >
                            {value}
                          </td>
                        ))}
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </InnerLayout>
  );
};

const tableHeading = [
  "Taxable Value (B2B)", // New entry
  "Taxable Value (B2C)", // New entry
  "Taxable Value (Exports)", // New entry
  "SGST Amount", // Already present
  "CGST Amount", // Already present
  "IGST Amount", // Already present
  "Cess Amount", // Already present
  "Total Tax Liability", // New entry
  "ITC Claimed", // New entry
  "ITC Utilized", // New entry
  "Tax Paid (Cash/ITC)", // New entry
  "Interest/Penalty", // New entry
];
const exportOptions = [
  // { label: "CSV", value: "csv" },
  { label: "Export PDF", value: "pdf" },
];

const tableColoumsn = {
  "Taxable Value (B2B)": "so.taxable_value_b2b", // New entry for B2B taxable value
  "Taxable Value (B2C)": "so.taxable_value_b2c", // New entry for B2C taxable value
  "Taxable Value (Exports)": "so.taxable_value_exports", // New entry for Exports taxable value
  "SGST Amount": "so.sot_sgst_amount", // Already present
  "CGST Amount": "so.sot_cgst_amount", // Already present
  "IGST Amount": "so.sot_igst_amount", // Already present
  "Cess Amount": "so.sot_cess_amount", // Already present
  "Total Tax Liability": "so.total_tax_liability", // New entry for total tax liability
  "ITC Claimed": "so.itc_claimed", // New entry for ITC claimed
  "ITC Utilized": "so.itc_utilized", // New entry for ITC utilized
  "Tax Paid (Cash/ITC)": "so.tax_paid_cash_itc", // New entry for tax paid in cash or ITC
  "Interest/Penalty": "so.interest_penalty", // New entry for interest or penalty
};

export default Gstr3bReport;
