import React, { useState } from "react";
import InputField from "../forms/input-field";
import AsyncCreateSelect from "../forms/async-create-select-field";
import { useFormikContext } from "formik";
import { useNavigate } from "react-router-dom";
import { customerListAction } from "../../redux/actions/general-action";
import { useDispatch } from "react-redux";
import TextAreaField from "../forms/textarea-field";
import { salesPaymentInCusAction } from "../../redux/actions/sales-action";
import moment from "moment";
import errorToast from "../helper-components/error-toast";
import { purchasePaymentOutCusAction } from "../../redux/actions/purchase-action";

const PaymentInOutHeader = ({
  disabled = false,
  searchRef = {},
  section = "pit",
}) => {
  const today = new Date();
  const dispatch = useDispatch();
  const [localData, setLocalData] = useState([]);
  const navigate = useNavigate();
  const { errors, values, setFieldValue, setFieldError } = useFormikContext();
  const loadOptions = (inputValue, field) => {
    return new Promise((resolve) => {
      const onSuccess = (data) => {
        const options = [
          ...data.data.map((item) => ({
            value: item.cmr_id,
            label:
              item?.cmr_code +
              "-" +
              (item?.cmr_first_name + item?.cmr_last_name),
          })),
          {
            value: "new_customer",
            label: (
              <span className="text-primary">
                {section === "pit" ? "+ New Customer" : "+ New Vendor"}
              </span>
            ),
          },
        ];
        setLocalData(data.data);
        resolve(options);
      };

      const onError = (error) => {
        resolve([
          {
            value: "new_customer",
            label: (
              <span className="text-primary">
                {section === "pit" ? "+ New Customer" : "+ New Vendor"}
              </span>
            ),
          },
        ]);
      };

      if (inputValue?.length > 0) {
        dispatch(
          customerListAction(
            {
              field: field,
              value: inputValue,
              isVendor: section === "pit" ? false : true,
            },
            onSuccess,
            onError
          )
        );
      } else {
        resolve([]);
      }
    });
  };

  function fetchPayFn(field, value) {
    let actionType = salesPaymentInCusAction;
    if (section === "pot") {
      actionType = purchasePaymentOutCusAction;
    }
    dispatch(
      actionType(
        {
          field: field,
          value: value,
        },
        onSuccess,
        onError
      )
    );
  }
  const onSuccess = (data) => {
    const prefix = section === "pit" ? "soit" : "poit";

    setFieldValue(
      "invoices",
      data?.data?.getInvoiceResult?.map((item) => {
        const startDate = new Date(item?.[`${prefix}_delivery_date`]);
        const endDate = new Date(today);
        const differenceInTime = endDate.getTime() - startDate.getTime();
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);

        return {
          id: item?.[`${prefix}_id`],
          [`${prefix}_invoice_number`]: item?.[`${prefix}_invoice_number`],
          [`${prefix}_Doc`]:
            section === "pit" ? "Sales Invoice" : "Purchase Invoice",
          [`${prefix}_document_date`]: moment(
            item?.[`${prefix}_document_date`]
          ).format("YYYY-MM-DD"),
          [`${prefix}_delivery_date`]: moment(
            item?.[`${prefix}_delivery_date`]
          ).format("YYYY-MM-DD"),
          [`${prefix}_due_days`]: parseInt(differenceInDays),
          [`${prefix}_total_amount`]: item?.[`${prefix}_total_amount`],
          [`${prefix}_total_payment`]: item?.[`${prefix}_due_amount`] ?? 0,
          [`${prefix}_due_amount`]: item?.[`${prefix}_due_amount`] ?? 0,
        };
      }) ?? []
    );

    setFieldValue(
      `${section}_open_balance`,
      data?.data?.cmrOpenBalance?.cmr_open_balance ?? 0
    );
  };
  const onError = (data) => {
    errorToast(data?.data?.message);
  };
  return (
    <>
      <div className="w-full flex items-center">
        {/* <div className="w-full xl:w-[40%]">
          <RadioButton
            disabled={disabled}
            mainContainerClass="flex gap-8 w-full"
            containerClass="flex"
            inputLabelClass="ms-2"
            onChange={(e) => {
              const { value } = e.target;
              setFieldValue("is_customer", value === "false" ? false : true);
            }}
            labelClass="whitespace-nowrap flex text-sm fw_600  gap-2 min-w-[8.5rem]"
            name="is_customer"
            options={activeOptns}
            label="Select One"
          />
        </div> */}
        {/* <div className="w-full xl:w-[30%] flex flex-col gap-4  px-9">
          <InputField
            labelClass="whitespace-nowrap flex text-sm fw_600 text-secondary"
            containerClass=""
            isDisabled={disabled}
            errMsgContainerClass="hidden"
            type="date"
            name={`${section}_posting_date`}
            label="Posting Date"
            labelWidth="9rem"
          />
        </div>
        <div className="w-full xl:w-[30%]">
      
        </div> */}
      </div>
      <div className="w-full flex items-center flex-wrap">
        <div className="w-full xl:w-[40%] flex flex-col gap-2 ">
          <AsyncCreateSelect
            className={`z-30 ${
              errors?.cmr_code && !values?.cmr_code
                ? "selectField_border__error"
                : "selectField_border"
            }`}
            errorClass="hidden"
            disabled={disabled}
            marginRight=""
            minWidth="8.5rem"
            cusType={section === "pit" ? "Customer" : "Vendor"}
            labelClass="whitespace-nowrap flex text-sm fw_600  gap-2"
            type="text"
            label={section === "pit" ? "Customer Code" : "Vendor Code"}
            name={"cmr_code"}
            placeholder="Enter Customer / Vendor Code"
            onCreateOption={() => {}}
            loadOptions={(e) => loadOptions(e, "cmr_code")}
            onChange={(selectedOption, actionMeta) => {
              if (actionMeta?.action === "clear") {
                setFieldValue("cmr_code", "");
                setFieldValue("cmr_name", "");
                setFieldValue("invoices", []);
              } else if (
                selectedOption === "new_customer" ||
                selectedOption?.value === "new_customer"
              ) {
                navigate("/add-customer");
              } else {
                const customerData = localData?.find(
                  (item) => item?.cmr_id === selectedOption?.value
                );

                fetchPayFn("cmr_id", customerData?.cmr_id);
                setFieldValue("cmr_code", {
                  label: customerData?.cmr_code,
                  value: customerData?.cmr_id,
                });
                setFieldValue("cmr_id", customerData?.cmr_id);
                setFieldValue("cmr_name", {
                  value: customerData?.cmr_id,
                  label:
                    customerData?.cmr_first_name +
                    " " +
                    customerData?.cmr_last_name,
                });

                if (searchRef && searchRef.current) {
                  searchRef.current.focus();
                  setFieldError("cmr_name", "");
                }
              }
            }}
          />
          <AsyncCreateSelect
            menuPortalTarget={document.body}
            className={`z-20 ${
              errors?.cmr_name && !values?.cmr_name
                ? "selectField_border__error"
                : "selectField_border"
            }`}
            errorClass="hidden"
            disabled={disabled}
            minWidth="8.5rem"
            marginRight=""
            cusType={section === "pit" ? "Customer" : "Vendor"}
            labelClass="whitespace-nowrap flex text-sm fw_600   gap-2"
            type="text"
            label={section === "pit" ? "Customer Name" : "Vendor Name"}
            autoFocus={true}
            name={"cmr_name"}
            placeholder="Enter Customer / Vendor Name"
            onCreateOption={() => {}}
            loadOptions={(e) => loadOptions(e, "cmr_first_name")}
            onChange={(selectedOption, actionMeta) => {
              if (actionMeta?.action === "clear") {
                setFieldValue("cmr_code", "");
                setFieldValue("cmr_name", "");
                setFieldValue("invoices", []);
              } else if (
                selectedOption === "new_customer" ||
                selectedOption?.value === "new_customer"
              ) {
                navigate("/add-customer");
              } else {
                const customerData = localData?.find(
                  (item) => item?.cmr_id === selectedOption?.value
                );
                fetchPayFn("cmr_id", customerData?.cmr_id);
                setFieldValue("cmr_code", {
                  label: customerData?.cmr_code,
                  value: customerData?.cmr_id,
                });
                setFieldValue("cmr_id", customerData?.cmr_id);
                setFieldValue("cmr_name", {
                  value: customerData?.cmr_id,
                  label:
                    customerData?.cmr_first_name +
                    " " +
                    customerData?.cmr_last_name,
                });
                if (searchRef && searchRef.current) {
                  setTimeout(() => {
                    searchRef.current.focus();
                    setFieldError("cmr_name", "");
                  }, 0);
                }
              }
            }}
          />
        </div>
        <div className="w-full xl:w-[30%] flex flex-col gap-2  px-9">
          <InputField
            labelClass="whitespace-nowrap flex text-sm fw_600 text-secondary"
            containerClass=""
            isDisabled={disabled}
            errMsgContainerClass="hidden"
            type="date"
            name={`${section}_due_date`}
            label="Due. Date"
            labelWidth="9rem"
          />
          <InputField
            labelClass="whitespace-nowrap flex text-sm fw_600 text-secondary"
            containerClass=""
            isDisabled={disabled}
            errMsgContainerClass="hidden"
            type="date"
            name={`${section}_doc_date`}
            label="Doc. Date "
            labelWidth="9rem"
          />
        </div>
        <div className="w-full xl:w-[30%]">
          <TextAreaField
            placeholder="your remarks here..."
            labelClass="whitespace-nowrap flex text-sm fw_600 text-secondary"
            labelWidth="9rem"
            isDisabled={disabled}
            label="Journal Remarks"
            name="payment_journal_remarks"
            id={"payment_journal_remarks"}
            isRequired={false}
          />
        </div>
      </div>




      <div className="w-full flex items-center flex-wrap">
        <div className="w-full xl:w-[40%] flex flex-col gap-2 ">
        <InputField
            placeholder="your remarks here..."
            labelClass="whitespace-nowrap flex text-sm fw_600 text-secondary"
            containerClass=""
            isDisabled={disabled}
            errMsgContainerClass="hidden"
            type="text"
            name={`remarks`}
            label="Remarks "
            labelWidth="8.5rem"
          />
        </div>
        <div className="w-full xl:w-[30%] flex flex-col gap-2  px-9">
        <InputField
            labelClass="whitespace-nowrap flex text-sm fw_600 text-secondary"
            containerClass=""
            isDisabled={disabled}
            errMsgContainerClass="hidden"
            type="date"
            name={`${section}_posting_date`}
            label="Posting Date"
            labelWidth="9rem"
          />
        </div>
        </div>










    </>
  );
};

export default PaymentInOutHeader;
export const activeOptns = [
  { label: "Customer", value: true },
  { label: "Vendor", value: false },
];
