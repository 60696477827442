import React, { useEffect, useState } from "react";
import { GetSavedReportsAction } from "../../redux/actions/report-action";
import { useDispatch } from "react-redux";
import errorToast from "../helper-components/error-toast";
import { TableRow } from "../../modules/reports/TableRow";
const ReportsFavItemContainer = ({navigate }) => {
  const [fav, setFav] = useState([]);
  const dispatch = useDispatch();
  const [get,setGet]=useState(true);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [filters, setFilters] = useState({
    filter_by: "",
    filters: [
      { column: null, condition: null, value: "", from: null, to: null },
    ],
    selectedColumns: [],
    from: null,
    to: null,
    group_by: "",
    save:"false",
    name:"",
  });
   useEffect(() => {
    if(get){
      dispatch(GetSavedReportsAction (filters,onSuccess, onError));
      setGet(false);
    }
   },[get]);
  
    const onSuccess = (data) => {
      setFav(data?.data?.result ?? []);  
    };
  
    const onError = (data) => {
    };

  return (
    <div className='w-full bg-faf9fa ' id="saved_reports">
          <div className="px-4 py-2 border-b  border-D0D5DD bg-F2F4F5">
            <h2 className="font-medium">Saved Reports</h2>
          </div>
        {fav.length > 0 ? (
          <div className="w-full ">
             {fav.map((item, index) => (
              <TableRow
                  key={`${item}`}
                  name={item}
                  type={"Custom Report"}
                  report={`/reports/savedReports?name=${item}`}
                  navigate={navigate}
              />
             ))}
          </div>
        ) : (
          <div className=" w-full flex items-center bg-white border-b border-D0D5DD px-4 py-3 justify-center">No Saved Reports</div>
        )}
    </div>
  );
};

export default ReportsFavItemContainer;
