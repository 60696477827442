import { Field, useFormikContext } from "formik";
import React, { Fragment } from "react";

const PaymentInOutTable = ({
  tableBodyData = [],
  checkedId,
  setCheckedId,
  disabled = false,
  section = "pit",
}) => {
  const prefix = section === "pit" ? "soit" : "poit";
  function checkboxChecker(e, all) {
    if (all === "all" && checkedId.length === e.length) {
      setCheckedId([]);
    } else if (all === "all") {
      setCheckedId(e);
    } else {
      if (checkedId.includes(e?.target?.id)) {
        const checkedVAlues = [...checkedId];
        const findIndex = checkedVAlues.findIndex(
          (item) => item === e.target?.id
        );
        checkedVAlues.splice(findIndex, 1);
        setCheckedId(checkedVAlues);
      } else {
        setCheckedId((previous) => {
          return [...previous, e.target.id];
        });
      }
    }
  }

  return (
    <div
      className="table-container mt-3"
      style={{
        maxHeight: `calc(100% - 15rem)`,
        height: `calc(100% - 15rem)`,
      }}
    >
      <table className="my-table m-0">
        <thead className="sticky top-0 z-10">
          <tr className="bg-F2F4F5">
            <th
              className={`whitespace-nowrap py-4 px-3 fs_12 fw_500 text-475467 bg-F2F4F5 border-y border-y-EAECF0`}
            >
              <input
                disabled={disabled}
                checked={
                  checkedId?.length ===
                    tableBodyData?.map((item) => item?.id).length &&
                  checkedId.length > 0
                }
                onChange={() =>
                  checkboxChecker(
                    tableBodyData.map((item) => item?.id),
                    "all"
                  )
                }
                style={{ transform: "scale(1.1)" }}
                type="checkbox"
                id="myCheckbox"
                className="custom-checkbox form-check-input"
              />
            </th>
            {tableHeading.map((heading, index) => (
              <th
                key={index}
                className={`whitespace-nowrap text-sm fw_500 text-475467 border-y border-y-EAECF0 text-left py-4 px-3`}
              >
                {heading?.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableBodyData?.length > 0 ? (
            <>
              {tableBodyData.map((rowData, rowIndex) => (
                <tr
                  className={`hover:bg-f3f8ff ${
                    checkedId.includes(rowData?.id) &&
                    "bg-D5E6Ff hover:bg-D5E6Ff"
                  } `}
                  key={rowIndex}
                >
                  <td className="text-sm border-b border-b-EAECF0 py-4 px-3  fw_400 text-secondary text-center ">
                    <input
                      disabled={disabled}
                      checked={checkedId.includes(rowData?.id)}
                      id={rowData?.id}
                      onChange={(e) => checkboxChecker(e)}
                      style={{ transform: "scale(1.2)" }}
                      type="checkbox"
                      className="custom-checkbox form-check-input"
                    />
                  </td>
                  {Object.values(rowData).map((cellData, cellIndex) => (
                    <Fragment key={cellIndex}>
                      {cellIndex !== 0 && (
                        <td
                          className={`border-b border-b-EAECF0 py-1 px-3 text-sm fw_400 text-secondary whitespace-nowrap cursor-pointer`}
                        >
                          {cellIndex === 7 ? (
                            <Field
                              name={`invoices[${rowIndex}].${prefix}_total_payment`}
                            >
                              {({ field, form }) => (
                                <input
                                  disabled={disabled}
                                  placeholder=""
                                  {...field}
                                  type="number"
                                  className={`disPerInput ps-2 max-w-[7rem] text-secondary fw_400 py-2   focus_outline__none focus:border-primary focus:border  input-field-no-spin-buttons`}
                                />
                              )}
                            </Field>
                          ) : (
                            cellData
                          )}
                        </td>
                      )}
                    </Fragment>
                  ))}
                </tr>
              ))}
            </>
          ) : (
            <tr
              style={{
                height: "20rem",
              }}
            ></tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentInOutTable;

const tableHeading = [
  { label: "Document Number", value: "sl_no" },
  { label: "Document Type", value: "doc_type" },
  { label: "Date", value: "pdt_doc_date" },
  { label: "Due Date", value: "pdt_doc_date" },
  { label: "Over Due", value: "pdt_balance_due" },
  { label: "Total ", value: "pdt_total_amount" },
  { label: "Total Payment ", value: "pdt_total_amount" },
  { label: "Balance Due", value: "pdt_balance_due" },
];
