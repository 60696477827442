import React, { useEffect, useState } from "react";
import InnerLayout from "../../../components/layouts/inner-layout";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../../../components/helper-components/loading-spinner";
import { purchaseInvoiceViewAction } from "../../../redux/actions/purchase-action";
import CreateEditViewPurchaseInvoice from "../../../components/purchase-section/purchase-invoice/create-edit-view-purchase-invoice";

const EditPurchaseInvoice = () => {
  const { id } = useParams();
  const [orderDetail, setOrderDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(purchaseInvoiceViewAction(id, onSuccPur, onErrPur));
  }, []);

  const onSuccPur = (data) => {
    setOrderDetail(data?.data?.[0]);
    setLoading(false);
  };

  const onErrPur = (data) => {
    setLoading(false);
  };

  return (
    <InnerLayout backBtn={true} title="Purchase Invoice Edit">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <CreateEditViewPurchaseInvoice
          page="edit"
          disabled={true}
          orderDetail={orderDetail?.itemData}
          vendorDetails={[orderDetail?.orderData]}
        />
      )}
    </InnerLayout>
  );
};

export default EditPurchaseInvoice;
