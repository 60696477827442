import React, { useState } from "react";
import InputField from "../forms/input-field";
import AsyncCreateSelect from "../forms/async-create-select-field";
import { useFormikContext } from "formik";
import { useNavigate } from "react-router-dom";
import { customerListAction } from "../../redux/actions/general-action";
import { useDispatch } from "react-redux";
import excelImg from "../../assets/icons/excel.png";
import DownloadIcon from "../../assets/icons/download-icon";
import errorToast from "../helper-components/error-toast";
import Papa from "papaparse";
import { uploadPoCsvAction } from "../../redux/actions/purchase-action";
import LoadingButtonSpinner from "../helper-components/loading-button-spinner";
import { downloadAllTemplates } from "../helper-components/helper-function";

const SalesPurchaseHeader = ({
  disabled = false,
  searchRef = {},
  name = "",
  section = "",
  importcsv = false,
  handleFileUpload = () => {},
  loading,
  tempalteArray = [],
}) => {
  const dispatch = useDispatch();
  const [localData, setLocalData] = useState([]);

  const navigate = useNavigate();
  const { errors, values, setFieldValue, setFieldError } = useFormikContext();
  const data = useFormikContext();

  const loadOptions = (inputValue, field) => {
    return new Promise((resolve) => {
      const onSuccess = (data) => {
        const options = [
          ...data.data.map((item) => ({
            value: item.cmr_id,
            label: item?.cmr_code + "-" + item?.cmr_first_name,
          })),
          {
            value: "new_customer",
            label: (
              <span className="text-primary">
                {section === "sales" ? "+ New Customer" : "+ New Vendor"}
              </span>
            ),
          },
        ];
        setLocalData(data.data);
        resolve(options);
      };

      const onError = (error) => {
        resolve([
          {
            value: "new_customer",
            label: (
              <span className="text-primary">
                {section === "sales" ? "+ New Customer" : "+ New Vendor"}
              </span>
            ),
          },
        ]);
      };

      if (inputValue?.length > 0) {
        dispatch(
          customerListAction(
            {
              field: field,
              value: inputValue,
              isVendor: section === "sales" ? false : true,
            },
            onSuccess,
            onError
          )
        );
      } else {
        resolve([]);
      }
    });
  };

  return (
    <>
      <div class="w-full md:w-full lg:w-2/3 xl:w-[50%] 2xl:w-[40%]  ">
        <div className="flex items-center gap-2 w-full  px-2 py-2">
          <div className="w-[60%]">
            <AsyncCreateSelect
              className={`z-30 ${
                errors?.cmr_code && !values?.cmr_code
                  ? "selectField_border__error"
                  : "selectField_border"
              }`}
              errorClass="hidden"
              disabled={disabled}
              marginRight=""
              minWidth="8.5rem"
              cusType={section === "sales" ? "Customer" : "Vendor"}
              labelClass="whitespace-nowrap flex text-sm fw_600  gap-2 "
              type="text"
              label={`${section === "sales" ? "Customer" : "Vendor"} Code`}
              name={"cmr_code"}
              placeholder={`Enter ${
                section === "sales" ? "Customer" : "Vendor"
              } Code`}
              onCreateOption={() => {}}
              loadOptions={(e) => loadOptions(e, "cmr_code")}
              onChange={(selectedOption, actionMeta) => {
                if (actionMeta?.action === "clear") {
                  setFieldValue("cmr_code", "");
                  setFieldValue("cmr_name", "");
                } else if (
                  selectedOption === "new_customer" ||
                  selectedOption?.value === "new_customer"
                ) {
                  navigate("/add-customer");
                } else {
                  const customerData = localData?.find(
                    (item) => item?.cmr_id === selectedOption?.value
                  );
                  setFieldValue("cmr_code", {
                    label: customerData?.cmr_code,
                    value: customerData?.cmr_id,
                  });
                  setFieldValue("cmr_id", customerData?.cmr_id);
                  setFieldValue(
                    "cmr_phone_number",
                    customerData?.cmr_phone_number
                  );
                  setFieldValue("cmr_name", {
                    value: customerData?.cmr_id,
                    label:
                      customerData?.cmr_first_name +
                      " " +
                      customerData?.cmr_last_name,
                  });

                  if (searchRef && searchRef.current) {
                    searchRef.current.focus();
                    setFieldError("cmr_name", "");
                  }
                }
              }}
            />
          </div>
          <InputField
            labelClass="whitespace-nowrap flex text-sm fw_600 text-secondary"
            errMsgContainerClass="hidden"
            isDisabled={disabled}
            containerClass="w-[40%]"
            isRequired={false}
            type="date"
            name={`${name}_order_date`}
            placeholder=""
            label="Date"
            labelWidth="2.5rem"
          />
        </div>
        <div className="w-full px-2 py-2">
          <AsyncCreateSelect
            menuPortalTarget={document.body}
            className={`z-20 ${
              errors?.cmr_name && !values?.cmr_name
                ? "selectField_border__error"
                : "selectField_border"
            }`}
            errorClass="hidden"
            disabled={disabled}
            minWidth="8.5rem"
            marginRight=""
            cusType={section === "sales" ? "Customer" : "Vendor"}
            labelClass="whitespace-nowrap flex text-sm fw_600   gap-2"
            type="text"
            label={`${section === "sales" ? "Customer" : "Vendor"} Name`}
            autoFocus={true}
            name={"cmr_name"}
            placeholder={`Enter ${
              section === "sales" ? "Customer" : "Vendor"
            } Name`}
            onCreateOption={() => {}}
            loadOptions={(e) => loadOptions(e, "cmr_first_name")}
            onChange={(selectedOption, actionMeta) => {
              if (actionMeta?.action === "clear") {
                setFieldValue("cmr_code", "");
                setFieldValue("cmr_name", "");
              } else if (
                selectedOption === "new_customer" ||
                selectedOption?.value === "new_customer"
              ) {
                navigate("/add-customer");
              } else {
                const customerData = localData?.find(
                  (item) => item?.cmr_id === selectedOption?.value
                );
                setFieldValue("cmr_code", {
                  label: customerData?.cmr_code,
                  value: customerData?.cmr_id,
                });
                setFieldValue(
                  "cmr_phone_number",
                  customerData?.cmr_phone_number
                );
                setFieldValue("cmr_id", customerData?.cmr_id);
                setFieldValue("cmr_name", {
                  value: customerData?.cmr_id,
                  label:
                    customerData?.cmr_first_name +
                    " " +
                    customerData?.cmr_last_name,
                });
                if (searchRef && searchRef.current) {
                  setTimeout(() => {
                    searchRef.current.focus();
                    setFieldError("cmr_name", "");
                  }, 0);
                }
              }
            }}
          />
        </div>
      </div>
      <div class="w-full md:w-full lg:w-2/3 xl:w-[45%] 2xl:w-[50%] flex items-start">
        <div className="w-full">
          <div className="px-2 py-2">
            <InputField
              labelClass="whitespace-nowrap flex text-sm fw_600 text-secondary"
              containerClass=""
              isDisabled={disabled}
              errMsgContainerClass="hidden"
              type="date"
              name={`${name}_document_date`}
              label="Document Date "
              labelWidth="9rem"
              isRequired={true}
            />
          </div>
          <div className="px-2 py-2">
            <InputField
              labelClass="whitespace-nowrap flex text-sm fw_600 text-secondary"
              containerClass=""
              isDisabled={disabled}
              errMsgContainerClass="hidden"
              type="date"
              name={`${name}_delivery_date`}
              label="Delivery Date"
              labelWidth="9rem"
              isRequired={true}
            />
          </div>
        </div>

        {importcsv && !disabled && (
          <div className="px-2 py-2 flex items-center gap-3">
            {name === "pot" && (
              <>
                <input
                  type="file"
                  accept=".csv"
                  onChange={handleFileUpload}
                  className="hidden"
                  id="csv-upload"
                />
                <label htmlFor="csv-upload">
                  <div className="border-027A48 border-2 rounded-md flex items-center gap-2 text-nowrap px-5 py-2 fw_700 w-[9.5rem]">
                    <img
                      width={20}
                      height={30}
                      src={excelImg}
                      alt="excel-icon"
                    />
                    {loading ? (
                      <LoadingButtonSpinner className="  h-6 w-6 border-t-2 border-027A48" />
                    ) : (
                      "Import CSV"
                    )}
                  </div>
                </label>
              </>
            )}

            {name === "gort" && (
              <button
                type="button"
                onClick={handleFileUpload}
                className="border-027A48 border-2 rounded-md flex items-center gap-2 text-nowrap px-5 py-2 fw_700 w-[9.5rem]"
              >
                <img width={20} height={30} src={excelImg} alt="excel-icon" />
                {loading ? (
                  <LoadingButtonSpinner className="  h-6 w-6 border-t-2 border-027A48" />
                ) : (
                  "Import CSV"
                )}
              </button>
            )}

            <button
              type="button"
              onClick={() => {
                downloadAllTemplates(tempalteArray);
              }}
              className="border-027A48 border-2 rounded-md px-2 py-2"
            >
              <DownloadIcon fill="var(--success-color)" />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default SalesPurchaseHeader;
