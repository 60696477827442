import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import InputField from "../../../forms/input-field";
import { useDispatch } from "react-redux";
import AddButton, { DraftButton } from "../../../buttons/button";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import {
  smtpAddAction,
  smtpUpdateAction,
  smtpViewAction,
} from "../../../../redux/actions/settings-action";
import InnerLayout from "../../../layouts/inner-layout";
import successToast from "../../../helper-components/success-toast";
import errorToast from "../../../helper-components/error-toast";

const SmtpConfiguration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [smtpData, setSmtpData] = useState({});

  useEffect(() => {
    dispatch(smtpViewAction({}, onSuccess, onError));
  }, []);

  const reload = () => {
    dispatch(smtpViewAction({}, onSuccess, onError));
  };

  const onSuccess = (data) => {
    setSmtpData(data?.data);
  };
  const onError = (data) => {
    errorToast(data?.message);
  };

  const initialValues = {
    id: smtpData?.id || null,
    email: smtpData?.email || "",
    host: smtpData?.host || "",
    user_name: smtpData?.user_name || "",
    password: smtpData?.password || "",
    port: smtpData?.port || "",
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Required"),
    host: Yup.string().required("Required"),
    user_name: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
    port: Yup.string().required("Required"),
  });

  const onSubmit = (values) => {
    if (values.id) {
      dispatch(smtpUpdateAction(values, onCreateSuccess, onError));
    } else {
      dispatch(smtpAddAction(values, onCreateSuccess, onError));
    }
  };

  const onCreateSuccess = (data) => {
    successToast(data?.message);
    reload();
  };

  return (
    <InnerLayout
      list={true}
      title="Settings"
      module="smtp-configuration"
      // permissionFn={permissionFn}
    >
      <div className="pl-4">
        <div className="flex justify-between pt-3">
          <div className=" pb-4 flex items-center gap-2">
            <button
              className="flex items-center gap-2"
              onClick={() => {
                navigate("/settings");
              }}
            >
              <ChevronLeftIcon className="h-5 w-5 inline-block" />
              Settings /
            </button>
            <span className="cursor-pointer">{" " + "SMTP Configuration"}</span>
          </div>
        </div>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <Form className="pl-4 pt-4">
              <div style={{ height: "78vh" }}>
                <div className="w-full flex flex-col gap-4">
                  <div style={{ maxWidth: "30rem" }} className="w-full">
                    <InputField
                      onKeyDown={() => {}}
                      containerClass="mt-1"
                      isRequired={true}
                      type="text"
                      name="email"
                      placeholder="From Email"
                      label="Email"
                      labelWidth="8rem"
                    />
                  </div>
                  <div style={{ maxWidth: "30rem" }} className="w-full">
                    <InputField
                      containerClass="mt-1"
                      isRequired={true}
                      type="text"
                      name="host"
                      placeholder="SMTP Host"
                      label="SMTP Host"
                      labelWidth="8rem"
                    />
                  </div>
                  <div style={{ maxWidth: "30rem" }} className="w-full">
                    <InputField
                      containerClass="mt-1"
                      isRequired={true}
                      type="text"
                      name="user_name"
                      placeholder="User name"
                      label="User Name"
                      labelWidth="8rem"
                    />
                  </div>
                  <div style={{ maxWidth: "30rem" }} className="w-full">
                    <InputField
                      containerClass="mt-1"
                      isRequired={true}
                      type="text"
                      name="password"
                      placeholder="password"
                      label="Password"
                      labelWidth="8rem"
                    />
                  </div>
                  <div style={{ maxWidth: "30rem" }} className="w-full">
                    <InputField
                      containerClass="mt-1"
                      isRequired={true}
                      type="number"
                      name="port"
                      placeholder="port"
                      label="SMTP Port"
                      labelWidth="8rem"
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-between px-6  bg-white py-4 invoice-bottom-container">
                <DraftButton
                  onclkFn={() => navigate(`/settings`)}
                  text="Cancel"
                />
                <AddButton
                  onclkFn={handleSubmit}
                  prefixIcon={false}
                  text="Save"
                  type="submit"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </InnerLayout>
  );
};

export default SmtpConfiguration;
