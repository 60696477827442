import React, { useEffect, useState } from "react";
import ReportsLayout from "../../../components/reports/reports-layout";
import {
  gstR1ReportAction,
  productWiseSalesReportAction,
} from "../../../redux/actions/report-action";
import { useDispatch } from "react-redux";
import moment from "moment";

const Gstr1Report = () => {
  const [dateRangeFilter, setDateRangeFilterValue] = useState({
    from: null,
    to: null,
  });
  const dispatch = useDispatch();
  const [groupByOptions, setgroupByOptions] = useState([
    { label: "None", value: "" },
    { label: "Drug Code", value: "oi.item_code" },
    { label: "Drug Name", value: "oi.item_name" },
    { label: "Bill Date", value: "so.created_date" },
    { label: "Drug Code & Bill Date", value: "oi.item_code,so.created_date" },
    { label: "Drug Name & Bill Date", value: "oi.item_name,so.created_date" },
    { label: "Drug Code & Branch", value: "oi.item_code,si.firm_name" },
    { label: "Drug Code & Customer", value: "oi.item_code,c.cmr_first_name" },
    { label: "Drug Code & UOM", value: "oi.item_code,oi.item_uom" },
    {
      label: "Drug Code & Batch No",
      value: "oi.item_code,oi.item_batch_number",
    },
    { label: "Drug Code & Qty", value: "oi.item_code,oi.item_quantity" },
    { label: "Drug Code & Rate", value: "oi.item_code,oi.item_unit_price" },
  ]);

  const [loading, setLoading] = useState(false);
  const [filterOptions, setFilterOptions] = useState(
    Object.entries(tableColoumsn).map(([label, value]) => ({
      label,
      value,
    }))
  );
  const [selectedColumns, setSelectedColumns] = useState(tableHeading);
  const [filters, setFilters] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [dateRange, setDateRange] = useState("");
  const [groupBy, setGroupBy] = useState("");

  useEffect(() => {
    const selectedColumnValues = selectedColumns.map(
      (column) => tableColoumsn[column]
    );
    const requestData = {
      selectedColumns: selectedColumnValues,
      filters: filters.map(({ id, ...rest }) => rest),
      filter_by: dateRange,
      group_by: groupBy,
      from: dateRangeFilter?.from,
      to: dateRangeFilter?.to,
    };
    dispatch(gstR1ReportAction(requestData, onSuccess, onError));
    setLoading(true);
  }, [selectedColumns, filters, dateRange, groupBy]);

  const isIso8601Date = (dateString) => {
    const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    return iso8601Regex.test(dateString);
  };

  const formatDate = (value) => {
    return moment(value).format("DD-MM-YYYY");
  };

  const transformData = (data) => {
    return data.map((item) => {
      let transformedItem = {};
      for (let [key, value] of Object.entries(tableColoumsn)) {
        const rawValue = item[value.split(".").pop()];
        transformedItem[key] = isIso8601Date(rawValue)
          ? formatDate(rawValue)
          : rawValue;
      }
      return transformedItem;
    });
  };

  const onSuccess = (data) => {
    
    const transformedData = transformData(data?.data?.result);
    setTableData(transformedData);
    setLoading(false);
  };
  const onError = (data) => {
    
    setLoading(false);
  };

  return (
    <ReportsLayout
      reportType={"gst"}
      title={"GSTR-1 (Outwards Supplies)"}
      tableHeading={selectedColumns}
      tableData={tableData}
      groupByOptions={groupByOptions}
      filterOptions={filterOptions}
      availableColumns={tableHeading}
      selectedColumns={selectedColumns}
      setSelectedColumns={setSelectedColumns}
      setFilters={setFilters}
      loading={loading}
      setDateRange={setDateRange}
      setGroupBy={setGroupBy}
      setDateRangeFilterValue={setDateRangeFilterValue}
    />
  );
};

const tableHeading = [
  "Invoice Number", // Previously "Bill Num"
  "Invoice Date", // Previously "Bill Date"
  "Customer GSTIN", // New entry
  "Customer Name", // Previously "Party Name"
  // "Customer Address", // Previously "Branch Address"
  "Place of Supply", // New entry
  "HSN Code", // New entry
  "Item Name",
  "Quantity",
  "Taxable Value", // Could replace "Gross Amount"
  "Discount", // Previously "Discounts"
  "GST Rate", // New entry
  "SGST Amount", // New entry
  "CGST Amount", // New entry
  "IGST Amount", // New entry
  "Cess Amount", // New entry
  "Invoice Total", // Could replace "Total Amount"
  "Reverse Charge Indicator", // New entry
  "Export Indicator", // New entry
  "Credit/Debit Notes", // New entry
];

const tableColoumsn = {
  "Invoice Number": "so.sot_invoice_number", // Previously "Bill Num"
  "Invoice Date": "so.created_date", // Previously "Bill Date"
  "Customer GSTIN": "c.cmr_gstin", // New entry
  "Customer Name": "c.cmr_first_name", // Previously "Party Name"
  // "Customer address": "c.cmr_address", // Previously "Branch Address"
  "Place of Supply": "so.cmr_state", // New entry
  "HSN Code": "oi.item_hsn_code", // New entry
  "Item Name": "oi.item_name", // New entry
  Quantity: "oi.item_quantity", // New entry
  "Taxable Value": "so.item_price_without_tax", // Could replace "Gross Amount"
  Discount: "oi.item_discount_amount", // Previously "Discounts"
  "GST Rate": "oi.item_total_tax_percentage", // New entry
  "SGST Amount": "so.item_sgst", // New entry
  "CGST Amount": "so.item_cgst", // New entry
  "IGST Amount": "so.item_igst", // New entry
  "Cess Amount": "so.sot_cess_amount", // New entry
  "Invoice Total": "so.sot_total_amount", // Could replace "Total Amount"
  "Reverse Charge Indicator": "so.reverse_charge_indicator", // New entry
  "Export Indicator": "so.export_indicator", // New entry
  "Credit/Debit Notes": "so.credit_debit_notes", // New entry
};

export default Gstr1Report;
