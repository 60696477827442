import React, { Fragment, useEffect, useState } from "react";
import InnerLayout from "../../../layouts/inner-layout";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import SearchAddFeild from "../../../forms/search-add-field";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { purchaseProductListAction } from "../../../../redux/actions/purchase-action";
import AddButton from "../../../buttons/button";
import DeleteIcon from "../../../../assets/icons/delete-icon";
import {
  alternativeItemsListAction,
  deleteAlternativeTransAction,
  mapAlternativerItemAction,
} from "../../../../redux/actions/settings-action";
import successToast from "../../../helper-components/success-toast";
import errorToast from "../../../helper-components/error-toast";
import SortingIcons from "../../../../assets/icons/arrowup-down";
import Pagination from "../../../tables/pagination";
import LoadingSpinner from "../../../helper-components/loading-spinner";

const AlternativeItemSetup = () => {
  const [heightOfFirstDiv, setHeightOfFirstDiv] = useState(0);
  useEffect(() => {
    const updateHeight = () => {
      const firstDivHeight = document.querySelector(".first-div")?.clientHeight;
      setHeightOfFirstDiv(firstDivHeight);
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);
  const [pageSize] = useState(10);
  const navigate = useNavigate();
  const [dataCount, setDataCount] = useState(0);
  const [alternativeItems, setAlternativeItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const [localData, setLocalData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [reload, setReload] = useState(false);

  const [filterData, setfilterData] = useState({
    sort_order: "",
    sort_by: "",
  });

  const paginationProps = {
    pageSize: pageSize,
    dataCount: dataCount,
    setCurrentPage: setCurrentPage,
    currentPage: currentPage,
  };
  const fetchData = async (inputValue, callback, field) => {
    const onSuccess = (data) => {
      const options =
        data?.data === null
          ? []
          : data.data.map((item) => ({
              value: item.item_id,
              label: item[field],
            }));
      //   .filter(
      //     (option) =>
      //       !values?.data?.some((item) => item?.item_id === option.value)
      //   );
      setLocalData(data.data);
      callback(options);
    };
    const onError = (data) => {};
    if (inputValue?.length > 0) {
      dispatch(
        purchaseProductListAction(
          {
            field: field,
            value: inputValue,
          },
          onSuccess,
          onError
        )
      );
    }
  };

  const validationSchema = Yup.object().shape({
    // cmr_code: Yup.object().required("Customer code number is required"),
    // cmr_name: Yup.object().required("Customer name is required"),
  });

  const handleSubmit = (values, actions) => {
    dispatch(
      mapAlternativerItemAction(
        {
          alternative_item_id: values?.alternative_item_id,
          main_item_id: values?.item_id,
        },
        onSuccess,
        onError
      )
    );
  };
  const onSuccess = (data) => {
    successToast(data?.message);
    setReload(!reload);
  };
  const onError = (data) => {
    errorToast(data?.message);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(
      alternativeItemsListAction(
        {
          pageNumber: currentPage,
          pageSize: pageSize,
        },
        onFetchSuccess,
        onFetchError
      )
    );
  }, [currentPage, filterData, reload]);
  const onFetchSuccess = (data) => {
    setError(false);
    setLoading(false);
    setAlternativeItems(data?.data?.itemList ?? []);
    setDataCount(data?.data?.totalCount);
  };
  const onFetchError = (data) => {};

  const tableBodyData = alternativeItems?.map((item) => {
    return {
      main_item_name: item?.main_item_name,
      alternative_item_name: item?.alternative_item_name,
      alternative_id: item?.alternative_id,
    };
  });

  function deleteAltItemFn(id) {
    dispatch(
      deleteAlternativeTransAction(
        {
          alternative_id: id,
        },
        onDeleteSuccess,
        onDeleteError
      )
    );
  }
  const onDeleteSuccess = (data) => {
    successToast(data?.data?.message);
    setReload(!reload);
  };
  const onDeleteError = (data) => {
    errorToast(data?.data?.message);
  };
  return (
    <InnerLayout
      list={true}
      title="Settings"
      module="item-setup"
      // permissionFn={permissionFn}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="my-2 pl-2 w-full h-full">
          <div className="flex justify-between pt-3">
            <div className="pl-2 pb-4 flex items-center gap-2 text-sm">
              <button
                className="flex items-center gap-2"
                onClick={() => {
                  navigate("/settings");
                }}
              >
                <ChevronLeftIcon className="h-5 w-5 inline-block" />
                Settings /
              </button>
              <span className="cursor-pointer">
                {" " + "Alternative Items Setup"}
              </span>
            </div>
            <div></div>
          </div>
          <Formik
            enableReinitialize={true}
            initialValues={{
              item_name: "",
              item_id: "",
              alternative_item_name: "",
              alternative_item_id: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => {
              
              return (
                <Form className="h-full w-full">
                  <div className="flex gap-5 items-center px-5 first-div">
                    <SearchAddFeild
                      border={true}
                      dropdown={true}
                      //   isDisabled={
                      //     values?.data?.length - 1 !== rowIndex || disabled
                      //       ? true
                      //       : false
                      //   }
                      errorClass="hidden"
                      isRequired={false}
                      // value={{
                      //   label: values?.item_name ?? "Search and Select Item..",
                      //   value: "",
                      // }}
                      value={{
                        label: values?.item_name ? values?.item_name : <p className="text-667085 text-sm">Search and Select Item..</p>,
                        value: "",
                      }}
                      name={`item_name`}
                      field={`item_name`}
                      isLabelReq={false}
                      defaultSelectClass="border border-D0D5DD rounded-md"
                      placeholder="Search and Select Item.."
                      fetchData={fetchData}
                      onChange={async (selectedOption) => {
                        const filteredData = localData?.find(
                          (item) => item?.item_id === selectedOption?.value
                        );
                        setFieldValue("item_name", filteredData?.item_name);
                        setFieldValue("item_id", filteredData?.item_id);
                        // inputRefs.current?.focus();
                      }}
                    />
                    <SearchAddFeild
                      border={true}
                      dropdown={true}
                      //   isDisabled={
                      //     values?.data?.length - 1 !== rowIndex || disabled
                      //       ? true
                      //       : false
                      //   }
                      errorClass="hidden"
                      isRequired={false}
                      value={{
                        label: values?.alternative_item_name ? values?.alternative_item_name : <p className="text-667085 text-sm">Search and select alternative Item..</p>,
                        value: "",
                      }}
                      name={`alternative_item_name`}
                      field={`item_name`}
                      isLabelReq={false}
                      defaultSelectClass="border border-D0D5DD rounded-md"
                      placeholder="Search and select alternative Item...."
                      fetchData={fetchData}
                      onChange={async (selectedOption) => {
                        const filteredData = localData?.find(
                          (item) => item?.item_id === selectedOption?.value
                        );
                        setFieldValue(
                          "alternative_item_name",
                          filteredData?.item_name
                        );
                        setFieldValue(
                          "alternative_item_id",
                          filteredData?.item_id
                        );
                      }}
                    />
                    <AddButton
                      className="bg-primary text-white px-8 py-[10px]  rounded-md flex justify-center fw_500 text-sm"
                      prefixIcon={true}
                      text=""
                      type="submit"
                    />
                  </div>

                  <div
                    style={{
                      // minHeight: `calc(100% - ${heightOfFirstDiv}px)`,
                      height: `calc(100% - ${heightOfFirstDiv}px)`,
                    }}
                    className="flex flex-col justify-between"
                  >
                    <div
                      style={{
                        minHeight: `calc(100% - 80px)`,
                      }}
                      className="tab-container"
                    >
                      <table className="my-tab mt-5">
                        <thead>
                          <tr>
                            {tableHeading.map((heading, index) => (
                              <th
                                key={index}
                                className={`${"tab-header"} whitespace-nowrap text-xs fw_500 text-475467 bg-F2F4F5`}
                              >
                                <span
                                  className={`flex items-center gap-2 ${heading?.className}`}
                                >
                                  {heading?.label}
                                  {heading?.display && (
                                    <button
                                    // onClick={() => sortFn(heading?.value)}
                                    >
                                      {" "}
                                      <SortingIcons
                                        inc={
                                          filterData?.sort_order === "ASC" &&
                                          heading?.value === filterData?.sort_by
                                            ? "#475467"
                                            : "#9A9FA6"
                                        }
                                        dec={
                                          filterData?.sort_order === "DESC" &&
                                          heading?.value === filterData?.sort_by
                                            ? "#475467"
                                            : "#9A9FA6"
                                        }
                                      />
                                    </button>
                                  )}
                                </span>
                              </th>
                            ))}
                            <th
                              className={`${"tab-header"} whitespace-nowrap text-center text-sm fw_500 text-475467 bg-F2F4F5`}
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="h-full">
                          {tableBodyData?.length > 0 && (
                            <>
                              {tableBodyData.map((rowData, rowIndex) => (
                                <>
                                  <tr
                                    className={`hover:bg-f3f8ff`}
                                    key={rowIndex}
                                  >
                                    <td
                                      className={`text-sm tab-cell  fw_400 text-secondary bg-white`}
                                    >
                                      {rowData?.main_item_name}
                                    </td>
                                    <td
                                      className={`text-sm tab-cell fw_400 text-secondary`}
                                    >
                                      {rowData?.alternative_item_name}
                                    </td>
                                    <td
                                      className={`text-sm tab-cell text-center fw_400 text-secondary bg-white`}
                                    >
                                      <button
                                        type="button"
                                        onClick={() => {
                                          deleteAltItemFn(
                                            rowData?.alternative_id
                                          );
                                        }}
                                      >
                                        <DeleteIcon />
                                      </button>
                                    </td>
                                  </tr>
                                </>
                              ))}
                              <tr style={{ height: "10rem" }}></tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <Pagination paginationProps={paginationProps} />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </InnerLayout>
  );
};

export default AlternativeItemSetup;

// const tablist = ["Item Type", "Item Category", "Item Groups"];

const tableHeading = [
  {
    label: "Main Item Name",
    value: "item_name",
    display: false,
  },
  {
    label: "Alternative Item Name",
    value: "item_name",
    display: false,
  },
  // {
  //   label: "Alternative Item Code",
  //   value: "alternative_item_code",
  //   display: true,
  // },
  // {
  //   label: "Alternative Item Name",
  //   value: "alternative_item_name",
  //   display: true,
  // },
];
