import React from "react";

const ApproveIcon = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.1 0.685073C6.26517 0.401159 6.5262 0.18547 6.83615 0.0767741C7.14611 -0.0319214 7.48468 -0.0264945 7.79099 0.0920795C8.09731 0.210653 8.35129 0.434598 8.50728 0.723661C8.66327 1.01272 8.71103 1.34795 8.642 1.66907L7.93 5.00007H11.869C12.1773 5.00009 12.4814 5.07138 12.7576 5.20836C13.0337 5.34535 13.2745 5.54432 13.4611 5.78975C13.6476 6.03517 13.7749 6.32041 13.833 6.62317C13.8911 6.92593 13.8785 7.23802 13.796 7.53507L12.917 10.6971C12.6617 11.617 12.0858 12.4153 11.2933 12.9476C10.5009 13.48 9.54416 13.7115 8.596 13.6001L3.5 13.0001L3 6.00007L6.1 0.685073ZM1.749 6.44707C1.74377 6.34757 1.71876 6.2501 1.67544 6.16037C1.63212 6.07064 1.57136 5.99044 1.4967 5.92445C1.42203 5.85847 1.33497 5.80802 1.24059 5.77606C1.14621 5.7441 1.04641 5.73127 0.947022 5.73831C0.847629 5.74535 0.750635 5.77213 0.661707 5.81708C0.572779 5.86203 0.493699 5.92424 0.429086 6.0001C0.364473 6.07595 0.315623 6.16392 0.285389 6.25886C0.255155 6.35381 0.244143 6.45383 0.252997 6.55307L0.752997 13.5531C0.770461 13.7488 0.864074 13.9299 1.01369 14.0574C1.16332 14.1848 1.35699 14.2484 1.55304 14.2346C1.74909 14.2207 1.93186 14.1304 2.06201 13.9831C2.19217 13.8358 2.25931 13.6433 2.249 13.4471L1.749 6.44707Z"
        fill="#027A48"
      />
    </svg>
  );
};

export default ApproveIcon;

export const DeclineIcon = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.9 14.3149C7.73483 14.5988 7.4738 14.8145 7.16385 14.9232C6.85389 15.0319 6.51532 15.0265 6.20901 14.9079C5.90269 14.7893 5.64871 14.5654 5.49272 14.2763C5.33673 13.9873 5.28897 13.6521 5.358 13.3309L6.07 9.99993L2.131 9.99993C1.82272 9.99991 1.51861 9.92862 1.24243 9.79164C0.966251 9.65466 0.725478 9.45568 0.538915 9.21025C0.352353 8.96483 0.22505 8.67959 0.166953 8.37683C0.108857 8.07407 0.121537 7.76198 0.204003 7.46493L1.083 4.30293C1.3383 3.38301 1.9142 2.58475 2.70665 2.05235C3.4991 1.51996 4.45584 1.28854 5.404 1.39993L10.5 1.99993L11 8.99993L7.9 14.3149ZM12.251 8.55293C12.2562 8.65243 12.2812 8.7499 12.3246 8.83963C12.3679 8.92936 12.4286 9.00956 12.5033 9.07555C12.578 9.14153 12.665 9.19198 12.7594 9.22394C12.8538 9.2559 12.9536 9.26873 13.053 9.26169C13.1524 9.25465 13.2494 9.22787 13.3383 9.18292C13.4272 9.13797 13.5063 9.07576 13.5709 8.9999C13.6355 8.92405 13.6844 8.83608 13.7146 8.74114C13.7448 8.64619 13.7559 8.54617 13.747 8.44693L13.247 1.44693C13.2295 1.25116 13.1359 1.07007 12.9863 0.942623C12.8367 0.815175 12.643 0.751552 12.447 0.765443C12.2509 0.779334 12.0681 0.86963 11.938 1.0169C11.8078 1.16417 11.7407 1.35665 11.751 1.55293L12.251 8.55293Z"
        fill="#F94B4B"
      />
    </svg>
  );
};
