import React, { useCallback, useContext, useEffect, useState } from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import DynamicTable from "../../components/tables/dynamic-table";
import LoadingSpinner from "../../components/helper-components/loading-spinner";
import { productDeleteAction } from "../../redux/actions/product-action";
import { storeTableAction } from "../../redux/actions/store-action";
import StoreFilter from "../../components/tables/table-filter/store-filter";
import { DataContext } from "../../components/layouts/main-layout";
import { useHotkeys } from "react-hotkeys-hook";

const StoreTable = () => {
  const { permissionFn } = useContext(DataContext);
  const dispatch = useDispatch();
  const [storeList, setStoreList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
  const [reload, setReload] = useState(false);
  const [pageSize] = useState(10);
  const [tableHeading, setTableHeading] = useState(tabHeading);
  const [filterData, setFilterData] = useState({
    sort_by: "",
    sort_order: "",
    selectColumn: "authorised_person_name",
  });

  useEffect(() => {
    dispatch(
      storeTableAction(
        {
          pageNumber: currentPage,
          pageSize: pageSize,
          filterData,
        },
        onSuccess,
        onError
      )
    );
  }, [currentPage, filterData, reload]);
  const onSuccess = (data) => {
    setError(false);
    setLoading(false);
    setStoreList(data?.data?.storeList);
    setDataCount(data?.data?.totalCount);
  };
  const onError = (data) => {
    setError(true);
    setLoading(false);
  };

  const navigate = useNavigate();

  const filteredTableHeading = tableHeading.filter(
    (item) => item.isColumnneeded
  );

  const tableBodyData = storeList?.map((item) => {
    const rowData = {
      id: item?.store_id,
    };
    filteredTableHeading.forEach((column) => {
      if (column?.value !== "action") {
        if (column.value === "authorised_person_name") {
          rowData.authorised_person_name =
            (
              <span className="fw_500">
                {item?.authorised_person_name ?? "--"}
              </span>
            ) ?? "--";
        } else {
          rowData[column.value] = item[column.value] ?? "--";
        }
      }
    });
    return rowData;
  });
  const paginationProps = {
    pageSize: pageSize,
    dataCount: dataCount,
    setCurrentPage: setCurrentPage,
    currentPage: currentPage,
  };
  const handleFilterClick = useCallback(() => {
    setShowFilter((prevShowFilter) => !prevShowFilter);
  }, []);

  const filterby = showFilter ? (
    <StoreFilter
      setTableHeading={setTableHeading}
      tableHeading={tableHeading}
      filterData={filterData}
      setFilterData={setFilterData}
      setShowFilter={setShowFilter}
    />
  ) : null;

  useHotkeys("escape", () => {
    setShowFilter(false);
  });

  return (
    <InnerLayout
      permissionFn={permissionFn}
      module="store_document_process"
      list={true}
      onclkFn={() => navigate("/add-store")}
      title="Store Information"
    >
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <></>
      ) : (
        <DynamicTable
        is_checkbox_req={false}
          onClickItemFn={(id) => navigate(`/view-store/${id}`)}
          list={true}
          onclkFn={() => {
            navigate("/add-store");
          }}
          permissionFn={permissionFn}
          module={"store_document_process"}
          date={false}
          path="store"
          id_name="store_id"
          reload={reload}
          setReload={setReload}
          loading={loading}
          setLoading={setLoading}
          deleteAction={productDeleteAction}
          label="Store"
          showFilter={showFilter}
          filterFn={handleFilterClick}
          setFilterData={setFilterData}
          filterData={filterData}
          paginationProps={paginationProps}
          tableHeading={filteredTableHeading}
          tableBodyData={tableBodyData}
          placeholder="Search"
          filterBy={filterby}
          filterText="Show or Hide Columns"
          initialFilter={{
            sort_by: "",
            sort_order: "",
            selectColumn: "authorised_person_name",
          }}
        />
      )}
    </InnerLayout>
  );
};

export default StoreTable;
const tabHeading = [
  {
    label: "CONTACT PERSON",
    value: "authorised_person_name",
    display: true,
    column: true,
    isColumnneeded: true,
  },
  {
    label: "MOBILE NUMBER",
    value: "phone_number",
    display: true,
    column: true,
    isColumnneeded: true,
  },
  {
    label: "FIRM NAME",
    value: "firm_name",
    display: true,
    column: true,
    isColumnneeded: true,
  },
  {
    label: "STORE TYPE",
    value: "store_type",
    display: false,
    column: false,
    isColumnneeded: true,
  },
  {
    label: "AREA",
    value: "shop_area",
    display: true,
    column: false,
    isColumnneeded: false,
  },
  {
    label: "CITY",
    value: "shop_city",
    display: true,
    column: false,
    isColumnneeded: false,
  },
  {
    label: "STATE",
    value: "shop_state",
    display: true,
    column: false,
    isColumnneeded: false,
  },
  {
    label: "STATUS",
    value: "store_status",
    display: true,
    column: false,
    isColumnneeded: true,
  },
  {
    label: "REQUEST RECEIVED ON",
    value: "created_date",
    display: true,
    column: false,
    isColumnneeded: false,
  },
  {
    label: "PAYMENT RECEIVE ON",
    value: "payment_recevie_time",
    display: true,
    column: false,
    isColumnneeded: false,
  },
  {
    label: "RM NAME",
    value: "relationship_manager",
    display: true,
    column: false,
    isColumnneeded: true,
  },
  {
    label: "",
    value: "action",
    column: false,
    isColumnneeded: true,
  },
];
