import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import AddButton from "../../buttons/button";
import InputField from "../../forms/input-field";
import { useDispatch } from "react-redux";
import { StoreAPersonalCreateAction } from "../../../redux/actions/store-action";
import successToast from "../../helper-components/success-toast";
import errorToast from "../../helper-components/error-toast";
import SelectField from "../../forms/select-field";
import ImageField from "../../forms/images-field";

const StorePersonalTab = ({
  id,
  setStoreData,
  storeData,
  initialValue,
  disabled = false,
}) => {
  const dispatch = useDispatch();
  const initialValues = {
    store_id: id ? id : null,
    store_status: initialValue?.store_status ?? "",
    authorised_person_name: initialValue?.authorised_person_name ?? "",
    gender: initialValue?.gender ?? "",
    phone_number: initialValue?.phone_number ?? "",
    qualifications: initialValue?.qualifications ?? "",
    experience: initialValue?.experience ?? "",
    email: initialValue?.email ?? "",
    adhar_number: initialValue?.adhar_number ?? "",
    date_of_birth: initialValue?.date_of_birth ?? "",
    adhar_front_pic: initialValue?.adhar_front_pic ?? "",
    adhar_back_pic: initialValue?.adhar_back_pic ?? "",
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email address"),
    adhar_number: Yup.string().matches(
      /^[0-9]{12}$/,
      "Aadhar number must be a 12-digit number"
    ),
    adhar_front_pic: Yup.string().required("Adhaar Front image is required"),
    adhar_back_pic: Yup.string().required("Adhaar Back image is required"),
  });
  const onSubmit = (values, actions) => {
    dispatch(StoreAPersonalCreateAction(values, onSuccess, onError));
  };
  const onSuccess = (data) => {
    setStoreData({
      ...storeData,
      personal: data?.data,
    });
    successToast(data?.data?.message);
  };
  const onError = (data) => {
    errorToast(data?.data?.message);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values }) => {
        return (
          <Form style={{ height: "calc(100% - 5rem)" }} className="pt-5">
            <div className="flex flex-col justify-between h-full ">
              <div>
                <div className="flex flex-wrap ">
                  <div className="w-full 2xl:w-[35%] lg:w-[50%] px-5 xl:px-10">
                    <SelectField
                      isDisabled={disabled}
                      placeholder={"Select state"}
                      options={statusList}
                      label={"Status"}
                      id={"store_status"}
                      name="store_status"
                      isSearchable={true}
                      errMsgContainerClass="ps-2"
                      labelWidth="10.5rem"
                    />
                    <InputField
                      isDisabled={disabled}
                      type="text"
                      name="authorised_person_name"
                      placeholder=""
                      label="Authorised Person Name"
                      labelWidth="10.5rem"
                    />
                    <SelectField
                      isDisabled={disabled}
                      placeholder={"Select gender"}
                      options={genderList}
                      label={"Gender"}
                      id={"gender"}
                      name="gender"
                      isSearchable={true}
                      errMsgContainerClass="ps-2"
                      labelWidth="10.5rem"
                    />
                    <InputField
                      isDisabled={disabled}
                      type="text"
                      name="phone_number"
                      placeholder=""
                      label="Phone"
                      labelWidth="10.5rem"
                    />
                    <InputField
                      isDisabled={disabled}
                      type="text"
                      name="qualifications"
                      placeholder=""
                      label="Qualifications"
                      labelWidth="10.5rem"
                    />
                    <InputField
                      isDisabled={disabled}
                      type="text"
                      name="experience"
                      placeholder=""
                      label="Experience"
                      labelWidth="10.5rem"
                    />
                  </div>
                  <div className="w-full 2xl:w-[35%] lg:w-[50%] px-5 xl:px-10 mt-10 lg:mt-0">
                    <InputField
                      onKeyDown={() => {}}
                      isDisabled={disabled}
                      type="text"
                      name="email"
                      placeholder=""
                      label="Email"
                      labelWidth="10.5rem"
                    />
                    <InputField
                      isDisabled={disabled}
                      type="text"
                      name="adhar_number"
                      placeholder="xxxxxxxxxxxx"
                      label="Adhaar"
                      labelWidth="10.5rem"
                    />
                    <InputField
                      isDisabled={disabled}
                      type="date"
                      name="date_of_birth"
                      placeholder=""
                      label="DOB"
                      labelWidth="10.5rem"
                    />
                    <ImageField
                      isDisabled={disabled}
                      label="Adhaar Front"
                      isRequired={true}
                      labelWidth="10.5rem"
                      name="adhar_front_pic"
                    />
                    <ImageField
                      isDisabled={disabled}
                      label="Adhaar Back"
                      isRequired={true}
                      labelWidth="10.5rem"
                      name="adhar_back_pic"
                      mainLabel=""
                    />
                  </div>
                </div>
              </div>
              {!disabled && (
                <div
                  style={{
                    bottom: "0",
                  }}
                  className="flex justify-end px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
                >
                  <AddButton prefixIcon={false} text="Save" type="submit" />
                </div>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default StorePersonalTab;
const statusList = [
  {
    label: "Hold",
    value: "hold",
  },
];
const genderList = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
];
