import React from "react";

const KibabMenu = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16634 10C4.16634 10.4602 4.53944 10.8333 5.00067 10.8333C5.4609 10.8333 5.83401 10.4602 5.83401 10C5.83401 9.53978 5.4609 9.16667 5.00067 9.16667C4.53944 9.16667 4.16634 9.53978 4.16634 10Z"
        stroke="#98A2B3"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.16634 10C9.16634 10.4602 9.53944 10.8333 10.0007 10.8333C10.4609 10.8333 10.834 10.4602 10.834 10C10.834 9.53978 10.4609 9.16667 10.0007 9.16667C9.53944 9.16667 9.16634 9.53978 9.16634 10Z"
        stroke="#98A2B3"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.833 10C15.833 10.4602 16.2061 10.8333 16.6663 10.8333C17.1266 10.8333 17.4997 10.4602 17.4997 10C17.4997 9.53978 17.1266 9.16667 16.6663 9.16667C16.2061 9.16667 15.833 9.53978 15.833 10Z"
        stroke="#98A2B3"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default KibabMenu;
