import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import AddButton, { DraftButton } from "../../buttons/button";
import ModalCloseIcon from "../../../assets/icons/modal-close-icon";
import search from "../../../assets/icons/searchIcon.svg";

const ColoumnsModal = ({
  isModalOpen,
  setIsModalOpen,
  selectedColumns,
  availableColumns,
  setSelectedColumns,
}) => {
  const [coloumns, setColoumns] = useState(selectedColumns);
  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    setColoumns(selectedColumns);
  }, [selectedColumns]);

  const handleToggleColumn = (column) => {
    if (coloumns.includes(column)) {
      setColoumns(coloumns.filter((col) => col !== column));
    } else {
      setColoumns([...coloumns, column]);
    }
  };

  const handleApply = () => {
    setSelectedColumns(coloumns);
    setIsModalOpen(false);
  };
  return (
    <Transition show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => setIsModalOpen(false)}
      >
        <div className="flex min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-4"
          >
            <div
              className="mx-auto mt-2 text-left align-middle transition-all transform bg-white shadow-xl rounded-xl"
              style={{ maxHeight: "45rem", maxWidth: "30rem" }}
            >
              <div className="w-full h-full flex flex-col justify-between">
                <div className="overflow-y-auto w-full">
                  <div
                    className="relative px-6 py-4 flex items-center justify-between"
                    style={{ backgroundColor: "#F4F6F9" }}
                  >
                    <label className="text-sm fw_600">Select Columns</label>
                    <ModalCloseIcon
                      className="cursor-pointer"
                      onClick={() => setIsModalOpen(false)}
                    />
                  </div>
                  <div
                    style={{ minWidth: "24.5rem", height: "2.5rem" }}
                    className={`input_border flex items-center rounded-md pb-1 w-10 mx-6 mt-2`}
                  >
                    <span className="px-2">
                      {" "}
                      <img width={"16px"} src={search} alt="search icon" />
                    </span>
                    <input
                      placeholder={`Search here`}
                      className=" table_search_input w-full rounded-md fs_12 placeholder:text-fs_12"
                      type="text"
                      value={searchValue}
                      onChange={(e) => {
                        setSearchValue(e?.target?.value);
                      }}
                    />
                    <span
                      className="text-sm mx-3 cursor-pointer text-primary"
                      onClick={() => setSearchValue("")}
                    >
                      Clear
                    </span>
                  </div>
                  <div className="mt-3">
                    {availableColumns
                      .filter((item) =>
                        item.toLowerCase().includes(searchValue.toLowerCase())
                      )
                      .map((item, index) => (
                        <div
                          key={index}
                          onClick={() => handleToggleColumn(item)}
                          className="relative px-6 py-4 flex cursor-pointer items-center gap-4 border-b-787878"
                          style={{
                            backgroundColor: coloumns.includes(item)
                              ? "#DFF7DF"
                              : "#F4F6F9",
                            borderBottom: "solid 1px lightgrey",
                            borderBottomColor: "lightgrey",
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={coloumns.includes(item)}
                          />
                          <label className="text-sm fw_600">{item}</label>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="px-6 flex end-0 justify-between py-5 invoice-bottom-container">
                  <DraftButton
                    onclkFn={() => {
                      setIsModalOpen(false);
                    }}
                    text="Cancel"
                    className="text-F51D00 border border-F51D00  border-solid px-10 py-2 items-center rounded-md flex justify-center fw_500 text-sm"
                  />
                  <AddButton
                    isDisabled={false}
                    type="submit"
                    className="bg-primary text-white px-8 py-2 rounded flex justify-center fw_500 text-sm"
                    text="Save"
                    prefixIcon={false}
                    onclkFn={handleApply}
                  />
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ColoumnsModal;
