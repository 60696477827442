import React, { Fragment, useEffect, useState } from "react";
import DynamicSelectField from "../forms/select-field1";
import searchIcon from "../../assets/icons/searchIcon.svg";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import "./setting.css";
import {
  RolesListAction,
  UsersListAction,
} from "../../redux/actions/general-action";
import { useDispatch } from "react-redux";
import {
  updatePermissionsAction,
  userPermissionsAction,
} from "../../redux/actions/user-action";
import AddButton from "../buttons/button";
import successToast from "../helper-components/success-toast";
import { formatString } from "../helper-components/helper-function";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
const PermissionSettings = () => {
  const [rolesList, setRolesList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [reload, setReload] = useState(false);
  const [initialPermissions, setInitialPermissions] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(RolesListAction(onSuccessRoles, onErrorRoles));
  }, []);
  const onSuccessRoles = (data) => {
    setRolesList(
      data?.data?.map((item) => ({
        label: item?.role_name,
        value: item?.role_name,
      }))
    );
  };
  const onErrorRoles = (data) => {};
  function fetchUsers(role) {
    dispatch(UsersListAction(role, onSuccessUsers, onErrorUsers));
  }

  const onSuccessUsers = (data) => {
    setUsersList(
      data?.data?.userList?.map((item) => ({
        label: item?.user_name,
        value: item?.user_id,
      }))
    );
  };
  const onErrorUsers = (data) => {};

  const [initialValues, setInitialValues] = useState({
    role: "",
    user: "",
    permissions: [],
  });
  const validationSchema = Yup.object().shape({});
  function fetchPermissions(user, search) {
    dispatch(
      userPermissionsAction({ user, search }, onSuccessPer(user), onErrorPer)
    );
  }
  const processPermissions = (permissions) => {
    return permissions.map((permission) => {
      const booleanProperties = Object.keys(permission).filter(
        (key) => typeof permission[key] === "boolean"
      );
      const allTrue = booleanProperties.every(
        (key) => permission[key] === true
      );

      if (permission.hasItems && permission.hasItems.length > 0) {
        const subPermissions = processPermissions(permission.hasItems);
        return {
          ...permission,
          hasItems: subPermissions,
        };
      } else {
        return {
          ...permission,
          all: allTrue,
        };
      }
    });
  };
  const onSuccessPer = (user) => (data) => {
    
    // const modifiedPermissions = processPermissions(data?.data || []);
    const modifiedPermissions = data?.data;
    const filteredPermissions = filterDeepestObjects(modifiedPermissions);
    setInitialPermissions(filteredPermissions);
    setInitialValues({
      ...formValues,
      user: user,
      permissions: modifiedPermissions,
    });
  };

  const onErrorPer = (data) => {
    setInitialValues({
      ...formValues,
      permissions: [],
    });
  };

  useEffect(() => {
    if (initialValues?.user) {
      fetchPermissions(initialValues?.user, searchTerm);
    }
  }, [searchTerm, reload]);
  const filterDeepestObjects = (permissions) => {
    const result = [];

    const traverse = (items) => {
      items.forEach((item) => {
        if (item.hasItems && item.hasItems.length > 0) {
          traverse(item.hasItems);
        } else {
          result.push(item);
        }
      });
    };

    traverse(permissions);

    return result;
  };

  const onSubmit = (data) => {
    // const filterDeepestObjects = (permissions) => {
    //   const result = [];

    //   const traverse = (items) => {
    //     items.forEach((item) => {
    //       if (item.hasItems && item.hasItems.length > 0) {
    //         traverse(item.hasItems);
    //       } else {
    //         result.push(item);
    //       }
    //     });
    //   };

    //   traverse(permissions);

    //   return result;
    // };
    const filteredPermissions = filterDeepestObjects(data?.permissions);

    const hasPermissionChanged = (initial, current) => {
      for (let key in initial) {
        if (initial[key] !== current[key]) {
          return true;
        }
      }
      return false;
    };

    const updatedPermissions = filteredPermissions.filter(
      (currentPermission) => {
        const initialPermission = initialPermissions.find(
          (initial) => initial.permission_id === currentPermission.permission_id
        );
        return hasPermissionChanged(initialPermission, currentPermission);
      }
    );
    const modifiedPermissions = filteredPermissions.map((permission) => {
      const {
        created_date,
        update_date,
        main_module_name,
        module,
        parent_main_module_id,
        main_module_id,
        ...modifiedPermission
      } = permission;
      return modifiedPermission;
    });

    dispatch(
      updatePermissionsAction(
        modifiedPermissions,
        onUpdatePerSuccess,
        onUpdatePerError
      )
    );
  };

  const onUpdatePerSuccess = (data) => {
    setReload(!reload);
    successToast(data?.message);
  };
  const onUpdatePerError = (data) => {};
  const [openPaths, setOpenPaths] = useState([]);
  const handleOpen = (itemPath) => {
    setOpenPaths((prevOpenPaths) =>
      prevOpenPaths.includes(itemPath)
        ? prevOpenPaths.filter((path) => path !== itemPath)
        : [...prevOpenPaths, itemPath]
    );
  };
  return (
    <div className=" h-full">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, handleChange }) => {
          setFormValues(values);
          return (
            <Form className="h-full pt-5">
              <div className="flex flex-col justify-between h-full ">
                <div className="p-5">
                  <div className="flex gap-5 2xl:w-[25%] xl:w-[35%] lg:w-[50%]">
                    <DynamicSelectField
                      handleSelect={(role) => {
                        setFieldValue("user", "");
                        fetchUsers(role);
                      }}
                      name={"role"}
                      placeholder="Select Role"
                      options={rolesList}
                    />
                    <DynamicSelectField
                      handleSelect={(user) => {
                        fetchPermissions(user, "");
                      }}
                      name={"user"}
                      placeholder="Select User"
                      options={usersList}
                      isDisabled={!values?.role}
                    />
                  </div>
                  {values?.user && (
                    <div className="mt-8 2xl:w-[75%]">
                      <table className="w-full ">
                        <thead>
                          <tr className="">
                            <th
                              style={{
                                width: "15rem",
                              }}
                              className="flex justify-start"
                            >
                              <div className="border-D0D5DD border rounded-md flex item-center py-2 px-2">
                                <img
                                  className="w-[14px]"
                                  src={searchIcon}
                                  alt="search icon"
                                />
                                <input
                                  type="text"
                                  placeholder="Search"
                                  value={searchTerm}
                                  onChange={(e) => {
                                    setSearchTerm(e?.target.value);
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault();
                                    }
                                  }}
                                  className=" rounded-md  text-667085 pl-1  search_input_role w-full"
                                />
                              </div>
                            </th>
                            {tableHeading?.length > 0 &&
                              tableHeading.map((key) => {
                                if (
                                  key !== "permission_id" &&
                                  key !== "module_name" &&
                                  key !== "update_date" &&
                                  key !== "user_id" &&
                                  key !== "created_date"
                                ) {
                                  return (
                                    <th
                                      key={key}
                                      className="p-2 fw_500 text-sm text-000000"
                                    >
                                      {formatString(key)}
                                    </th>
                                  );
                                }
                                return null;
                              })}
                          </tr>
                        </thead>
                        <tbody>
                          {values?.permissions?.map((item, index) => (
                            <Fragment key={index}>
                              <SidebarItem
                                modulename={item?.module_name}
                                handleChange={handleChange}
                                initialValues={initialValues}
                                setFieldValue={setFieldValue}
                                values={values}
                                key={index}
                                item={item}
                                handleOpen={handleOpen}
                                isOpen={openPaths}
                                index={index}
                              />
                            </Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                {values?.user && values?.permissions?.length > 0 && (
                  <div
                    style={{
                      bottom: "0",
                    }}
                    className="flex justify-end gap-5 px-10 mb-5x sticky bg-white py-4 invoice-bottom-container w-full"
                  >
                    <AddButton
                      prefixIcon={false}
                      text="Update Permissions"
                      type="submit"
                    />
                  </div>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default PermissionSettings;

const SidebarItem = ({
  item,
  initialValues,
  depth = 0,
  handleOpen,
  isOpen,
  parent,
  index,
  values,
  setFieldValue,
  handleChange,
  subIndex = 0,
  subIndex1 = 0,
  modulename = "",
}) => {
  
  const hasSubItems = item.hasItems && item.hasItems.length > 0;
  const isAnyChildOpen = (item) => {
    if (isOpen === item.module_name) return true;
    if (item.hasItems) {
      return item.hasItems.some((subItem) => isAnyChildOpen(subItem));
    }
    return false;
  };

  const open = isAnyChildOpen(item);

  // const toggleOpen = () => {
  // open
  //   ? handleOpen(parent ? parent : item.module_name)
  //   :
  //      handleOpen(item.module_name);
  // };

  const isOpenItem = isOpen.includes(item.module_name);

  const toggleOpen = () => {
    handleOpen(item.module_name);
  };
  const handleallCheckboxChange = (
    index,
    checked,
    setFieldValue,
    subIndex,
    subIndex1
  ) => {
    const updatedPermissions = [...initialValues.permissions];
    const permission = updatedPermissions[index];

    if (subIndex && subIndex1) {
      // For depth 2 (subIndex1 exists)
      const subPermission =
        permission.hasItems[subIndex - 1].hasItems[subIndex1 - 1];
      for (const key in subPermission) {
        if (
          key !== "permission_id" &&
          key !== "module_name" &&
          key !== "update_date" &&
          key !== "user_id" &&
          key !== "module" &&
          key !== "main_module_name" &&
          key !== "module_id" &&
          key !== "created_date"
        ) {
          subPermission[key] = checked;
        }
      }
      setFieldValue(
        `permissions.${index}.hasItems.${subIndex - 1}.hasItems.${
          subIndex1 - 1
        }`,
        subPermission
      );
    } else if (subIndex) {
      // For depth 1 (subIndex exists)
      const subPermission = permission.hasItems[subIndex - 1];
      for (const key in subPermission) {
        if (
          key !== "permission_id" &&
          key !== "module_name" &&
          key !== "update_date" &&
          key !== "user_id" &&
          key !== "module" &&
          key !== "main_module_name" &&
          key !== "module_id" &&
          key !== "created_date"
        ) {
          subPermission[key] = checked;
        }
      }
      setFieldValue(
        `permissions.${index}.hasItems.${subIndex - 1}`,
        subPermission
      );
    } else {
      // For top level (no subIndex or subIndex1)
      for (const key in permission) {
        if (
          key !== "permission_id" &&
          key !== "module_name" &&
          key !== "update_date" &&
          key !== "user_id" &&
          key !== "module" &&
          key !== "main_module_name" &&
          key !== "module_id" &&
          key !== "created_date"
        ) {
          permission[key] = checked;
        }
      }
      setFieldValue(`permissions.${index}`, permission);
      // Update all nested items if they exist
      if (permission.hasItems && permission.hasItems.length > 0) {
        permission.hasItems.forEach((subItem) => {
          if (subItem.hasItems && subItem.hasItems.length > 0) {
            subItem.hasItems.forEach((subItem1) => {
              for (const key in subItem1) {
                if (
                  key !== "permission_id" &&
                  key !== "module_name" &&
                  key !== "update_date" &&
                  key !== "user_id" &&
                  key !== "module" &&
                  key !== "main_module_name" &&
                  key !== "module_id" &&
                  key !== "created_date"
                ) {
                  subItem1[key] = checked;
                }
              }
            });
          }
        });
        setFieldValue(`permissions.${index}.hasItems`, permission.hasItems);
      }
    }
  };
  const generatePermissionName = (
    index,
    depth,
    subIndex,
    subIndex1,
    heading
  ) => {
    let name = `permissions.${index}`;

    if (depth > 0) {
      if (depth >= 1) {
        const hasItemsString = `.hasItems.${subIndex - 1}`;
        name += hasItemsString;
      }
      if (depth === 2) {
        const hasItemsString1 = `.hasItems.${subIndex1 - 1}`;
        name += hasItemsString1;
      }
    }

    name += `.${heading}`;
    return name;
  };
  const getCheckedValue = (
    values,
    index,
    depth,
    subIndex,
    subIndex1,
    heading
  ) => {
    let value = false;

    if (depth === 0) {
      value = values?.permissions?.[index]?.[heading];
    } else if (depth === 1) {
      value = values?.permissions?.[index]?.hasItems?.[subIndex - 1]?.[heading];
    } else if (depth === 2) {
      value =
        values?.permissions?.[index]?.hasItems?.[subIndex - 1]?.hasItems?.[
          subIndex1 - 1
        ]?.[heading];
    }

    return !!value;
  };

  return (
    <>
      {hasSubItems ? (
        <>
          <tr
            className={`cursor-pointer  ${index > 0 && ""}`}
            onClick={toggleOpen}
            key={item?.module_name}
          >
            <td className="py-3 fw_600 flex justify-between text-sm items-center max-w-[300px]">
              <span style={{ paddingLeft: `${depth * 10}px` }}>
                {formatString(item.module_name)}
              </span>{" "}
              <span>
                {" "}
                <ChevronRightIcon
                  className={`text-secondary h-5 w-5 transition-transform ${
                    hasSubItems && isOpenItem ? "rotate-90" : ""
                  }`}
                />
              </span>
            </td>
          </tr>

          {hasSubItems && isOpenItem && (
            <>
              {item.hasItems.map((subItem, i) => (
                <SidebarItem
                  modulename={modulename}
                  handleChange={handleChange}
                  initialValues={initialValues}
                  setFieldValue={setFieldValue}
                  values={values}
                  index={index}
                  item={subItem}
                  isOpen={isOpen}
                  depth={depth + 1}
                  handleOpen={handleOpen}
                  parent={item.path}
                  subIndex={depth === 0 ? i + 1 : subIndex}
                  subIndex1={depth === 1 ? i + 1 : 0}
                />
              ))}
            </>
          )}
        </>
      ) : (
        <tr key={item?.module_name}>
          <td className={`p-0 text-left max-w-[300px] w-[300px] min-w-[300px] text-sm`}>
            {" "}
            <span style={{ paddingLeft: `${depth * 10}px` }}>
              {" "}
              {formatString(item.module_name)}
            </span>
          </td>

          <td className="p-2 text-center cursor-pointer">
            <input
              style={{
                width: "14px",
                height: "14px",
              }}
              className="rounded-lg cursor-pointer"
              type="checkbox"
              name={
                subIndex1
                  ? `permissions.${index}.hasItems.${subIndex - 1}.hasItems.${
                      subIndex1 - 1
                    }.all`
                  : subIndex
                  ? `permissions.${index}.hasItems.${subIndex - 1}.all`
                  : `permissions.${index}.all`
              }
              checked={
                subIndex1
                  ? values?.permissions?.[index]?.hasItems?.[subIndex - 1]
                      ?.hasItems?.[subIndex1 - 1]?.all
                  : subIndex
                  ? values?.permissions?.[index]?.hasItems?.[subIndex - 1]?.all
                  : values?.permissions?.[index]?.all
              }
              onChange={(e) => {
                handleallCheckboxChange(
                  index,
                  e?.target?.checked,
                  setFieldValue,
                  subIndex,
                  subIndex1
                );
              }}
            />
          </td>
          {modulename !== "settings" && (
            <>
              {tableHeading.slice(1).map((heading, headingIndex) => (
                <td
                  key={headingIndex}
                  className="p-2 text-center cursor-pointer"
                >
                  <input
                    style={{
                      width: "14px",
                      height: "14px",
                    }}
                    className="rounded-lg cursor-pointer"
                    type="checkbox"
                    name={generatePermissionName(
                      index,
                      depth,
                      subIndex,
                      subIndex1,
                      heading
                    )}
                    checked={getCheckedValue(
                      values,
                      index,
                      depth,
                      subIndex,
                      subIndex1,
                      heading
                    )}
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.checked) {
                        let allOthersTrue = false;

                        if (subIndex1) {
                          allOthersTrue = Object.keys(
                            values.permissions[index]?.hasItems[subIndex - 1]
                              ?.hasItems[subIndex1 - 1]
                          )
                            .filter(
                              (k) =>
                                ![
                                  "permission_id",
                                  "module_name",
                                  "update_date",
                                  "user_id",
                                  "created_date",
                                  "module",
                                  "main_module_name",
                                  "module_id",
                                  "all",
                                  heading,
                                ].includes(k)
                            )
                            .every(
                              (k) =>
                                values.permissions[index].hasItems[subIndex - 1]
                                  .hasItems[subIndex1 - 1][k] === true
                            );

                          if (allOthersTrue) {
                            setFieldValue(
                              `permissions.${index}.hasItems.${
                                subIndex - 1
                              }.hasItems.${subIndex1 - 1}.all`,
                              true
                            );
                          }
                        } else if (subIndex) {
                          allOthersTrue = Object.keys(
                            values.permissions[index]?.hasItems[subIndex - 1]
                          )
                            .filter(
                              (k) =>
                                ![
                                  "permission_id",
                                  "module_name",
                                  "update_date",
                                  "user_id",
                                  "created_date",
                                  "module",
                                  "main_module_name",
                                  "module_id",
                                  "all",
                                  heading,
                                ].includes(k)
                            )
                            .every(
                              (k) =>
                                values.permissions[index].hasItems[
                                  subIndex - 1
                                ][k] === true
                            );

                          if (allOthersTrue) {
                            setFieldValue(
                              `permissions.${index}.hasItems.${
                                subIndex - 1
                              }.all`,
                              true
                            );
                          }
                        } else {
                          allOthersTrue = Object.keys(values.permissions[index])
                            .filter(
                              (k) =>
                                ![
                                  "permission_id",
                                  "module_name",
                                  "update_date",
                                  "user_id",
                                  "created_date",
                                  "module",
                                  "main_module_name",
                                  "module_id",
                                  "all",
                                  heading,
                                ].includes(k)
                            )
                            .every(
                              (k) => values.permissions[index][k] === true
                            );

                          if (allOthersTrue) {
                            setFieldValue(`permissions.${index}.all`, true);
                          }
                        }
                      } else {
                        if (subIndex1) {
                          setFieldValue(
                            `permissions.${index}.hasItems.${
                              subIndex - 1
                            }.hasItems.${subIndex1 - 1}.all`,
                            false
                          );
                        } else if (subIndex) {
                          setFieldValue(
                            `permissions.${index}.hasItems.${subIndex - 1}.all`,
                            false
                          );
                        } else {
                          setFieldValue(`permissions.${index}.all`, false);
                        }
                      }
                    }}
                  />
                </td>
              ))}
            </>
          )}
        </tr>
      )}
    </>
  );
};

const tableHeading = [
  "all",
  "create",
  "update",
  "delete",
  "print",
  "export",
  "send",
  "read",
];

// isOpen={isOpen === item.module_name ? subItem.module_name : isOpen}

{
  /* <td className="p-2 text-center cursor-pointer">
            <input
              style={{
                width: "14px",
                height: "14px",
              }}
              className="rounded-lg "
              type="checkbox"
              name={
                subIndex
                  ? `permissions.${index}.hasItems${subIndex - 1}.all`
                  : `permissions.${index}.all`
              }
              checked={
                subIndex
                  ? values?.permissions?.[index]?.hasItems?.[subIndex - 1]?.all
                  : values?.permissions?.[index]?.all
              }
              onChange={(e) => {
                handleallCheckboxChange(
                  index,
                  e.target.checked,
                  setFieldValue,
                  subIndex
                );
              }}
            />
          </td>

          <td className="p-2 text-center cursor-pointer">
            <input
              style={{
                width: "14px",
                height: "14px",
              }}
              className="rounded-lg "
              type="checkbox"
              name={
                subIndex
                  ? `permissions.${index}.hasItems.${subIndex - 1}.create`
                  : `permissions.${index}.create`
              }
              checked={
                subIndex
                  ? values?.permissions?.[index]?.hasItems?.[subIndex - 1]
                      ?.create
                  : values?.permissions?.[index]?.create
              }
              onChange={(e) => {
                handleChange(e);
                if (e.target.checked) {
                  const allOthersTrue = Object.keys(
                    subIndex
                      ? values.permissions[index]?.hasItems[subIndex - 1]
                      : values.permissions[index]
                  )
                    .filter((k) => {
                      return ![
                        "permission_id",
                        "module_name",
                        "update_date",
                        "user_id",
                        "created_date",
                        "module",
                        "main_module_name",
                        "module_id",
                        "all",
                        "create",
                      ].includes(k);
                    })
                    .every((k) =>
                      subIndex
                        ? values.permissions[index].hasItems[subIndex - 1][
                            k
                          ] === true
                        : values.permissions[index][k] === true
                    );

                  if (allOthersTrue) {
                    if (subIndex) {
                      setFieldValue(
                        `permissions.${index}.hasItems.${subIndex - 1}.all`,
                        true
                      );
                    } else {
                      setFieldValue(`permissions.${index}.all`, true);
                    }
                  }
                } else {
                  if (subIndex) {
                    setFieldValue(
                      `permissions.${index}.hasItems.${subIndex - 1}.all`,
                      false
                    );
                  } else {
                    setFieldValue(`permissions.${index}.all`, false);
                  }
                }
              }}
            />
          </td>
          <td className="p-2 text-center cursor-pointer">
            <input
              style={{
                width: "14px",
                height: "14px",
              }}
              className="rounded-lg "
              type="checkbox"
              name={
                subIndex
                  ? `permissions.${index}.hasItems.${subIndex - 1}.update`
                  : `permissions.${index}.update`
              }
              checked={
                subIndex
                  ? values?.permissions?.[index]?.hasItems?.[subIndex - 1]
                      ?.update
                  : values?.permissions?.[index]?.update
              }
              onChange={(e) => {
                handleChange(e);
                if (e.target.checked) {
                  const allOthersTrue = Object.keys(
                    subIndex
                      ? values.permissions[index]?.hasItems[subIndex - 1]
                      : values.permissions[index]
                  )
                    .filter((k) => {
                      return ![
                        "permission_id",
                        "module_name",
                        "update_date",
                        "user_id",
                        "created_date",
                        "module",
                        "main_module_name",
                        "module_id",
                        "all",
                        "update",
                      ].includes(k);
                    })
                    .every((k) =>
                      subIndex
                        ? values.permissions[index].hasItems[subIndex - 1][
                            k
                          ] === true
                        : values.permissions[index][k] === true
                    );

                  if (allOthersTrue) {
                    if (subIndex) {
                      setFieldValue(
                        `permissions.${index}.hasItems.${subIndex - 1}.all`,
                        true
                      );
                    } else {
                      setFieldValue(`permissions.${index}.all`, true);
                    }
                  }
                } else {
                  if (subIndex) {
                    setFieldValue(
                      `permissions.${index}.hasItems.${subIndex - 1}.all`,
                      false
                    );
                  } else {
                    setFieldValue(`permissions.${index}.all`, false);
                  }
                }
              }}
            />
          </td>
          <td className="p-2 text-center cursor-pointer">
            <input
              style={{
                width: "14px",
                height: "14px",
              }}
              className="rounded-lg "
              type="checkbox"
              name={
                subIndex
                  ? `permissions.${index}.hasItems.${subIndex - 1}.delete`
                  : `permissions.${index}.delete`
              }
              checked={
                subIndex
                  ? values?.permissions?.[index]?.hasItems?.[subIndex - 1]
                      ?.delete
                  : values?.permissions?.[index]?.delete
              }
              onChange={(e) => {
                handleChange(e);
                if (e.target.checked) {
                  const allOthersTrue = Object.keys(
                    subIndex
                      ? values.permissions[index]?.hasItems[subIndex - 1]
                      : values.permissions[index]
                  )
                    .filter((k) => {
                      return ![
                        "permission_id",
                        "module_name",
                        "update_date",
                        "user_id",
                        "created_date",
                        "module",
                        "main_module_name",
                        "module_id",
                        "all",
                        "delete",
                      ].includes(k);
                    })
                    .every((k) =>
                      subIndex
                        ? values.permissions[index].hasItems[subIndex - 1][
                            k
                          ] === true
                        : values.permissions[index][k] === true
                    );

                  if (allOthersTrue) {
                    if (subIndex) {
                      setFieldValue(
                        `permissions.${index}.hasItems.${subIndex - 1}.all`,
                        true
                      );
                    } else {
                      setFieldValue(`permissions.${index}.all`, true);
                    }
                  }
                } else {
                  if (subIndex) {
                    setFieldValue(
                      `permissions.${index}.hasItems.${subIndex - 1}.all`,
                      false
                    );
                  } else {
                    setFieldValue(`permissions.${index}.all`, false);
                  }
                }
              }}
            />
          </td>
          <td className="p-2 text-center cursor-pointer">
            <input
              style={{
                width: "14px",
                height: "14px",
              }}
              className="rounded-lg "
              type="checkbox"
              name={
                subIndex
                  ? `permissions.${index}.hasItems.${subIndex - 1}.print`
                  : `permissions.${index}.print`
              }
              checked={
                subIndex
                  ? values?.permissions?.[index]?.hasItems?.[subIndex - 1]
                      ?.print
                  : values?.permissions?.[index]?.print
              }
              onChange={(e) => {
                handleChange(e);
                if (e.target.checked) {
                  const allOthersTrue = Object.keys(
                    subIndex
                      ? values.permissions[index]?.hasItems[subIndex - 1]
                      : values.permissions[index]
                  )
                    .filter((k) => {
                      return ![
                        "permission_id",
                        "module_name",
                        "update_date",
                        "user_id",
                        "created_date",
                        "module",
                        "main_module_name",
                        "module_id",
                        "all",
                        "print",
                      ].includes(k);
                    })
                    .every((k) =>
                      subIndex
                        ? values.permissions[index].hasItems[subIndex - 1][
                            k
                          ] === true
                        : values.permissions[index][k] === true
                    );

                  if (allOthersTrue) {
                    if (subIndex) {
                      setFieldValue(
                        `permissions.${index}.hasItems.${subIndex - 1}.all`,
                        true
                      );
                    } else {
                      setFieldValue(`permissions.${index}.all`, true);
                    }
                  }
                } else {
                  if (subIndex) {
                    setFieldValue(
                      `permissions.${index}.hasItems.${subIndex - 1}.all`,
                      false
                    );
                  } else {
                    setFieldValue(`permissions.${index}.all`, false);
                  }
                }
              }}
            />
          </td>
          <td className="p-2 text-center cursor-pointer">
            <input
              style={{
                width: "14px",
                height: "14px",
              }}
              className="rounded-lg "
              type="checkbox"
              name={
                subIndex
                  ? `permissions.${index}.hasItems.${subIndex - 1}.export`
                  : `permissions.${index}.export`
              }
              checked={
                subIndex
                  ? values?.permissions?.[index]?.hasItems?.[subIndex - 1]
                      ?.export
                  : values?.permissions?.[index]?.export
              }
              onChange={(e) => {
                handleChange(e);
                if (e.target.checked) {
                  const allOthersTrue = Object.keys(
                    subIndex
                      ? values.permissions[index]?.hasItems[subIndex - 1]
                      : values.permissions[index]
                  )
                    .filter((k) => {
                      return ![
                        "permission_id",
                        "module_name",
                        "update_date",
                        "user_id",
                        "created_date",
                        "module",
                        "main_module_name",
                        "module_id",
                        "all",
                        "export",
                      ].includes(k);
                    })
                    .every((k) =>
                      subIndex
                        ? values.permissions[index].hasItems[subIndex - 1][
                            k
                          ] === true
                        : values.permissions[index][k] === true
                    );

                  if (allOthersTrue) {
                    if (subIndex) {
                      setFieldValue(
                        `permissions.${index}.hasItems.${subIndex - 1}.all`,
                        true
                      );
                    } else {
                      setFieldValue(`permissions.${index}.all`, true);
                    }
                  }
                } else {
                  if (subIndex) {
                    setFieldValue(
                      `permissions.${index}.hasItems.${subIndex - 1}.all`,
                      false
                    );
                  } else {
                    setFieldValue(`permissions.${index}.all`, false);
                  }
                }
              }}
            />
          </td>
          <td className="p-2 text-center cursor-pointer">
            <input
              style={{
                width: "14px",
                height: "14px",
              }}
              className="rounded-lg "
              type="checkbox"
              name={
                subIndex
                  ? `permissions.${index}.hasItems.${subIndex - 1}.send`
                  : `permissions.${index}.send`
              }
              checked={
                subIndex
                  ? values?.permissions?.[index]?.hasItems?.[subIndex - 1]?.send
                  : values?.permissions?.[index]?.send
              }
              onChange={(e) => {
                handleChange(e);
                if (e.target.checked) {
                  const allOthersTrue = Object.keys(
                    subIndex
                      ? values.permissions[index]?.hasItems[subIndex - 1]
                      : values.permissions[index]
                  )
                    .filter((k) => {
                      return ![
                        "permission_id",
                        "module_name",
                        "update_date",
                        "user_id",
                        "created_date",
                        "module",
                        "main_module_name",
                        "module_id",
                        "all",
                        "send",
                      ].includes(k);
                    })
                    .every((k) =>
                      subIndex
                        ? values.permissions[index].hasItems[subIndex - 1][
                            k
                          ] === true
                        : values.permissions[index][k] === true
                    );

                  if (allOthersTrue) {
                    if (subIndex) {
                      setFieldValue(
                        `permissions.${index}.hasItems.${subIndex - 1}.all`,
                        true
                      );
                    } else {
                      setFieldValue(`permissions.${index}.all`, true);
                    }
                  }
                } else {
                  if (subIndex) {
                    setFieldValue(
                      `permissions.${index}.hasItems.${subIndex - 1}.all`,
                      false
                    );
                  } else {
                    if (subIndex) {
                      setFieldValue(
                        `permissions.${index}.hasItems.${subIndex - 1}.all`,
                        false
                      );
                    } else {
                      setFieldValue(`permissions.${index}.all`, false);
                    }
                  }
                }
              }}
            />
          </td>
          <td className="p-2 text-center cursor-pointer">
            <input
              style={{
                width: "14px",
                height: "14px",
              }}
              className="rounded-lg "
              type="checkbox"
              name={
                subIndex
                  ? `permissions.${index}.hasItems.${subIndex - 1}.read`
                  : `permissions.${index}.read`
              }
              checked={
                subIndex
                  ? values?.permissions?.[index]?.hasItems?.[subIndex - 1]?.read
                  : values?.permissions?.[index]?.read
              }
              onChange={(e) => {
                handleChange(e);
                if (e.target.checked) {
                  const allOthersTrue = Object.keys(
                    subIndex
                      ? values.permissions[index]?.hasItems[subIndex - 1]
                      : values.permissions[index]
                  )
                    .filter((k) => {
                      return ![
                        "permission_id",
                        "module_name",
                        "update_date",
                        "user_id",
                        "created_date",
                        "module",
                        "main_module_name",
                        "module_id",
                        "all",
                        "read",
                      ].includes(k);
                    })
                    .every((k) =>
                      subIndex
                        ? values.permissions[index].hasItems[subIndex - 1][
                            k
                          ] === true
                        : values.permissions[index][k] === true
                    );

                  if (allOthersTrue) {
                    if (subIndex) {
                      setFieldValue(
                        `permissions.${index}.hasItems.${subIndex - 1}.all`,
                        true
                      );
                    } else {
                      setFieldValue(`permissions.${index}.all`, true);
                    }
                  }
                } else {
                  if (subIndex) {
                    setFieldValue(
                      `permissions.${index}.hasItems.${subIndex - 1}.all`,
                      false
                    );
                  } else {
                    setFieldValue(`permissions.${index}.all`, false);
                  }
                }
              }}
            />
          </td> */
}
