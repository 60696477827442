import React, { useState } from "react";
import { Field, useFormikContext } from "formik";
import StarIcon from "../../assets/icons/req-star";
import UploadIcon, { UploadIcon1 } from "../../assets/icons/upload-icon";
import { useDispatch } from "react-redux";
import { imageUploadAction } from "../../redux/actions/general-action";
import { url } from "../../redux/config";
import LoadingSpinner from "../helper-components/loading-spinner";

const ImageField = ({
  isRequired = false,
  labelClass = "whitespace-nowrap flex text-sm fw_400 text-secondary",
  labelWidth = "10rem",
  name,
  label = "",
  mainLabel = "Add Images",
  isDisabled = false,
}) => {
  const [loading, setLoading] = useState(false);
  const { setFieldValue, values, errors } = useFormikContext();
  
  const dispatch = useDispatch();
  const handleFileChange = (event) => {
    setLoading(true);
    const files = event.target.files?.[0];
    dispatch(imageUploadAction(files, onSuccess, onError));
  };
  const onSuccess = (data) => {
    setLoading(false);

    setFieldValue(name, `${url}/image/${data}`);
  };
  const onError = (data) => {
    setLoading(false);
  };
  return (
    <>
      <div className={`flex items-start ${labelClass} w-full mt-3`}>
        <span
          style={{
            minWidth: labelWidth,
          }}
          className="flex text-label-color"
        >
          {mainLabel}
          {isRequired && mainLabel && (
            <span className="ml-2">
              <StarIcon />
            </span>
          )}
        </span>{" "}
        {loading ? (
          <LoadingSpinner />
        ) : values?.[name] ? (
          <div className="relative w-full group">
            <img
            alt="buttom image"
              className="rounded-md"
              style={{
                height: "10rem",
                width: "100%",
                objectFit: "cover",
              }}
              src={values?.[name]}
            />
            <button
              type="button"
              onClick={() => setFieldValue(name, "")}
              className="absolute bottom-0 right-0 p-2 bg-F51D00 text-white text-sm hover:bg-F51D00 transition duration-300 invisible group-hover:visible rounded-t-none rounded-bl-md rounded-br-md"
              style={{ width: "100%" }}
            >
              Delete
            </button>
          </div>
        ) : (
          <label
            htmlFor={name}
            style={{
              height: "2.9rem",
            }}
            className={`${
              errors[name] && errors[name]
                ? ` inputField_border__error`
                : ` inputField_border`
            }  flex items-center gap-3 w-full py-10 text-667085`}
          >
            <UploadIcon fill="var(--placeholder-color)" />
            {label}
          </label>
        )}
      </div>

      <Field name={name}>
        {({ field }) => (
          <input
            disabled={isDisabled}
            id={name}
            type="file"
            accept=".png, .jpg, .jpeg"
            onChange={(event) => {
              handleFileChange(event);
              field.onChange(event);
            }}
            onBlur={field.onBlur}
            style={{ display: "none" }}
          />
        )}
      </Field>
      {/* <ErrorMsg containerClass={errMsgContainerClass} name={name} /> */}
    </>
  );
};

export default ImageField;

export const ImageField1 = ({
  isRequired = false,
  labelClass = "whitespace-nowrap flex fs_14 fw_400 secondary-color",
  labelWidth = "10rem",
  name,
  label = "",
  mainLabel = "Add Images",
}) => {
  const [loading, setLoading] = useState(false);
  const { setFieldValue, values, errors } = useFormikContext();   
  const dispatch = useDispatch();
  const handleFileChange = (event) => {
    setLoading(true);
    const files = event.target.files?.[0];
    dispatch(imageUploadAction(files, onSuccess, onError));
  };
  const onSuccess = (data) => {
    setLoading(false);

    setFieldValue(name, `${url}/image/${data}`);
  };
  const onError = (data) => {
    setLoading(false);
  };
  return (
    <>
      <div className={`flex items-start ${labelClass} w-full mt-3`}>
        <span
          style={{
            minWidth: labelWidth,
          }}
          className="flex"
        >
          {mainLabel}
          {isRequired && mainLabel && (
            <span className="ml-2">
              <StarIcon />
            </span>
          )}
        </span>{" "}
        {loading ? (
          <div
            style={{
              height: "10rem",
              width: "10rem",
            }}
          >
            <LoadingSpinner />
          </div>
        ) : values?.[name] ? (
          <div className="relative group">
            <img
              className="rounded-md"
              style={{
                height: "10rem",
                width: "10rem",
                objectFit: "cover",
              }}
              src={values?.[name]}
            />
            <button
              type="button"
              onClick={() => setFieldValue(name, "")}
              className="absolute bottom-0 right-0 p-2 bg-F51D00 text-white text-xs hover:bg-F51D00 transition duration-300 invisible group-hover:visible rounded-t-none rounded-bl-md rounded-br-md"
              style={{ width: "10rem" }}
            >
              Delete
            </button>                                          
          </div>
        ) : (
          <label
            htmlFor={name}
            className={`${
              errors[name] && errors[name] ? ` inputField_border__error` : ` `
            }  flex items-center rounded-xl justify-center cursor-pointer gap-3 w-[10rem] h-[10rem] py-10 color-667085 bg-EBEBEB`}
          >
            <UploadIcon1 fill="#6C7184" />
          </label>
        )}
      </div>

      <Field name={name}>
        {({ field }) => (
          <input
            id={name}
            type="file"
            accept=".png, .jpg, .jpeg"
            onChange={(event) => {
              handleFileChange(event);
              field.onChange(event);
            }}
            onBlur={field.onBlur}
            style={{ display: "none" }}
          />
        )}
      </Field>
      {/* <ErrorMsg containerClass={errMsgContainerClass} name={name} /> */}
    </>
  );
};
