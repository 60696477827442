import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import SelectField from "../forms/select-field";
import InputField from "../forms/input-field";
import AddButton from "../buttons/button";
import {
  adminCustomerPaymentTermsAction,
  adminCustomerUpdatePaymentTermsAction,
} from "../../redux/actions/admin-customer-action";
import successToast from "../helper-components/success-toast";
import { useDispatch } from "react-redux";
import { priceListAction } from "../../redux/actions/general-action";

const PaymentTermsTab = ({
  setCurrentTab,
  customerGeneralData,
  customerId,
  setReload,
  reload,
  customerDetails,
  isDisabled = false,
}) => {
  const [priceList, setPriceList] = useState([]);
  const dispatch = useDispatch();
  const initialValues = {
    cmr_id: customerId,
    cmr_payment_terms: customerDetails.cmr_payment_terms || "",
    cmr_interest_on_arreas_percentage:
      customerDetails.cmr_interest_on_arreas_percentage || null,
    cmr_price_list: customerDetails.cmr_price_list || "",
    cmr_total_discount_percentage:
      customerDetails.cmr_total_discount_percentage || null,
    cmr_credit_limit: customerDetails.cmr_credit_limit || null,
    cmr_commitment_limit: customerDetails.cmr_commitment_limit || null,
    cmr_discount_group: customerDetails.cmr_discount_group || "",
    cmr_effective_price: customerDetails.cmr_effective_price || "",
  };

  const validationSchema = Yup.object().shape({
    cmr_payment_terms: Yup.string().required("Payment terms is required"),
    cmr_interest_on_arreas_percentage: Yup.number()
      .nullable(true)
      .typeError("Rate of Interest must be a number"),
    cmr_total_discount_percentage: Yup.number()
      .nullable(true)
      .typeError("Total Discount must be a number"),
    cmr_credit_limit: Yup.number()
      .nullable(true)
      .typeError("Credit Limit must be a number"),
    cmr_commitment_limit: Yup.number()
      .nullable(true)
      .typeError("Commitment Limit must be a number"),
  });

  useEffect(() => {
    
  
    dispatch(priceListAction(onpriceSuccess, onpriceError));

  }, [])
  
  const innerOnSubmit = (values) => {
    let actionType = adminCustomerPaymentTermsAction;
    if (customerDetails.cmr_payment_terms) {
      actionType = adminCustomerUpdatePaymentTermsAction;
    }

    dispatch(
      actionType(
        {
          customerGeneralData: customerGeneralData,
          paymentTermData: values,
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = (data) => {
    setReload(!reload);
    successToast(data?.message);
    setCurrentTab("Accounting");
  };
  const onError = (data) => {
    setReload(!reload);
  };

  const onpriceSuccess = (data) => {
    setPriceList(
      data?.data?.map((item) => {
        return { label: item?.price_list_name, value: item?.price_list_id };
      })
    );
  };
  const onpriceError = (data) => {
    // errorToast(data?.message);
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={innerOnSubmit}
    >
      {({ values, handleSubmit, errors }) => {
        return (
          <>
            <div className="flex flex-wrap">
              <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10">
                <SelectField
                  isDisabled={isDisabled}
                  placeholder="Select Payment Terms"
                  options={paymentTermsList}
                  label="Payment Terms"
                  id="cmr_payment_terms"
                  name="cmr_payment_terms"
                  isSearchable={true}
                  errMsgContainerClass="ps-2"
                  isRequired={true}
                  labelWidth="10rem"
                />
                <InputField
                  isDisabled={isDisabled}
                  type="number"
                  name="cmr_interest_on_arreas_percentage"
                  placeholder="xxxxxxx"
                  label="Rate of Interest"
                  isRequired={false}
                  labelWidth="10rem"
                />
                <SelectField
                  isDisabled={isDisabled}
                  placeholder="Select Price List"
                  options={priceList}
                  label="Price List"
                  id="price_list"
                  name="cmr_price_list"
                  isSearchable={true}
                  errMsgContainerClass="ps-2"
                  isRequired={false}
                  labelWidth="10rem"
                />
                <InputField
                  isDisabled={isDisabled}
                  type="number"
                  name="cmr_total_discount_percentage"
                  placeholder="xxxxxxx"
                  label="Total Discount"
                  isRequired={false}
                  labelWidth="10rem"
                />
              </div>

              <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10">
                <InputField
                  isDisabled={isDisabled}
                  type="number"
                  name="cmr_credit_limit"
                  placeholder="xxxxxxx"
                  label="Credit Limit"
                  isRequired={false}
                  labelWidth="10rem"
                />

                <InputField
                  isDisabled={isDisabled}
                  type="number"
                  name="cmr_commitment_limit"
                  placeholder="xxxxxxx"
                  label="Commitment Limit"
                  isRequired={false}
                  labelWidth="10rem"
                />
                <SelectField
                  isDisabled={isDisabled}
                  placeholder="Select Discount Groups"
                  options={discountGroups}
                  label="Discount Groups"
                  id="cmr_discount_group "
                  name="cmr_discount_group"
                  isSearchable={true}
                  errMsgContainerClass="ps-2"
                  isRequired={false}
                  labelWidth="10rem"
                />
                <SelectField
                  isDisabled={isDisabled}
                  placeholder="Select Effective Price List"
                  options={priceList}
                  label="Effective Price List"
                  id="cmr_effective_price"
                  name="cmr_effective_price"
                  isSearchable={true}
                  errMsgContainerClass="ps-2"
                  isRequired={false}
                  labelWidth="10rem"
                />
              </div>
            </div>
            {!isDisabled && (
              <div
                style={{
                  bottom: "0",
                }}
                className="flex w-full justify-end px-10 mb-5x sticky bg-white py-4 invoice-bottom-container mt-auto"
              >
                <AddButton
                  onclkFn={handleSubmit}
                  prefixIcon={false}
                  text="Save"
                  type="submit"
                />
              </div>
            )}
          
          </>
        );
      }}
    </Formik>
  );
};
export default PaymentTermsTab;

const paymentTermsList = [
  { value: "Net 15", label: "Net 15" },
  { value: "Net 30", label: "Net 30" },
  { value: "Net 45", label: "Net 45" },
  { value: "Net 60", label: "Net 60" },
  { value: "Net 90", label: "Net 90" },
  { value: "COD", label: "COD" },
  { value: "Prepaid", label: "Prepaid" },
  { value: "Advance", label: "Advance" },
];



const discountGroups = [
  { value: "Discount Group 1", label: "Discount Group 1" },
  { value: "Discount Group 2", label: "Discount Group 2" },
  { value: "Discount Group 3", label: "Discount Group 3" },
  { value: "Discount Group 4", label: "Discount Group 4" },
  { value: "Discount Group 5", label: "Discount Group 5" },
  { value: "Discount Group 6", label: "Discount Group 6" },
  { value: "Discount Group 7", label: "Discount Group 7" },
  { value: "Discount Group 8", label: "Discount Group 8" },
];


