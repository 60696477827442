import { all } from "redux-saga/effects";
import {
  allDisGroupWatcher,
  disItemGroupCreateWatcher,
  disItemsCreateWatcher,
  disItemsFetchWatcher,
  disManufacGroupCreateWatcher,
  perDisItemsListWatcher,
  perVolCreateWatcher,
  perVolumePriceListItemsWatcher,
  periodVolumeAddWatcher,
  periodVolumeGetWatcher,
  priceListCreateWatcher,
  priceListDeleteWatcher,
  priceListEditWatcher,
  priceListItemEditWatcher,
  priceListItemsWatcher,
  priceListTableWatcher,
  priceListViewWatcher,
} from "../discount-saga";

export default function* discountRootSaga() {
  yield all([
    priceListTableWatcher(),
    priceListCreateWatcher(),
    priceListItemsWatcher(),
    priceListViewWatcher(),
    priceListEditWatcher(),
    priceListItemEditWatcher(),
    allDisGroupWatcher(),
    disItemGroupCreateWatcher(),
    disManufacGroupCreateWatcher(),
    disItemsCreateWatcher(),
    disItemsFetchWatcher(),
    perDisItemsListWatcher(),
    perVolumePriceListItemsWatcher(),
    perVolCreateWatcher(),
    periodVolumeAddWatcher(),
    periodVolumeGetWatcher(),
    priceListDeleteWatcher(),
  ]);
}
