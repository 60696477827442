import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import InnerLayout from "../../../components/layouts/inner-layout";
import * as Yup from "yup";
import "../../../components/customer-information/customer-information.css";
import { useDispatch } from "react-redux";
import AddButton from "../../../components/buttons/button";
import successToast from "../../../components/helper-components/success-toast";
import errorToast from "../../../components/helper-components/error-toast";
import { useNavigate } from "react-router-dom";
import InputField from "../../../components/forms/input-field";
import SelectField from "../../../components/forms/select-field";
import {
  roundingMethodoptions,
  roundingRuleoptions,
} from "../../../components/helper-components/data";
import { priceListAction } from "../../../redux/actions/general-action";
import { priceListCreateAction } from "../../../redux/actions/discount-action";
import CheckBox from "../../../components/forms/check-box-field";
const AddPriceList = () => {
  const dispatch = useDispatch();
  const [priceList, setPriceList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(priceListAction(onpriceSuccess, onpriceError));
  }, []);
  const onpriceSuccess = (data) => {
    setPriceList(
      data?.data?.map((item) => {
        return { label: item?.price_list_name, value: item?.price_list_id };
      })
    );
  };
  const onpriceError = () => {};

  const initialValues = {
    price_list_name: "",
    default_base_price_list_id: "",
    default_factor: 0,
    margin_factor: 0,
    rounding_method: "",
    rounding_rule: "",
    default_price_list: false,
    is_margin_percentage: false,
  };

  const validationSchema = Yup.object().shape({
    price_list_name: Yup.string().required("Price list name is required"),
    default_base_price_list_id: Yup.string().required(
      "Default base price list ID is required"
    ),
    default_factor: Yup.number()
      .required("Discount % is required")
      .max(100, "Discount % cannot exceed 100"),
    margin_factor: Yup.number()
      .required("Margin % is required")
      .max(100, "Margin % cannot exceed 100"),
    rounding_method: Yup.string(),
    rounding_rule: Yup.string(),
  });

  const onSubmit = (values, { resetForm }) => {
    const data = {
      default_base_price_list: priceList?.find(
        (item) => item?.value === values?.default_base_price_list_id
      )?.label,
      ...values,
    };
    dispatch(priceListCreateAction(data, onSuccess, onError));
  };

  const onSuccess = (data) => {
    navigate("/price-list");
    successToast(data?.message);
  };
  const onError = (data) => {
    errorToast(data?.message);
  };
  return (
    <InnerLayout backBtn={true} bckbutton title="Add Price List ">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, values, setFieldValue }) => {
          return (
            <Form className="h-full pt-5">
              <div className="flex flex-col justify-between h-full ">
                <div>
                  <div className="flex flex-wrap">
                    <div className="w-full 2xl:w-[25%] lg:w-[50%] px-2 xl:px-10">
                      <InputField
                        type="text"
                        name="price_list_name"
                        placeholder="Enter name"
                        label="Name"
                        isRequired={true}
                        labelWidth="8rem"
                      />
                      <SelectField
                        placeholder={"Select base price"}
                        options={priceList}
                        label={"Base Price List"}
                        id={"default_base_price_list_id"}
                        name="default_base_price_list_id"
                        isSearchable={true}
                        errMsgContainerClass="ps-2"
                        isRequired={true}
                        labelWidth="8rem"
                      />

                      {/* </div> */}
                    </div>
                    <div className="w-full 2xl:w-[25%] lg:w-[50%] px-2 xl:px-10 mt-10 lg:mt-0">
                      <SelectField
                        placeholder={"Select rounding method"}
                        options={roundingMethodoptions}
                        label={"Rounding Method"}
                        id={"rounding_method"}
                        name="rounding_method"
                        isSearchable={true}
                        errMsgContainerClass="ps-2"
                        labelWidth="8rem"
                      />
                      <SelectField
                        placeholder={"Select rounding rule"}
                        options={roundingRuleoptions}
                        label={"Rounding rule"}
                        id={"rounding_rule"}
                        name="rounding_rule"
                        isSearchable={true}
                        errMsgContainerClass="ps-2"
                        labelWidth="8rem"
                      />

                      {/* <CheckBox
                        conClass="flex flex-wrap justify-between gap-4 fw_600"
                        items={[
                          {
                            label: "Set as default",
                            name: "default_price_list",
                            isRequired: false,
                          },
                        ]}
                      /> */}
                    </div>
                    <div className="w-full 2xl:w-[25%] lg:w-[50%] px-2 xl:px-10 mt-10 lg:mt-0">
                      <InputField
                        isDisabled={values?.is_margin_percentage}
                        max="100"
                        handleChange={(event) => {
                          if (parseFloat(event?.target?.value) > 100) {
                            event.preventDefault();
                          }
                        }}
                        onKeyDown={(event) => {
                          if (event.key === "-" || event.key === "e") {
                            event.preventDefault();
                          }
                        }}
                        type="number"
                        name="default_factor"
                        placeholder="Enter discount %"
                        label="Discount %"
                        isRequired={true}
                        labelWidth="8rem"
                      />
                      <InputField
                        isDisabled={!values?.is_margin_percentage}
                        max="100"
                        onKeyDown={(event) => {
                          if (event.key === "-" || event.key === "e") {
                            event.preventDefault();
                          }
                        }}
                        type="number"
                        name="margin_factor"
                        placeholder="Enter margin %"
                        label="Margin %"
                        isRequired={true}
                        labelWidth="8rem"
                      />
                      {/* <CheckBox
                        conClass="flex flex-wrap justify-between gap-4 fw_600"
                        items={[
                          {
                            label: "Set as default",
                            name: "default_price_list",
                            isRequired: false,
                          },
                        ]}
                      /> */}
                    </div>
                    <div className="w-full 2xl:w-[25%] lg:w-[50%] px-2 xl:px-10 mt-10 lg:mt-0">
                      <div className="flex gap-5 items-center mt-3">
                        <span
                          className={`${
                            values.is_margin_percentage
                              ? "text-disabled-color"
                              : ""
                          }`}
                        >
                          Discount %
                        </span>
                        <button
                          type="button"
                          role="switch"
                          aria-checked={values.is_margin_percentage}
                          onClick={() => {
                            setFieldValue(
                              "is_margin_percentage",
                              !values.is_margin_percentage
                            );
                          }}
                          data-state={
                            values.is_margin_percentage
                              ? "checked"
                              : "unchecked"
                          }
                          className={`peer inline-flex h-5 w-14 shrink-0 cursor-pointer items-center rounded-full border-2 border-white shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 ${
                            values.is_margin_percentage
                              ? "bg-D5E6Ff"
                              : "bg-D5E6Ff"
                          }`}
                          id="toggleSwitch"
                          aria-label="Toggle Switch"
                        >
                          <span
                            data-state={
                              values.is_margin_percentage
                                ? "checked"
                                : "unchecked"
                            }
                            className={`pointer-events-none block h-5 w-5 rounded-full bg-primary shadow-lg ring-0 transition-transform ${
                              values.is_margin_percentage
                                ? "translate-x-9"
                                : "translate-x-0"
                            }`}
                          ></span>
                        </button>
                        <span
                          className={`${
                            values.is_margin_percentage
                              ? ""
                              : "text-disabled-color"
                          }`}
                        >
                          Margin %
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* <div className="px-5 xl:px-10">
                    <AddButton
                      onclkFn={handleSubmit}
                      prefixIcon={false}
                      text="Save"
                      type="submit"
                    />
                  </div> */}
                </div>
                <div
                  style={{
                    bottom: "0",
                  }}
                  className="flex justify-end gap-5 px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
                >
                  <AddButton
                    onclkFn={handleSubmit}
                    prefixIcon={false}
                    text="Save"
                    type="submit"
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </InnerLayout>
  );
};

export default AddPriceList;
