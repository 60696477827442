import React, { useEffect, useState } from "react";
import CreateEditViewPriceList from "../../../components/discounts/price-lists/create-edit-view-price-list";
import InnerLayout from "../../../components/layouts/inner-layout";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { priceListViewAction } from "../../../redux/actions/discount-action";
import LoadingSpinner from "../../../components/helper-components/loading-spinner";

const EditPriceList = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [reload, setReload] = useState(false);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [priceListData, setPriceListData] = useState({});
  const [dataCount, setDataCount] = useState(0);
  const [filterData, setFilterData] = useState({
    price_list_id: id,
    item_from: "",
    item_to: "",
  });
  useEffect(() => {
    setLoading(true);
    dispatch(
      priceListViewAction(
        {
          pageNumber: currentPage,
          pageSize: pageSize,
          filterData,
        },
        onSuccess,
        onError
      )
    );
  }, [currentPage, filterData, reload]);
  const onSuccess = (data) => {
    setDataCount(data?.data?.totalCount);
    setLoading(false);
    setPriceListData(data?.data);
  };
  const onError = (data) => {
    setLoading(false);
  };
  const paginationProps = {
    pageSize: pageSize,
    dataCount: dataCount,
    setCurrentPage: setCurrentPage,
    currentPage: currentPage,
  };

  return (
    <InnerLayout backBtn={true} title="Edit Price List">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <CreateEditViewPriceList
          setReload={setReload}
          reload={reload}
          setFilterData={setFilterData}
          filterData={filterData}
          paginationProps={paginationProps}
          priceListData={priceListData}
        />
      )}
    </InnerLayout>
  );
};

export default EditPriceList;
