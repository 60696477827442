import { Field, useFormikContext } from "formik";
import React from "react";
import SelectField from "../../components/forms/select-field";
import AddButton from "../../components/buttons/button";
import DeleteIcon from "../../assets/icons/delete-icon";
import {
  decimalNumberFn,
  positiveIntegerFn,
} from "../helper-components/helper-function";
import moment from "moment";
import errorToast from "../helper-components/error-toast";
import { useDispatch } from "react-redux";
import { purchaseTaxCalAction } from "../../redux/actions/purchase-action";
import BatchIcon from "../../assets/icons/batches-icons/batch-icon";
import QtyIcon from "../../assets/icons/batches-icons/qty-icon";
import RupeeIcon from "../../assets/icons/batches-icons/rupeeIcon";
import CalendorIcon from "../../assets/icons/batches-icons/calendor-icon";

const PurchaseBatchesPopover = ({
  setAddbatch,
  itemId,
  setItemId,
  binLocationList = [],
  disName = "",
  disabled = false,
}) => {
  const { values, setFieldValue, errors } = useFormikContext();

  const dispatch = useDispatch();
  const defaultSelectedItem = values?.data?.find(
    (item, index) => itemId === item?.item_id
  );
  const defaultSelectedItemIndex = values?.data?.findIndex(
    (item, index) => itemId === item?.item_id
  );
  const today = new Date();
  const sixMonthsLater = new Date(today.setMonth(today.getMonth() + 6));
  const initData = {
    item_id: defaultSelectedItem?.item_id,
    item_batch_number: "",
    item_sellable_quantity: null,
    item_non_sellable_quantity: 0,
    item_batch_free_quantity: 0,
    item_batch_purchase_rate: 0,
    item_batch_discount_percentage: 0,
    item_batch_discount_amount: 0,
    item_batch_tax_percentage: 0,
    item_batch_tax_amount: 0,
    item_batch_final_purchase_rate: 0,
    item_batch_unit_price: 0,
    item_batch_total_purchase_rate: 0,
    item_exp_date: moment(sixMonthsLater).format("YYYY-MM-DD"), // Correct format
    item_mfg_date: "2023/12/02",
    from_bin_location: "",
    to_bin_location: "receiving-bin",
    to_bin_id: binLocationList?.find(
      (bin) => bin?.actualLabel === "receiving-bin"
    )?.value,
    from_bin_id: "",
  };

  const handleQuantityChange = async (
    rowIndex,
    batchIndex,
    qty,
    pr = 0,
    dis = 0
  ) => {
    let updatedData = [...values.data];
    const purchaseRate = pr === "" ? 0 : pr;
    dispatch(
      purchaseTaxCalAction(
        {
          item_id: defaultSelectedItem?.item_id,
          purchase_rate: purchaseRate - purchaseRate * dis,
        },
        (data) => {
          const {
            item_discount_amount,
            item_tax_amount,
            item_total_tax_percentage,
          } = data.data;

          const discount = item_discount_amount || 0;
          const tax = +item_tax_amount || 0;
          const sgst = +(tax / 2)?.toFixed(2);
          const cgst = +(tax / 2)?.toFixed(2);
          const igst = 0;
          const item_batch_final_purchase_rate = (
            parseFloat(purchaseRate) -
            parseFloat(dis * purchaseRate) +
            parseFloat(tax)
          )?.toFixed(2);
          const updatedBatch = {
            ...updatedData[rowIndex].item_batches[batchIndex],
            item_sellable_quantity: +qty,
            item_batch_purchase_rate: pr,
            item_batch_discount_percentage: dis * 100,
            item_batch_discount_amount:
              parseInt(qty) * dis * purchaseRate?.toFixed(2),
            item_batch_tax_percentage: item_total_tax_percentage,
            item_batch_tax_amount: parseFloat(qty * tax).toFixed(2),
            item_batch_final_purchase_rate: item_batch_final_purchase_rate,
            item_batch_total_purchase_rate: (
              item_batch_final_purchase_rate * qty
            ).toFixed(2),
            item_batch_sgst: +sgst.toFixed(2),
            item_batch_cgst: +cgst.toFixed(2),
            item_batch_igst: +igst,
          };
          updatedData[rowIndex].item_batches[batchIndex] = updatedBatch;
          const sumQuantity = updatedData[rowIndex].item_batches.reduce(
            (sum, batch) =>
              sum + (parseFloat(batch.item_sellable_quantity) || 0),
            0
          );
          const totalDiscount = updatedData[rowIndex].item_batches.reduce(
            (sum, batch) =>
              sum + (parseFloat(batch.item_batch_discount_amount) || 0),
            0
          );
          const totalTax = updatedData[rowIndex].item_batches.reduce(
            (sum, batch) =>
              sum + (parseFloat(batch.item_batch_tax_amount) || 0),
            0
          );
          const totalAmt = updatedData[rowIndex].item_batches.reduce(
            (sum, batch) =>
              sum + (parseFloat(batch.item_batch_total_purchase_rate) || 0),
            0
          );
          const totalFreeQty = updatedData[rowIndex].item_batches.reduce(
            (sum, batch) =>
              sum + (parseFloat(batch.item_batch_free_quantity) || 0),
            0
          );
          const updatedDataRow = {
            ...updatedData[rowIndex],
            quantity: sumQuantity,
            item_free_quantity: +totalFreeQty,
            item_discount_amount: totalDiscount?.toFixed(2),
            item_tax_amount: totalTax?.toFixed(2),
            // item_discount_percentage: +item_discount_percentage,
            item_gst: +item_total_tax_percentage || 0,
            item_sgst: +(totalTax / 2)?.toFixed(2),
            item_cgst: +(totalTax / 2)?.toFixed(2),
            item_igst: +igst,
            item_total_amount: totalAmt.toFixed(2),
          };
          updatedData[rowIndex] = updatedDataRow;
          setFieldValue("data", updatedData);
          setFieldValue(
            disName,
            updatedData
              .reduce(
                (acc, item) =>
                  acc + (parseFloat(item?.item_discount_amount) || 0),
                0
              )
              .toFixed(2)
          );
        },
        (data) => {}
      )
    );
  };

  const onErrorDis = (data) => {
    console.error("API Error:", data);
  };

  const handleShowErrorToast = async (item_id = "") => {
    if (
      errors?.data &&
      errors?.data[defaultSelectedItemIndex] &&
      defaultSelectedItem?.item_batches?.length > 0
    ) {
      const dataError = errors?.data[defaultSelectedItemIndex];

      if (dataError?.item_batches) {
        let hasError = false;

        // Loop through each item batch and show the first error found in the correct order
        for (const batchError of dataError?.item_batches) {
          if (batchError) {
            if (batchError.item_batch_number && !hasError) {
              errorToast(batchError.item_batch_number + "!");
              hasError = true;
            } else if (batchError.item_batch_purchase_rate && !hasError) {
              errorToast(batchError.item_batch_purchase_rate + "!");
              hasError = true;
            } else if (batchError.item_batch_unit_price && !hasError) {
              errorToast(batchError.item_batch_unit_price + "!");
              hasError = true;
            } else if (batchError.item_sellable_quantity && !hasError) {
              errorToast(batchError.item_sellable_quantity + "!");
              hasError = true;
            } else if (batchError.item_batch_free_quantity && !hasError) {
              errorToast(batchError.item_batch_free_quantity + "!");
              hasError = true;
            } else if (batchError.item_exp_date && !hasError) {
              errorToast(batchError.item_exp_date + "!");
              hasError = true;
            } else if (batchError.to_bin_id && !hasError) {
              errorToast(batchError.to_bin_id + "!");
              hasError = true;
            }
          }

          // If an error is found in the batch, stop and don't check the rest of the fields in that batch
          if (hasError) {
            break;
          }
        }

        // If no errors were found, set addBatch to false
        if (!hasError && item_id === "") {
          setAddbatch(false);
        }
        if (!hasError && item_id !== "") {
          setItemId(item_id);
        }
      } else {
        if (item_id === "") {
          setAddbatch(false);
        }
        if (item_id !== "") {
          setItemId(item_id);
        }
      }
    } else {
      if (item_id === "") {
        setAddbatch(false);
      }
      if (item_id !== "") {
        setItemId(item_id);
      }
    }
  };

  const handleDeleteBatch = (rowIndex, batchIndex, batch) => {
    const updatedBatches = [...defaultSelectedItem?.item_batches];
    updatedBatches.splice(batchIndex, 1);
    setFieldValue(`data.${rowIndex}.item_batches`, updatedBatches);

    const sumQuantity = updatedBatches.reduce(
      (sum, batch) => sum + (parseFloat(batch.item_sellable_quantity) || 0),
      0
    );
    const totalDiscount = updatedBatches.reduce(
      (sum, batch) => sum + (parseFloat(batch.item_batch_discount_amount) || 0),
      0
    );
    const totalTax = updatedBatches.reduce(
      (sum, batch) => sum + (parseFloat(batch.item_batch_tax_amount) || 0),
      0
    );
    const totalAmt = updatedBatches.reduce(
      (sum, batch) =>
        sum + (parseFloat(batch.item_batch_total_purchase_rate) || 0),
      0
    );
    const totalFreeQty = updatedBatches.reduce(
      (sum, batch) => sum + (parseFloat(batch.item_batch_free_quantity) || 0),
      0
    );

    let updatedData = [...values.data]; // Ensure values.data is your main data array
    const updatedDataRow = {
      ...updatedData[rowIndex],
      item_batches: updatedBatches,
      quantity: sumQuantity,
      item_free_quantity: +totalFreeQty,
      gort_total_discount: +totalDiscount,
      item_discount_amount: +totalDiscount,
      item_tax_amount: +totalTax?.toFixed(2),
      // item_discount_percentage: +item_discount_percentage,
      item_gst: updatedBatches?.[0]?.item_total_tax_percentage || 0,
      item_sgst: +(totalTax / 2)?.toFixed(2),
      item_cgst: +(totalTax / 2)?.toFixed(2),
      item_igst: 0,
      item_total_amount: totalAmt.toFixed(2),
    };
    updatedData[rowIndex] = updatedDataRow;
    setFieldValue("data", updatedData);
    setFieldValue(
      disName,
      updatedData
        .reduce(
          (acc, item) => acc + (parseFloat(item?.item_discount_amount) || 0),
          0
        )
        .toFixed(2)
    );
  };
  return (
    <div className="absolute w-[-webkit-fill-available]  z-40">
      <div className="  h-[72vh] bg-white flex flex-col justify-between">
        <div
          style={{
            height: `calc(72vh - 5rem)`,
          }}
        >
          <div className="bg-[#F4F6F9] w-full p-3 gap-2 font-semibold text-xl  flex items-center">
            Batch details -
            <SelectField
              isDisabled={disabled}
              general_color={false}
              handleSelected={(selected) => {
                handleShowErrorToast(selected?.value);
              }}
              containerClass="w-[20rem]"
              placeholder={"Select product"}
              defaultValue={
                defaultSelectedItem
                  ? {
                      label: defaultSelectedItem.item_name,
                      value: defaultSelectedItem.item_id,
                    }
                  : null
              }
              options={values?.data
                ?.map((item) => ({
                  label: item?.item_name,
                  value: item?.item_id,
                }))
                ?.filter((item) => item?.value)}
              label={""}
              id={"item_id"}
              name="item_id"
              isSearchable={true}
              errMsgContainerClass="hidden"
              labelWidth="10.5rem"
            />
          </div>
          <div
            style={{
              height: `calc(100% - 5rem)`,
            }}
            className="p-3 overflow-y-auto "
          >
            <table className="lg:w-[100%] w-full border-D0D5DD border border-1 rounded-lg ">
              <thead>
                <tr className="bg-F2F4F5">
                  <th className="py-2 px-2  border-D0D5DD border border-1 text-left  text-secondary fw_400 text-sm text-nowrap  min-w-[10rem]">
                    <span className="flex gap-2 items-center ">
                      {" "}
                      <BatchIcon /> Batch No.
                    </span>
                  </th>
                  <th className="py-2 px-2  border-D0D5DD border border-1   text-secondary fw_400 text-sm text-nowrap max-w-[5rem] min-w-[3rem]">
                    <span className="flex gap-2 items-center justify-end">
                      {" "}
                      <QtyIcon /> Qty
                    </span>
                  </th>
                  <th className="py-2 px-2 border-D0D5DD border border-1 text-right  text-secondary fw_400 text-sm text-nowrap min-w-[5rem]">
                    <span className="flex gap-2 items-center justify-end">
                      {" "}
                      <QtyIcon /> Free Qty
                    </span>
                  </th>
                  <th className="py-2 px-2 border-D0D5DD border border-1 text-right  text-secondary fw_400 text-sm text-nowrap min-w-[5rem]">
                    <span className="flex gap-2 items-center justify-end">
                      {" "}
                      <RupeeIcon /> Base PR
                    </span>
                  </th>
                  <th className="py-2 px-2 border-D0D5DD border border-1 text-right  text-secondary fw_400 text-sm text-nowrap min-w-[5rem]">
                    <span className="flex gap-2 items-center justify-end">
                      {" "}
                      <QtyIcon /> Disc. %
                    </span>
                  </th>
                  <th className="py-2 px-2 border-D0D5DD border border-1 text-right  text-secondary fw_400 text-sm text-nowrap min-w-[5rem]">
                    <span className="flex gap-2 items-center justify-end">
                      {" "}
                      <RupeeIcon /> Disc. Amt
                    </span>
                  </th>
                  <th className="py-2 px-2 border-D0D5DD border border-1 text-right text-nowrap  text-secondary fw_400 text-sm  min-w-[5rem]">
                    <span className="flex gap-2 items-center justify-end">
                      {" "}
                      <RupeeIcon /> Disc. PR
                    </span>
                  </th>
                  <th className="py-2 px-2 border-D0D5DD border border-1 text-right text-nowrap  text-secondary fw_400 text-sm min-w-[5rem]">
                    <span className="flex gap-2 items-center justify-end">
                      {" "}
                      <QtyIcon /> Tax %
                    </span>
                  </th>
                  <th className="py-2 px-2 border-D0D5DD border border-1 text-right text-nowrap  text-secondary fw_400 text-sm min-w-[5rem]">
                    <span className="flex gap-2 items-center justify-end">
                      {" "}
                      <RupeeIcon />
                      Tax Amt.
                    </span>
                  </th>
                  <th className="py-2 px-2 border-D0D5DD border border-1 text-nowrap text-right  text-secondary fw_400 text-sm min-w-[5rem]">
                    <span className="flex gap-2 items-center justify-end">
                      {" "}
                      <RupeeIcon /> Final PR
                    </span>
                  </th>
                  <th className="py-2 px-2 border-D0D5DD border border-1 text-right text-nowrap  text-secondary fw_400 text-sm min-w-[5rem]">
                    <span className="flex gap-2 items-center justify-end">
                      {" "}
                      <RupeeIcon /> MRP
                    </span>
                  </th>
                  <th className="py-2 px-2 border-D0D5DD border text-nowrap border-1 text-right  text-secondary fw_400 text-sm min-w-[5rem]">
                    <span className="flex gap-2 items-center justify-end">
                      {" "}       
                      <RupeeIcon /> Tot Amt
                    </span>
                  </th>

                  <th className="py-2 px-2 border-D0D5DD border border-1 text-left  text-secondary fw_400 text-sm">
                    <span className="flex gap-2 items-center ">
                      {" "}
                      <CalendorIcon /> Exp. Date
                    </span>
                  </th>
                  <th className="py-2 px-2 border-D0D5DD border border-1 text-left  text-secondary fw_400 text-sm  min-w-[8rem]">
                    <span className="flex gap-2 items-center ">
                      {" "}
                      <QtyIcon /> To Bin{" "}
                    </span>
                  </th>
                  {!disabled && (
                    <th className="py-2 px-2 border-D0D5DD border border-1 text-left  text-secondary fw_400 text-sm"></th>
                  )}
                </tr>
              </thead>
              <tbody>
                {defaultSelectedItem?.item_batches.map((batch, batchindex) => (
                  <tr key={batchindex}>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm ">
                      <Field
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.item_batch_number`}
                      >
                        {({ field }) => (
                          <input
                            disabled={disabled}
                            autoFocus={true}
                            onKeyDown={(e) => {
                              if (e.key === " ") {
                                e.preventDefault(); // Prevent space from being typed
                              }
                            }}
                            placeholder=""
                            {...field}
                            type="text"
                            className={`w-full focus_outline__none focus:border-focus-color focus:border rounded-md py-2 px-2`}
                          />
                        )}
                      </Field>
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm max-w-[6rem] min-w-[5rem]">
                      <Field
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.item_sellable_quantity`}
                      >
                        {({ field, form }) => (
                          <input
                            disabled={disabled}
                            min={0}
                            placeholder=""
                            {...field}
                            type="number"
                            className={`w-full focus_outline__none focus:border-focus-color focus:border rounded-md py-2 px-2 text-right`}
                            onChange={(e) => {
                              field.onChange(e); // Ensure field.onChang
                              handleQuantityChange(
                                defaultSelectedItemIndex,
                                batchindex,
                                +e?.target?.value ?? 0,
                                +batch?.item_batch_purchase_rate ?? 0,
                                parseFloat(
                                  batch?.item_batch_discount_percentage ?? 0
                                ) / 100
                              );
                            }}
                            onInput={(e) => {
                              const value = e.target.value;
                              if (value < 0) {
                                form.setFieldValue(field.name, 0);
                              }
                            }}
                            onKeyDown={positiveIntegerFn}
                          />
                        )}
                      </Field>
                    </td>

                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm max-w-[6rem] min-w-[5rem]">
                      <Field
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.item_batch_free_quantity`}
                      >
                        {({ field }) => (
                          <input
                            disabled={disabled}
                            onKeyDown={positiveIntegerFn}
                            placeholder=""
                            {...field}
                            type="number"
                            className={`w-full focus_outline__none focus:border-focus-color focus:border rounded-md py-2 px-2 text-right`}
                          />
                        )}
                      </Field>
                    </td>

                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm max-w-[10rem]">
                      <Field
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.item_batch_purchase_rate`}
                      >
                        {({ field }) => (
                          <input
                            min={0}
                            disabled={disabled}
                            // value={field.value ? parseInt(field.value, 10) : ""}
                            onKeyDown={decimalNumberFn}
                            placeholder=""
                            {...field}
                            type="number"
                            onChange={(e) => {
                              field.onChange(e); // Ensure field.onChang

                              
                              handleQuantityChange(
                                defaultSelectedItemIndex,
                                batchindex,
                                +batch?.item_sellable_quantity,
                                e?.target?.value !== ""
                                  ? parseFloat(e?.target?.value)
                                  : e?.target?.value,
                                parseFloat(
                                  batch?.item_batch_discount_percentage ?? 0
                                ) / 100
                              );
                            }}
                            className={`w-full focus_outline__none focus:border-focus-color focus:border rounded-md py-2 px-2 text-right`}
                          />
                        )}
                      </Field>
                    </td>

                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm ">
                      <Field
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.item_batch_discount_percentage`}
                      >
                        {({ field }) => (
                          <input
                            min={0}
                            max={100}
                            disabled={disabled}
                            onKeyDown={decimalNumberFn}
                            placeholder=""
                            {...field}
                            type="number"
                            className={`w-full focus_outline__none focus:border-focus-color focus:border rounded-md py-2 px-2 text-right`}
                            onChange={(e) => {
                              field.onChange(e);
                              handleQuantityChange(
                                defaultSelectedItemIndex,
                                batchindex,
                                +batch?.item_sellable_quantity,
                                parseFloat(batch?.item_batch_purchase_rate),
                                parseFloat(e?.target?.value) / 100
                              );
                            }}
                          />
                        )}
                      </Field>
                    </td>

                    <td className="border-D0D5DD border border-1 py-2 pr-4 text-secondary fw_400 text-sm w-[5rem] text-right">
                      {batch?.item_batch_discount_amount?.toFixed(2)}
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 pr-4 text-secondary fw_400 text-sm text-right">
                      {(
                        parseFloat(
                          batch?.item_batch_purchase_rate
                            ? batch?.item_batch_purchase_rate
                            : 0
                        ) -
                        parseFloat(
                          batch?.item_batch_purchase_rate *
                            (batch?.item_batch_discount_percentage / 100)
                        )
                      )?.toFixed(2)}
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 pr-4 text-secondary fw_400 text-sm w-[5rem] text-right">
                      {batch?.item_batch_tax_percentage}
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 pr-4 text-secondary fw_400 text-sm w-[5rem] text-right">
                      {batch?.item_batch_tax_amount}
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 pr-4 text-secondary fw_400 text-sm w-[5rem] text-right">
                      {batch?.item_batch_final_purchase_rate}
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm max-w-[12rem]">
                      <Field
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.item_batch_unit_price`}
                      >
                        {({ field }) => (
                          <input
                            min={0}
                            disabled={disabled}
                            onKeyDown={decimalNumberFn}
                            placeholder=""
                            {...field}
                            type="number"
                            className={`w-full focus_outline__none focus:border-focus-color focus:border rounded-md py-2 px-2 text-right`}
                          />
                        )}
                      </Field>
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 pr-4 text-secondary fw_400 text-sm w-[5rem] text-right">
                      {batch?.item_batch_total_purchase_rate}
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm w-[5rem]">
                      <Field
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.item_exp_date`}
                      >
                        {({ field }) => (
                          <input
                            disabled={disabled}
                            placeholder=""
                            {...field}
                            type="date"
                            className={`w-full focus_outline__none focus:border-focus-color focus:border rounded-md py-2`}
                          />
                        )}
                      </Field>
                    </td>
                    <td className="border-D0D5DD border border-1  py-2 px-1 text-secondary fw_400 text-sm w-[8rem]">
                      <SelectField
                        isDisabled={disabled}
                        general_color={false}
                        // autoFocus={
                        //   values.data[defaultSelectedItemIndex]?.item_batches[
                        //     batchindex
                        //   ]?.item_batch_number
                        // }
                        defaultValue={{
                          label:
                            binLocationList?.find(
                              (bin) =>
                                bin?.value ===
                                values.data[defaultSelectedItemIndex]
                                  ?.item_batches[batchindex]?.to_bin_id
                            )?.label ?? "",
                          value: "",
                        }}
                        handleSelected={(selectedoption) => {
                          setFieldValue(
                            `data.${defaultSelectedItemIndex}.item_batches.${batchindex}.to_bin_location`,
                            selectedoption?.actualLabel
                          );
                        }}
                        labelClass="whitespace-nowrap flex text-sm fw_600 text-secondary"
                        containerClass="w-full"
                        placeholder={"select Bin"}
                        options={binLocationList}
                        label={""}
                        id={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.to_bin_id`}
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.to_bin_id`}
                        isSearchable={true}
                        errMsgContainerClass="ps-2 hidden"
                        labelWidth="7rem"
                      />
                    </td>

                    {!disabled && (
                      <td className="border-D0D5DD border border-1 py-2 px-2 text-secondary fw_400 text-sm ">
                        <button
                          onClick={() =>
                            handleDeleteBatch(
                              defaultSelectedItemIndex,
                              batchindex,
                              batch
                            )
                          }
                          // onClick={() => {
                          //   const updatedBatches = [
                          //     ...defaultSelectedItem.item_batches,
                          //   ];
                          //   updatedBatches.splice(batchindex, 1);
                          //   setFieldValue(
                          //     `data.${defaultSelectedItemIndex}.item_batches`,
                          //     updatedBatches
                          //   );
                          //   handleQuantityChange(
                          //     defaultSelectedItemIndex,
                          //     batchindex,
                          //     +batch?.item_sellable_quantity,
                          //     +batch?.item_batch_purchase_rate,
                          //     true
                          //   );
                          // }}
                          type="button"
                        >
                          <DeleteIcon />
                        </button>
                        {/* )} */}
                      </td>
                    )}

                    {/* <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm">
                      <SelectField
                        defaultValue={{
                          label:
                            binLocationList?.find(
                              (bin) =>
                                bin?.value ===
                                values.data[defaultSelectedItemIndex]
                                  ?.item_batches[batchindex]?.from_bin_id
                            )?.label ?? "",
                          value: "",
                        }}
                        handleSelected={(selectedoption) => {
                          setFieldValue(
                            `data.${defaultSelectedItemIndex}.item_batches.${batchindex}.from_bin_location`,
                            selectedoption?.actualLabel
                          );
                        }}
                        labelClass="whitespace-nowrap flex text-sm fw_600 text-secondary"
                        containerClass="w-full"
                        isDisabled={true}
                        placeholder={"select Bin"}
                        options={binLocationList}
                        label={""}
                        id={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.from_bin_id`}
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.from_bin_id`}
                        isSearchable={true}
                        errMsgContainerClass="ps-2 hidden"
                        labelWidth="7rem"
                      />
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>{" "}
            {!disabled && (
              <div className="flex justify-end lg:w-[100%] w-full">
                <AddButton
                  className="bg-primary text-white px-8 py-1 mt-2 rounded flex justify-center fw_500 text-sm"
                  onclkFn={() => {
                    setFieldValue(
                      `data.${defaultSelectedItemIndex}.item_batches`,
                      [
                        ...values?.data?.[defaultSelectedItemIndex]
                          ?.item_batches,
                        initData,
                      ]
                    );
                  }}
                  prefixIcon={true}
                  text=""
                  type="button"
                />
              </div>
            )}
          </div>
        </div>
        {!disabled && (
          <div className="flex justify-between px-10 sticky bottom-0   bg-white py-2 invoice-bottom-container">
            <AddButton
              onclkFn={() => handleShowErrorToast("")}
              prefixIcon={false}
              text="Done"
              type="button"
            />
          </div>
        )}
      </div>
      <div
        className="bg-black h-[23vh] opacity-50"
        onClick={() => handleShowErrorToast("")}
      ></div>
    </div>
  );
};

export default PurchaseBatchesPopover;
