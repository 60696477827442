import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import InputField from "../forms/input-field";
import CheckBox from "../forms/check-box-field";
import AddButton from "../buttons/button";
import { useDispatch } from "react-redux";
import successToast from "../helper-components/success-toast";
import {
  adminCustomerAccountingAction,
  adminCustomerUpdateAccountingAction,
} from "../../redux/actions/admin-customer-action";

const AccountingTab = ({
  setCurrentTab,
  customerGeneralData,
  customerId,
  setReload,
  reload,
  customerDetails,
  isDisabled = false,
  
}) => {
  const dispatch = useDispatch();

  const initialValues = {
    cmr_id: customerId,
    cmr_business_partner: customerDetails.cmr_business_partner || "",
    cmr_accounts_payable: customerDetails.cmr_accounts_payable || 0,
    cmr_payment_advances: customerDetails.cmr_payment_advances || 0,
    cmr_connected_customer: customerDetails.cmr_connected_customer || "",
    cmr_payment_consolidation: customerDetails.cmr_payment_consolidation || false,
    cmr_delivery_consolidation: customerDetails.cmr_delivery_consolidation || false,
  };
  

  const validationSchema = Yup.object().shape({
    cmr_business_partner: Yup.string().required("Buisness Partner is required"),
  });
  const innerOnSubmit = (values) => {
    let actionType = adminCustomerAccountingAction;
    if (customerDetails?.cmr_business_partner) {
      actionType = adminCustomerUpdateAccountingAction;
    }
    dispatch(
      actionType(
        {
          customerGeneralData: customerGeneralData,
          accountingData: values,
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = (data) => {
    successToast(data?.message);
    setCurrentTab("Address");
    setReload(!reload);
  };
  
  const onError = (data) => {};

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={innerOnSubmit}
    >
      {({ values, handleSubmit, errors }) => {
        return (
          <>
            <div className="flex flex-wrap">
              <div className="w-full 2xl:w-[35%] lg:w-[50%] px-5 xl:px-10">
                <InputField
                 isDisabled={isDisabled}
                  type="text"
                  name="cmr_business_partner"
                  placeholder="Business Partner"
                  label="Business Partner"
                  isRequired={true}
                  errMsgContainerClass="ps-2"
                  labelWidth="10rem"
                />
                <InputField
                 isDisabled={isDisabled}
                  type="text"
                  name="cmr_accounts_payable"
                  placeholder="Accounts Payable"
                  label="Accounts Payable"
                  isRequired={false}
                  errMsgContainerClass="ps-2"
                  labelWidth="10rem"
                />
                <InputField
                 isDisabled={isDisabled}
                  type="text"
                  name="cmr_payment_advances"
                  placeholder="Payment Advances"
                  label="Payment Advances"
                  isRequired={false}
                  errMsgContainerClass="ps-2"
                  labelWidth="10rem"
                />
                <InputField
                 isDisabled={isDisabled}
                  type="text"
                  name="cmr_connected_customer"
                  placeholder="Connected Customer"
                  label="Connected Customer"
                  isRequired={false}
                  errMsgContainerClass="ps-2"
                  labelWidth="10rem"
                />
              </div>

              <div className="w-full 2xl:w-[65%] lg:w-[50%] px-5 xl:px-10">
                <CheckBox  isDisabled={isDisabled} items={checkboxItems} />
              </div>
            </div>
            {!isDisabled && (
              <div
                style={{
                  bottom: "0",
                }}
                className="flex w-full justify-end px-10 mb-5x sticky bg-white py-4 invoice-bottom-container mt-auto"
              >
                <AddButton
                  onclkFn={handleSubmit}
                  prefixIcon={false}
                  text="Save"
                  type="submit"
                />
              </div>
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default AccountingTab;

const checkboxItems = [
  {
    label: "Payment Consolidation",
    name: "cmr_payment_consolidation",
    isRequired: false,
  },
  {
    label: "Delivery Consolidation",
    name: "cmr_delivery_consolidation",
    isRequired: false,
  },
];
