import React, { useEffect, useState } from "react";
import BarGraph from "../../components/graphs/bar-graph";
import InnerLayout from "../../components/layouts/inner-layout";
import LineGraph from "../../components/graphs/line-grapj";
import LowStockTable from "../../components/dashboard/low-stock-product-table";
import ExpirationProductTable from "../../components/dashboard/expiration-product-table";
import { useDispatch } from "react-redux";
import {
  dashboardExpStockAction,
  dashboardLowStockAction,
  dashboardNewCusGraphAction,
  dashboardSalesAction,
  dashboardTopSellingProductsAction,
} from "../../redux/actions/dashboard-action";
import LoadingSpinner from "../../components/helper-components/loading-spinner";
import NewCusLineGraph from "../../components/graphs/new-cus-line-graph";
import errorToast from "../../components/helper-components/error-toast";

const DashBoard = () => {
  const dispatch = useDispatch();
  const [lineGraphData, setLineGraphData] = useState([]);
  const [cusGraphData, setCusGraphData] = useState([]);
  const [barGraphData, setBarGraphData] = useState([]);
  const [lowStockPro, setLowStockPro] = useState([]);
  const [expStockPro, setExpStockPro] = useState([]);
  const [loading, setLoading] = useState(true);
  const [salesData, setSalesData] = useState();
  const [newCusData, setNewCusData] = useState();
  const [topSellingData, setTopSellingData] = useState();
  const [selectedOption, setSelectedOption] = useState({
    lineGraph: "daily",
    lineGraphDate: { from: null, to: null },
    barGraph: "daily",
    barGraphDate: { from: null, to: null },
    barRadio: "quantity",
    cusGraph: "daily",
    cusGraphDate: { from: null, to: null },
  });

  useEffect(() => {
    if (selectedOption?.lineGraph) {
      dispatch(
        dashboardSalesAction(selectedOption, onSuccessSales, onErrorSales)
      );
    }
  }, [selectedOption?.lineGraph, selectedOption?.lineGraphDate]);

  useEffect(() => {
    if (selectedOption?.cusGraph) {
      dispatch(
        dashboardNewCusGraphAction(selectedOption, onSuccessCus, onErrorCus)
      );
    }
  }, [selectedOption?.cusGraph, selectedOption?.cusGraphDate]);

  useEffect(() => {
    if (selectedOption?.barGraph) {
      dispatch(
        dashboardTopSellingProductsAction(
          selectedOption,
          onSuccessTopSelling,
          onErrorTopSelling
        )
      );
    }
  }, [
    selectedOption?.barGraph,
    selectedOption?.barRadio,
    ,
    selectedOption?.barGraphDate,
  ]);

  const onSuccessSales = (data) => {
    setLoading(false);
    setSalesData(data?.data);
  };
  const onSuccessCus = (data) => {
    setLoading(false);
    setNewCusData(data?.data);
  };
  const onSuccessTopSelling = (data) => {
    
    setLoading(false);
    setTopSellingData(data?.data);
  };
  const onErrorSales = (data) => {
    setLoading(false);
    errorToast(data?.data?.message);
    // throw new Error("An error occurred while fetching sales data");
  };
  const onErrorCus = (data) => {
    setLoading(false);
    // throw new Error("An error occurred while fetching sales data");
  };
  const onErrorTopSelling = (data) => {
    setLoading(false);
    // throw new Error("An error occurred while fetching sales data");
  };

  useEffect(() => {
    dispatch(dashboardLowStockAction(onSuccessLow, onErrorLow));
    dispatch(dashboardExpStockAction(onSuccessExp, onErrorExp));
  }, []);
  const onSuccessLow = (data) => {
    
    setLoading(false);
    setLowStockPro(
      data?.data.map((item) => ({
        name: item?.item_name,
        quantity: parseInt(item?.total_quantity),
      }))
    );
  };
  const onErrorLow = () => {
    setLoading(false);
  };
  const onSuccessExp = (data) => {
    setLoading(false);
    setExpStockPro(
      data?.data.map((item) => ({
        name: item?.item_name,
        item_batch_number: item?.item_batch_number,
        item_exp_date: item?.item_exp_date,
      }))
    );
  };
  const onErrorExp = () => {
    setLoading(false);
  };

  useEffect(() => {
    const transformData = (option, data) => {
      if (!data) return [];
      if (option === "daily") {
        return data.map((item) => ({
          name: new Date(item.hour).toLocaleTimeString("en-IN", {
            hour: "2-digit",
            minute: "2-digit",
          }),
          uv: parseFloat(item.total_sales),
          sales: parseFloat(item.total_sales),
          amt: parseFloat(item.total_sales),
        }));
      } else if (option === "weekly") {
        const weeklyData = data.map((item) => ({
          name: item.day_of_week?.trim()?.slice(0, 3),
          uv: parseFloat(item.total_sales),
          sales: parseFloat(item.total_sales),
          amt: parseFloat(item.total_sales),
        }));

        return weeklyData;
      } else if (option === "yearly") {
        return data.map((item) => ({
          name: item.month_name?.trim().slice(0, 3),
          uv: parseFloat(item.total_sales),
          sales: parseFloat(item.total_sales),
          amt: parseFloat(item.total_sales),
        }));
      } else if (option === "custom_date") {
        return data.map((item) => ({
          name: item.date?.trim(),
          uv: parseFloat(item.total_sales),
          sales: parseFloat(item.total_sales),
          amt: parseFloat(item.total_sales),
        }));
      } else {
        return [];
      }
    };

    setLineGraphData(
      transformData(selectedOption?.lineGraph, salesData?.line_graph_data)
    );
  }, [selectedOption?.lineGraph, salesData]);

  useEffect(() => {
    const transformData = (option, data) => {
      if (!data) return [];
      if (option === "daily") {
        return data.map((item) => ({
          name: new Date(item.hour).toLocaleTimeString("en-IN", {
            hour: "2-digit",
            minute: "2-digit",
          }),
          uv: parseFloat(item.total_customers),
          customers: parseFloat(item.total_customers),
          amt: parseFloat(item.total_customers),
        }));
      } else if (option === "weekly") {
        const weeklyData = data.map((item) => ({
          name: item.day_of_week?.trim()?.slice(0, 3),
          uv: parseFloat(item.total_customers),
          customers: parseFloat(item.total_customers),
          amt: parseFloat(item.total_customers),
        }));

        return weeklyData;
      } else if (option === "yearly") {
        return data.map((item) => ({
          name: item.month_name?.trim().slice(0, 3),
          uv: parseFloat(item.total_customers),
          customers: parseFloat(item.total_customers),
          amt: parseFloat(item.total_customers),
        }));
      } else if (option === "custom_date") {
        return data.map((item) => ({
          name: item.date?.trim(),
          uv: parseFloat(item.total_customers),
          customers: parseFloat(item.total_customers),
          amt: parseFloat(item.total_customers),
        }));
      } else {
        return [];
      }
    };

    setCusGraphData(
      transformData(selectedOption?.cusGraph, newCusData?.line_graph_data)
    );
  }, [selectedOption?.cusGraph, newCusData]);

  useEffect(() => {
    const transformBarData = (option, data, type) => {
      if (!data) return [];
      if (option) {
        return data.map((item) => ({
          name: item.item_name?.trim(),
          Sales: parseFloat(
            type === "quantity" ? item.total_quantity : item.total_sales
          ),
        }));
      } else {
        return [];
      }
    };
    setBarGraphData(
      transformBarData(
        selectedOption?.barGraph,
        topSellingData,
        selectedOption?.barRadio
      )
    );
  }, [selectedOption?.barGraph, selectedOption?.barRadio, topSellingData]);

  const options = [
    { value: "daily", label: "Today" },
    { value: "weekly", label: "Weekly" },
    { value: "yearly", label: "Yearly" },
    { value: "custom_date", label: "Custom Date" },
  ];

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <InnerLayout title="Dashboard">
          <div class="flex flex-wrap">
            <div class="w-full  p-4 lg:w-1/2">
              <LineGraph
                total_orders={salesData?.total_orders}
                total_sales={salesData?.total_sales}
                options={options}
                lineGraphData={lineGraphData}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
            </div>
            <div class="w-full  p-4 lg:w-1/2">
              <BarGraph
                yCoordinate={
                  selectedOption?.barRadio === "revenue"
                    ? "Revenue"
                    : "No. of product sold"
                }
                barGraphData={barGraphData}
                options={options}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full  p-7 lg:w-1/2">
              <LowStockTable lowStockPro={lowStockPro} />
            </div>
            <div class="w-full  p-7 lg:w-1/2">
              <ExpirationProductTable expStockPro={expStockPro} />
            </div>
          </div>
          <div class="w-full  p-4 lg:w-1/2">
            <NewCusLineGraph
              total_sales={newCusData?.total_customers}
              options={options}
              cusGraphData={cusGraphData}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          </div>
   
        </InnerLayout>
      )}
    </>
  );
};

export default DashBoard;
