import React from 'react';
import { ChevronRight } from 'lucide-react';
export const TableRow = ({ name, type, report, navigate }) => {
  const handleItemClick = (clickedReport) => {
    if(type==="Custom Report"){
      navigate(clickedReport);
    }
    else{
      navigate(clickedReport.path); 
    }
    // Navigate to the path of the clicked report
    const element = document.querySelector(".innerLayoutScroller"); // Select the scrollable container
    if (element.style.overflow === "hidden") {
      element.style.overflow = "auto"; // Enable scrolling if it's disabled
    }
  };

  return (
    <div
      className="flex items-center bg-white border-b border-D0D5DD px-4 py-3 hover:bg-f3f8ff cursor-pointer font-semibold"
      onClick={() => handleItemClick(report)} // Trigger the navigation and overflow change on click
    >
      {/* Use flex to space the items like columns */}
      <div className="flex-1 text-primary">{name}</div> {/* Name aligned in the first column */}
      <div className="flex items-center flex-1 ">
        <span className="text-gray-600 text-sm">{type}</span> {/* Type aligned in the second column */}
        <ChevronRight className="w-4 h-4 " />
      </div>
    </div>
  );
};
