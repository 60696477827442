import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { useHotkeys } from "react-hotkeys-hook";
import { CustomerIcon } from "../../assets/icons/invoice-header-icons";
import ArrowDownIcon from "../../assets/icons/arrow-down";
import {
  phoneListAction,
  transactionPrintAction,
} from "../../redux/actions/general-action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { returnBillCreateAction } from "../../redux/actions/invoices-action";
import AddButton from "../buttons/button";
import successToast from "../helper-components/success-toast";
import AsyncCreateSelect from "../forms/async-create-select-field";
import errorToast from "../helper-components/error-toast";
import { calculateSubtotal } from "../invoice-section/billing-final-table";
import SelectField from "../forms/select-field";
import TextAreaField from "../forms/textarea-field";
import ReturnBillingTable from "./return-billing-table";
import ReturnBillPdfContent from "./return-bill-pdf-content";
import { handleReturnBillPrint } from "./invoice-thermal-printer";
import moment from "moment";
import {
  handleElectronPrint,
  handlea4Print,
} from "../helper-components/helper-function";

const CreateEditViewReturnBill = ({
  disabled = false,
  storeDetails = {},
  storeId = {},
  orderDetail = {},
  page = "",
}) => {
  const searchRef = useRef(null);
  const [heightOfFirstDiv, setHeightOfFirstDiv] = useState(0);
  useEffect(() => {
    const updateHeight = () => {
      const firstDivHeight =
        document?.querySelector(".first-div")?.clientHeight;
      setHeightOfFirstDiv(firstDivHeight);
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const initialData = {
    sl_no: 1,
    linked_invoice_number: "",
    item_code: "",
    item_generic_name: "",
    item_name: "",
    item_open_quantity: "",
    quantity: "",
    item_free_quantity: 0,
    item_uom: "",
    item_batch_number: "",
    item_exp_date: "",
    item_rack_location: "",
    item_manufacturer_name: "",
    item_schedule: "",
    item_hsn: "",
    item_unit_price: 0,
    item_price_wiithout_tax: 0,
    item_discount_amount: 0.0,
    item_discount_percentage: 0,
    item_tax_amount: 0,
    item_gst: 0,
    item_final_price: 0,
    item_total_amount: 0,
    item_id: "",
    instock: true,
    item_sgst: 0,
    item_cgst: 0,
    item_price_wiithout_tax: 0,
    item_manufacturer_id: "",
  };
  const initialFormValues = {
    phone: orderDetail?.vendorDetails?.cmr_phone_number
      ? {
          label: orderDetail?.vendorDetails?.cmr_phone_number,
          value: orderDetail?.vendorDetails?.cmr_id,
        }
      : "",
    cmr_first_name: orderDetail?.vendorDetails?.cmr_first_name,
    cmr_last_name: orderDetail?.vendorDetails?.cmr_last_name,
    data: [
      ...orderDetail?.itemData?.map((item, index) => ({
        sl_no: index + 1,
        linked_invoice_number:
          item?.linked_invoice_number ?? item?.sot_invoice_number,
        item_code: item?.item_code,
        item_generic_name: item?.item_generic_name,
        item_name: item?.item_name,
        item_open_quantity: item?.item_open_quantity,
        quantity:
          page === "view" ? item?.item_quantity : item?.item_open_quantity,
        item_free_quantity:
          item?.item_free_quantity === null ? 0 : item?.item_free_quantity ?? 0,
        item_uom: item?.item_uom,
        item_batch_number: item?.item_batch_number,
        item_exp_date: item?.item_exp_date,
        item_rack_location: item?.item_rack_location,
        item_manufacturer_name: item?.item_manufacturer_name,
        item_schedule: item?.item_schedule,
        item_hsn: item?.item_hsn,
        item_unit_price: item?.item_unit_price,
        item_price_wiithout_tax: itemTotalPricewithoutTax({
          ...item,
          item_open_quantity:
            page === "view" ? item?.item_quantity : item?.item_open_quantity,
        }),
        item_discount_amount: calculateItemDis({
          ...item,
          item_open_quantity:
            page === "view" ? item?.item_quantity : item?.item_open_quantity,
        }),
        item_discount_percentage: item?.item_discount_percentage,
        item_tax_amount: itemTotalTax({
          ...item,
          item_open_quantity:
            page === "view" ? item?.item_quantity : item?.item_open_quantity,
        }),
        item_gst: item?.item_gst,
        item_final_price:
          (parseFloat(item?.item_unit_price) *
            parseFloat(
              page === "view" ? item?.item_quantity : item?.item_open_quantity
            ) -
            calculateItemDis({
              ...item,
              item_open_quantity:
                page === "view"
                  ? item?.item_quantity
                  : item?.item_open_quantity,
            })) /
          parseFloat(
            page === "view" ? item?.item_quantity : item?.item_open_quantity
          ),
        item_total_amount: (
          parseInt(
            page === "view" ? item?.item_quantity : item?.item_open_quantity
          ) *
            parseFloat(item?.item_unit_price) -
          calculateItemDis({
            ...item,
            item_open_quantity:
              page === "view" ? item?.item_quantity : item?.item_open_quantity,
          })
        ).toFixed(2),
        item_id: item?.item_id,
        instock: true,
        item_cgst: item?.item_cgst,
        item_sgst: item?.item_sgst,
        itemBatchList: item?.itemBatchList,
        sot_id: item?.sot_id,
        item_max_free_quantity:
          parseInt(item?.item_free_quantity ?? 0, 10) || 0,
        item_manufacturer_id: item?.item_manufacturer_id ?? "",
      })),
      initialData,
    ],
    doctor: "",
    payment_method: "Cash",
    icn_remarks:
      orderDetail?.vendorDetails?.icn_remarks ?? "Thank you for choosing us 🙏🏼",
    paymentData: [
      {
        payment_mode_name: "cash",
        payment_amount: null,
        transaction: "",
      },
    ],
  };
  const [initialValue, setInitialValue] = useState(initialFormValues);
  const [formValues, setFormValues] = useState({});
  const [localData, setLocalData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [invoiceType, setInvoiceType] = useState("");
  const [alternativeID, setAlternativeID] = useState({
    itemid: "",
    index: null,
  });
  const validationSchema = Yup.object().shape({
    phone: Yup.object()
      .required("Phone number is required")
      .test(
        "is-ten-digits",
        "Please enter valid phone number",
        (value) => value && value.label && /^\d{10}$/.test(value.label)
      ),
  });
  const invoiceDetails = [
    {
      image: <CustomerIcon />,
      title: "View Customer Details",
    },
  ];

  const loadOptions = (inputValue) => {
    return new Promise((resolve) => {
      const onSuccess = (data) => {
        const options = [
          ...data.data.map((item) => ({
            value: item.cmr_id,
            label: item?.cmr_phone_number,
          })),
          {
            value: { text: "new_customer", number: inputValue },
            label: <span className="text-primary">+ New Customer</span>,
          },
        ];
        setLocalData(data.data);
        resolve(options);
      };

      const onError = (error) => {
        resolve([
          {
            value: { text: "new_customer", number: inputValue },
            label: <span className="text-primary">+ New Customer</span>,
          },
        ]);
      };

      if (inputValue?.length > 0) {
        dispatch(
          phoneListAction(
            {
              phone: inputValue,
            },
            onSuccess,
            onError
          )
        );
      } else {
        resolve([]);
      }
    });
  };

  const handleSubmit = (values, actions) => {
    setTimeout(() => {
      if (
        values?.data
          ?.slice(0, -1)
          .some((item) => parseInt(item.quantity) === 0 || item.quantity === "")
      ) {
        errorToast("Quantity cannot be zero");
      } else {
        if (values?.data?.length > 1) {
          dispatch(
            returnBillCreateAction(
              {
                orderData: {
                  store_id: storeId,
                  cmr_phone_number: parseInt(values?.phone?.label),
                  icn_total_gst: parseFloat(
                    formValues?.data
                      ?.slice(0, -1)
                      .reduce(
                        (total, item) =>
                          total + parseFloat(item?.item_tax_amount),
                        0
                      )
                  ),
                  icn_total_discount: values?.data
                    ?.slice(0, -1)
                    .reduce(
                      (total, item) =>
                        total + parseFloat(item?.item_discount_amount),
                      0
                    ),
                  icn_payment_status:
                    values.submittedButton === "draft" ? "pending" : "paid",
                  icn_transaction_id: "",
                  icn_payment_method: values?.payment_method,
                  icn_billing_address: "",
                  icn_sub_total: parseFloat(calculateSubtotal(values?.data)),
                  icn_total_amount: parseFloat(calculateTotal(values)),
                  doctor_name: values?.doctor?.value,
                  icn_remarks: values?.icn_remarks,
                },
                paymentData: [
                  {
                    payment_mode_name: "cash",
                    payment_amount: parseFloat(calculateTotal(values)),
                  },
                ],
                itemData: values?.data?.slice(0, -1).map((item, index) => ({
                  sot_id: item?.sot_id,
                  item_code: item?.item_code,
                  item_generic_name: item?.item_generic_name,
                  item_name: item?.item_name,
                  item_quantity: parseInt(item?.quantity),
                  item_open_quantity: parseInt(item?.item_open_quantity),
                  item_free_quantity:
                    parseInt(item?.item_free_quantity ?? 0, 10) || 0,
                  item_uom: item?.item_uom,
                  item_batch_number: item?.item_batch_number,
                  item_exp_date: moment(item?.item_exp_date).format(
                    "YYYY-MM-DD"
                  ),
                  item_rack_location: item?.item_rack_location,
                  // item_mfg_by: item?.item_mfg_by,
                  item_schedule: item?.item_schedule,
                  item_hsn: item?.item_hsn,
                  item_unit_price: parseFloat(item?.item_unit_price),
                  item_price_wiithout_tax: parseFloat(
                    item?.item_price_wiithout_tax
                  ),
                  item_discount_amount: parseFloat(item?.item_discount_amount),
                  item_discount_percentage: parseFloat(
                    item?.item_discount_percentage
                  ),
                  item_tax_amount: itemTotalTax({
                    ...item,
                    item_open_quantity: item?.quantity,
                  }),
                  // item_final_price: item?.item_final_price,
                  item_total_amount: parseFloat(
                    parseFloat(item.item_unit_price) * parseInt(item.quantity) -
                      parseFloat(item.item_discount_amount)
                  ),
                  item_id: item?.item_id,
                  item_gst: parseFloat(item?.item_gst),
                  item_sgst: parseFloat(item?.item_sgst),
                  item_cgst: parseFloat(item?.item_cgst),
                  item_manufacturer_id: item?.item_manufacturer_id ?? "",
                  item_manufacturer_name: item?.item_manufacturer_name ?? "",
                })),
              },
              onSuccessOrder,
              onErrorOrder
            )
          );
        }
      }
    }, 400); // Timeout set to 100ms
  };
  const downloadPdf = async (data) => {
    const fileName = "test.pdf";
    try {
      const blob = await pdf(
        <ReturnBillPdfContent data={data} storeDetails={storeDetails} />
      ).toBlob();
      saveAs(blob, fileName);
    } catch (error) {}
  };

  const onSuccessOrder = (data) => {
    navigate("/return-bills");
    handlea4Print(
      data?.data?.orderData?.[0]?.icn_id,
      "invoice_credit_note",
      dispatch
    );

    //Unwrap
    // handleReturnBillPrint(
    //   {
    //     items: data?.data?.itemData,
    //     orderData: data?.data?.orderData,
    //   },
    //   storeDetails
    // );
    setInitialValue({
      phone: "",
      cmr_first_name: "",
      cmr_last_name: "",
      cmr_acquired_source: "",
      gst_tax: "",
      RepeatLastOrder: false,
      data: [initialData],
      icn_remarks: "",
      paymentData: [
        {
          payment_mode_name: "",
          payment_amount: null,
          transaction: "",
        },
        {
          payment_mode_name: "",
          payment_amount: null,
          transaction: "",
        },
        {
          payment_mode_name: "",
          payment_amount: null,
          transaction: "",
        },
      ],
      doctor: "",
      payment_method: "",
    });
    // downloadPdf({
    //   items: data?.data?.itemData,
    //   orderData: data?.data?.orderData,
    // });
    successToast(data?.message);
    setTimeout(() => {}, 50);
  };
  const onErrorOrder = () => {};
  useHotkeys("escape", () => {
    setInvoiceType("");
  });

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, setFieldError, errors }) => {
          setFormValues(values);
          return (
            <Form className="h-full">
              <div className="flex flex-col h-full ">
                <div className="first-div">
                  <>
                    <div className="bg-F2F4F5 flex flex-col lg:flex-row justify-start lg:justify-between lg:items-center px-3 border-EAECF0 border py-1 whitespace-wrap">
                      <div className="flex space-x-5 h-full items-center">
                        {invoiceDetails?.map((item, index) => (
                          <button
                            type="button"
                            key={index}
                            onClick={() =>
                              setInvoiceType(
                                item?.title === invoiceType ? "" : item?.title
                              )
                            }
                            id={item?.title.toLowerCase().split(" ").join("-")}
                            disabled={index === 0 && !values?.cmr_first_name}
                            className={`flex items-center space-x-3 text-sm text-secondary cursor-pointer h-full ${
                              index === 0 &&
                              !values?.cmr_first_name &&
                              ` cursor-not-allowed`
                            }
                                  ${
                                    index === 1 &&
                                    alternativeID?.itemid === "" &&
                                    `cursor-not-allowed`
                                  }
                                  `}
                          >
                            {item?.image}{" "}
                            <span className="select-none">{item?.title}</span>{" "}
                            <ArrowDownIcon
                              invoiceType={item?.title === invoiceType}
                            />
                            {index < invoiceDetails.length - 1 && (
                              <span className="ml-2 h-6 bg-EAECF0 w-0.5 inline-block" />
                            )}
                          </button>
                        ))}
                      </div>
                    </div>
                    {invoiceType === "View Customer Details" && (
                      <div className="border-EAECF0 border flex gap-5 py-4 flex-wrap px-5">
                        {values?.cmr_first_name && (
                          <div className="fw_400 text-sm">
                            Name:{" "}
                            <span className="fw_500 text-sm">
                              {values?.cmr_first_name }
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                  <div className="mt-5 px-3 flex justify-between md:gap-0 gap-5">
                    <div
                      style={{
                        maxWidth: "32rem",
                      }}
                      className="w-full"
                    >
                      <AsyncCreateSelect
                        disabled={disabled}
                        maxlength={10}
                        menuPortalTarget={document.body}
                        className="z-1 selectField_border"
                        marginRight="26px"
                        isValidNewOption={true}
                        autoFocus={true}
                        name={"phone"}
                        placeholder="Enter customer Phone"
                        onCreateOption={() => {}}
                        loadOptions={loadOptions}
                        onChange={(selectedOption) => {
                          if (
                            selectedOption === "new_customer" ||
                            selectedOption?.value?.text === "new_customer"
                          ) {
                            navigate(
                              `/add-customer?phoneNumber=${selectedOption?.value?.number}`
                            );
                          } else {
                            setFieldValue("phone", selectedOption);
                            const customerData = localData?.find(
                              (item) => item?.cmr_id === selectedOption?.value
                            );

                            setFieldValue(
                              "cmr_first_name",
                              customerData?.cmr_first_name
                            );
                            setFieldValue(
                              "cmr_last_name",
                              customerData?.cmr_last_name
                            );
                            setFieldValue(
                              "cmr_acquired_source",
                              customerData?.cmr_acquired_source
                            );
                            if (searchRef && searchRef.current) {
                              searchRef.current.focus();
                              setFieldError("phone", "");
                            }
                          }
                        }}
                      />
                    </div>
                    {!disabled && (
                      <button
                        type="button"
                        onClick={() => {
                          setFieldValue("data", [initialData]);
                        }}
                        className="text-primary underline text-sm fw_400 whitespace-nowrap mb-5"
                        tabIndex="-1"
                      >
                        Clear all
                      </button>
                    )}
                  </div>
                </div>
                {heightOfFirstDiv && (
                  <div
                    style={{
                      maxHeight: `calc(100% - ${heightOfFirstDiv}px)`,
                      height: `calc(100% - ${heightOfFirstDiv}px)`,
                    }}
                    className="flex flex-col "
                  >
                    <ReturnBillingTable
                      page={page}
                      disabled={disabled}
                      searchRef={searchRef}
                      initialData={initialData}
                      setAlternativeID={setAlternativeID}
                    />
                    <div className="mt-auto">
                      <div className="table-container flex">
                        <div
                          style={{
                            minWidth: `calc(100% - 20rem)`,
                          }}
                          className="bg-F2F4F5  flex "
                        >
                          <div className="w-[40%] border-r-EAECF0 border-r-2 px-4 py-3 ">
                            <span className="text-000000 fw_600 text-sm">
                              Remarks
                            </span>
                            <TextAreaField
                              errMsgContainerClass="hidden"
                              isDisabled={page === "view"}
                              containerClass="mt-2"
                              placeholder="Enter Remarks"
                              name="icn_remarks"
                              id={"icn_remarks"}
                              isRequired={false}
                              labelWidth="7rem"
                              feildHeight="5.6rem"
                            />
                          </div>

                          <div className="w-[35%] border-r-EAECF0 border-r-2 px-2 py-3 ">
                            <span className="text-000000 fw_600 text-sm">
                              Payment method
                            </span>
                            <div className="mt-2 flex flex-col gap-2 w-full">
                              {/* 1111111111111111111111 */}
                              <div className="flex gap-2 w-full">
                                <div
                                  style={{
                                    width: "40%",
                                  }}
                                >
                                  <SelectField
                                    defaultSelectClass="text-sm fw_500 fm_inter text-030304 h-[2.5rem] focus_outline__none "
                                    isDisabled={true}
                                    defaultValue={
                                      values.paymentData[0]
                                        ?.payment_mode_name && {
                                        label:
                                          values.paymentData[0]
                                            ?.payment_mode_name,
                                      }
                                    }
                                    errMsgContainerClass="flex justify-start hidden"
                                    id={`paymentData[${0}].payment_mode_name`}
                                    isContainer={false}
                                    options={[{ label: "Cash", value: "cash" }]}
                                    name={`paymentData[${0}].payment_mode_name`}
                                    placeholder={"Select"}
                                    style={{
                                      control: (base, state) => ({
                                        ...base,
                                        color: "#0D111A",
                                        height: "100%",

                                        minHeight: "",
                                        fontFamily: "Poppins,sans-serif ",
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        borderRadius: "10px",
                                        width: "100%",
                                        backgroundColor: state.isDisabled
                                          ? "#EAECF0"
                                          : base.backgroundColor,
                                        borderColor: state.isFocused
                                          ? "#277dfe"
                                          : "transparent",
                                        boxShadow: state.isFocused
                                          ? null
                                          : null,
                                        "&:hover": {},
                                      }),
                                      input: (base) => ({
                                        ...base,
                                        margin: "0",
                                        padding: "0",
                                      }),
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    height: "2.5rem",
                                  }}
                                  className={` w-[40%]  relative`}
                                >
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    ₹
                                  </span>
                                  {/* <Field
                                    // name={`paymentData[${0}].payment_amount`}
                                  > */}
                                  {/* {({ field, form }) => ( */}
                                  <input
                                    value={calculateTotal(values)?.toFixed(2)}
                                    // ref={inputRef1}
                                    disabled={true}
                                    placeholder=""
                                    // {...field}
                                    // name={`paymentData[${0}].payment_amount`}
                                    type="number"
                                    className={` w-full h-full   pl-7 fw_600 focus_outline__none input-field-no-spin-buttons rounded-lg border-D0D5DD border focus:border-primary focus:border bg-white disabled:bg-EAECF0 `}
                                    // onChange={(e) => {
                                    //   const value = e?.target?.value;
                                    //   form.setFieldValue(field.name, value);
                                    // }}
                                  />
                                  {/* //   )} */}
                                  {/* // </Field> */}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-col justify-between xl:px-10 w-[25%] text-sm border-r-EAECF0 border-r-2 px-4 py-3 fw_500 text-secondary whitespace-nowrap">
                            <span className="text-000000 fw_600 text-sm">
                              GST Information
                            </span>
                            <div className="w-full flex justify-between ">
                              <span>CGST (₹)</span>{" "}
                              <span className="text-000000">
                                {calculateTotalCGST(values?.data).toFixed(2)}
                              </span>
                            </div>
                            <div className="w-full flex justify-between ">
                              <span>SGST (₹)</span>{" "}
                              <span className="text-000000">
                                {" "}
                                {calculateTotalSGST(values?.data).toFixed(2)}
                              </span>
                            </div>
                            <div className="w-full flex justify-between ">
                              <span>IGST (₹)</span>{" "}
                              <span className="text-000000">
                                {calculateTotalIGST(values?.data).toFixed(2)}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          className="bg-F2F4F5 text-sm fw_500 text-secondary"
                          style={{
                            minWidth: "20rem",
                          }}
                        >
                          <div className="pt-2 pb-1 px-4 flex justify-between items-center">
                            <span>Sub Total (₹)</span>
                            <span>
                              {" "}
                              <input
                                disabled={true}
                                className="text-end w-[8rem] px-2 py-1 border border-D0D5DD rounded-md  text-000000"
                                type="text"
                                value={`${calculateSubtotal(
                                  values?.data
                                ).toFixed(2)}`}
                              />
                            </span>
                          </div>
                          <div className="py-1 px-4 flex justify-between items-center">
                            <span>Tax (₹)</span>
                            <span>
                              <input
                                disabled={true}
                                className="text-end w-[8rem] px-2 py-1 border border-D0D5DD rounded-md text-000000"
                                type="text"
                                value={`${calculateTotalTax(
                                  values?.data
                                ).toFixed(2)}`}
                              />
                            </span>
                          </div>
                          <div className="py-1 px-4 flex justify-between items-center">
                            <span>Discount (₹)</span>
                            <span>
                              <input
                                value={calculateTotalDiscount(
                                  values?.data
                                ).toFixed(2)}
                                disabled={true}
                                className="text-end w-[8rem] px-2 py-1 rounded-md border focus:placeholder-transparent focus:outline-none border-D0D5DD input-field-no-spin-buttons text-000000"
                                type="text"
                              />
                            </span>
                          </div>
                          <div className="py-1 px-4 flex justify-between fw_700 text-sm items-center">
                            <span>Total (₹)</span>
                            <span>
                              <input
                                disabled={true}
                                className="text-end w-[8rem] px-2 py-1 border rounded-md border-D0D5DD text-000000"
                                type="text"
                                value={calculateTotal(values)?.toFixed(2)}
                              />
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-between w-full invoice-bottom-container py-4 px-4 items-center">
                        <div></div>

                        {page === "view" ? (
                          <AddButton
                            prefixIcon={false}
                            text="Print"
                            type="button"
                            onclkFn={
                              () =>
                                handlea4Print(
                                  orderDetail?.vendorDetails?.icn_id,
                                  "invoice_credit_note",
                                  dispatch
                                )

                              // downloadPdf({
                              //   items: orderDetail?.itemData,
                              //   orderData: [orderDetail?.vendorDetails],
                              // })
                            }
                          />
                        ) : (
                          <AddButton
                            onclkFn={() =>
                              setFieldValue("submittedButton", "save")
                            }
                            prefixIcon={false}
                            text="Save and Print"
                            type="submit"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
export default CreateEditViewReturnBill;

export const calculateTotal = (values) => {
  return values.data?.reduce(
    (total, item) => total + parseFloat(item.item_total_amount || 0),
    0
  );
};
export const calculateTotalTax = (data) => {
  let totalTax = 0;

  data?.forEach((rowData) => {
    totalTax += parseFloat(rowData.item_tax_amount) || 0;
  });
  return totalTax;
};

const calculateTotalDiscount = (data) => {
  return data.reduce((totalDiscount, item) => {
    const item_discount_amount = parseFloat(item.item_discount_amount || 0);
    return totalDiscount + item_discount_amount;
  }, 0);
};

export const calculateTotalCGST = (data) => {
  let totalCGST = 0;

  data?.forEach((rowData) => {
    totalCGST += parseFloat(rowData.item_cgst) || 0;
  });

  return totalCGST;
};

export const calculateTotalSGST = (data) => {
  let totalSGST = 0;

  data?.forEach((rowData) => {
    totalSGST += parseFloat(rowData.item_sgst) || 0;
  });

  return totalSGST;
};
export const calculateTotalIGST = (data) => {
  let totalIGST = 0;
  data?.forEach((rowData) => {
    totalIGST += parseFloat(rowData.item_igst) || 0;
  });
  return totalIGST;
};
export const calculateTotalPayment = (values) => {
  return values.paymentData?.reduce((acc, item) => {
    return acc + parseFloat(item.payment_amount || 0);
  }, 0);
};

export const itemTotalPricewithoutTax = (item) => {
  const totalamt =
    parseInt(item?.item_open_quantity ?? item?.item_quantity) *
      parseFloat(item?.item_unit_price) -
    calculateItemDis(item);
  const totalTaxamt =
    parseFloat(totalamt) -
    parseFloat(totalamt) * (100 / (100 + parseFloat(item?.item_gst)));
  return (parseFloat(totalamt) - parseFloat(totalTaxamt))?.toFixed(2);
};
export const itemTotalTax = (item) => {
  const totalamt =
    parseInt(item?.item_open_quantity ?? item?.item_quantity) *
      parseFloat(item?.item_unit_price) -
    calculateItemDis(item);

  const totalTaxamt =
    parseFloat(totalamt) -
    parseFloat(totalamt) * (100 / (100 + parseFloat(item?.item_gst)));
  return parseFloat(totalTaxamt)?.toFixed(2);
};

export const calculateItemDis = (item) => {
  const totalamt =
    parseInt(item?.item_open_quantity ?? item?.item_quantity) *
    parseFloat(item?.item_unit_price);

  return (
    (totalamt * parseFloat(item?.item_discount_percentage ?? 0)) /
    100
  ).toFixed(2);
};

export const itemTotalAmt = (item) => {
  const totalamt =
    parseInt(item?.item_open_quantity ?? item?.item_quantity) *
      parseFloat(item?.item_unit_price) -
    calculateItemDis(item);

  return totalamt?.toFixed(2);
};
