import React, { useContext, useEffect, useState } from "react";
import InnerLayout from "../../../components/layouts/inner-layout";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import SortingIcons from "../../../assets/icons/arrowup-down";
import KibabMenu from "../../../assets/icons/kibab-menu";
import {
  PrintIcon,
  ShareIcon,
  VoidIcon,
} from "../../../assets/icons/action-icons";
import { storeDetailsAction } from "../../../redux/actions/invoices-action";
import LoadingSpinner from "../../../components/helper-components/loading-spinner";
import moment from "moment";
import { DataContext } from "../../../components/layouts/main-layout";
import { Fragment } from "react";
import Pagination from "../../../components/tables/pagination";
import PlusIcon from "../../../assets/icons/plus-icon";
import IntoIcon from "../../../assets/icons/into-icon";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import successToast from "../../../components/helper-components/success-toast";
import errorToast from "../../../components/helper-components/error-toast";
import SalesPurchaseTableHeader from "../../../components/sales-section/sales-purchase-table-header";
import {
  stockTransferTableAction,
  stockTransferViewAction,
  voidStockTransferAction,
  voidStockTransferItemAction,
} from "../../../redux/actions/inventory-action";
import StockManagementFilter from "../../../components/tables/table-filter/stock-transfer-filter";
import StockPdfContent from "../../../components/inventory/stock-transfer/stock-pdf-content";
import { formatString } from "../../../components/helper-components/helper-function";

const StockTransferTable = () => {
  const { permissionFn } = useContext(DataContext);
  const dispatch = useDispatch();
  const [currentOrder, setCurrentOrder] = useState(null);
  const [expandedRows, setExpandedRows] = useState([]);
  const [reload, setReload] = useState(false);
  const [orders, setOrders] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [action, setAction] = useState(false);
  const [subAction, setSubAction] = useState(false);
  const [itemLoading, setItemLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [currentSubIndex, setCurrentSubIndex] = useState(null);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [storeDetails, setStoreDetails] = useState({});

  useEffect(() => {
    dispatch(storeDetailsAction(onSuccessStoreDetails, onErrorStoreDetails));
  }, []);
  const onSuccessStoreDetails = (data) => {
    setStoreDetails(data?.data);
  };
  const onErrorStoreDetails = () => {};
  const [filterData, setFilterData] = useState({
    sort_by: "",
    sort_order: "",
    selectColumn: "stock_transfer_doc_number",
    from: "",
    to: "",
    inputValue: "",
    filterValue: "",
  });
  useEffect(() => {
    dispatch(
      stockTransferTableAction(
        {
          pageNumber: currentPage,
          pageSize: pageSize,
          filterData,
        },
        onSuccess,
        onError
      )
    );
  }, [currentPage, filterData, reload]);
  const onSuccess = (data) => {
    setError(false);
    setLoading(false);
    setOrders(data?.data?.orderList);
    setDataCount(data?.data?.totalCount);
  };
  const onError = (data) => {
    setError(true);
    setLoading(false);
  };
  const navigate = useNavigate();
  const paginationProps = {
    pageSize: pageSize,
    dataCount: dataCount,
    setCurrentPage: setCurrentPage,
    currentPage: currentPage,
  };

  const initialFilter = {
    sort_by: "",
    sort_order: "",
    selectColumn: "stock_transfer_doc_number",
    from: "",
    to: "",
    inputValue: "",
    filterValue: "",
  };
  function sortFn(item) {
    let direction = "ASC";
    if (
      filterData != null &&
      filterData?.sort_by === item &&
      filterData?.sort_order === "ASC"
    ) {
      direction = "DESC";
    }
    setFilterData({ ...filterData, sort_by: item, sort_order: direction });
  }

  const isItemChecked = (rowData) => {
    return invoiceItems.some(
      (item) =>
        item.pot_id === rowData.id &&
        item.cmr_id === rowData.cmr_id &&
        Object.keys(item).length === 2
    );
  };
  const handleCheckboxChange = (e, rowData) => {
    if (e.target.checked) {
      setInvoiceItems((prevItems) => [
        ...prevItems,
        {
          pot_id: rowData.id,
          cmr_id: rowData.cmr_id,
        },
      ]);
    } else {
      setInvoiceItems((prevItems) =>
        prevItems.filter(
          (item) =>
            !(
              item.pot_id === rowData.id &&
              item.cmr_id === rowData.cmr_id &&
              Object.keys(item).length === 2
            )
        )
      );
    }
  };

  function itemsChecker(e, rowData) {
    const id = e.target.id;
    if (e.target.checked) {
      setInvoiceItems((prevItems) => [
        ...prevItems,
        {
          pot_id: rowData.id,
          item_id: id,
          cmr_id: rowData.cmr_id,
        },
      ]);
    } else {
      setInvoiceItems((prevItems) =>
        prevItems.filter(
          (item) =>
            !(
              item.pot_id === rowData.id &&
              item.item_id === id &&
              item.cmr_id === rowData.cmr_id
            )
        )
      );
    }
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        action &&
        currentIndex != null &&
        !event.target.closest(".relative")
      ) {
        setAction(false);
        setCurrentIndex(null);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [action, currentIndex]);
  const toggleRow = (index, id) => {
    setItemLoading(true);
    if (expandedRows.includes(index)) {
      setExpandedRows((prevRows) => prevRows.filter((row) => row !== index));
    } else {
      setExpandedRows((prevRows) => [...prevRows, index]);
      dispatch(
        stockTransferViewAction(
          id,
          (data) => {
            setOrders((prevOrders) =>
              prevOrders.map((order) =>
                order.itt_id === id
                  ? { ...order, itemData: data?.data?.itemData }
                  : order
              )
            );
            setItemLoading(false);
          },
          onErrPur
        )
      );
    }
  };

  const tableBodyData = orders?.map((item) => {
    return {
      id: item?.itt_id,
      stock_transfer_doc_number:
        (
          <span className="cursor-pointer text-secondary fw_500">
            {item?.stock_transfer_doc_number ?? "--"}
          </span>
        ) ?? "--",

      posting_date: item?.posting_date
        ? moment(item?.posting_date).format("DD-MM-YYYY")
        : null,
      stock_management_action:
        formatString(item?.stock_management_action) ?? "--",
      from_warehouse_name: item?.from_warehouse_name ?? "--",
      to_warehouse_name: item?.to_warehouse_name ?? "--",
      stock_transfer_status: item?.stock_transfer_status ?? "--",
      is_stock_in_void_able: item?.is_stock_in_void_able ?? false,
      itemData: item?.itemData?.length > 0 ? item?.itemData : [],
    };
  });

  const onErrPur = (data) => {
    setItemLoading(false);
  };
  const removeItemData = (id) => {
    const order = orders.find((item) => item.pot_id === id);
    if (order) {
      const updatedOrders = orders.map((item) => {
        if (item.pot_id === id) {
          return {
            ...item,
            itemData: [],
          };
        }
        return item;
      });
      setOrders(updatedOrders);
    }
  };

  const downloadPdf = async (data) => {
    const fileName = "test.pdf";
    try {
      const blob = await pdf(
        <StockPdfContent data={data} storeDetails={storeDetails} />
      ).toBlob();
      saveAs(blob, fileName);
    } catch (error) {}
  };

  function printFn(id) {
    dispatch(
      stockTransferViewAction(
        id,
        (data) => {
          downloadPdf(data?.data);
        },
        onErrPur
      )
    );
  }

  function voidFn(id, type, item_id) {
    let actionType = voidStockTransferAction;
    if (type === "subItem") {
      actionType = voidStockTransferItemAction;
    }
    dispatch(
      actionType(
        {
          itt_id: id,
          ...(item_id ? { item_id: item_id } : {}),
        },
        (data) => {
          setReload(!reload);
          successToast(data?.message);
          setItemLoading(false);
          setInvoiceItems([]);
          setAction(false);
          setSubAction(false);
        },
        (error) => {
          errorToast(
            <p>
              {error?.data?.message}
              {error?.data?.data?.map((invoice) => (
                <div>
                  {invoice?.gort_invoice_number ?? invoice?.poit_invoice_number}
                </div>
              ))}
            </p>
          );
        }
      )
    );
  }
  const [heightOfFirstDiv, setHeightOfFirstDiv] = useState(0);
  useEffect(() => {
    const updateHeight = () => {
      const firstDivHeight = document.querySelector(".head-div")?.clientHeight;
      setHeightOfFirstDiv(firstDivHeight);
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [loading]);
  return (
    <InnerLayout
      permissionFn={permissionFn}
      module="invoice"
      list={true}
      title="Stock Management"
    >
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <></>
      ) : (
        <>
          <div className="head-div">
            <SalesPurchaseTableHeader
              FilterComponent={() => {
                return (
                  <StockManagementFilter
                    setCurrentPage={setCurrentPage}
                    filterData={filterData}
                    setFilterData={setFilterData}
                  />
                );
              }}
              usePropFiltComp={true}
              setCurrentPage={setCurrentPage}
              tableHeading={tableHeading}
              filterData={filterData}
              setFilterData={setFilterData}
              initialFilter={initialFilter}
              module="stock_transfer"
              permissionFn={permissionFn}
              invoiceItems={[]}
              options={options}
              paginationProps={paginationProps}
              newFn={() => navigate("/add-stock-transfer")}
            />
          </div>
          <div
            style={{
              // minHeight: `calc(100% - ${heightOfFirstDiv}px)`,
              height: `calc(100% - ${heightOfFirstDiv}px)`,
            }}
            className="flex flex-col justify-between"
          >
            <div
              style={{
                minHeight: `calc(100% - 80px)`,
              }}
              className="tab-container"
            >
              <table className="my-tab">
                <thead>
                  <tr>
                    <th
                      className={`whitespace-nowrap text-xs fw_500 text-475467 bg-F2F4F5 tab-header w-[5rem]`}
                    ></th>
                    {tableHeading.map((heading, index) => {
                      if (index < tableHeading?.length - 1)
                        return (
                          <th
                            key={index}
                            className={`${"tab-header"} whitespace-nowrap text-xs fw_500 text-475467 bg-F2F4F5`}
                          >
                            <span
                              className={`flex items-center gap-2 ${heading?.className}`}
                            >
                              {heading?.label}
                              {heading?.display && (
                                <button onClick={() => sortFn(heading?.value)}>
                                  {" "}
                                  <SortingIcons
                                    inc={
                                      filterData?.sort_order === "ASC" &&
                                      heading?.value === filterData?.sort_by
                                        ? "#475467"
                                        : "#9A9FA6"
                                    }
                                    dec={
                                      filterData?.sort_order === "DESC" &&
                                      heading?.value === filterData?.sort_by
                                        ? "#475467"
                                        : "#9A9FA6"
                                    }
                                  />
                                </button>
                              )}
                            </span>
                          </th>
                        );
                    })}
                  </tr>
                </thead>
                <tbody className="h-full">
                  {tableBodyData?.length > 0 ? (
                    <>
                      {tableBodyData.map((rowData, rowIndex) => (
                        <>
                          <tr
                            className={`hover:bg-f3f8ff ${
                              isItemChecked(rowData) &&
                              "bg-D5E6Ff hover:bg-D5E6Ff"
                            } ${
                              expandedRows.includes(rowIndex) &&
                              "bg-C4EAB7 bg-opacity-50"
                            } `}
                            key={rowIndex}
                          >
                            <td className="text-sm tab-cell fw_400 text-secondary">
                              <div className="flex gap-5">
                                <input
                                  disabled={
                                    invoiceItems.some(
                                      (invoice) =>
                                        invoice.cmr_id !== rowData.cmr_id
                                    ) ||
                                    expandedRows.includes(rowIndex) ||
                                    rowData?.order_status === "Closed" ||
                                    rowData?.order_status === "Cancelled"
                                  }
                                  checked={isItemChecked(rowData)}
                                  id={rowData?.id}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, rowData)
                                  }
                                  style={{ transform: "scale(1.2)" }}
                                  type="checkbox"
                                  className="custom-checkbox form-check-input"
                                />
                                {expandedRows.includes(rowIndex) ? (
                                  <button
                                    onClick={() => {
                                      removeItemData(rowData?.id);
                                      toggleRow(rowIndex, rowData?.id);
                                    }}
                                    className={`border-D0D5DD border-2 rounded-md p-2 ${
                                      expandedRows.includes(rowIndex) &&
                                      "bg-AECAFF"
                                    }`}
                                  >
                                    <IntoIcon fill="black" />
                                  </button>
                                ) : (
                                  <button
                                    disabled={
                                      invoiceItems.some(
                                        (invoice) =>
                                          invoice.cmr_id !== rowData.cmr_id
                                      ) ||
                                      rowData?.order_status === "Closed" ||
                                      rowData?.order_status === "Cancelled"
                                    }
                                    onClick={() => {
                                      toggleRow(rowIndex, rowData?.id);
                                      setCurrentOrder(rowIndex);
                                    }}
                                    className={`border-D0D5DD border-2 rounded-md p-2 ${
                                      (invoiceItems.some(
                                        (invoice) =>
                                          invoice.cmr_id !== rowData.cmr_id
                                      ) ||
                                        rowData?.order_status === "Closed" ||
                                        rowData?.order_status ===
                                          "Cancelled") &&
                                      "bg-F4F4F4"
                                    }`}
                                  >
                                    <PlusIcon fill="black" />
                                  </button>
                                )}
                              </div>
                            </td>
                            {Object.values(rowData).map(
                              (cellData, cellIndex) => (
                                <Fragment key={cellIndex}>
                                  {cellIndex !== 0 && cellIndex < 8 && (
                                    <td
                                      onClick={() => {
                                        navigate(
                                          `/view-stock-transfer/${rowData?.id}`
                                        );
                                      }}
                                      className={`tab-cell text-sm cursor-pointer fw_400 text-secondary whitespace-nowrap`}
                                    >
                                      {cellIndex === 7 ? (
                                        rowData?.stock_transfer_status ===
                                          "success" &&
                                        !rowData?.is_stock_in_void_able ? (
                                          "Yes"
                                        ) : rowData?.stock_transfer_status ===
                                          "success" ? (
                                          "No"
                                        ) : (
                                          "--"
                                        )
                                      ) : cellIndex === 6 ? (
                                        <div
                                          style={{
                                            backgroundColor:
                                              rowData?.stock_transfer_status ===
                                              "success"
                                                ? "var(--success-color-opacity)" // Fully opaque success color
                                                : "var(--danger-color-opacity)", // F51D00 with approximately 15% opacity
                                          }}
                                          className={`fw_500 w-fit flex items-center gap-2 px-3 py-1 rounded-full ${
                                            rowData?.stock_transfer_status ===
                                            "success"
                                              ? " text-027A48 "
                                              : "text-F51D00 "
                                          }`}
                                        >
                                          {rowData?.stock_transfer_status ??
                                            "--"}
                                        </div>
                                      ) : (
                                        cellData
                                      )}
                                    </td>
                                  )}
                                </Fragment>
                              )
                            )}
                            <td
                              className={`text-sm tab-cell fw_400 text-secondary`}
                            >
                              {(permissionFn("stock_transfer")?.update ||
                                permissionFn("stock_transfer")?.delete ||
                                permissionFn("stock_transfer")?.print ||
                                permissionFn("stock_transfer")?.send) && (
                                <div className="relative">
                                  <button
                                    onClick={() => {
                                      setAction(!action);
                                      setCurrentIndex(rowIndex);
                                      setSubAction(false);
                                    }}
                                  >
                                    <KibabMenu />
                                  </button>
                                  {action && currentIndex === rowIndex ? (
                                    <div
                                      style={{
                                        minWidth: "13rem",
                                        zIndex: 999,
                                        top: "0px",
                                        right: "0px",
                                      }}
                                      className="absolute  border border-EAECF0 border-solid border-1 top-4 right-0 mt-8  py-4 px-4  bg-white  rounded shadow-lg z-10 flex flex-col gap-3"
                                    >
                                      {permissionFn("stock_transfer")
                                        ?.print && (
                                        <button
                                          onClick={() => printFn(rowData?.id)}
                                          className="flex items-center gap-2 fw_500 text-sm"
                                        >
                                          <PrintIcon />
                                          Print
                                        </button>
                                      )}
                                      {permissionFn("stock_transfer")
                                        ?.print && (
                                        <button
                                          onClick={() => {
                                            navigate(
                                              `/add-stock-transfer?transferId=${rowData?.id}`
                                            );
                                          }}
                                          className="flex items-center gap-2 fw_500 text-sm"
                                        >
                                          <ShareIcon />
                                          Clone
                                        </button>
                                      )}
                                      {permissionFn("stock_transfer")?.delete &&
                                        rowData?.is_stock_in_void_able &&
                                        rowData?.stock_transfer_status ===
                                          "success" &&
                                        rowData?.stock_management_action !==
                                          "In Store Transfer" && (
                                          <button
                                            onClick={() => {
                                              voidFn(rowData?.id, "item", "");
                                            }}
                                            className="flex items-center gap-2 fw_500 text-sm"
                                          >
                                            <VoidIcon />
                                            Void
                                          </button>
                                        )}
                                    </div>
                                  ) : null}
                                </div>
                              )}
                            </td>
                          </tr>
                          {expandedRows.includes(rowIndex) && (
                            <>
                              {itemLoading && rowIndex === currentOrder ? (
                                <tr>
                                  <td colSpan={tableHeading.length + 1}>
                                    <LoadingSpinner />
                                  </td>
                                </tr>
                              ) : (
                                <>
                                  {rowData?.itemData?.length > 0 && (
                                    <tr>
                                      <td colSpan={1}></td>
                                      <td
                                        className="p-0"
                                        colSpan={tableHeading.length - 2}
                                      >
                                        <div className=" w-100 w-full">
                                          <table className="w-full">
                                            <thead>
                                              <tr>
                                                <th
                                                  className={`whitespace-nowrap w-[3rem] text-center text-xs fw_500 text-475467 bg-F2F4F5 table-header`}
                                                ></th>
                                                {tableHeading1.map(
                                                  (heading, index) => (
                                                    <th
                                                      key={index}
                                                      className={` table-header  whitespace-nowrap text-xs fw_500 text-475467 bg-F2F4F5 ${
                                                        index === 6 ||
                                                        index === 7
                                                          ? "w-[7rem] text-right"
                                                          : "text-left"
                                                      } ${
                                                        (index === 2 ||
                                                          index === 3) &&
                                                        "w-[7rem]"
                                                      }
                                                      ${
                                                        index === 1 &&
                                                        "w-[8rem]"
                                                      }
                                                      `}
                                                    >
                                                      {heading?.label}
                                                    </th>
                                                  )
                                                )}
                                                <th
                                                  className={`whitespace-nowrap w-[3rem] text-center text-xs fw_500 text-475467 bg-F2F4F5 table-header`}
                                                ></th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {rowData?.itemData?.map(
                                                (
                                                  row_item_data,
                                                  rowSubIndex
                                                ) => (
                                                  <tr
                                                    className={`${
                                                      invoiceItems.some(
                                                        (item) =>
                                                          item.pot_id ===
                                                            rowData.id &&
                                                          item.item_id ===
                                                            row_item_data.item_id &&
                                                          item.cmr_id ===
                                                            rowData.cmr_id
                                                      )
                                                        ? "bg-C4EAB7 bg-opacity-60"
                                                        : ""
                                                    }`}
                                                    key={rowSubIndex}
                                                  >
                                                    <td
                                                      className={`table-cell text-center text-sm fw_400 text-secondary `}
                                                    >
                                                      <input
                                                        checked={invoiceItems.some(
                                                          (item) =>
                                                            item.pot_id ===
                                                              rowData.id &&
                                                            item.item_id ===
                                                              row_item_data.item_id &&
                                                            item.cmr_id ===
                                                              rowData.cmr_id
                                                        )}
                                                        id={
                                                          row_item_data?.item_id
                                                        }
                                                        onChange={(e) => {
                                                          itemsChecker(
                                                            e,
                                                            rowData
                                                          );
                                                        }}
                                                        style={{
                                                          transform:
                                                            "scale(1.2)",
                                                        }}
                                                        type="checkbox"
                                                        className="custom-checkbox form-check-input"
                                                      />
                                                    </td>
                                                    <td
                                                      style={{
                                                        maxWidth: "10rem",
                                                      }}
                                                      className={`table-cell text-left text-sm fw_400 text-secondary `}
                                                    >
                                                      {row_item_data?.item_name}
                                                    </td>
                                                    <td
                                                      style={{
                                                        maxWidth: "10rem",
                                                      }}
                                                      className={`table-cell text-left text-sm fw_400 text-secondary `}
                                                    >
                                                      {row_item_data?.item_code}
                                                    </td>
                                                    <td
                                                      className={`table-cell text-left text-sm fw_400 text-secondary `}
                                                    >
                                                      {row_item_data?.item_quantity ??
                                                        "--"}
                                                    </td>
                                                    <td
                                                      className={`table-cell text-left text-sm fw_400 text-secondary `}
                                                    >
                                                      {row_item_data?.itemBatchData?.map(
                                                        (bch) => (
                                                          <div className="text-nowrap">
                                                            {bch?.item_batch_number +
                                                              "-" +
                                                              bch?.item_batch_quantity +
                                                              "-" +
                                                              moment(
                                                                bch?.item_exp_date
                                                              ).format(
                                                                "DD-MM-YYYY"
                                                              )}
                                                          </div>
                                                        )
                                                      )}
                                                    </td>

                                                    <td
                                                      className={`table-cell text-left text-sm fw_400 text-secondary `}
                                                    >
                                                      <div
                                                        style={{
                                                          backgroundColor:
                                                            row_item_data?.item_void_status ===
                                                            "success"
                                                              ? "var(--success-color-opacity)" // Fully opaque success color
                                                              : "var(--danger-color-opacity)", // F51D00 with approximately 15% opacity
                                                        }}
                                                        className={`fw_500 w-fit flex items-center gap-2 px-3 py-1 rounded-full ${
                                                          row_item_data?.item_void_status ===
                                                          "success"
                                                            ? " text-027A48 bg-027A48 bg-opacity-15"
                                                            : "text-F51D00 bg-F51D00 bg-opacity-15"
                                                        }`}
                                                      >
                                                        {row_item_data?.item_void_status ??
                                                          "--"}
                                                      </div>
                                                    </td>

                                                    <td
                                                      className={`text-sm table-cell fw_400 text-secondary bg-white`}
                                                    >
                                                      {permissionFn(
                                                        "stock_transfer"
                                                      )?.delete &&
                                                        row_item_data?.is_item_void_able &&
                                                        row_item_data?.item_void_status ===
                                                          "success" &&
                                                        rowData?.stock_management_action !==
                                                          "In Store Transfer" && (
                                                          <div className="relative">
                                                            <button
                                                              onClick={() => {
                                                                setSubAction(
                                                                  !subAction
                                                                );
                                                                setCurrentIndex(
                                                                  rowIndex
                                                                );
                                                                setCurrentSubIndex(
                                                                  rowSubIndex
                                                                );
                                                              }}
                                                            >
                                                              <KibabMenu />
                                                            </button>
                                                            {subAction &&
                                                            currentIndex ===
                                                              rowIndex &&
                                                            currentSubIndex ===
                                                              rowSubIndex ? (
                                                              <div
                                                                style={{
                                                                  minWidth:
                                                                    "13rem",
                                                                  zIndex: 99,
                                                                  top: "0px",
                                                                  right: "0px",
                                                                }}
                                                                className="absolute  border border-EAECF0 border-solid border-1 top-4 right-0 mt-8  py-4 px-4  bg-white  rounded shadow-lg z-10 flex flex-col gap-3"
                                                              >
                                                                {permissionFn(
                                                                  "stock_transfer"
                                                                )?.delete &&
                                                                  row_item_data?.is_item_void_able && (
                                                                    <button
                                                                      onClick={() => {
                                                                        voidFn(
                                                                          rowData?.id,
                                                                          "subItem",
                                                                          row_item_data.item_id
                                                                        );
                                                                      }}
                                                                      className="flex items-center gap-2 fw_500 text-sm"
                                                                    >
                                                                      <VoidIcon />
                                                                      Void
                                                                    </button>
                                                                  )}
                                                              </div>
                                                            ) : null}
                                                          </div>
                                                        )}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      ))}
                      <tr style={{ height: "10rem" }}></tr>
                    </>
                  ) : (
                    <tr
                      style={{
                        height: "20rem",
                      }}
                    >
                      <td
                        colSpan={tableHeading.length + 1}
                        className="text-center text-primary"
                      >
                        No {"Purchase order" + "s"} found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <Pagination paginationProps={paginationProps} />
          </div>
        </>
      )}
    </InnerLayout>
  );
};

export default StockTransferTable;
const tableHeading = [
  {
    label: "STOCK TRANSFER NUMBER",
    value: "stock_transfer_doc_number",
    display: false,
    column: true,
  },
  {
    label: "DATE",
    value: "posting_date",
    display: true,
    column: false,
  },
  {
    label: "ACTION TYPE ",
    value: "stock_management_action",
    display: false,
    column: false,
  },
  {
    label: "SOURCE(From)",
    value: "from_warehouse_name",
    display: false,
    column: true,
  },
  {
    label: "DESTINATION(To)",
    value: "to_warehouse_name",
    display: false,
    column: true,
  },
  {
    label: "Transfer Status",
    value: "stock_transfer_status",
    display: false,
    column: false,
  },
  {
    label: "Is Billed",
    value: "is_item_void_able",
    display: false,
    column: false,
  },
  {
    label: "",
    value: "",
    display: false,
    column: false,
  },
  {
    label: "ITEM NAME",
    value: "item_name",
    display: false,
    column: true,
  },
];
const tableHeading1 = [
  { label: "PRODUCT NAME", value: "item_product_name" },
  { label: "ITEM CODE", value: "item_code" },
  { label: "QTY", value: "item_quantity" },
  { label: "Batch Details", value: "bch" },
  { label: "Status", value: "item_void_status" },
];

const options = [
  { value: "goods_receipt_note", label: "Goods Receipt Note" },
  { value: "purchase_invoice", label: "Purchase Invoice" },
];
