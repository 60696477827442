import { useFormikContext } from "formik";
import React from "react";
import SelectField from "../../forms/select-field";
import { url } from "../../../redux/config";
import { api } from "../../../redux/apis/api";

const BatchNumberEdit = ({ rowIndex, disabled }) => {
  const { values, setFieldValue } = useFormikContext();
  const selectedBatchNumbers = values.data
    .filter((_, index) => index !== rowIndex)
    .map((row) => row?.item_batch_number);

  const selectedFromBins = values.data
    .filter((_, index) => index !== rowIndex)
    ?.map((row) => row?.to_bin_id);

  console.log(selectedFromBins, "dsfsfsfsfsdfsdfsdfsdf");

  const handleBatchChange = async (sel) => {
    const updatedData = [...values?.data];
    const totalAmount =
      parseFloat(values?.data?.[rowIndex]?.quantity) *
      parseFloat(sel?.item_unit_price);
    try {
      const response = await api.get(
        `${url}/discount/get?totalAmountOfItem=${totalAmount}&item_quantity=${values?.data?.[rowIndex]?.quantity}&item_id=${values?.data?.[rowIndex]?.item_id}&cmr_phone_number=${values?.phone?.label}&batchNumber=${sel?.value}`
      );
      if (response.data?.code === 200) {
        const discount =
          parseFloat(response.data?.data?.item_discount_amount) || 0;
        const tax = parseFloat(response.data?.data?.item_tax_amount) || 0;
        const item_discount_percentage =
          parseFloat(response.data?.data?.item_discount_percentage) || 0;
        const item_total_tax_percentage =
          parseFloat(response.data?.data?.item_total_tax_percentage) || 0;
        const item_price_without_tax = (
          parseFloat(sel?.item_unit_price) *
            parseFloat(values?.data?.[rowIndex]?.quantity) -
            discount -
            tax || 0
        ).toFixed(2);

        const item_final_price = (
          (parseFloat(sel?.item_unit_price) *
            parseFloat(values?.data?.[rowIndex]?.quantity) -
            discount) /
          parseFloat(values?.data?.[rowIndex]?.quantity)
        ).toFixed(2);
        const updatedDataRow = {
          ...updatedData[rowIndex], // Copy the current data at rowIndex
          item_exp_date: values.data[rowIndex].itemBatchList?.find(
            (item) => item?.created_date === sel?.created_date
          )?.item_exp_date,
          to_bin_id: sel?.to_bin_id,
          item_batch_number: sel?.value,
          item_unit_price: sel?.item_unit_price,
          item_rack_location: sel?.item_rack_location,
          item_discount_amount: discount.toFixed(2),
          item_tax_amount: tax.toFixed(2),
          item_discount_percentage: item_discount_percentage,
          item_price_wiithout_tax: item_price_without_tax,
          item_gst: item_total_tax_percentage,
          item_sgst: (tax / 2).toFixed(2),
          item_cgst: (tax / 2).toFixed(2),
          item_igst: 0,
          item_final_price: item_final_price,
          item_total_amount: (
            parseFloat(sel?.item_unit_price) *
              parseFloat(values?.data?.[rowIndex]?.quantity) -
            discount
          ).toFixed(2),
          item_total_tax_percentage: item_total_tax_percentage.toFixed(2),
        };

        updatedData[rowIndex] = updatedDataRow;

        setFieldValue("data", [...updatedData]);
        setFieldValue("paymentData", [
          {
            payment_mode_name: "",
            payment_amount: null,
            tender_check: false,
          },
          {
            payment_mode_name: "",
            payment_amount: null,
            tender_check: false,
          },
          {
            payment_mode_name: "",
            payment_amount: null,
            tender_check: false,
          },
        ]);
      } else {
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  return (
    <>
      <SelectField
        menuWidth="16rem"
        general_color={false}
        // handleSelected={(sel) => {
        //   setFieldValue(
        //     `data[${rowIndex}].item_exp_date`,
        //     values.data[rowIndex].itemBatchList?.find(
        //       (item) => item?.created_date === sel?.created_date
        //     )?.item_exp_date
        //   );
        //   setFieldValue(`data[${rowIndex}].to_bin_id`, sel?.to_bin_id);
        //   setFieldValue(
        //     `data[${rowIndex}].item_rack_location`,
        //     sel?.item_rack_location
        //   );
        // }}
        handleSelected={handleBatchChange}
        borderclass=""
        defaultSelectClass="border-0"
        selectClass="w-full"
        defaultValue={{
          label: values.data[rowIndex]?.item_batch_number,
        }}
        isDisabled={disabled}
        errMsgContainerClass="hidden"
        id={`data[${rowIndex}].item_batch_number`}
        isContainer={false}
        options={values.data[rowIndex].itemBatchList
          ?.filter(
            (item) =>
              parseFloat(item?.item_quantity) >=
                values.data[rowIndex]?.quantity &&
              !selectedBatchNumbers.some(
                (batch, index) =>
                  values.data[index]?.item_id ===
                    values.data[rowIndex]?.item_id &&
                  batch === item?.item_batch_number
              ) // Prevent duplicate batch for the same item_id
          )
          .map((item) => {
            return {
              label: (
                <span className="flex flex-col">
                  <span>Avail Qty: {item?.item_quantity}</span>
                  <span>Btch Num: {item?.item_batch_number}</span>
                </span>
              ),
              value: item?.item_batch_number,
              to_bin_id: item?.to_bin_id,
              created_date: item?.created_date,
              item_rack_location: item?.item_rack_location,
              item_unit_price:
                item?.item_unit_price / parseInt(item?.base_quantity),
            };
          })}
        name={`data[${rowIndex}].item_batch_number`}
        placeholder={"Select Payment Method"}
      />
    </>
  );
};

export default BatchNumberEdit;
