import React from "react";

const ArrowDownIcon = ({ invoiceType = false,  }) => {
  const d = invoiceType
    ? "M8.75009 6.41671L1.25009 6.41671C1.17415 6.41647 1.09972 6.39553 1.0348 6.35614C0.969888 6.31674 0.916944 6.26039 0.881671 6.19314C0.8464 6.1259 0.830134 6.05031 0.834627 5.97451C0.83912 5.89871 0.864201 5.82557 0.90717 5.76296L4.65717 0.346289C4.81259 0.121708 5.18675 0.121708 5.34259 0.346289L9.09259 5.76296C9.13599 5.82544 9.16145 5.89862 9.16618 5.97454C9.17092 6.05047 9.15476 6.12624 9.11946 6.19363C9.08415 6.26102 9.03105 6.31744 8.96593 6.35677C8.90081 6.3961 8.82616 6.41683 8.75009 6.41671Z"
    : "M8.75009 0.583289L1.25009 0.583289C1.17415 0.583527 1.09972 0.60447 1.0348 0.643863C0.969888 0.683256 0.916944 0.739608 0.881671 0.806853C0.8464 0.874098 0.830134 0.949689 0.834627 1.02549C0.83912 1.10129 0.864201 1.17443 0.90717 1.23704L4.65717 6.65371C4.81259 6.87829 5.18675 6.87829 5.34259 6.65371L9.09259 1.23704C9.13599 1.17456 9.16145 1.10138 9.16618 1.02546C9.17092 0.94953 9.15476 0.873756 9.11946 0.806369C9.08415 0.738982 9.03105 0.682558 8.96593 0.643229C8.90081 0.603899 8.82616 0.583169 8.75009 0.583289Z";
  return (
    <svg
      width="8"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d={d} fill="black" />
    </svg>
  );
};

export default ArrowDownIcon;
