import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import SelectOptions from "../forms/select-options";
import { formatYAxisTick } from "../helper-components/helper-function";
const primaryColor = "var(--primary)";

const NewCusLineGraph = ({
  setSelectedOption,
  selectedOption,
  cusGraphData,
  options,
  total_sales = 0,
  graphLabel = "New Customers",
}) => {
  const handleChange = (option) => {
    setSelectedOption({ ...selectedOption, cusGraph: option?.value });
  };

  return (
    <div className="border border-EAECF0 p-3">
      <div className="flex justify-between items-center px-5 flex-wrap">
        <span className="text-000000 fw_600 text-sm text-nowrap ">
          {" "}
          {graphLabel}
        </span>
        <div className=" flex items-center gap-2">
          <div className="w-[8rem]">
            <SelectOptions
              value={selectedOption?.value}
              handleChange={handleChange}
              options={options}
            />
          </div>
          {selectedOption?.cusGraph === "custom_date" && (
            <div className="flex items-center border-D0D5DD border-2 py-1 px-1 rounded-md gap-2">
              <input
                className="text-sm"
                value={selectedOption?.cusGraphDate?.from}
                onChange={(e) => {
                  const fromDate = e.target.value;
                  setSelectedOption({
                    ...selectedOption,
                    cusGraphDate: {
                      ...selectedOption.cusGraphDate,
                      from: fromDate,
                    },
                  });
                }}
                type="date"
              />
              -
              <input
                className="text-sm"
                value={selectedOption?.cusGraphDate?.to}
                onChange={(e) => {
                  const toDate = e.target.value;
                  setSelectedOption({
                    ...selectedOption,
                    cusGraphDate: {
                      ...selectedOption.cusGraphDate,
                      to: toDate,
                    },
                  });
                }}
                type="date"
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-between items-center p-5">
        <span className="text-primary fw_800 fs_18 text-nowrap ">
          {" "}
          {new Intl.NumberFormat("en-IN").format(total_sales)}
        </span>
        {/* <div className="w-[7rem]">{total_orders} Orders</div> */}
      </div>

      <ResponsiveContainer width="100%" height={320}>
        <LineChart
          data={cusGraphData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />

          <XAxis tick={{ fontSize: 12 }} dataKey="name" />

          <YAxis tick={{ fontSize: 12 }} tickFormatter={formatYAxisTick} />
          <Tooltip />
          <Line
            type="linear"
            dataKey="customers"
            stroke={primaryColor}
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>

      <div className="flex justify-center items-center p-5 text-000000 fw_400 text-sm">
        <span>X-time period, Y-New Customers</span>
      </div>
    </div>
  );
};

export default NewCusLineGraph;
