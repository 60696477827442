import { Form, Formik } from "formik";
import React from "react";
import SelectField from "../../forms/select-field";

const BulkPurchaseFilter = ({
  setFilterData,
  filterData,
  vendorList = [],
  manufacturesList = [],
  catList = [],
  itemGroup = [],
  itemType = [],
}) => {
  // const [filterData, setFilterData] = useState({
  //   group_by: "",
  //   vendor: "",
  //   manufacturer: "",
  //   item_group: "",
  //   item_type: "",
  //   item_category: "",
  // });
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      style={{
        minWidth: "15rem",
        right: "-30px", // Adjust this value as needed
      }}
      className="absolute  border border-EAECF0 border-solid border-1 top-4 right-0 mt-8 mr-8 py-4  bg-white  rounded shadow-lg z-20"
    >
      <div className="px-3">
        <Formik
          initialValues={{
            group_by: filterData?.group_by,
            vendor: filterData?.vendor,
            manufacturer: filterData?.manufacturer,
            item_group: filterData?.item_group,
            item_type: filterData?.item_type,
            item_category: filterData?.item_category,
          }}
        >
          {({ values, handleChange }) => {
            
            return (
              <Form className="h-full">
                <div>
                  <label className="fs_12">Vendor</label>
                  <SelectField
                    defaultSelectClass=""
                    handleSelected={(selectedOption) => {
                      setFilterData({
                        ...filterData,
                        vendor: selectedOption?.value,
                      });
                    }}
                    containerClass="mt-1"
                    placeholder={"Select vendor"}
                    options={vendorList}
                    label={""}
                    id={"vendor"}
                    name="vendor"
                    isSearchable={true}
                    errMsgContainerClass="ps-2"
                    isRequired={true}
                    isMulti={false}
                  />
                </div>
                <div>
                  <label className="fs_12">Manufacturer</label>
                  <SelectField
                    defaultSelectClass=""
                    handleSelected={(selectedOption) => {
                      setFilterData({
                        ...filterData,
                        manufacturer: selectedOption?.value,
                      });
                    }}
                    containerClass="mt-1"
                    placeholder={"Select vendor"}
                    options={manufacturesList}
                    label={""}
                    id={"manufacturer"}
                    name="manufacturer"
                    isSearchable={true}
                    errMsgContainerClass="ps-2"
                    isRequired={true}
                    isMulti={false}
                  />
                </div>
                <div>
                  <label className="fs_12">Item group</label>
                  <SelectField
                    defaultSelectClass=""
                    handleSelected={(selectedOption) => {
                      setFilterData({
                        ...filterData,
                        item_group: selectedOption?.value,
                      });
                    }}
                    containerClass="mt-1"
                    placeholder={"Select group"}
                    options={itemGroup}
                    label={""}
                    id={"item_group"}
                    name="item_group"
                    isSearchable={true}
                    errMsgContainerClass="ps-2"
                    isRequired={true}
                    isMulti={false}
                  />
                </div>
                <div>
                  <label className="fs_12">Item type</label>
                  <SelectField
                    defaultSelectClass=""
                    handleSelected={(selectedOption) => {
                      setFilterData({
                        ...filterData,
                        item_type: selectedOption?.value,
                      });
                    }}
                    containerClass="mt-1"
                    placeholder={"Select vendor"}
                    options={itemType}
                    label={""}
                    id={"item_type"}
                    name="item_type"
                    isSearchable={true}
                    errMsgContainerClass="ps-2"
                    isRequired={true}
                    isMulti={false}
                  />
                </div>
                <div>
                  <label className="fs_12">Item Category</label>
                  <SelectField
                    defaultSelectClass=""
                    handleSelected={(selectedOption) => {
                      setFilterData({
                        ...filterData,
                        item_category: selectedOption?.value,
                      });
                    }}
                    containerClass="mt-1"
                    placeholder={"Select category"}
                    options={catList}
                    label={""}
                    id={"item_category"}
                    name="item_category"
                    isSearchable={true}
                    errMsgContainerClass="ps-2"
                    isRequired={true}
                    isMulti={false}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default BulkPurchaseFilter;
