import { Field, useFormikContext } from "formik";
import React from "react";
import SelectField from "../forms/select-field";
import AddButton from "../buttons/button";
import moment from "moment";
import {
  decimalNumberFn,
  positiveIntegerFn,
} from "../helper-components/helper-function";
import { url } from "../../redux/config";
import { api } from "../../redux/apis/api";
import DeleteIcon from "../../assets/icons/delete-icon";
import BatchIcon from "../../assets/icons/batches-icons/batch-icon";
import RupeeIcon from "../../assets/icons/batches-icons/rupeeIcon";
import QtyIcon from "../../assets/icons/batches-icons/qty-icon";
import CalendorIcon from "../../assets/icons/batches-icons/calendor-icon";

const SalesBatchPopover = ({
  setAddbatch,
  itemId,
  setItemId,
  handleQtyChangeFn = () => {},
  disabled = true,
  disName = "",
}) => {
  const { values, setFieldValue } = useFormikContext();

  const defaultSelectedItem = values?.data?.find(
    (item) => itemId === item?.item_id
  );
  const defaultSelectedItemIndex = values?.data?.findIndex(
    (item) => itemId === item?.item_id
  );

  const selectedBatchNumbers = values.data
    .find((_, index) => index === defaultSelectedItemIndex)
    .item_batches.map((row) => row?.item_batch_number);

  const handleBatchChange = async (rowIndex, sel, batchindex) => {
    let updatedData = [...values.data];
    const totalAmount =
      parseFloat(sel?.item_batch_quantity) *
      parseFloat(sel?.item_selling_price);
    try {
      const response = await api.get(
        `${url}/discount/sales/get?totalAmountOfItem=${totalAmount}&item_quantity=${sel?.item_batch_quantity}&item_id=${sel?.item_id}&batchNumber=${sel?.value}&cmr_id=${values?.cmr_id}`
      );
      const {
        item_discount_amount,
        item_discount_percentage,
        item_tax_amount,
        item_total_tax_percentage,
      } = response?.data.data;
      const discount = item_discount_amount || 0;
      const tax = +item_tax_amount || 0;
      const sgst = +(tax / 2)?.toFixed(2);
      const cgst = +(tax / 2)?.toFixed(2);
      const igst = 0;
      const updatedBatch = {
        ...updatedData[rowIndex].item_batches[batchindex],
        item_batch_unit_price: parseFloat(sel?.item_unit_price),
        item_batch_discount_amount: discount.toFixed(2),
        item_batch_discount_percentage: item_discount_percentage,
        item_batch_tax_percentage: item_total_tax_percentage,
        item_batch_tax_amount: item_tax_amount.toFixed(2),
        item_batch_number: sel?.value,
        item_batch_sales_rate: sel?.item_selling_price,
        item_batch_final_sales_rate: (
          (parseFloat(sel?.item_selling_price) *
            parseInt(sel?.item_batch_quantity) -
            parseFloat(discount) +
            parseFloat(item_tax_amount)) /
          parseInt(sel?.item_batch_quantity)
        ).toFixed(2),
        item_batch_total_sales_rate: (
          parseFloat(sel?.item_selling_price) *
            parseInt(sel?.item_batch_quantity) -
          parseFloat(discount) +
          parseFloat(item_tax_amount)
        ).toFixed(2),
        from_bin_id: sel?.to_bin_id,
        from_bin_location: sel?.from_bin_location,
        item_exp_date: sel?.item_exp_date,
        item_batch_sgst: +sgst.toFixed(2),
        item_batch_cgst: +cgst.toFixed(2),
        item_batch_igst: +igst,
      };
      updatedData[rowIndex].item_batches[batchindex] = updatedBatch;
      const sumQuantity = updatedData[rowIndex].item_batches.reduce(
        (sum, batch) => sum + (parseFloat(batch.item_batch_quantity) || 0),
        0
      );
      const totalDiscount = updatedData[rowIndex].item_batches.reduce(
        (sum, batch) =>
          sum + (parseFloat(batch.item_batch_discount_amount) || 0),
        0
      );
      const totalTax = updatedData[rowIndex].item_batches.reduce(
        (sum, batch) => sum + (parseFloat(batch.item_batch_tax_amount) || 0),
        0
      );
      const totalAmt = updatedData[rowIndex].item_batches.reduce(
        (sum, batch) =>
          sum + (parseFloat(batch.item_batch_total_sales_rate) || 0),
        0
      );
      const totalFreeQty = updatedData[rowIndex].item_batches.reduce(
        (sum, batch) => sum + (parseFloat(batch.item_batch_free_quantity) || 0),
        0
      );
      const updatedDataRow = {
        ...updatedData[rowIndex],
        quantity: sumQuantity,
        item_free_quantity: +totalFreeQty,
        item_discount_amount: totalDiscount?.toFixed(2),
        item_tax_amount: totalTax?.toFixed(2),
        // item_discount_percentage: +item_discount_percentage,
        item_gst: +item_total_tax_percentage || 0,
        item_sgst: +(totalTax / 2)?.toFixed(2),
        item_cgst: +(totalTax / 2)?.toFixed(2),
        item_igst: +igst,
        item_total_amount: totalAmt.toFixed(2),
      };
      updatedData[rowIndex] = updatedDataRow;
      setFieldValue("data", updatedData);
      setFieldValue(
        disName,
        updatedData
          .reduce(
            (acc, item) => acc + (parseFloat(item?.item_discount_amount) || 0),
            0
          )
          .toFixed(2)
      );
    } catch (error) {}
  };

  const handleDiscountPercentageChange = (rowIndex, batchIndex, dis, qty) => {
    let updatedData = [...values.data];
    const discountPercentage = parseFloat(dis) || 0;
    const sellingPrice = parseFloat(
      updatedData[rowIndex].item_batches[batchIndex].item_batch_sales_rate
    );
    const item_batch_quantity = qty === "" ? 0 : parseInt(qty);
    const discountAmount =
      (sellingPrice * item_batch_quantity * discountPercentage) / 100;
    const sellingPriceAfterDiscount =
      sellingPrice * item_batch_quantity - discountAmount;

    const taxPercentage =
      parseFloat(
        updatedData[rowIndex].item_batches[batchIndex].item_batch_tax_percentage
      ) || 0;
    const taxAmount = (sellingPriceAfterDiscount * taxPercentage) / 100;

    const finalSellingPrice = sellingPriceAfterDiscount + taxAmount;

    updatedData[rowIndex].item_batches[batchIndex] = {
      ...updatedData[rowIndex].item_batches[batchIndex],
      item_batch_quantity: qty,
      item_batch_discount_percentage: discountPercentage,
      item_batch_discount_amount: discountAmount.toFixed(2),
      item_batch_tax_amount: taxAmount.toFixed(2),
      // If itemBatchQuantity is 0, set the final sales rate to 0 or an appropriate fallback value
      item_batch_final_sales_rate:
        item_batch_quantity > 0
          ? (
              (parseFloat(sellingPrice) * item_batch_quantity -
                parseFloat(discountAmount) +
                parseFloat(taxAmount)) /
              item_batch_quantity
            ).toFixed(2)
          : 0,
      item_batch_total_sales_rate: finalSellingPrice.toFixed(2),
    };
    const sumQuantity = updatedData[rowIndex].item_batches.reduce(
      (sum, batch) => sum + (parseFloat(batch.item_batch_quantity) || 0),
      0
    );
    const totalDiscount = updatedData[rowIndex].item_batches.reduce(
      (sum, batch) => sum + (parseFloat(batch.item_batch_discount_amount) || 0),
      0
    );
    const totalTax = updatedData[rowIndex].item_batches.reduce(
      (sum, batch) => sum + (parseFloat(batch.item_batch_tax_amount) || 0),
      0
    );
    const totalAmt = updatedData[rowIndex].item_batches.reduce(
      (sum, batch) =>
        sum + (parseFloat(batch.item_batch_total_sales_rate) || 0),
      0
    );
    const totalFreeQty = updatedData[rowIndex].item_batches.reduce(
      (sum, batch) => sum + (parseFloat(batch.item_batch_free_quantity) || 0),
      0
    );
    const updatedDataRow = {
      ...updatedData[rowIndex],
      quantity: sumQuantity,
      item_free_quantity: +totalFreeQty,
      item_discount_amount: totalDiscount?.toFixed(2),
      item_tax_amount: totalTax?.toFixed(2),
      item_gst: +taxPercentage || 0,
      item_sgst: +(totalTax / 2)?.toFixed(2),
      item_cgst: +(totalTax / 2)?.toFixed(2),
      item_igst: +0,
      item_total_amount: totalAmt.toFixed(2),
    };
    updatedData[rowIndex] = updatedDataRow;
    setFieldValue("data", updatedData);
    setFieldValue(
      disName,
      updatedData
        .reduce(
          (acc, item) => acc + (parseFloat(item?.item_discount_amount) || 0),
          0
        )
        .toFixed(2)
    );
  };

  const handleDeleteBatch = (rowIndex, batchIndex, batch) => {
    const updatedBatches = [...defaultSelectedItem?.item_batches];
    updatedBatches.splice(batchIndex, 1);
    setFieldValue(`data.${rowIndex}.item_batches`, updatedBatches);

    const sumQuantity = updatedBatches.reduce(
      (sum, batch) => sum + (parseFloat(batch.item_batch_quantity) || 0),
      0
    );
    const totalDiscount = updatedBatches.reduce(
      (sum, batch) => sum + (parseFloat(batch.item_batch_discount_amount) || 0),
      0
    );
    const totalTax = updatedBatches.reduce(
      (sum, batch) => sum + (parseFloat(batch.item_batch_tax_amount) || 0),
      0
    );
    const totalAmt = updatedBatches.reduce(
      (sum, batch) =>
        sum + (parseFloat(batch.item_batch_total_sales_rate) || 0),
      0
    );
    const totalFreeQty = updatedBatches.reduce(
      (sum, batch) => sum + (parseFloat(batch.item_batch_free_quantity) || 0),
      0
    );

    let updatedData = [...values.data]; // Ensure values.data is your main data array
    const updatedDataRow = {
      ...updatedData[rowIndex],
      item_batches: updatedBatches,
      quantity: sumQuantity,
      item_free_quantity: +totalFreeQty,
      item_discount_amount: +totalDiscount,
      item_tax_amount: +totalTax?.toFixed(2),
      // item_discount_percentage: +item_discount_percentage,
      item_gst: updatedBatches?.[0]?.item_total_tax_percentage || 0,
      item_sgst: +(totalTax / 2)?.toFixed(2),
      item_cgst: +(totalTax / 2)?.toFixed(2),
      item_igst: 0,
      item_total_amount: totalAmt.toFixed(2),
    };
    updatedData[rowIndex] = updatedDataRow;
    setFieldValue("data", updatedData);
    setFieldValue(
      disName,
      updatedData
        .reduce(
          (acc, item) => acc + (parseFloat(item?.item_discount_amount) || 0),
          0
        )
        .toFixed(2)
    );
  };

  return (
    <div className="absolute w-[-webkit-fill-available]  z-40">
      <div className="h-[72vh] bg-white flex flex-col justify-between">
        <div
          style={{
            height: `calc(72vh - 5rem)`,
          }}
        >
          <div className="bg-F4F4F4 w-full h-[5rem] px-3 gap-2 font-semibold text-xl  flex items-center">
            Batch details -
            <SelectField
              general_color={false}
              handleSelected={(selected) => {
                setItemId(selected?.value);
              }}
              containerClass="w-[20rem]"
              placeholder={"Select product"}
              defaultValue={
                defaultSelectedItem
                  ? {
                      label: defaultSelectedItem.item_name,
                      value: defaultSelectedItem.item_id,
                    }
                  : null
              }
              options={values?.data?.map((item) => ({
                label: item?.item_name,
                value: item?.item_id,
              }))}
              label={""}
              id={"item_id"}
              name="item_id"
              isSearchable={true}
              errMsgContainerClass="hidden"
              labelWidth="10.5rem"
            />
          </div>
          <div
            style={{
              height: `calc(100% - 5rem)`,
            }}
            className="p-3 overflow-y-auto "
          >
            <table className="lg:w-[100%] w-full border-D0D5DD border border-1 rounded-lg ">
              <thead>
                <tr className="bg-F2F4F5">
                  <th className="py-2 px-2 border-D0D5DD border border-1 text-left  text-secondary fw_400 text-sm text-nowrap min-w-[10rem]">
                    <span className="flex gap-2 items-center ">
                      {" "}
                      <BatchIcon /> Batch No.
                    </span>
                  </th>{" "}
                  <th className="py-2 px-2 border-D0D5DD border border-1 text-secondary fw_400 text-sm  text-right text-nowrap min-w-[8rem]">
                    <span className="flex gap-2 items-center justify-end">
                      {" "}
                      <RupeeIcon /> Base SP
                    </span>
                  </th>{" "}
                  <th className="py-2 px-2 border-D0D5DD border border-1 text-secondary fw_400 text-sm text-nowrap text-right max-w-[5rem] min-w-[3rem]">
                    <span className="flex gap-2 items-center justify-end">
                      {" "}
                      <QtyIcon /> Qty
                    </span>
                  </th>
                  <th className="py-2 px-2 border-D0D5DD border border-1 text-secondary fw_400 text-sm text-nowrap text-right min-w-[5rem]">
                    <span className="flex gap-2 items-center justify-end">
                      {" "}
                      <QtyIcon /> Free Qty
                    </span>
                  </th>
                  <th className="py-2 px-2 border-D0D5DD border border-1 text-secondary fw_400 text-sm text-nowrap text-right min-w-[5rem]">
                    <span className="flex gap-2 items-center justify-end">
                      {" "}
                      <QtyIcon /> Disc. %
                    </span>
                  </th>
                  <th className="py-2 px-2 border-D0D5DD border border-1 text-secondary fw_400 text-sm text-nowrap text-right min-w-[5rem]">
                    <span className="flex gap-2 items-center justify-end">
                      {" "}
                      <RupeeIcon /> Disc. Amt
                    </span>
                  </th>
                  <th className="py-2 px-2 border-D0D5DD border border-1 text-secondary fw_400 text-sm text-nowrap text-right min-w-[5rem]">
                    <span className="flex gap-2 items-center justify-end">
                      {" "}
                      <RupeeIcon /> Disc. SP
                    </span>
                  </th>
                  <th className="py-2 px-2 border-D0D5DD border border-1 text-secondary fw_400 text-sm text-nowrap text-right min-w-[5rem]">
                    <span className="flex gap-2 items-center justify-end">
                      {" "}
                      <QtyIcon /> Tax %
                    </span>
                  </th>
                  <th className="py-2 px-2 border-D0D5DD border border-1 text-secondary fw_400 text-sm text-nowrap text-right min-w-[5rem]">
                    <span className="flex gap-2 items-center justify-end">
                      {" "}
                      <RupeeIcon />
                      Tax Amt.
                    </span>
                  </th>
                  <th className="py-2 px-2 border-D0D5DD border border-1 text-secondary fw_400 text-sm text-nowrap text-right min-w-[5rem]">
                    <span className="flex gap-2 items-center justify-end">
                      {" "}
                      <RupeeIcon /> Final SP
                    </span>
                  </th>
                  <th className="py-2 px-2 border-D0D5DD border border-1 text-secondary fw_400 text-sm text-nowrap text-right min-w-[5rem]">
                    <span className="flex gap-2 items-center justify-end">
                      {" "}
                      <RupeeIcon /> MRP
                    </span>
                  </th>
                  <th className="py-2 px-2 border-D0D5DD border border-1 text-secondary fw_400 text-sm text-nowrap text-right min-w-[5rem]">
                    <span className="flex gap-2 items-center justify-end">
                      {" "}
                      <RupeeIcon /> Tot Amt
                    </span>
                  </th>
                  <th className="py-2 px-2 border-D0D5DD border border-1 text-secondary fw_400 text-sm text-nowrap ">
                    <span className="flex gap-2 items-center ">
                      {" "}
                      <CalendorIcon /> Exp. Date
                    </span>
                  </th>
                  <th className="py-2 px-2 border-D0D5DD border border-1 text-secondary fw_400 text-sm text-nowrap  min-w-[6rem]">
                    <span className="flex gap-2 items-center">
                      {" "}
                      <QtyIcon /> To Bin{" "}
                    </span>
                  </th>
                  {!disabled && (
                    <th className="py-2 px-2 border-D0D5DD border border-1 text-left  text-secondary fw_400 text-sm"></th>
                  )}
                </tr>
              </thead>
              <tbody>
                {defaultSelectedItem?.item_batches?.map((batch, batchindex) => (
                  <tr key={batchindex}>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm min-w-[12rem]">
                      <SelectField
                        isDisabled={disabled}
                        general_color={false}
                        autoFocus={true}
                        handleSelected={(selectedBatch) => {
                          handleBatchChange(
                            defaultSelectedItemIndex,
                            selectedBatch,
                            batchindex
                          );
                          // setFieldValue(
                          //   `data[${defaultSelectedItemIndex}].item_batches[${batchindex}].item_exp_date`,
                          //   values.data[
                          //     defaultSelectedItemIndex
                          //   ].itemBatchList?.find(
                          //     (item) =>
                          //       item?.item_batch_number ===
                          //       values.data[defaultSelectedItemIndex]
                          //         ?.item_batches[batchindex]?.item_batch_number
                          //   )?.item_exp_date
                          // );
                        }}
                        selectClass="w-full"
                        defaultValue={{
                          label:
                            values.data[defaultSelectedItemIndex]?.item_batches[
                              batchindex
                            ]?.item_batch_number,
                        }}
                        errMsgContainerClass="hidden"
                        id={`data[${defaultSelectedItemIndex}].item_batches[${batchindex}].item_batch_number`}
                        isContainer={false}
                        options={values.data[
                          defaultSelectedItemIndex
                        ].itemBatchList
                          ?.filter(
                            (item) =>
                              parseFloat(item?.item_quantity) >=
                                values.data[defaultSelectedItemIndex]
                                  ?.item_batches[batchindex]
                                  .item_batch_quantity &&
                              !selectedBatchNumbers.includes(
                                item?.item_batch_number
                              )
                          )
                          .map((item) => {
                            return {
                              label: (
                                <span className="flex flex-col">
                                  <span>Avail Qty: {item?.item_quantity}</span>
                                  <span>
                                    Btch Num: {item?.item_batch_number}
                                  </span>
                                </span>
                              ),
                              value: item?.item_batch_number,
                              to_bin_id: item?.to_bin_id,
                              created_date: item?.created_date,
                              from_bin_location: item?.item_rack_location,
                              item_unit_price: item?.item_unit_price,
                              item_batch_quantity: batch?.item_batch_quantity,
                              ...item,
                            };
                          })}
                        name={`data[${defaultSelectedItemIndex}].item_batches[${batchindex}].item_batch_number`}
                        placeholder={"Select Payment Method"}
                      />
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 pr-4 text-secondary fw_400 text-sm text-right max-w-[10rem]">
                      {batch?.item_batch_sales_rate}
                    </td>

                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm text-right max-w-[6rem] min-w-[5rem]">
                      <Field
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.item_batch_quantity`}
                      >
                        {({ field }) => (
                          <input
                            disabled={disabled}
                            min={0}
                            max={
                              // values.data[
                              //   defaultSelectedItemIndex
                              // ].itemBatchList?.find(
                              //   (bat) =>
                              //     bat?.item_batch_number ===
                              //     batch?.item_batch_number
                              // )?.item_quantity ??
                              parseInt(batch?.item_batch_avail_quantity)
                            }
                            // disabled={true}
                            placeholder=""
                            {...field}
                            type="number"
                            className={`w-full focus_outline__none focus:border-focus-color focus:border rounded-md py-2 px-2 text-right`}
                            // onChange={(e) => {
                            //   // if (
                            //   //   e.key === "-" ||
                            //   //   e.key === "e" ||
                            //   //   e.key === "."
                            //   // ) {
                            //   //   e.preventDefault();
                            //   // }

                            //   if (
                            //     e.target === document.activeElement &&
                            //     (parseInt(e?.target?.value) ?? 0) <=
                            //       // (parseInt(
                            //       //   values.data[
                            //       //     defaultSelectedItemIndex
                            //       //   ].itemBatchList?.find(
                            //       //     (bat) =>
                            //       //       bat?.item_batch_number ===
                            //       //       batch?.item_batch_number
                            //       //   )?.item_quantity
                            //       // ) ??
                            //       parseInt(batch?.item_batch_avail_quantity)
                            //   ) {
                            //     field.onChange(e);

                            //     handleDiscountPercentageChange(
                            //       defaultSelectedItemIndex,
                            //       batchindex,
                            //       batch?.item_batch_discount_percentage,
                            //       e?.target?.value
                            //     );
                            //   }
                            // }}
                            onChange={(e) => {
                              const inputValue = e?.target?.value;
                              const parsedValue = parseInt(inputValue);
                              if (
                                e.key === "-" ||
                                e.key === "e" ||
                                e.key === "."
                              ) {
                                e.preventDefault();
                              }

                              if (
                                e.target === document.activeElement &&
                                (inputValue === "" ||
                                  parsedValue <=
                                    parseInt(batch?.item_batch_avail_quantity))
                              ) {
                                field.onChange(e);

                                handleDiscountPercentageChange(
                                  defaultSelectedItemIndex,
                                  batchindex,
                                  batch?.item_batch_discount_percentage,
                                  inputValue
                                );
                              }
                            }}
                          />
                        )}
                      </Field>
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm max-w-[6rem] min-w-[5rem]">
                      <Field
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.item_batch_free_quantity`}
                      >
                        {({ field }) => (
                          <input
                            min={0}
                            disabled={disabled}
                            onKeyDown={positiveIntegerFn}
                            placeholder=""
                            {...field}
                            type="number"
                            className={`w-full focus_outline__none focus:border-focus-color focus:border rounded-md py-2 px-2 text-right`}
                          />
                        )}
                      </Field>
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm max-w-[6rem] min-w-[5rem]">
                      <Field
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.item_batch_discount_percentage`}
                      >
                        {({ field }) => (
                          <input
                            min={0}
                            disabled={disabled}
                            onKeyDown={decimalNumberFn}
                            placeholder=""
                            {...field}
                            type="number"
                            className={`w-full focus_outline__none focus:border-focus-color focus:border rounded-md py-2 px-2 text-right`}
                            onChange={(e) => {
                              field.onChange(e); // Ensure field.onChang
                              handleDiscountPercentageChange(
                                defaultSelectedItemIndex,
                                batchindex,
                                e?.target?.value,
                                batch?.item_batch_quantity
                              );
                            }}
                          />
                        )}
                      </Field>
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 pr-4 text-secondary fw_400 text-sm text-right">
                      {batch?.item_batch_discount_amount}
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 pr-4 text-secondary fw_400 text-sm text-right">
                      {(
                        parseFloat(
                          batch?.item_batch_sales_rate
                            ? batch?.item_batch_sales_rate
                            : 0
                        ) -
                        parseFloat(
                          batch?.item_batch_sales_rate *
                            (batch?.item_batch_discount_percentage / 100)
                        )
                      )?.toFixed(2)}
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 pr-4 text-secondary fw_400 text-sm text-right">
                      {batch?.item_batch_tax_percentage}
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 pr-4 text-secondary fw_400 text-sm text-right">
                      {batch?.item_batch_tax_amount}
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 pr-4 text-secondary fw_400 text-sm text-right">
                      {batch?.item_batch_final_sales_rate}
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm max-w-[8rem]">
                      <Field
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.item_batch_unit_price`}
                      >
                        {({ field }) => (
                          <input
                            min={0}
                            disabled={disabled}
                            onKeyDown={decimalNumberFn}
                            placeholder=""
                            {...field}
                            type="number"
                            className={`w-full focus_outline__none focus:border-focus-color focus:border rounded-md py-2 px-2 text-right`}
                          />
                        )}
                      </Field>
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm w-[5rem] text-right pr-4">
                      {batch?.item_batch_total_sales_rate}
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm">
                      {batch?.item_exp_date}
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm">
                      {batch?.from_bin_location}
                    </td>
                    {batchindex > 0 && disabled === false && (
                      <td className="border-D0D5DD border border-1 py-2 px-2 text-secondary fw_400 text-sm">
                        <button
                          onClick={() => {
                            handleDeleteBatch(
                              defaultSelectedItemIndex,
                              batchindex,
                              batch
                            );
                          }}
                          // onClick={() => {
                          //   const updatedBatches = [
                          //     ...defaultSelectedItem.item_batches,
                          //   ];
                          //   updatedBatches.splice(batchindex, 1);
                          //   setFieldValue(
                          //     `data.${defaultSelectedItemIndex}.item_batches`,
                          //     updatedBatches
                          //   );
                          // }}
                          type="button"
                        >
                          <DeleteIcon />
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex justify-between px-10 sticky bottom-0   bg-white py-2 invoice-bottom-container h-[5rem] items-center ">
          <AddButton
            onclkFn={() => setAddbatch(false)}
            prefixIcon={false}
            text="Done"
            type="submit"
          />
        </div>
      </div>
      <div
        className="bg-black h-[23vh] opacity-50"
        onClick={() => setAddbatch(false)}
      ></div>
    </div>
  );
};

export default SalesBatchPopover;
