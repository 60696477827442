import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import SelectField from "../forms/select-field";
import InputField from "../forms/input-field";
import TextAreaField from "../../components/forms/textarea-field";
import AddButton from "../buttons/button";
import { useDispatch } from "react-redux";
import successToast from "../helper-components/success-toast";
import {
  adminCustomerContactPersonAction,
  adminCustomerUpdateContactPersonAction,
} from "../../redux/actions/admin-customer-action";
import { stateListAction } from "../../redux/actions/general-action";
import { getBackgroundColor } from "../helper-components/helper-function";

const ContactPersonsTab = ({
  setCurrentTab,
  customerGeneralData,
  customerId,
  setReload,
  reload,
  customerDetails,
  isDisabled = false,
}) => {
  const dispatch = useDispatch();
  const [stateList, setStateList] = useState([]);
  useEffect(() => {
    dispatch(stateListAction(onSuccessState, onErrorState));
  }, []);

  const onSuccessState = (data) => {
    setStateList(
      data.map((item) => {
        return { label: item?.name, value: item?.name };
      })
    );
  };
  const onErrorState = () => {};
  const initData = {
    cmr_id: customerId,
    contact_person_name: "",
    contact_person_district: "",
    contact_person_phone: "",
    contact_person_city: "",
    contact_person_email: "",
    contact_person_pincode: "",
    contact_person_country: "india",
    contact_address: "",
    contact_person_state: "",
  };

  const initialValues = {
    contact_persons:
      customerDetails.contact_persons?.length > 0
        ? customerDetails.contact_persons.map((person) => ({ ...person }))
        : [initData],
  };

  const validationSchema = Yup.object().shape({
    contact_persons: Yup.array().of(
      Yup.object().shape({
        contact_person_name: Yup.string().required("Name is required"),
        contact_person_phone: Yup.string().required("Phone is required"),
        contact_person_email: Yup.string()
          .email("Invalid email")
          .required("Email is required"),
        contact_person_pincode: Yup.string().required("Pincode is required"),
      })
    ),
  });
  const [activeContact, setActiveContact] = useState(1);

  const innerOnSubmit = (values) => {
    let actionType = adminCustomerContactPersonAction;
    if (customerDetails.contact_persons?.length > 0) {
      actionType = adminCustomerUpdateContactPersonAction;
    }

    dispatch(
      actionType(
        {
          customerGeneralData: customerGeneralData,
          contactPersonsData: values?.contact_persons,
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = (data) => {
    successToast(data?.message);
    setCurrentTab("Bank Details");
    setReload(!reload);
  };
  const onError = (data) => {};
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={innerOnSubmit}
      >
        {({ values, handleSubmit, setFieldValue }) => {
          console.log(values, "dfgdsssfdsfsdf");
          return (
            <>
              <div className="flex items-center  ml-5">
                {values?.contact_persons?.map((person, index) => (
                  <button
                    style={{
                      backgroundColor: getBackgroundColor(
                        activeContact === index + 1
                      ),
                    }}
                    type="button"
                    onClick={() => setActiveContact(index + 1)}
                    key={index}
                    className={`mr-4 py-2 px-6 fs_14  z-5 ${
                      activeContact === index + 1
                        ? "text-primary  rounded-lg fw_700"
                        : "color-000000 bg-gray bg-opacity-10 rounded-lg fw_500"
                    }`}
                  >
                    {person.name || `Contact Person ${index + 1}`}
                  </button>
                ))}

                {!isDisabled && (
                  <button
                    type="button"
                    onClick={() => {
                      setFieldValue("contact_persons", [
                        ...values.contact_persons,
                        { ...initData },
                      ]);
                      setActiveContact(values.contact_persons?.length + 1);
                    }}
                  >
                    +
                  </button>
                )}
              </div>
              {values.contact_persons?.map((person, index) => {
                return (
                  <div
                    key={index}
                    className={
                      index + 1 === activeContact
                        ? "flex flex-wrap mt-4"
                        : "hidden mt-4"
                    }
                  >
                    <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10">
                      <InputField
                        isDisabled={isDisabled}
                        type="text"
                        name={`contact_persons[${index}].contact_person_name`} // Dynamic name using Formik's field structure
                        placeholder="Name"
                        label="Name"
                        isRequired={true}
                        errMsgContainerClass="ps-2"
                        labelWidth="8rem"
                      />
                      <InputField
                        isDisabled={isDisabled}
                        type="text"
                        name={`contact_persons[${index}].contact_person_district`} // Dynamic name using Formik's field structure
                        placeholder="District"
                        label="District"
                        isRequired={false}
                        errMsgContainerClass="ps-2"
                        labelWidth="8rem"
                      />
                      <InputField
                        isDisabled={isDisabled}
                        type="text"
                        name={`contact_persons[${index}].contact_person_phone`} // Dynamic name using Formik's field structure
                        placeholder="Phone"
                        label="Phone"
                        isRequired={true}
                        errMsgContainerClass="ps-2"
                        labelWidth="8rem"
                      />
                      <InputField
                        isDisabled={isDisabled}
                        type="text"
                        name={`contact_persons[${index}].contact_person_city`} // Dynamic name using Formik's field structure
                        placeholder="City"
                        label="City"
                        isRequired={false}
                        errMsgContainerClass="ps-2"
                        labelWidth="8rem"
                      />
                      <InputField
                        isDisabled={isDisabled}
                        type="email"
                        name={`contact_persons[${index}].contact_person_email`} // Dynamic name using Formik's field structure
                        placeholder="Email"
                        label="Email"
                        isRequired={true}
                        errMsgContainerClass="ps-2"
                        labelWidth="8rem"
                      />
                    </div>

                    <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10">
                      <InputField
                        isDisabled={isDisabled}
                        type="number"
                        name={`contact_persons[${index}].contact_person_pincode`} // Dynamic name using Formik's field structure
                        placeholder="Pincode"
                        label="Pincode"
                        isRequired={true}
                        errMsgContainerClass="ps-2"
                        labelWidth="8rem"
                      />
                      <SelectField
                        isDisabled={isDisabled}
                        defaultValue={[{ label: "India", value: "india" }].find(
                          (option) =>
                            option.value ===
                            values.contact_persons[index].contact_person_country
                        )}
                        placeholder="Select country"
                        options={[{ label: "India", value: "india" }]}
                        label="Country"
                        id={`contact_persons[${index}].contact_person_country`}
                        name={`contact_persons[${index}].contact_person_country`}
                        isSearchable={true}
                        errMsgContainerClass="ps-2"
                        isRequired={false}
                        labelWidth="8rem"
                      />
                      <SelectField
                        isDisabled={isDisabled}
                        defaultValue={stateList.find(
                          (option) =>
                            option.value ===
                            values.contact_persons[index].contact_person_state
                        )}
                        placeholder={"Select state"}
                        options={stateList}
                        label={"State"}
                        id={`contact_persons[${index}].contact_person_state`}
                        name={`contact_persons[${index}].contact_person_state`}
                        isSearchable={true}
                        errMsgContainerClass="ps-2"
                        isRequired={true}
                        labelWidth="8rem"
                      />
                      <TextAreaField
                        isDisabled={isDisabled}
                        name={`contact_persons[${index}].contact_address`} // Dynamic name using Formik's field structure
                        placeholder="Address"
                        label="Address"
                        isRequired={false}
                        errMsgContainerClass="ps-2"
                        labelWidth="8rem"
                      />
                    </div>

                    {!isDisabled && (
                      <div className="flex justify-start w-full px-5 xl:px-10">
                        <button
                          type="button"
                          onClick={() => {
                            if (values?.contact_persons.length > 1) {
                              const updatedContactPersons = [
                                ...values.contact_persons,
                              ];
                              updatedContactPersons.splice(index, 1);
                              setFieldValue(
                                "contact_persons",
                                updatedContactPersons
                              );
                              setActiveContact(1);
                            }
                          }}
                          className="py-2 px-6 color-ffffff bg-red text-white rounded-lg fw_700"
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                );
              })}
              {!isDisabled && (
                <div
                  style={{
                    bottom: "0",
                  }}
                  className="flex w-full justify-end px-10 mb-5x sticky bg-white py-4 invoice-bottom-container mt-auto"
                >
                  <AddButton
                    onclkFn={handleSubmit}
                    prefixIcon={false}
                    text="Save"
                    type="submit"
                  />
                </div>
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default ContactPersonsTab;

const countryList = [{ value: "india", label: "India" }];
