import React, { useEffect, useState } from "react";
import InnerLayout from "../../../components/layouts/inner-layout";
import CreateEditViewStockTransfer from "../../../components/inventory/stock-transfer/create-edit-view-stock-transfer";
import { useDispatch } from "react-redux";
import { stockTransferViewAction } from "../../../redux/actions/inventory-action";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../../components/helper-components/loading-spinner";

const ViewStockTransfer = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [stockTransferDetails, setStoreTransferDetails] = useState({});
  useEffect(() => {
    dispatch(stockTransferViewAction(id, onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    setStoreTransferDetails(data?.data);
    setLoading(false);
  };
  const onError = (data) => {
    setLoading(false);
  };
  

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <InnerLayout backBtn={true} title="Stock Management">
          <CreateEditViewStockTransfer
            disabled={true}
            stockTransferDetails={stockTransferDetails}
          />
        </InnerLayout>
      )}
    </>
  );
};

export default ViewStockTransfer;
