import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import AddButton, { DraftButton } from "../../buttons/button";
import UploadIcon from "../../../assets/icons/upload-icon";
import { Form, Formik } from "formik";
import errorToast from "../../helper-components/error-toast";
import Papa from "papaparse";
import { getBackgroundColor } from "../../helper-components/helper-function";
import { useDispatch } from "react-redux";
import { goodsReceiptCreateBulkAction } from "../../../redux/actions/purchase-action";
import { initUploadDocumentsGrn } from "../goods-receipt/goods-receipt";
const BulkGrnModal = ({
  isModalOpen,
  setIsModalOpen,
  setOrderDetail,
  setLoading,
}) => {
  const [invalidData, setInvalidData] = useState({});
  const [isDataReady, setIsDataReady] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const dispatch = useDispatch();

  const [uploadDocuments, setUploadDocuments] = useState(
    initUploadDocumentsGrn
  );

  const initialValues = {
    cmr_code: "",
    cmr_phone_number: "",
    cmr_name: "",
    cmr_id: "",
    remarks: "",
    gort_total_discount: 0,
    gort_id: "",
    gort_order_date: "",
    gort_document_date: "",
    gort_delivery_date: "",
    gort_order_status: "",
    itemData: [],
    itemBatchData: [],
  };

  const [initialValue, setInitialValue] = useState(initialValues);
  const handleFileUpload = (event, upload, setValues, values) => {
    const file = event.target.files[0];
    if (!file) return;
    event.target.value = null; // This resets the file input

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        const parsedData = results.data;
        if (!parsedData || parsedData.length === 0 || !parsedData[0]) {
          errorToast(
            "Error: The CSV file is empty or contains no valid records."
          );
          return; // Stop further processing
        }
        const fileHeaders = Object.keys(parsedData[0]);

        const isValidTemplate = upload?.expectedHeaders.every((header) =>
          fileHeaders.includes(header)
        );
        if (!isValidTemplate) {
          errorToast("Error: CSV template does not match the required format.");
          return;
        }

        console.log(upload, "sdfgsdggsgsgdsgdsgsgsgd");
        console.log(parsedData, "sdfgsdggsgsgdsgdsgsgsgd");
        if (upload?.documentType === "Order-Data") {
          setValues({ ...values, ...parsedData?.[0] });
        } else if (upload?.documentType === "Item-Data") {
          setValues({ ...values, itemData: parsedData });
        } else if (upload?.documentType === "Batch-Data") {
          setValues({ ...values, itemBatchData: parsedData });
        }
        setUploadDocuments((prevDocs) =>
          prevDocs.map((doc) =>
            doc.index === upload.index ? { ...doc, filename: file.name } : doc
          )
        );
      },
      error: (error) => {
        errorToast("Error parsing file:", error);
      },
    });
  };

  function handleSubmit(values, actions) {
    console.log(values, "sdfgsdggsgsgdsgdsgsgsgd");
    if (values?.itemData?.length === 0) {
      errorToast("Please Upload Item Data");
    } else if (values?.itemBatchData?.length === 0) {
      errorToast("Please Upload Batch Data");
    } else if (!values?.cmr_id) {
      errorToast("Please upload Order Data");
    } else {
      if (values?.submittedButton === "import") {
        setLoadingButton(true);
        dispatch(
          goodsReceiptCreateBulkAction(
            {
              itemData: values?.itemData,
              itembatchdata: values?.itemBatchData,
            },
            (data) => {
              const response = data?.data;
              setInvalidData(response?.invalidData);

              if (
                response?.validData?.length > 0 &&
                response?.invalidData?.itemData?.length === 0 &&
                response?.invalidData?.itembatchdata?.length === 0
              ) {
                const { itemData, itemBatchData, data, ...filteredValues } =
                  values;
                setOrderDetail({
                  itemData: response.validData,
                  vendorDetails: [filteredValues],
                });
                setInitialValue({ ...values, data: response.validData });
                setIsModalOpen(false);
                setLoading(true);
                setTimeout(() => {
                  setLoading(false);
                }, 10);
              } else if (response?.validData?.length > 0) {
                setIsDataReady(true);
                setLoadingButton(false);
                setInitialValue({ ...values, data: response.validData });
              } else {
                setLoadingButton(false);
                errorToast("No valid Products found");
              }
            },
            onError
          )
        );
      } else if (values?.submittedButton === "final") {
        const { itemData, itemBatchData, data, ...filteredValues } = values;
        setOrderDetail({ itemData: data, vendorDetails: [filteredValues] });
        setIsModalOpen(false);
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 10);
      }
    }
  }

  const onError = (data) => {
    setLoadingButton(false);
  };

  console.log(initialValue, "sdfgsdggsgsgdsgdsgsgsgd");

  return (
    <Transition show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => setIsModalOpen(false)}
      >
        <div className="flex min-h-screen px-4 text-center min-w-full">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-4"
          >
            <div
              className="mx-auto mt-2 text-left align-middle transition-all transform bg-white shadow-xl rounded-xl p-5"
              style={{ maxHeight: "50rem", minWidth: "60%" }}
            >
              <Formik
                key={initialValue}
                enableReinitialize={true}
                initialValues={initialValue}
                // validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  handleSubmit(values, actions); // Pass values and actions if needed
                  actions.setSubmitting(false); // Notify Formik that submission is done
                }}
              >
                {({ values, setFieldValue, errors, setValues }) => {
                  console.log(errors, "dfsfsfdsfdsfsdfdsfs");
                  return (
                    <Form className="h-full ">
                      {!isDataReady ? (
                        <div className="h-full flex flex-col">
                          <div style={{ height: "calc(100% - 4rem)" }}>
                            <h2 className="text-center text-lg fw_600  text-000000">
                              GRN CSV IMPORT
                            </h2>
                            <div className=" gap-5 w-full flex justify-center mt-10 flex-wrap">
                              {uploadDocuments?.map((upload, index) => (
                                <label
                                  style={{
                                    backgroundColor: `${
                                      upload?.filename
                                        ? getBackgroundColor(true, "--primary")
                                        : ""
                                    }`,
                                  }}
                                  key={index}
                                  className="min-w-[45%] min-h-[10rem] border-dotted border-primary border-[3px] rounded-md flex justify-center items-center"
                                  htmlFor={`csv-upload-${index}`}
                                >
                                  <div className="flex flex-col justify-center">
                                    <div className="flex justify-center">
                                      <UploadIcon
                                        width="20px"
                                        height="20px"
                                        fill="var(--primary)"
                                      />
                                    </div>
                                    <span className="text-center text-primary text-sm mt-3">
                                      {upload?.filename
                                        ? upload?.filename
                                        : upload?.documentType}
                                    </span>
                                  </div>
                                  <input
                                    type="file"
                                    accept=".csv"
                                    onChange={(e) => {
                                      handleFileUpload(
                                        e,
                                        upload,
                                        setValues,
                                        values
                                      );
                                    }}
                                    className="hidden"
                                    id={`csv-upload-${index}`} // Make the ID unique
                                  />
                                </label>
                              ))}
                            </div>
                            <div className="mt-5 flex flex-col gap-10">
                              {uploadDocuments?.map((upload, index) => (
                                <>
                                  {upload.filename && (
                                    <div className="flex justify-between">
                                      <div className="flex gap-5 text-000000 fw_600">
                                        <span>{index + 1}.</span>{" "}
                                        <span>{upload?.filename}</span>
                                      </div>
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setUploadDocuments((prevDocs) =>
                                            prevDocs.map((doc) =>
                                              doc.index === upload.index
                                                ? { ...doc, filename: "" }
                                                : doc
                                            )
                                          );
                                          // Update form values based on the removed file type
                                          if (
                                            upload.documentType === "Order-Data"
                                          ) {
                                            setValues({
                                              ...values,
                                              cmr_code: "",
                                              cmr_phone_number: "",
                                              cmr_name: "",
                                              cmr_id: "",
                                              remarks: "",
                                              gort_total_discount: 0,
                                              gort_id: "",
                                              gort_order_date: "",
                                              gort_document_date: "",
                                              gort_delivery_date: "",
                                              gort_order_status: "",
                                              gort_sub_total: "",
                                              gort_total_amount: "",
                                              gort_total_gst: "",
                                            });
                                          } else if (
                                            upload.documentType === "Item-Data"
                                          ) {
                                            setValues({
                                              ...values,
                                              itemData: [],
                                            });
                                          } else if (
                                            upload.documentType === "Batch-Data"
                                          ) {
                                            setValues({
                                              ...values,
                                              itemBatchData: [],
                                            });
                                          }
                                        }}
                                        style={{
                                          backgroundColor: getBackgroundColor(
                                            true,
                                            "--error-color"
                                          ),
                                        }}
                                        className="text-F51D00 rounded-md bg-F51D00 px-3 py-1 text-sm"
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  )}
                                </>
                              ))}
                            </div>
                          </div>
                          <div className="flex justify-between items-center h-[4rem]">
                            <DraftButton
                              className="text-F94B4B border border-F94B4B  border-solid px-10 py-2 items-center rounded-md flex justify-center fw_600 text-sm"
                              type="button"
                              onclkFn={() => {
                                setInitialValue(initialValues);
                                setUploadDocuments(initUploadDocumentsGrn);
                                setIsModalOpen(false);
                              }}
                              text="Cancel"
                            />
                            <AddButton
                              isDisabled={loadingButton}
                              loading={loadingButton}
                              prefixIcon={false}
                              text="Import"
                              type="submit"
                              onclkFn={() => {
                                setFieldValue("submittedButton", "import");
                              }}
                            />{" "}
                          </div>
                        </div>
                      ) : (
                        <div className="h-full flex flex-col justify-between">
                          <div style={{ height: "calc(100% - 4rem)" }}>
                            <h2 className="text-center text-lg fw_600  text-000000">
                              GRN CSV IMPORT
                            </h2>

                            <div
                              style={{ height: "calc(100% - 2rem)" }}
                              className="h-full overflow-y-scroll"
                            >
                              {invalidData?.itemData?.length > 0 && (
                                <h3 className="text-sm fw_600  text-000000">
                                  Error in Item Data
                                </h3>
                              )}
                              {invalidData?.itemData?.map((item, index) => (
                                <div className="text-sm text-F51D00 py-1">
                                  {index + 1}.{" "}
                                  {item?.item_error +
                                    " For " +
                                    "Item Code " +
                                    item?.item_code}
                                </div>
                              ))}
                              {invalidData?.itembatchdata?.length > 0 && (
                                <h3 className="text-sm fw_600 py-3 text-000000">
                                  Error in Batch Data
                                </h3>
                              )}
                              {invalidData?.itembatchdata?.map(
                                (item, index) => (
                                  <div className="text-sm text-F51D00 py-1">
                                    {index + 1}.{" "}
                                    {item?.item_error +
                                      " For " +
                                      "Item Code " +
                                      item?.item_code}
                                  </div>
                                )
                              )}
                            </div>
                          </div>

                          <div className="flex justify-between items-center h-[4rem]">
                            <DraftButton
                              className="text-F94B4B border border-F94B4B  border-solid px-10 py-2 items-center rounded-md flex justify-center fw_600 text-sm"
                              type="button"
                              onclkFn={() => {
                                setInitialValue(initialValues);
                                setUploadDocuments(initUploadDocumentsGrn);
                                setIsDataReady(false);
                              }}
                              text="Re-Upload"
                            />
                            <AddButton
                              // isDisabled={loadingButton}
                              // loading={loadingButton}
                              prefixIcon={false}
                              text={` ${
                                invalidData?.itembatchdata?.length > 0 &&
                                invalidData?.itemData?.length > 0
                                  ? "Remove error causing items and Continue ->"
                                  : "Continue"
                              }
                                  `}
                              type="submit"
                              onclkFn={() => {
                                setFieldValue("submittedButton", "final");
                              }}
                            />{" "}
                          </div>
                        </div>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default BulkGrnModal;
