import React, { useEffect, useRef, useState } from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import AddButton, {
  DeleteButton,
  DraftButton,
} from "../../components/buttons/button";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  financialAccountAddAction,
  financialAccountDeleteAction,
  financialAccountGetAction,
  financialAccountUpdateAction,
} from "../../redux/actions/financial-action";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Form, Formik } from "formik";
import InputField from "../../components/forms/input-field";
import RadioButton from "../../components/forms/radio-button";
import LoadingSpinner from "../../components/helper-components/loading-spinner";
import successToast from "../../components/helper-components/success-toast";
import errorToast from "../../components/helper-components/error-toast";
import { useNavigate } from "react-router-dom";
import { getBackgroundColor } from "../../components/helper-components/helper-function";
const ChartOfAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [level1Input, setLevel1Input] = useState("");
  const [level2Input, setLevel2Input] = useState("");
  const [reload, setReload] = useState(false);
  const [accountData, setAccountData] = useState([]);
  const [showAddInput, setShowAddInput] = useState(false);
  const [newEntry, setNewEntry] = useState(false);
  const [showLevelInput, setShowLevelInput] = useState(false);
  const inputRef = useRef(null);
  const levRef = useRef(null);
  const mainRef = useRef(null);
  const inputlevelRef = useRef(null);
  const [mainTab, setMainTab] = useState("");
  const [expandedAccounts, setExpandedAccounts] = useState([]);
  const [currentlevel, setCurrentlevel] = useState({});
  const [currentEntry, setCurrentEntry] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    dispatch(financialAccountGetAction(onSuccess, onError));
  }, [reload]);

  const onSuccess = (data) => {
    setShowAddInput(false);
    setAccountData(data?.data);
    setMainTab(data?.data?.[0]?.account_id);
  };
  const onError = (data) => {};
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleClickOutside = (e) => {
    if (
      levRef.current &&
      !levRef.current.contains(e.target) &&
      e.target === mainRef.current
    ) {
      setCurrentlevel({});
      setCurrentEntry({});
    }
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setShowAddInput(false);
    }
    if (inputlevelRef.current && !inputlevelRef.current.contains(e.target)) {
      setShowLevelInput(false);
    }
  };
  const handleSaveClick = (input) => {
    if (input) {
      dispatch(
        financialAccountAddAction(
          {
            account_name: input,
            account_level: 1,
            is_directory: true,
          },
          onSuccessadd,
          onErrorAdd
        )
      );
    }
  };
  const onSuccessadd = (data) => {
    setShowAddInput(false);
    setReload(!reload);
    setLevel1Input("");
  };
  const onErrorAdd = (data) => {};
  const toggleAccountVisibility = (accountId) => {
    setExpandedAccounts((prevExpandedAccounts) =>
      prevExpandedAccounts.includes(accountId)
        ? prevExpandedAccounts.filter((id) => id !== accountId)
        : [...prevExpandedAccounts, accountId]
    );
  };

  const handleSaveDirectory = (input) => {
    if (input) {
      setLoading(true);
      dispatch(
        financialAccountAddAction(
          {
            account_name: input,
            parent_account_key:
              Object.keys(currentlevel).length > 0
                ? currentlevel?.account_id
                : mainTab,
            account_level:
              Object.keys(currentlevel).length > 0
                ? currentlevel?.account_level + 1
                : 2,
            is_directory: true,
          },
          onSuccesdDir,
          onErrorDir
        )
      );
    }
  };
  const onSuccesdDir = (data) => {
    setLoading(false);
    setShowLevelInput(false);
    setReload(!reload);
    setLevel2Input("");
  };
  const onErrorDir = (data) => {
    setLoading(false);
  };
  const onSuccessEntry = (data) => {
    setCurrentEntry({});
    setLoading(false);
    setNewEntry(false);
    setReload(!reload);
    successToast(data?.message);
  };
  const onErrorEntry = (data) => {
    setLoading(false);
    errorToast(data?.message);
  };

  const renderAccounts = (accounts) => {
    return accounts.map((account) => (
      <div className="w-full text-sm" key={account.account_id}>
        {account?.parent_account_key ? (
          <div
            style={{
              background: `${
                currentEntry?.account_id === account.account_id
                  ? "#277DFE0F"
                  : ""
              }`,
            }}
            onClick={() => {
              setCurrentlevel({});
              setCurrentEntry(account);
            }}
            className={`border-D0D5DD border border-1 w-full mt-3 flex rounded-md fw_600 text-344054  cursor-pointer ${
              currentEntry?.account_id === account.account_id
                ? "border border-primary  border-solid "
                : "border-D0D5DD border border-1"
            }`}
          >
            <div className="w-[50%] border-r-EAECF0 border-r-2 px-3 py-1">
              {account?.account_code}
            </div>
            <div className="w-[50%] px-3 py-1">{account?.account_name}</div>
          </div>
        ) : (
          <div
            style={{
              background: `${
                currentlevel?.account_id === account.account_id
                  ? "#277DFE0F"
                  : ""
              }`,
            }}
            onClick={() => {
              toggleAccountVisibility(account.account_id);
              setCurrentlevel(account);
              setCurrentEntry({});
            }}
            className={` w-full mt-4 flex px-3 gap-2 py-3 text-sm rounded-lg cursor-pointer text-secondary items-center ${
              currentlevel?.account_id === account.account_id
                ? "border border-primary  border-solid "
                : "border-D0D5DD border border-1"
            }`}
          >
            <div className="h-5 w-5">
              {expandedAccounts.includes(account.account_id) ? (
                <ChevronDownIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </div>
            {account.account_name}
          </div>
        )}

        {account.children &&
          account.children.length > 0 &&
          expandedAccounts.includes(account.account_id) && (
            <div style={{ marginLeft: "25px" }}>
              {renderAccounts(account.children)}
            </div>
          )}
      </div>
    ));
  };

  function deleteSccountFn() {
    setLoading(true);
    dispatch(
      financialAccountDeleteAction(
        {
          account_id: currentEntry?.account_id,
        },
        onSuccessEntry,
        onErrorEntry
      )
    );
  }

  return (
    <InnerLayout title="Chart of accounts">
      <div className="pl-4 py-3 flex items-center gap-2">
        <button
          className="flex items-center gap-2"
          onClick={() => {
            navigate("/settings");
          }}
        >
          <ChevronLeftIcon className="h-5 w-5 inline-block" />
          Settings /
        </button>
        <span className="cursor-pointer">{" " + "Chart of accounts"}</span>
      </div>
      <div className="h-full flex flex-col justify-between">
        <div className="flex">
          <div
            style={{
              maxWidth: "12rem",
              minWidth: "12rem",
            }}
            className="w-[12rem] border-r-EAECF0 border-r-2 overflow-y-auto h-screen"
          >
            <div className="flex flex-col gap-[1.5rem] pl-3 mt-5">
              {accountData?.map((level0) => {
                if (level0?.is_gl_account)
                  return (
                    <span
                      onClick={() => {
                        setMainTab(level0?.account_id);
                        setCurrentEntry({});
                        setCurrentlevel({});
                      }}
                      style={{
                        backgroundColor: getBackgroundColor(
                          mainTab === level0?.account_id
                        ),
                      }}
                      className={` fw_500 cursor-pointer text-sm py-2 px-5 w-fit rounded-md ${
                        mainTab === level0?.account_id
                          ? "text-primary bg-primary bg-opacity-10"
                          : "text-787878"
                      }`}
                    >
                      {level0?.account_name}
                    </span>
                  );
              })}

              {showAddInput ? (
                <div ref={inputRef} className="flex flex-col pr-2">
                  <input
                    type="text"
                    className="border border-787878 rounded-md px-3 py-2 mt-2 w-auto focus-visible:outline-none "
                    onChange={(e) => {
                      setLevel1Input(e?.target?.value);
                    }}
                  />
                  <div className="flex justify-between mt-3">
                    {" "}
                    <XMarkIcon
                      className="h-6 w-6 text-787878 opacity-60 cursor-pointer"
                      onClick={() => setShowAddInput(false)}
                    />
                    <PlusCircleIcon
                      className="h-8 w-8 text-027A48 opacity-60 cursor-pointer"
                      onClick={() => handleSaveClick(level1Input)}
                    />
                  </div>
                </div>
              ) : (
                <span
                  style={{
                    backgroundColor: getBackgroundColor(true),
                  }}
                  onClick={() => setShowAddInput(true)}
                  className="w-[4.5rem]  px-3 cursor-pointer py-2  text-primary fw_600 rounded-lg text-sm"
                >
                  + Add
                </span>
              )}
            </div>
          </div>

          <div className="w-full flex xl:flex-row  flex-col">
            <div className="xl:w-[38%] border-r-EAECF0 border-r-2">
              <Formik
                enableReinitialize={true}
                initialValues={{
                  is_active: true,
                  account_name: currentEntry?.account_name ?? "",
                  account_code: currentEntry?.account_code ?? "",
                  account_level:
                    currentEntry?.account_level ?? currentlevel?.account_level,
                  is_file: true,
                  opening_balance: currentEntry?.opening_balance ?? "",
                  external_code: currentEntry?.external_code ?? "",
                }}
                validationSchema={Yup.object().shape({
                  account_name: Yup.string().required(
                    "Account name is required"
                  ),
                  account_code: Yup.string().required(
                    "Account code is required"
                  ),
                  account_level: Yup.string().required(
                    "Account level is required"
                  ),
                  opening_balance: Yup.string().required(
                    "Opening balance is required"
                  ),
                  external_code: Yup.string().required(
                    "External code is required"
                  ),
                })}
                onSubmit={(values, { resetForm }) => {
                  if (Object.keys(currentEntry)?.length > 0) {
                    setLoading(true);
                    dispatch(
                      financialAccountUpdateAction(
                        {
                          account_name: values?.account_name,
                          account_id: currentEntry?.account_id,
                          account_level: values?.account_level,
                          is_file: true,
                          opening_balance: values?.opening_balance,
                          external_code: values?.external_code,
                          account_code: values?.account_code,
                        },
                        onSuccessEntry,
                        onErrorEntry
                      )
                    );
                  } else {
                    setLoading(true);
                    dispatch(
                      financialAccountAddAction(
                        {
                          account_name: values?.account_name,
                          parent_account_key: currentlevel?.account_id,
                          account_level: values?.account_level + 1,
                          is_file: true,
                          opening_balance: values?.opening_balance,
                          external_code: values?.external_code,
                          account_code: values?.account_code,
                        },
                        onSuccessEntry,
                        onErrorEntry
                      )
                    );
                  }

                  resetForm();
                }}
              >
                {({ values, setFieldValue, handleChange }) => {
                  return (
                    <Form className="h-full p-5">
                      <div className="flex flex-col  h-full ">
                        <InputField
                          labelWidth="7rem"
                          type="text"
                          name="account_code"
                          placeholder=""
                          label="G/L Account"
                          isRequired={false}
                        />
                        <InputField
                          labelWidth="7rem"
                          type="text"
                          name="external_code"
                          placeholder=""
                          label="External Code"
                          isRequired={false}
                        />

                        <RadioButton
                          mainContainerClass="flex justify-between mb-3"
                          inputLabelClass="ms-2"
                          onChange={(e) => {
                            const { value } = e.target;
                            setFieldValue(
                              "is_active",
                              value === "false" ? false : true
                            );
                          }}
                          labelClass="color_9E9E9E d-flex text-sm mb-1 "
                          name="is_active"
                          options={activeOptns}
                          label=""
                        />
                        <InputField
                          labelWidth="7rem"
                          type="text"
                          name="account_name"
                          placeholder=""
                          label="Name"
                          isRequired={false}
                        />
                        <InputField
                          isDisabled={true}
                          labelWidth="7rem"
                          type="text"
                          name="account_level"
                          placeholder=""
                          label="Level"
                          isRequired={false}
                        />
                        <InputField
                          labelWidth="7rem"
                          type="text"
                          name="opening_balance"
                          placeholder=""
                          label="Balance"
                          isRequired={false}
                        />
                        {((newEntry && Object.keys(currentlevel)?.length > 0) ||
                          Object.keys(currentEntry)?.length > 0) && (
                          <div
                            style={{
                              bottom: "0",
                            }}
                            className="flex gap-5 flex-wrap"
                          >
                            {" "}
                            <AddButton
                              prefixIcon={false}
                              text={
                                Object.keys(currentEntry)?.length > 0
                                  ? "Update"
                                  : "Add"
                              }
                              type="submit"
                            />
                            <DraftButton
                              onclkFn={() => {
                                setNewEntry(false);
                                setCurrentEntry({});
                              }}
                              type="button"
                              text="Cancel"
                            />
                            {Object.keys(currentEntry)?.length > 0 && (
                              <DeleteButton
                                onclkFn={() => deleteSccountFn()}
                                type="button"
                                prefixIcon={false}
                                text="Delete"
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
            <div ref={mainRef} className="xl:w-[62%] px-5">
              <div ref={levRef}>
                <div className="flex gap-4 justify-start mt-5">
                  <button
                    onClick={() => {
                      setShowLevelInput(!showLevelInput);
                    }}
                    className="border-secondary border  px-5 py-2 bg-white rounded-md text-sm"
                  >
                    + New Level
                  </button>
                  <button
                    onClick={() => setNewEntry(true)}
                    className="border-secondary border  px-5 py-2 bg-white rounded-md text-sm"
                  >
                    + New Entry
                  </button>
                </div>
                <div className="flex justify-center">
                  {showLevelInput && (
                    <div
                      ref={inputlevelRef}
                      className="flex px-1 border py-2 border-787878 rounded-md justify-center items-center mt-3 w-fit"
                    >
                      <input
                        type="text"
                        className=" px-3  w-auto border-b-2 border-secondary focus-visible:outline-none"
                        onChange={(e) => {
                          setLevel2Input(e?.target?.value);
                        }}
                      />
                      <button
                        onClick={() => handleSaveDirectory(level2Input)}
                        className="text-white rounded-sm bg-primary px-2 flex items-center ms-1"
                      >
                        +
                      </button>
                    </div>
                  )}
                </div>
                <div className="w-full">
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <>
                      {accountData?.find((acc) => acc?.account_id === mainTab)
                        ?.children &&
                        accountData?.find((acc) => acc?.account_id === mainTab)
                          ?.children?.length > 0 &&
                        renderAccounts(
                          accountData?.find(
                            (acc) => acc?.account_id === mainTab
                          )?.children
                        )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          style={{
            bottom: "0",
          }}
          className="flex  justify-between gap-5 px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
        >
          <DraftButton text="Cancel" />
          <AddButton prefixIcon={false} text="Save" type="submit" />
        </div> */}
      </div>
    </InnerLayout>
  );
};

export default ChartOfAccount;

export const activeOptns = [
  { label: "Title", value: true },
  { label: "Active Account", value: false },
];