import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import StocktransferHeader from "./stock-transfer-header";
import AddButton, { DraftButton } from "../../buttons/button";
import StocktransferItemTable from "./stock-transfer-item-table";
import {
  binLocationListAction,
  wareHouseListAction,
} from "../../../redux/actions/general-action";
import BatchQuantityBinPopover from "./batch-qty-bin-popover";
import moment from "moment";
import { stockTransferCreateAction } from "../../../redux/actions/inventory-action";
import errorToast from "../../helper-components/error-toast";
import successToast from "../../helper-components/success-toast";

const CreateEditViewStockTransfer = ({
  disabled = false,
  stockTransferDetails = {},
}) => {
  const [loadingButton, setLoadingButton] = useState(false);
  const stockTransferData = stockTransferDetails?.stocktransferData ?? {};
  const today = new Date();
  const itemData = stockTransferDetails?.itemData ?? [];
  const [warehouse, setWarehouse] = useState("");
  const [warehousename, setWarehousename] = useState("");
  const searchRef = useRef(null);
  const [heightOfFirstDiv, setHeightOfFirstDiv] = useState(0);
  const [addbatch, setAddbatch] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [warehouseList, setWarehouseList] = useState([]);
  const [binLocationList, setBinLocationList] = useState([]);
  const date = new Date();
  useEffect(() => {
    dispatch(wareHouseListAction(onSuccessWare, onerrorWare));
    dispatch(binLocationListAction(onSuccessBin, onerrorBin));
    const value = localStorage.getItem("warehouse_id");
    const name = localStorage.getItem("warehouse_name");
    if (value) {
      setWarehouse(value);
    }
    if (name) {
      setWarehousename(name);
    }
  }, []);
  const onSuccessWare = (data) => {
    setWarehouseList(
      data?.data?.map((item) => ({
        label: item?.warehouse_name,
        value: item?.warehouse_id,
      }))
    );
  };
  const onerrorWare = () => {};
  const onSuccessBin = (data) => {
    setBinLocationList(
      data?.data?.map((item) => ({
        label: (
          <span
            className={`${
              item?.is_expired_bin_location ||
              item?.is_non_sellable_bin_location
                ? "text-F51D00"
                : ""
            }`}
          >
            {item?.bin_location_no}
            {item?.is_expired_bin_location}
            {item?.is_non_sellable_bin_location}
          </span>
        ),
        value: item?.bin_no_id,
        actualLabel: item?.bin_location_no,
      }))
    );
  };
  const onerrorBin = () => {};

  useEffect(() => {
    const updateHeight = () => {
      const firstDivHeight = document.querySelector(".first-div").clientHeight;
      setHeightOfFirstDiv(firstDivHeight);
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);
  const initialData = {
    sl_no: 1,
    item_name: "",
    item_code: "",
    item_batches: [],
    item_quantity: "",
    item_uom: "",
    current_avail: {
      item_uom_source: "",
      item_uom_destination: "",
    },
    from_warehouse_id: "",
    from_warehouse_name: "",
    to_warehouse_id: "",
    to_warehouse_name: "",
    item_id: "",
    item_uom_id: "",
  };
  const initialFormValues = {
    stock_management_action: stockTransferData?.stock_management_action ?? "",
    from_warehouse_id: stockTransferData?.from_warehouse_id ?? "",
    to_warehouse_id: stockTransferData?.to_warehouse_id ?? "",
    posting_date: stockTransferData?.posting_date
      ? moment(stockTransferData?.posting_date).format("YYYY-MM-DD")
      : moment(today).format("YYYY-MM-DD"),
    stock_transfer_reason: stockTransferData?.stock_transfer_reason ?? "",
    data: [
      ...itemData?.map((item) => ({
        sl_no: 1,
        item_name: item?.item_name,
        item_code: item?.item_code,
        item_uom: item?.item_uom,
        quantity: item?.item_quantity ?? "",
        item_batches:
          item?.itemBatchData?.map((bch) => ({
            item_id: bch?.item_id,
            item_batch_number: bch?.item_batch_number,
            item_batch_quantity: bch?.item_batch_quantity,
            from_bin_location: bch?.from_bin_location,
            item_batch_free_quantity: bch?.item_batch_free_quantity,
            item_batch_purchase_rate: bch?.item_batch_purchase_rate,
            item_batch_unit_price: bch?.item_batch_unit_price,
            to_bin_location: bch?.to_bin_location,
            item_exp_date: moment(bch?.item_exp_date).format("YYYY-MM-DD"),
            from_bin_id: bch?.from_bin_id,
            to_bin_id: bch?.to_bin_id,
          })) ?? [],
        current_avail: {
          item_uom_source: "",
          item_uom_destination: "",
        },
        from_warehouse_id: item?.from_warehouse_id,
        from_warehouse_name: item?.from_warehouse_name,
        to_warehouse_id: item?.to_warehouse_id,
        to_warehouse_name: item?.to_warehouse_name,
        item_id: item?.item_id,
        item_uom_id: item?.item_uom_id,
      })),
      initialData,
    ],
  };
  const [initialValue] = useState(initialFormValues);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    stock_management_action: Yup.string().required("Stock Action is required"),
    from_warehouse_id: Yup.string().required("Source from is required"),
    to_warehouse_id: Yup.string().required("Source to is required"),
    data: Yup.array().of(
      Yup.object().shape({
        item_batches: Yup.array().of(
          Yup.object().shape({
            item_batch_purchase_rate: Yup.number()
              .typeError("Must be a number")
              .required("Purchase rate is required")
              .moreThan(0, "Purchase rate must be greater than 0"),
            item_batch_unit_price: Yup.number()
              .typeError("Must be a number")
              .required("Unit price is required")
              .moreThan(0, "Unit price must be greater than 0"),

            item_batch_quantity: Yup.number()
              .integer("Quantity must be an integer")
              .required("Sellable quantity is required")
              .typeError("Must be a number")
              .moreThan(0, "Qty must be greater than 0"),
            item_batch_free_quantity: Yup.number()
              .transform((value) =>
                typeof value === "string" ? parseInt(value, 10) : value
              ) // Transform string to number
              .typeError("Must be a number")
              .integer("Quantity must be an integer"),
            item_batch_number: Yup.string().required(
              "Batch number is required"
            ),
            // to_bin_id: Yup.string().required("Bin ID is required"),
            item_exp_date: Yup.date().required("Expiration date is required"),
          })
        ),
      })
    ),
  });
  function dispatchFn(values) {
    setLoadingButton(true);
    dispatch(
      stockTransferCreateAction(
        {
          stockTransferData: {
            warehouse_id: warehouse,
            warehouse_name: warehousename,
            stock_management_action: values?.stock_management_action,
            posting_date: values?.posting_date,
            from_warehouse_id: values?.from_warehouse_id,
            from_warehouse_name: warehouseList?.find(
              (item) => item?.value === values?.from_warehouse_id
            )?.label,
            to_warehouse_id: values?.to_warehouse_id,
            to_warehouse_name: warehouseList?.find(
              (item) => item?.value === values?.to_warehouse_id
            )?.label,
          },
          itemData: values?.data?.slice(0, -1)?.map((item) => ({
            item_id: item?.item_id,
            item_name: item?.item_name,
            item_uom: item?.item_uom,
            item_code: item?.item_code,
            from_warehouse_id: values?.from_warehouse_id,
            from_warehouse_name: warehouseList?.find(
              (item) => item?.value === values?.from_warehouse_id
            )?.label,
            to_warehouse_id: values?.to_warehouse_id,
            to_warehouse_name: warehouseList?.find(
              (item) => item?.value === values?.to_warehouse_id
            )?.label,
            item_quantity: item?.quantity,
            item_uom_id: item?.item_uom_id ?? "",
          })),
          itemBatchData:
            values?.stock_management_action === "stock_transfer_request"
              ? {
                  item_id: null,
                  item_batch_number: "",
                  item_batch_quantity: 0,
                  from_bin_location: "",
                  to_bin_location: "",
                  item_exp_date: null,
                  from_bin_id: "",
                  to_bin_id: "",
                }
              : values.data
                  ?.slice(0, -1)
                  .map((item) =>
                    item.item_batches.map((batch) => {
                      const { created_date, remainingQty, ...rest } = batch;
                      return rest;
                    })
                  )
                  .flat(),
        },
        onSuccess,
        onError
      )
    );
  }

  const handleSubmit = (values) => {
    const isValidQuantity = values?.data?.slice(0, -1)?.every((item) => {
      const totalBatchQuantity = item.item_batches.reduce(
        (acc, batch) => acc + (parseFloat(batch.item_batch_quantity) || 0),
        0
      );
      return totalBatchQuantity === parseFloat(item.quantity);
    });
    const missingBatchNumbers = values?.data
      ?.slice(0, -1)
      .some((item) =>
        item.item_batches.some((batch) => !batch.item_batch_number)
      );
    const missingExpDate = values?.data
      ?.slice(0, -1)
      .some((item) => item.item_batches.some((batch) => !batch.item_exp_date));

    const missingQty = values?.data
      ?.slice(0, -1)
      .some((item) =>
        item.item_batches.some(
          (batch) =>
            !batch.item_batch_quantity ||
            parseFloat(batch.item_batch_quantity) === 0 ||
            batch.item_batch_quantity === ""
        )
      );
    const missingUOM = values?.data
      ?.slice(0, -1)
      .some((item) => !item.item_uom);

    if (values?.data?.slice(0, -1)?.length < 1) {
      errorToast("Please add items");
    } else if (
      values?.data
        ?.slice(0, -1)
        .some((item) => parseInt(item.quantity) === 0 || item.quantity === "")
    ) {
      errorToast("Quantity cannot be zero");
    } else if (
      !isValidQuantity &&
      values?.stock_management_action !== "stock_transfer_request"
    ) {
      errorToast("Quantities do not match");
    } else if (missingBatchNumbers || missingQty || missingExpDate) {
      errorToast("Please add batch number, Exp date and Qty for all batches");
    } else if (missingUOM) {
      errorToast("Please add UOM for all items");
    } else if (values?.stock_management_action === "stock_transfer_request") {
      dispatchFn(values);
    } else {
      dispatchFn(values);
    }
  };
  const onSuccess = (data) => {
    setLoadingButton(false);
    successToast(data?.message);
    navigate("/stock-transfer");
  };
  const onError = (data) => {
    setLoadingButton(false);
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, errors }) => {
          return (
            <Form className="h-full">
              <div className="flex flex-col h-full ">
                <div class="flex flex-wrap justify-between first-div px-3 pt-3">
                  <StocktransferHeader
                    initialData={initialData}
                    warehouse={warehouse}
                    warehouseList={warehouseList}
                    disabled={disabled}
                  />
                </div>
                {addbatch && (
                  <BatchQuantityBinPopover
                    setItemId={setItemId}
                    itemId={itemId}
                    setAddbatch={setAddbatch}
                    binLocationList={binLocationList}
                    disabled={disabled}
                  />
                )}
                <div
                  className="flex flex-col"
                  style={{
                    maxHeight: `calc(100% - ${heightOfFirstDiv}px)`,
                    height: `calc(100% - ${heightOfFirstDiv}px)`,
                  }}
                >
                  <div className="overflow-y-auto">
                    {values?.stock_management_action && (
                      <StocktransferItemTable
                        addBatchFn={(id) => {
                          setAddbatch(true);
                          setItemId(id);
                        }}
                        disabled={disabled}
                        searchRef={searchRef}
                        initialData={initialData}
                      />
                    )}
                  </div>

                  {!disabled && (
                    <div className="flex justify-between invoice-bottom-container w-full py-4 px-4 items-center mt-auto">
                      <div className="flex gap-3 fw_600">
                        <DraftButton
                          className="text-primary border border-primary  border-solid px-10 py-2 items-center rounded-md flex justify-center fw_700 text-sm"
                          type="button"
                          text="Clear All"
                          onclkFn={() => setFieldValue("data", [initialData])}
                        />
                        <DraftButton
                          className="text-F94B4B border border-F94B4B  border-solid px-10 py-2 items-center rounded-md flex justify-center fw_600 text-sm"
                          type="button"
                          onclkFn={() => {
                            navigate("/stock-transfer");
                          }}
                          text="Cancel"
                        />
                      </div>
                      <AddButton
                        isDisabled={loadingButton}
                        loading={loadingButton}
                        prefixIcon={false}
                        text="Transfer"
                        type="submit"
                        onclkFn={() => {
                          setFieldValue("submittedButton", "save");
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CreateEditViewStockTransfer;
