import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import "tw-elements-react/dist/css/tw-elements-react.min.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// "start-main": "node --es-module-specifier-resolution=node dist/server.js",
// "start-admin": "cd src/administrativesettings && node --es-module-specifier-resolution=node dist/server.js",
// "install-all": "npm install && cd src/administrativesettings && npm install",
// "start": "npm run install-all && npm run build-main && concurrently \"npm run start-main\""

//     "compile": "npm run tsc",
//     "start": "npm run install-all && concurrently \"npm run start-main\" \"npm run start-admin\"",
//     "start-main": "node --experimental-specifier-resolution=node --loader ts-node/esm ./src/server.ts",
//     "start-admin": "cd src/administrativesettings && npm start",
//     "install-all": "npm install && cd src/administrativesettings && npm install && cd ../../",
//     "tsc": "tsc"
