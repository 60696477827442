import React from 'react';

export function SidebarSection({ title, children }) {
  return (
    <div className="mb-4">
      <h3 className="text-xs font-medium text-gray-500 px-4 mb-2">{title}</h3>
      <ul className="space-y-1">
        {children}
      </ul>
    </div>
  );
}