import { Field } from "formik";
import React from "react";
import Select from "react-select";

const DynamicSelectField = ({
  options,
  placeholder = "Select Role",
  isDisabled = false,
  name,
  defaultValue = null,
  isSearchable = false,
  handleSelect = () => {},
}) => {
  const customStyles = {
    indicatorSeparator: () => ({ display: "none" }),
    control: (base, state) => ({
      ...base,
      fontFamily: "Poppins,sans-serif ",
      fontWeight: "500",
      // fontSize: "14px",
      borderRadius: "8px",
      width: "100%",
      backgroundColor: state.isDisabled ? "#EFF8FF" : "#EFF8FF",
      borderColor: state.isFocused ? "transparent" : "transparent",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {},
      height: "3rem",
    }),
    option: (provided, state) => ({
      ...provided,
      ...(state.data.color ? { color: state.data.color } : { color: "black" }),
      backgroundColor: state.isSelected ? "#e5ecffb2" : null,
      // fontSize: "14px",
      zIndex: "999",
      borderBottom: "1px solid #F5F5F5",
      fontFamily: "Inter, sans-serif",
      "&:hover": {
        backgroundColor: state.isFocused ? "#0000002E" : "#0000002E",
      },
    }),
    placeholder: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isDisabled ? "#AFAFAF" : "#1570EF", // Adjust the color here
      fontFamily: "Inter,sans-serif",
      // fontSize: "13px",
      fontWeight: "600",
      opacity: "1",
      whiteSpace: "nowrap",
    }),
    singleValue: (base) => ({
      ...base,
      color: "#1570EF",
      fontWeight: "600",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? "#AFAFAF" : "#1570EF",
      ...(state.selectProps.menuIsOpen
        ? {
            transform: "rotate(270deg)",
          }
        : {}),
    }),
  };

  const selectValue = (form) => {
    let result = null;
    if (form.values[name]) {
      result = options?.find((item) => item.value === form.values[name]) ?? {
        label: form.values[name],
        value: form.values[name],
      };
    }
    return result ?? defaultValue ?? null;
  };

  return (
    <>
      <Field name={name}>
        {({ form }) => {
          return (
            <Select
              isSearchable={isSearchable}
              isDisabled={isDisabled}
              className="w-full text-sm placeholder:text-sm"
              styles={customStyles}
              options={options}
              onChange={(selectedOption) => {
                form.setFieldValue(name, selectedOption?.value);
                handleSelect(selectedOption?.value);
              }}
              placeholder={placeholder}
              value={selectValue(form)}
            />
          );
        }}
      </Field>
    </>
  );
};

export default DynamicSelectField;
