import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { storeIdAction } from "../../../redux/actions/general-action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { storeDetailsAction } from "../../../redux/actions/invoices-action";
import successToast from "../../../components/helper-components/success-toast";
import errorToast from "../../../components/helper-components/error-toast";
import {
  salesDeliveryEditAction,
  salesDeliveryReceiptCreateAction,
} from "../../../redux/actions/sales-action";
import moment from "moment";
import DeliveryPdfContent from "./delivery-pdf-content";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import SalesPurchaseHeader from "../sales-purchase-header";
import SalesPurchaseFinalBillTable, {
  calculateSubtotal,
  calculateTotal,
  calculateTotalDis,
  calculateTotalTax,
} from "../sales-purchase-finalBill-table";
import { useHotkeys } from "react-hotkeys-hook";
import SalesItemsTable from "../sales-items-table";
import SalesBatchPopover from "../sales-batches-popover";
import { handlea4Print } from "../../helper-components/helper-function";

const CreateEditViewDelivery = ({
  orderDetail = [],
  vendorDetails = [],
  disabled = false,
  page = "",
  data,
  type = "",
  standAlone = false,
}) => {
  const rowData = useSelector((state) => state.invoiceRowReducer);
  const searchRef = useRef(null);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [heightOfFirstDiv, setHeightOfFirstDiv] = useState(0);
  useEffect(() => {
    const updateHeight = () => {
      const firstDivHeight = document.querySelector(".first-div").clientHeight;
      setHeightOfFirstDiv(firstDivHeight);
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);
  const [addbatch, setAddbatch] = useState(false);
  const [itemId, setItemId] = useState(null);
  const initialData = {
    sl_no: 1,
    item_code: "",
    item_generic_name: "",
    item_name: "",
    item_hsn: "",
    item_manufacturer_name: "",
    item_uom: "",
    item_to_be_delivered: 0,
    quantity: 0,
    item_free_quantity: 0,
    item_batches: [],
    item_discount_amount: 0.0,
    item_total_tax_percentage: 0,
    item_tax_amount: 0,
    item_total_amount: 0,
    item_id: "",
    instock: true,
    item_gst: 0,
    item_sgst: 0,
    item_cgst: 0,
    item_uom_id: "",
    item_manufacturer_id: "",
    base_quantity: 0,
    pot_id: null,
    pos_id: null,
    base_quantity: 1,
  };

  const initialFormValues =
    rowData?.data?.length >= 1
      ? {
          ...rowData,
          data: [...rowData.data, initialData],
        }
      : {
          cmr_code: vendorDetails?.[0]?.cmr_id
            ? {
                label: vendorDetails?.[0]?.cmr_code ?? "",
                value: vendorDetails?.[0]?.cmr_id ?? "",
              }
            : "",
          cmr_name: vendorDetails?.[0]?.cmr_id
            ? {
                label: vendorDetails?.[0]?.cmr_name,
                value: vendorDetails?.[0]?.cmr_id,
              }
            : "",
          cmr_id: vendorDetails?.[0]?.cmr_id,
          cmr_phone_number: vendorDetails?.[0]?.cmr_phone_number ?? "",
          remarks: vendorDetails?.[0]?.remarks ?? "",
          sodtt_total_discount: 0,
          ...(vendorDetails?.[0]?.sodtt_id
            ? { sodtt_id: vendorDetails?.[0]?.sodtt_id }
            : {}),
          sodtt_order_date: vendorDetails?.[0]?.sodtt_order_date
            ? moment(vendorDetails?.[0]?.sodtt_order_date).format("YYYY-MM-DD")
            : moment(new Date()).format("YYYY-MM-DD"),
          sodtt_document_date: vendorDetails?.[0]?.sodtt_document_date
            ? moment(vendorDetails?.[0]?.sodtt_document_date).format(
                "YYYY-MM-DD"
              )
            : moment(new Date()).format("YYYY-MM-DD"),
          sodtt_delivery_date: vendorDetails?.[0]?.sodtt_delivery_date
            ? moment(vendorDetails?.[0]?.sodtt_delivery_date).format(
                "YYYY-MM-DD"
              )
            : moment(new Date()).format("YYYY-MM-DD"),
          pot_id: vendorDetails?.[0]?.pot_id ?? null,
          pos_id: vendorDetails?.[0]?.pos_id ?? null,
          sodtt_round_off_value: vendorDetails?.[0]?.sodtt_round_off_value ?? 0,

          data: [
            ...orderDetail?.map((item, index) => ({
              sl_no: index + 1,
              item_code: item?.item_code,
              item_generic_name: item?.item_generic_name,
              item_name: item?.item_name,
              item_hsn: item?.item_hsn,
              item_manufacturer_name: item?.item_manufacturer_name,
              item_uom: item?.item_uom ?? "--",
              item_to_be_delivered: item?.item_to_be_delivered,
              quantity: type === "sales" ? 0 : item?.item_quantity ?? 0,
              item_free_quantity: parseInt(item?.item_free_quantity ?? 0) ?? 0,
              item_batches:
                // page === ""
                //   ? []
                //   :
                item?.itemBatchData?.map((batch) => ({
                  item_batch_number: batch?.item_batch_number ?? 0,
                  item_batch_quantity:
                    parseInt(batch?.item_batch_quantity) || 0,
                  item_batch_free_quantity:
                    parseInt(batch?.item_batch_free_quantity) || 0,
                  item_batch_sales_rate:
                    parseFloat(batch?.item_batch_sales_rate) || 0,
                  item_batch_discount_percentage:
                    parseFloat(batch?.item_batch_discount_percentage) || 0,
                  item_batch_discount_amount:
                    parseFloat(batch?.item_batch_discount_amount) || 0,
                  item_batch_tax_percentage:
                    parseFloat(batch?.item_batch_tax_percentage) || 0,
                  item_batch_tax_amount:
                    parseFloat(batch?.item_batch_tax_amount) || 0,
                  item_batch_final_sales_rate:
                    parseFloat(batch?.item_batch_final_sales_rate) || 0,
                  item_batch_total_sales_rate:
                    parseFloat(batch?.item_batch_total_sales_rate) || 0,
                  item_batch_sgst:
                    parseFloat(batch?.item_batch_tax_amount) / 2 || 0,
                  item_batch_cgst:
                    parseFloat(batch?.item_batch_tax_amount) / 2 || 0,
                  item_batch_unit_price:
                    parseFloat(batch?.item_batch_unit_price) || 0,
                  item_exp_date: batch?.item_exp_date
                    ? moment(batch?.item_exp_date).format("YYYY-MM-DD")
                    : "1970-01-01", // Default to a date if not provided
                  item_id: batch?.item_id ?? 0,
                  from_bin_location: batch?.from_bin_location ?? "",
                  from_bin_id: batch?.from_bin_id ?? 0,
                  item_batch_avail_quantity:
                    parseInt(batch?.item_batch_quantity) ?? 0,
                })),
              item_discount_amount:
                type === "sales" ? 0 : item?.item_discount_amount,
              item_total_tax_percentage:
                type === "sales" ? 0 : item?.item_total_tax_percentage,
              item_tax_amount:
                type === "sales" ? 0 : item?.item_tax_amount ?? 0,
              item_total_amount: type === "sales" ? 0 : item?.item_total_amount,
              item_id: item?.item_id,
              sott_id: item?.sott_id ?? null,
              ...(item?.srt_id && type === "return"
                ? { srt_id: item?.srt_id }
                : { srt_id: null }),
              instock: true,
              item_gst: type === "sales" ? 0 : item?.item_gst,
              item_cgst: type === "sales" ? 0 : item?.item_cgst,
              item_sgst: type === "sales" ? 0 : item?.item_sgst,
              uom_dropdown: item?.uom_dropdown
                ? item?.uom_dropdown.map((uom) => ({
                    label: uom?.alt_uom,
                    value: uom?.alt_uom,
                  }))
                : [],
              item_uom_id: item?.item_uom_id ?? "",
              item_manufacturer_id: item?.item_manufacturer_id ?? "",
              pot_id: item?.pot_id ?? null,
              pos_id: item?.pos_id ?? null,
              base_quantity: item?.base_quantity ?? 1,
            })),
            initialData,
          ],
        };
  const [initialValue, setInitialValue] = useState(initialFormValues);
  const [reload, setReload] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [storeId, setStoreId] = useState("");
  const [storeDetails, setStoreDetails] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    cmr_code: Yup.object().required("Customer code number is required"),
    cmr_name: Yup.object().required("Customer name is required"),
  });
  useEffect(() => {
    dispatch(storeIdAction(onSuccessStore, onErrorStore));
    dispatch(storeDetailsAction(onSuccessStoreDetails, onErrorStoreDetails));
  }, []);
  const onSuccessStore = (data) => {
    setStoreId(data?.data?.store_id);
    setReload(!reload);
  };
  const onErrorStore = () => {};
  const onSuccessStoreDetails = (data) => {
    setStoreDetails(data?.data);
  };
  const onErrorStoreDetails = () => {};

  const handleSubmit = (values, actions) => {
    const missingUOM = values?.data
      ?.slice(0, -1)
      .some((item) => !item.item_uom);
    if (page === "edit") {
      setButtonLoading(true);

      dispatch(
        salesDeliveryEditAction(
          {
            sodtt_id: values?.sodtt_id,
            remarks: values?.remarks,
          },
          onSuccessOrder,
          onErrorOrder
        )
      );
    } else {
      if (
        values?.data
          ?.slice(0, -1)
          .some((item) => parseInt(item.quantity) === 0 || item.quantity === "")
      ) {
        errorToast("Quantity cannot be zero");
      } else if (missingUOM) {
        errorToast("Please add UOM for all items");
      } else {
        if (values?.data?.length > 1) {
          setButtonLoading(true);

          dispatch(
            salesDeliveryReceiptCreateAction(
              {
                orderData: {
                  store_id: storeId,
                  ...(values?.sodtt_id ? { sodtt_id: values?.sodtt_id } : {}),
                  sodtt_total_gst: parseFloat(
                    formValues?.data
                      ?.slice(0, -1)
                      .reduce(
                        (total, item) =>
                          total + parseFloat(item?.item_tax_amount),
                        0
                      )
                  ),
                  sodtt_total_discount: calculateTotalDis(
                    values?.data
                  )?.toFixed(2),
                  sodtt_payment_status: "paid",
                  sodtt_transaction_id: "",
                  sodtt_billing_address: "",
                  sodtt_total_amount: Math.round(
                    calculateTotal(values?.data)
                  )?.toFixed(2),
                  sodtt_sub_total: parseFloat(
                    parseFloat(calculateTotal(values?.data)) -
                      parseFloat(calculateTotalTax(values?.data)) -
                      calculateTotalDis(values?.data)?.toFixed(2)
                  ).toFixed(2),
                  cmr_code: values?.cmr_code?.label,
                  cmr_id: values?.cmr_id,
                  cmr_name: values?.cmr_name?.label,
                  remarks: values?.remarks,
                  sodtt_order_date: values?.sodtt_order_date,
                  sodtt_document_date: values?.sodtt_document_date,
                  sodtt_delivery_date: values?.sodtt_delivery_date,
                  pot_id: values?.pot_id ?? null,
                  pos_id: values?.pos_id ?? null,
                  sodtt_round_off_value: (
                    Math.round(calculateTotal(values?.data)) -
                    calculateTotal(values?.data)
                  )?.toFixed(2),
                },
                itemData: values?.data?.slice(0, -1).map((item, index) => ({
                  item_code: item?.item_code,
                  item_generic_name: item?.item_generic_name,
                  item_name: item?.item_name,
                  item_hsn: item?.item_hsn,
                  item_quantity: parseInt(item?.quantity),
                  item_delivered_quantity: parseInt(item?.quantity),
                  item_free_quantity: item?.item_batches?.reduce(
                    (sum, batch) =>
                      sum + (parseInt(batch.item_batch_free_quantity, 10) || 0),
                    0
                  ),
                  item_batch_number: item?.item_batch_number,
                  item_schedule: item?.item_schedule,
                  item_discount_amount: parseFloat(item?.item_discount_amount),
                  item_total_tax_percentage: item?.item_total_tax_percentage,
                  item_tax_amount: parseFloat(item?.item_tax_amount),
                  item_total_amount: item?.item_total_amount,
                  item_id: item?.item_id,
                  ...(item?.sott_id
                    ? { sott_id: item?.sott_id }
                    : { sott_id: null }),
                  ...(item?.srt_id
                    ? { srt_id: item?.srt_id }
                    : { srt_id: null }),
                  item_gst: parseFloat(item?.item_gst),
                  item_sgst: parseFloat(item?.item_sgst),
                  item_cgst: parseFloat(item?.item_cgst),
                  item_uom_id: item?.item_uom_id ?? "",
                  item_uom: item?.item_uom ?? "",
                  item_manufacturer_id: item?.item_manufacturer_id ?? "",
                  item_manufacturer_name: item?.item_manufacturer_name ?? "",
                  pot_id: item?.pot_id ?? null,
                  pos_id: item?.pos_id ?? null,
                })),

                itemBatchData: values.data
                  ?.slice(0, -1)
                  .map((item) => item.item_batches)
                  .flat()
                  .map((batch) => ({
                    item_batch_number: batch?.item_batch_number,
                    item_batch_quantity: parseInt(batch?.item_batch_quantity),
                    item_batch_free_quantity: parseInt(
                      batch?.item_batch_free_quantity
                    ),
                    item_batch_sales_rate: parseFloat(
                      batch?.item_batch_sales_rate
                    ),
                    item_batch_discount_percentage:
                      batch?.item_batch_discount_percentage,
                    item_batch_discount_amount: parseFloat(
                      batch?.item_batch_discount_amount
                    ),
                    item_batch_tax_percentage: batch?.item_batch_tax_percentage,
                    item_batch_tax_amount: parseFloat(
                      batch?.item_batch_tax_amount
                    ),
                    item_batch_final_sales_rate: parseFloat(
                      batch?.item_batch_final_sales_rate
                    ),
                    item_batch_total_sales_rate: parseFloat(
                      batch?.item_batch_total_sales_rate
                    ),
                    item_batch_unit_price: parseFloat(
                      batch?.item_batch_unit_price
                    ),
                    item_exp_date: batch?.item_exp_date,
                    item_id: batch?.item_id,
                    from_bin_location: batch?.from_bin_location,
                    from_bin_id: batch?.from_bin_id,
                    item_uom: orderDetail?.[0]?.item_uom,
                    item_uom_id: orderDetail?.[0]?.item_uom_id,
                  })),
              },
              onSuccessOrder,
              onErrorOrder
            )
          );
        }
      }
    }
  };
  const downloadPdf = async (data) => {
    const fileName = "test.pdf";
    try {
      const blob = await pdf(
        <DeliveryPdfContent data={data} storeDetails={storeDetails} />
      ).toBlob();
      saveAs(blob, fileName);
    } catch (error) {}
  };
  const onSuccessOrder = (data) => {
    setButtonLoading(false);

    if (formValues?.submittedButton === "print") {
      handlea4Print(
        data?.data?.orderData?.sodtt_id,
        "sales_delivery",
        dispatch
      );

      // downloadPdf(data?.data);
    }
    navigate("/sales-delivery");
    successToast(data?.message);
    setInitialValue({
      cmr_code: "",
      cmr_name: "",
      cmr_id: "",
      remarks: "",
      sodtt_total_discount: 0,
      sodtt_order_date: null,
      sodtt_document_date: null,
      sodtt_delivery_date: null,
      data: [initialData],
    });
  };
  const onErrorOrder = () => {
    setButtonLoading(false);
  };

  useHotkeys("escape", () => {
    setAddbatch(false);
  });
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValue}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        setTimeout(() => {
          handleSubmit(values, actions); // Pass values and actions if needed
          actions.setSubmitting(false); // Notify Formik that submission is done
        }, 500);
      }}
    >
      {({ values }) => {
        setFormValues(values);
        return (
          <Form className="h-full">
            <div className="flex flex-col h-full ">
              <div class="flex flex-wrap justify-between first-div px-3">
                <SalesPurchaseHeader
                  section="sales"
                  name="sodtt"
                  disabled={disabled}
                  searchRef={searchRef}
                />
              </div>
              {addbatch && (
                <SalesBatchPopover
                  setItemId={setItemId}
                  itemId={itemId}
                  setAddbatch={setAddbatch}
                  disabled={disabled}
                  disName="sodtt_total_discount"
                />
              )}
              <div
                className="flex flex-col"
                style={{
                  maxHeight: `calc(100% - ${heightOfFirstDiv}px)`,
                  height: `calc(100% - ${heightOfFirstDiv}px)`,
                }}
              >
                <SalesItemsTable
                  standAlone={standAlone}
                  disName="sodtt_total_discount"
                  type={type}
                  disabled={disabled}
                  addBatchFn={(id) => {
                    setAddbatch(true);
                    setItemId(id);
                  }}
                  searchRef={searchRef}
                  initialData={initialData}
                />
                <div className="h-[16.9rem] mt-auto">
                  <SalesPurchaseFinalBillTable
                    transaction="Delivery Note"
                    transaction_type="sales_delivery"
                    buttonLoading={buttonLoading}
                    name="sodtt_total_discount"
                    page={page}
                    disabled={disabled}
                    EditFn={() =>
                      navigate(`/edit-delivery/${vendorDetails?.[0]?.sodtt_id}`)
                    }
                    printFn={() => downloadPdf(data)}
                  />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateEditViewDelivery;