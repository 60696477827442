import React, { useEffect, useState } from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import AdminCusCreateEditView from "../../components/admin-customer/admin-cus-create-edit-view";
import { useDispatch } from "react-redux";
import { adminCustomerViewAction } from "../../redux/actions/admin-customer-action";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../components/helper-components/loading-spinner";

const AdminEditCustomer = () => {
  const [reload, setReload] = useState(false);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [customerId, setCustomerId] = useState(null);
  const dispatch = useDispatch();
  const [customerDetails, setCustomerDetails] = useState({});

  useEffect(() => {
    dispatch(adminCustomerViewAction(id, onSuccess, onError));
  }, [reload]);

  const onSuccess = (data) => {
    setLoading(false);
    setCustomerId(data?.data?.cmr_id);
    setCustomerDetails(data?.data);
  };
  const onError = (data) => {
    console.log(data);
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <InnerLayout
          onclkFn={"/admin/add-customer"}
          title1={customerId}
          title="Update Customer"
        >
          <AdminCusCreateEditView
            page="edit"
            customerId={customerId}
            setCustomerId={setCustomerId}
            reload={reload}
            setReload={setReload}
            customerDetails={customerDetails}
          />
        </InnerLayout>
      )}
    </>
  );
};

export default AdminEditCustomer;
