import { call, takeLatest } from "redux-saga/effects";
import {
  forgotPasswordApi,
  resetPasswordApi,
  storeLoginApi,
} from "../apis/auth-api";
import {
  STORE_FORGOT_PASSWORD,
  STORE_LOGIN,
  STORE_RESET_PASSWORD,
} from "../types";

export function* storeLoginWorker(action) {
  try {
    const res = yield call(storeLoginApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
      localStorage.setItem("accessToken", res?.data?.data?.token);
      localStorage.setItem("warehouse_id", res?.data?.data?.warehouse_id);
      localStorage.setItem("warehouse_name", res?.data?.data?.warehouse_name);
      localStorage.setItem("user_role", res?.data?.data?.user_role);
      localStorage.setItem("user_name", res?.data?.data?.user_name);
      localStorage.setItem("company", res?.data?.data?.company);
      localStorage.setItem(
        "company_landmark",
        res?.data?.data?.company_landmark
      );
      localStorage.setItem("company_logo", res?.data?.data?.company_logo);
      localStorage.setItem("store_icon", res?.data?.data?.store_icon);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* forgotPasswordWorker(action) {
  try {
    const res = yield call(forgotPasswordApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
      localStorage.setItem("accessToken", res?.data?.data?.token);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* resetPasswordWorker(action) {
  try {
    const res = yield call(resetPasswordApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
      localStorage.setItem("accessToken", res?.data?.data?.token);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* resetPasswordWatcher() {
  yield takeLatest(STORE_RESET_PASSWORD, resetPasswordWorker);
}
export function* forgotPasswordWatcher() {
  yield takeLatest(STORE_FORGOT_PASSWORD, forgotPasswordWorker);
}
export function* storeLoginWatcher() {
  yield takeLatest(STORE_LOGIN, storeLoginWorker);
}
