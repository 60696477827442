import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import FilterIcon from "../../assets/icons/filter-icon";
import search from "../../assets/icons/searchIcon.svg";
import AddButton from "../buttons/button";
import ClearIcon from "../../assets/icons/clear-icon";
import { capitalizeWords } from "../helper-components/helper-function";
const FilterSection = ({
  filterData,
  setFilterData,
  placeholder,
  filterFn = () => {},
  tableHeading,
  filterBy,
  showFilter,
  initialFilter = {},
  paginationProps,
  filterText = "Filter",
  date = true,
  filter,
  permissionFn,
  module,
  onclkFn,
  list,
  filterSec = true,
  newBtnTxt = "New",
}) => {
  const defaultPermissionFn = () => {};
  const filterPopupRef = useRef(null);
  const handleSelectChange = (event) => {
    setFilterData({ ...filterData, selectColumn: event?.value });
  };
  const searchRef = useRef();
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        showFilter &&
        filterPopupRef.current &&
        !filterPopupRef.current.contains(event.target)
      ) {
        filterFn();
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [filterFn, showFilter]);
  const [isFocused, setIsFocused] = useState(false);

  return (
    <>
      {filterSec ? (
        <div className="flex items-center  gap-2   flex-wrap justify-between pt-2 px-4">
          {" "}
          <div
            style={{ maxWidth: "27rem", height: "2.6rem" }}
            className={`w-full  flex items-center rounded-md    ${
              isFocused ? "border border-focus-color" : "input_border"
            }`}
          >
            <Select
              className="border-0 text-xs placeholder:text-xs"
              menuPortalTarget={document.body}
              styles={customStyles}
              value={
                filterData?.selectColumn
                  ? tableHeading?.find(
                      (item) => item?.value === filterData?.selectColumn
                    )
                    ? {
                        ...tableHeading?.find(
                          (item) => item?.value === filterData?.selectColumn
                        ),
                        label: capitalizeWords(
                          tableHeading?.find(
                            (item) => item?.value === filterData?.selectColumn
                          )?.label
                        ),
                        // Capitalize first letter of each word
                      }
                    : null
                  : null
              }
              onChange={(e) => {
                handleSelectChange(e);
                setIsFocused(true);
                if (searchRef && searchRef?.current) {
                  searchRef?.current?.focus();
                }
              }}
              options={tableHeading
                ?.filter((item) => item?.column)
                ?.map((item) => ({
                  ...item,
                  label: capitalizeWords(item?.label),
                  // Capitalize first letter
                }))}
              placeholder="Select Column"
              isSearchable={false}
            />
            <div
              className={`h-full w-0.5 ${
                isFocused
                  ? "border-r border-r-focus-color"
                  : "border-r border-r-D0D5DD"
              }`}
            ></div>{" "}
            <input
              ref={searchRef}
              autoFocus={true}
              onBlur={() => setIsFocused(false)}
              onFocus={() => setIsFocused(true)}
              placeholder={`${placeholder}`}
              className=" table_search_input w-full rounded-md text-xs h-full placeholder:text-xs placeholder:text-787878 pl-3"
              type="text"
              value={filterData?.inputValue}
              onChange={(e) => {
                setFilterData({ ...filterData, inputValue: e.target.value });
                paginationProps.setCurrentPage(1);
              }}
            />
            <span className="px-2">
              <img
                style={{
                  height: "25px",
                  width: "25px",
                }}
                src={search}
                alt="search icon"
              />
            </span>
          </div>
          <div className={"flex items-center gap-3"}>
            {" "}
            {date && (
              <div
                style={{
                  width: "fit-content",
                  height: "2.6rem",
                }}
                className="input_border rounded-md text-344054 fw_600 flex items-center px-2"
              >
                <>
                  {" "}
                  <div className="px-1 flex items-center">
                    <span className="text-xs">From:</span>
                    <input
                      style={{
                        width: "7rem",
                      }}
                      placeholder="DD-MM-YYYY"
                      className=" table_search_input w-full rounded-md mr-4 ml-1 text-xs placeholder:text-fs_12"
                      type="date"
                      value={filterData?.from}
                      onChange={(e) => {
                        setFilterData({ ...filterData, from: e.target.value });
                        paginationProps.setCurrentPage(1);
                      }}
                    />
                    <span className="text-xs">To:</span>
                    <input
                      style={{
                        width: "7rem",
                      }}
                      placeholder="DD-MM-YYYY"
                      className=" table_search_input w-full rounded-md ml-1 text-xs placeholder:text-fs_12"
                      type="date"
                      value={filterData?.to}
                      onChange={(e) => {
                        setFilterData({ ...filterData, to: e.target.value });
                        paginationProps.setCurrentPage(1);
                      }}
                    />
                  </div>
                </>
              </div>
            )}
            <button
              style={{
                height: "2.6rem",
              }}
              onClick={() => {
                setFilterData(initialFilter);
                paginationProps.setCurrentPage(1);
                if (searchRef && searchRef?.current) {
                  searchRef?.current?.focus();
                }
              }}
              className={`px-4 whitespace-nowrap text-sm  fw_600 rounded-md input_border flex items-center gap-2  h-full ${
                JSON.stringify(filterData) === JSON.stringify(initialFilter) &&
                "bg-disabled-color"
              }`}
            >
              <ClearIcon />
              Clear Filter
            </button>
            {filter && (
              <div ref={filterPopupRef} className="relative">
                <button
                  style={{
                    height: "2.6rem",
                  }}
                  onClick={filterFn}
                  className="flex items-center whitespace-nowrap text-sm input_border rounded-md px-3 gap-2 text-344054 fw_600"
                >
                  <FilterIcon />
                  {filterText}
                </button>
                {filterBy}
              </div>
            )}
            {(permissionFn || defaultPermissionFn)(module)?.create && list && (
              <AddButton onclkFn={onclkFn} text={newBtnTxt} />
            )}
          </div>
        </div>
      ) : (
        <div className="w-full flex justify-end mt-2 px-4">
          <></>
          {(permissionFn || defaultPermissionFn)(module)?.create && list && (
            <AddButton onclkFn={onclkFn} text={newBtnTxt} />
          )}
        </div>
      )}
    </>
  );
};

export default FilterSection;
export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    // fontSize: "12px",
    fontWeight: "600",
    color: "#344054",
    width: "15rem",
    boxShadow: "none", // Remove box shadow
    border: "none",
    borderRadius: "6px",
  }),
  // placeholder: (defaultStyles) => {
  //   return {
  //     ...defaultStyles,
  //     color: "gray",
  //     fontFamily: "Inter,sans-serif",
  //     fontSize: "12px",
  //     fontWeight: "600",
  //     opacity: "1",
  //     position: "absolute",
  //     whiteSpace: "nowrap",
  //   };
  // },
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none", // Hide indicator separator
  }),
  singleValue: (base) => ({
    ...base,
    fontWeight: "600",
    color: "#344054",
    // fontSize: "12px",
  }),
  option: (provided, state) => {
    return {
      ...provided,
      ...(state.data.color ? { color: state.data.color } : { color: "black" }),
      backgroundColor: state.isSelected ? "#e5ecffb2" : null,
      borderBottom: "1px solid #F5F5F5",
      fontSize: "12px",
      fontFamily: "Inter,sans-serif",
      "&:hover": {
        backgroundColor: state.isFocused ? "#0000002E" : "#0000002E",
      },
    };
  },
};
