import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import CloseIcon from "../../assets/icons/close-icon";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import CheckBox from "../forms/check-box-field";
import InputField from "../forms/input-field";
import AddButton, { DraftButton } from "../buttons/button";
import { ShareIcon } from "../../assets/icons/action-icons";
import { useDispatch } from "react-redux";
import successToast from "./success-toast";
import errorToast from "./error-toast";
import { transactionShareAction } from "../../redux/actions/general-action";

const ShareTransactionModal = ({
  isModalOpen,
  setIsModalOpen,
  transaction = "Invoice",  
  transaction_type = "",
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handleSubmit = (values) => {
    setLoading(true);
    dispatch(
      transactionShareAction(
        {
          transaction_type: transaction_type ?? "",
          transaction_id: isModalOpen ?? "",
          email_address: values?.email_address ?? "",
          email: values?.email ?? false,
          whats_app: values?.whats_app ?? false,
          sms: values?.sms ?? false,
        },
        onSuccess,
        onError
      )
    );
  };
  const onSuccess = (data) => {
    successToast(data?.message);
    setIsModalOpen("");
    setLoading(false);
  };
  const onError = (data) => {
    errorToast(data?.message);
    setLoading(false);
  };

  return (
    <Transition show={isModalOpen ? true : false} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => setIsModalOpen("")}
      >
        <div className="flex items-center justify-center min-h-screen px-4 text-center">
          {/* Overlay */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>

          {/* Modal Content */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="mx-auto w-full max-w-md my-8 p-6 text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
              {/* Modal Header */}
              <div className="flex justify-between items-center">
                <h2 className="text-lg font-medium leading-6 text-gray-900">
                  Share {transaction}
                </h2>
                <button
                  className="close-button bg-transparent border-none p-0 hover:bg-787878"
                  onClick={() => setIsModalOpen("")}
                >
                  <CloseIcon />
                </button>
              </div>

              <Formik
                enableReinitialize={true}
                initialValues={{
                  email: false,
                  whats_app: false,
                  sms: false,
                  email_address: "",
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.boolean(),
                  whats_app: Yup.boolean(),
                  sms: Yup.boolean(),
                  atLeastOne: Yup.mixed().test(
                    "at-least-one",
                    "At least one communication method (Email, WhatsApp, or SMS) must be selected.",
                    function () {
                      const { email, whats_app, sms } = this.parent;
                      return email || whats_app || sms;
                    }
                  ),
                  email_address: Yup.string().email("Invalid email address"),
                })}
                onSubmit={handleSubmit}
              >
                {({ values, errors }) => {
                  
                  return (
                    <Form>
                      <CheckBox
                        conClass="flex mt-5  gap-10"
                        items={[
                          { label: "Email", name: "email" },
                          { label: "SMS", name: "sms" },
                          { label: "WhatsApp", name: "whats_app" },
                        ]}
                      />

                      {values?.email && (
                        <InputField
                          containerClass="mt-5 mx-5"
                          onKeyDown={() => {}}
                          isRequired={
                            values?.cmr_type === "individual" &&
                            values?.is_corporate_cmr
                          }
                          isDisabled={false}
                          type="email"
                          name="email_address"
                          placeholder="Enter E-mail"
                          label="Email"
                          labelWidth="4rem"
                        />
                      )}

                      <div className="flex justify-between items-center mt-5">
                        <DraftButton
                          className={
                            "text-primary border border-primary  border-solid px-6 py-2 items-center rounded-md flex justify-center fw_500 text-sm"
                          }
                          // onclkFn={() => setIsModalOpen("")}
                          text="Cancel"
                        />
                        <AddButton
                          loading={loading}
                          onclkFn={() => {
                            if (errors?.atLeastOne) {
                              errorToast(errors?.atLeastOne);
                            }
                          }}
                          icon={<ShareIcon fill="#ffffff" />}
                          type="submit"
                          className="bg-primary text-white px-4 py-2 rounded flex items-center gap-3 justify-center fw_500 text-sm"
                          text="Share"
                          prefixIcon={true}
                        />
                      </div>
                    </Form>
                  );
                }}
              </Formik>

              {/* Modal Footer */}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ShareTransactionModal;
