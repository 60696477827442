import React, { useRef, useEffect } from "react";
import moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake?.vfs;

const GeneratedPDFViewer = ({ initialValue }) => {
  const pdfRef = useRef(null);

  useEffect(() => {
    if (initialValue) {
      generatePDF(initialValue);
    }
  }, [initialValue]);

  const generatePDF = (data) => {
    const stockReceivedDate = data.stock_recevied_date
      ? moment(data.stock_recevied_date).format("YYYY-MM-DD")
      : "";
    const openingDate = data.opening_date
      ? moment(data.opening_date).format("YYYY-MM-DD")
      : "";
    const openingTime = data.opening_time
      ? moment(data.opening_time, "HH:mm:ss").format("HH:mm:ss")
      : "";

    const documentDefinition = {
      content: [
        { text: "Stock Received Date: ", style: "header" },
        { text: stockReceivedDate, style: "content" },
        { text: "Opening Date: ", style: "header" },
        { text: openingDate, style: "content" },
        { text: "Opening Time: ", style: "header" },
        { text: openingTime, style: "content" },
        { text: "Chief Guest Name: ", style: "header" },
        { text: data.cheif_guest_name, style: "content" },
        { text: "Chief Guest Designation: ", style: "header" },
        { text: data.cheif_guest_desgination, style: "content" },
      ],
      styles: {
        header: {
          bold: true,
          fontSize: 14,
          color: "blue", // Change the color to your desired color
          margin: [0, 5, 0, 10], // [left, top, right, bottom] margins
        },
        content: {
          fontSize: 12,
          color: "green", // Change the color to your desired color
          margin: [0, 5, 0, 10], // [left, top, right, bottom] margins
        },
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

    pdfDocGenerator.getDataUrl((dataUrl) => {
      pdfRef.current.src = dataUrl;
    });
  };

  return <iframe ref={pdfRef} style={{ width: "100%", height: "100vh" }} />;
};

export default GeneratedPDFViewer;
