import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import BarcodeIcon from "../../assets/icons/barcode-icon";
import AddButton from "../../components/buttons/button";
import "../../components/customer-information/customer-information.css";
import CheckBox from "../../components/forms/check-box-field";
import InputField from "../../components/forms/input-field";
import RadioButton from "../../components/forms/radio-button";
import SelectField from "../../components/forms/select-field";
import TextAreaField from "../../components/forms/textarea-field";
import errorToast from "../../components/helper-components/error-toast";
import { hsnRegExp } from "../../components/helper-components/regexp";
import successToast from "../../components/helper-components/success-toast";
import InventoryTab from "../../components/product-information/inventory-tab";
import PlanningTab from "../../components/product-information/planning-tab";
import PurchasingTab from "../../components/product-information/purchasing-tab";
import RemarksTab from "../../components/product-information/remarks-tab";
import RestrictionsTab from "../../components/product-information/restrictions-tab";
import SalesTab from "../../components/product-information/sales-tab";
import {
  catListAction,
  itemGroupListAction,
  itemTypeListAction,
  manufacturesListAction,
  priceListAction,
  schListAction,
  sectorListAction,
  shippingTypeListAction,
  taxCatListAction,
  uomGroupListAction,
  uomListAction,
  vendorListAction,
} from "../../redux/actions/general-action";
import { productEditAction } from "../../redux/actions/product-action";
import BarcodeModal from "../modals/product-modals/barcode-modal";
import { useHotkeys } from "react-hotkeys-hook";
import TabsComponent from "../helper-components/tabs-component";
import { useNavigate } from "react-router-dom";
import { uomGroupTableListAction } from "../../redux/actions/settings-action";
import { handleShowErrorToast } from "../helper-components/helper-function";
const ViewEditProduct = ({ disabled, product }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [catList, setCatList] = useState([]);
  const [uomList, setUomList] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [itemGroup, setItemGroup] = useState([]);
  const [uomGroupData, setUomGroupData] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [manufacturesList, setManufacturesList] = useState([]);
  const [shippingTypeList, setShippingTypeList] = useState([]);
  const [sectorList, setSectorList] = useState([]);
  const [schList, setSchList] = useState([]);
  const [currentTab, setCurrentTab] = useState("General");
  const containerRef = useRef(null);
  const [uomGroupList, setUomGroupList] = useState([]);
  const [itemType, setItemType] = useState([]);
  const [productId, setProductId] = useState(product?.item_id);
  const [vendorList, setVendorList] = useState([]);
  const [uomGroupID, setUomGroupID] = useState("");

  const handleClick = (item) => {
    setCurrentTab(productId ? item : "General");
    const container = containerRef.current;
    const selectedTab = document.getElementById(item);
    if (container && selectedTab) {
      const containerWidth = container.offsetWidth;
      const selectedTabWidth = selectedTab.offsetWidth;
      const selectedTabLeft = selectedTab.offsetLeft;
      const scrollTo =
        selectedTabLeft - (containerWidth - selectedTabWidth) / 2;
      container.scrollTo({
        left: scrollTo,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    dispatch(vendorListAction(onVendorSuccess, onVendorError));

    dispatch(catListAction(onCatSuccess, onCatError));
    dispatch(uomListAction(onUomSuccess, onUomError));
    dispatch(priceListAction(onpriceSuccess, onpriceError));
    dispatch(taxCatListAction(ontaxCatSuccess, ontaxCatError));
    dispatch(
      manufacturesListAction(onmanufacturesSuccess, onmanufacturesError)
    );
    dispatch(
      shippingTypeListAction(onshippingTypeSuccess, onshippingTypeError)
    );
    dispatch(itemTypeListAction(onitemTypeSuccess, onitemTypeError));

    dispatch(itemGroupListAction(onitemGroupSuccess, onitemGroupError));
    dispatch(sectorListAction(onsectorSuccess, onsectorError));
    dispatch(schListAction(onschSuccess, onschError));
    // dispatch(uomGroupListAction(onUomGrpSuccess, onUomGrpError));

    dispatch(
      uomGroupTableListAction(
        {},
        onFetchUomGroupListSuccess,
        onFetchUomGroupListError
      )
    );
  }, []);

  const onFetchUomGroupListSuccess = (data) => {
    setUomGroupData(data?.data?.uomList);
    setUomGroupList(
      data?.data?.uomList?.map((item) => {
        return { label: item?.name, value: item?.group_id };
      })
    );
  };

  const onFetchUomGroupListError = (data) => {
    setUomGroupData([]);
    errorToast(data?.message);
  };

  const onVendorSuccess = (data) => {
    setVendorList(
      data?.data?.vendors?.map((item) => {
        return { label: item?.vendors, value: item?.id };
      })
    );
  };
  const onVendorError = (data) => {
    errorToast(data?.message);
  };

  const onCatSuccess = (data) => {
    setCatList(
      data?.data?.map((item) => {
        return { label: item?.name, value: item?.id };
      })
    );
  };
  const onCatError = (data) => {
    errorToast(data?.message);
  };
  const onUomSuccess = (data) => {
    setUomList(
      data?.data?.map((item) => {
        return { label: item?.name, value: item?.name };
      })
    );
  };
  const onUomError = (data) => {
    setUomList([]);
    errorToast(data?.message);
  };

  const onpriceSuccess = (data) => {
    setPriceList(
      data?.data?.map((item) => {
        return { label: item?.price_list_name, value: item?.price_list_id };
      })
    );
  };
  const onpriceError = (data) => {
    errorToast(data?.message);
  };
  const ontaxCatSuccess = (data) => {
    setTaxList(
      data?.data?.map((item) => {
        return { label: item?.code, value: item?.id };
      })
    );
  };
  const ontaxCatError = (data) => {
    errorToast(data?.message);
  };
  const onmanufacturesSuccess = (data) => {
    setManufacturesList(
      data?.data?.map((item) => {
        return {
          label: item?.name,
          value: item?.id,
        };
      })
    );
  };
  const onmanufacturesError = (data) => {
    setManufacturesList([]);
    errorToast(data?.message);
  };

  const onshippingTypeSuccess = (data) => {
    setShippingTypeList(
      data?.data?.map((item) => {
        return { label: item?.name, value: item?.id };
      })
    );
  };
  const onshippingTypeError = (data) => {
    setShippingTypeList([]);
    errorToast(data?.message);
  };

  const onitemGroupSuccess = (data) => {
    setItemGroup(
      data?.data?.map((item) => {
        return { label: item?.name, value: item?.id };
      })
    );
  };
  const onitemGroupError = (data) => {
    errorToast(data?.message);
  };

  const onitemTypeSuccess = (data) => {
    setItemType(
      data?.data?.map((item) => {
        return { label: item?.name, value: item?.id };
      })
    );
  };
  const onitemTypeError = (data) => {};
  const onsectorSuccess = (data) => {
    setSectorList(
      data?.data?.sector?.map((item) => {
        return { label: item?.sector, value: item?.sector };
      })
    );
  };
  const onsectorError = (data) => {
    errorToast(data?.message);
  };
  const onschSuccess = (data) => {
    setSchList(
      data?.data?.scheduleList?.map((item) => {
        return { label: item?.schedule, value: item?.schedule };
      })
    );
  };
  const onschError = (data) => {
    errorToast(data?.message);
  };

  // const onUomGrpError = (data) => {
  //   errorToast(data?.message);
  // };
  // const onUomGrpSuccess = (data) => {
  //   setUomGroupList(
  //     data?.data?.map((item) => {
  //       return { label: item?.name, value: item?.group_id };
  //     })
  //   );
  // };

  const initialValues = {
    item_id: product?.item_id ?? null,
    item_code: product?.item_code ?? null,
    item_type: product?.item_type ?? "",
    item_name: product?.item_name ?? "",
    item_categeory: product?.item_categeory ?? "",
    item_price_list: product?.item_price_list ?? "",
    item_group: product?.item_group ?? "",
    item_generic_name: product?.item_generic_name ?? "",
    item_barcode: product?.item_barcode ?? "",
    item_uom: product?.item_uom ?? "",
    item_unit_price: product?.item_unit_price ?? null,
    item_description: product?.item_description ?? "",
    is_inventory_item: product?.is_inventory_item ?? false,
    is_purchase_item: product?.is_purchase_item ?? false,
    is_sales_item: product?.is_sales_item ?? false,
    item_manufacturer: product?.item_manufacturer ?? "",
    item_shipping_type: product?.item_shipping_type ?? "",
    item_sector: product?.item_sector ?? "",
    item_schedule: product?.item_schedule ?? "",
    // item_gst: product?.item_gst ?? false,
    item_hsn: product?.item_hsn ?? "",
    item_tax_category: product?.item_tax_category ?? "",
    item_remarks: product?.item_remarks ?? "",
    country: product?.country ?? "",
    // manage_item_by: product?.manage_item_by ?? "",
    active_from: product?.active_from ?? null,
    active_to: product?.active_to ?? null,
    inactive_from: product?.inactive_from ?? null,
    inactive_to: product?.inactive_to ?? null,
    is_active: product?.is_active ?? true,
  };

  const validationSchema = Yup.object().shape({
    item_name: Yup.string().required("product name is required"),
    item_hsn: Yup.string()
      .required("HSN code is required") // Added required validation
      .matches(hsnRegExp, "Invalid HSN code"),
    item_uom: Yup.string().required("uom is required"),
    item_tax_category: Yup.string().required("tax is required"),
  });
  const onSubmit = (values, actions) => {
    const submittedValues = {
      ...values,
      item_unit_price:
        values.item_unit_price === "" ? null : values.item_unit_price,
    };
    dispatch(productEditAction(submittedValues, onSuccess, onError));
    actions.setSubmitting(false);
  };

  const onSuccess = (data) => {
    setProductId(data?.data?.item_id);
    setCurrentTab("Purchasing");
    // navigate("/product");
    successToast("Product created Successfully");
  };
  const onError = (data) => {
    errorToast(data?.message);
  };
  const [formValues, setFormValues] = useState({
    purchasing: null,
    sales: null,
    inventory: null,
    planning: null,
    restrictions: null,
    remarks: null,
  });
  useHotkeys("escape", () => {
    setIsModalOpen(false);
  });
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, errors }) => {
          return (
            <Form className="h-full pt-5">
              <div className="flex flex-col justify-between h-full ">
                {" "}
                <div>
                  <div className="flex flex-wrap">
                    <div className="w-full 2xl:w-[43%] lg:w-[60%] px-5 xl:px-10">
                      <InputField
                        isDisabled={disabled}
                        type="text"
                        name="item_name"
                        placeholder="Enter item name"
                        label="Item Name"
                        isRequired={true}
                        labelWidth="7rem"
                      />
                      <InputField
                        isDisabled={disabled}
                        type="text"
                        name="item_generic_name"
                        placeholder="Enter Generic name"
                        label="Generic Name"
                        isRequired={false}
                        labelWidth="7rem"
                      />
                      <TextAreaField
                        isDisabled={disabled}
                        label="Description"
                        placeholder="Enter Description"
                        name="item_description"
                        id={"item_description"}
                        isRequired={false}
                        labelWidth="7rem"
                        feildHeight="9rem"
                      />
                      <div className="flex gap-3 items-start w-full">
                        <InputField
                          containerClass="mb-2 position-relative w-full"
                          isDisabled={disabled}
                          type="text"
                          name="item_barcode"
                          placeholder="Enter bar code"
                          label="Bar Code"
                          isRequired={false}
                          labelWidth="7rem"
                        />
                        <button
                          type="button"
                          onClick={() => {
                            setIsModalOpen(true);
                          }}
                          className="p-3 rounded-md bg-D5E6Ff"
                        >
                          <BarcodeIcon />
                        </button>
                      </div>
                      <BarcodeModal
                        disabled={disabled}
                        product={values}
                        setIsModalOpen={setIsModalOpen}
                        isModalOpen={isModalOpen}
                      />
                      <CheckBox
                        isDisabled={disabled}
                        conClass="flex flex-wrap justify-between gap-4"
                        items={itemoptions}
                      />
                      {/* </div> */}
                    </div>
                    <div className="w-full 2xl:w-[27%] lg:w-[40%] px-5 xl:px-10 mt-10 lg:mt-0">
                      <SelectField
                        isDisabled={disabled}
                        placeholder="Select item type"
                        options={itemType}
                        label="Item Type"
                        id="item_type"
                        name="item_type"
                        isSearchable={true}
                        errMsgContainerClass="ps-2"
                        isRequired={false}
                        labelWidth="7rem"
                      />

                      <SelectField
                        isDisabled={disabled}
                        placeholder="Select item category"
                        options={catList}
                        label="Item Category"
                        id="item_categeory"
                        name="item_categeory"
                        isSearchable={true}
                        errMsgContainerClass="ps-2"
                        isRequired={false}
                        labelWidth="7rem"
                      />
                      <SelectField
                        isDisabled={disabled}
                        placeholder="Select item group"
                        options={itemGroup}
                        label="Item Group"
                        id="item_group"
                        name="item_group"
                        isSearchable={true}
                        errMsgContainerClass="ps-2"
                        isRequired={false}
                        labelWidth="7rem"
                      />
                      <SelectField
                        isDisabled={disabled}
                        placeholder="Select Price"
                        options={priceList}
                        label="Price List"
                        id="item_price_list"
                        name="item_price_list"
                        isSearchable={true}
                        errMsgContainerClass="ps-2"
                        isRequired={false}
                        labelWidth="7rem"
                      />

                      <SelectField
                        isDisabled={disabled}
                        placeholder="Select uom"
                        // options={uomList}
                        options={uomGroupList}
                        label="UOM"
                        id="item_uom"
                        name="item_uom"
                        isSearchable={true}
                        errMsgContainerClass="ps-2"
                        isRequired={true}
                        labelWidth="7rem"
                      />

                      <InputField
                        isDisabled={disabled}
                        type="number"
                        name="item_unit_price"
                        placeholder="xxxxx"
                        label="Unit Price"
                        labelWidth="7rem"
                      />
                    </div>
                  </div>
                  <TabsComponent
                    handleClickfn={(item) => {
                      setCurrentTab(item);
                    }}
                    itemTabs={itemTabs}
                    setCurrentTab={setCurrentTab}
                    currentTab={currentTab}
                  />
                </div>
                {currentTab === "General" && (
                  <>
                    <div className="flex flex-wrap mt-3">
                      <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10">
                        <SelectField
                          isDisabled={disabled}
                          placeholder="Select manufacturer"
                          options={manufacturesList}
                          label="Manufacturer"
                          id="item_manufacturer"
                          name="item_manufacturer"
                          isSearchable={true}
                          errMsgContainerClass="ps-2"
                          isRequired={false}
                          labelWidth="7rem"
                        />
                        <SelectField
                          isDisabled={disabled}
                          placeholder="Select sector"
                          options={sectorList}
                          label="Sector"
                          id="item_sector"
                          name="item_sector"
                          isSearchable={true}
                          errMsgContainerClass="ps-2"
                          isRequired={false}
                          labelWidth="7rem"
                        />
                        <SelectField
                          isDisabled={disabled}
                          placeholder="Select SCH"
                          options={schList}
                          label="SCH"
                          id="item_schedule"
                          name="item_schedule"
                          isSearchable={true}
                          errMsgContainerClass="ps-2"
                          isRequired={false}
                          labelWidth="7rem"
                        />
                      </div>
                      <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10 mt-10 lg:mt-0">
                        <SelectField
                          isDisabled={disabled}
                          placeholder="Select shipping type"
                          options={shippingTypeList}
                          label="Shipping Type"
                          id="item_shipping_type"
                          name="item_shipping_type"
                          isSearchable={true}
                          errMsgContainerClass="ps-2"
                          isRequired={false}
                          labelWidth="7rem"
                        />
                      </div>
                    </div>

                    <h2 className="text-000000 fw_500 text-sm mb-8 px-5 xl:px-10 mt-10">
                      Item Category
                    </h2>

                    <div className="flex flex-wrap mt-3">
                      <div className="w-full 2xl:w-[35%] lg:w-1`1/2 px-5 xl:px-10">
                        <InputField
                          isDisabled={disabled}
                          type="text"
                          name="item_hsn"
                          placeholder="Enter HSN"
                          label="HSN"
                          isRequired={false}
                          labelWidth="7rem"
                        />
                        <InputField
                          isDisabled={disabled}
                          type="text"
                          name="item_remarks"
                          placeholder="Enter remarks"
                          label="Remarks"
                          isRequired={false}
                          labelWidth="7rem"
                        />
                        {/* <CheckBox
                          isDisabled={disabled}
                          conClass="flex flex-col gap-4"
                          items={[
                            {
                              label: "Gst",
                              name: "item_gst",
                              isRequired: false,
                            },
                          ]}
                        /> */}
                        {/* <h2 className="text-000000 fw_500 text-sm mb-8 mt-5">
                          Serial and Batch Numbers{" "}
                        </h2>
                        <SelectField
                          isDisabled={disabled}
                          placeholder="Select item"
                          options={serialBatchesOptns}
                          label="Manage Item by"
                          id="manage_item_by"
                          name="manage_item_by"
                          isSearchable={true}
                          errMsgContainerClass="ps-2"
                          isRequired={false}
                          labelWidth="8rem"
                        /> */}
                      </div>
                      <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10 mt-10 lg:mt-0">
                        <SelectField
                          isDisabled={disabled}
                          placeholder="Select tax category"
                          options={taxList}
                          label="Tax Category"
                          id="item_tax_category"
                          name="item_tax_category"
                          isSearchable={true}
                          errMsgContainerClass="ps-2"
                          isRequired={true}
                          labelWidth="7rem"
                        />

                        <SelectField
                          isDisabled={disabled}
                          placeholder="Select country"
                          options={[{ label: "India", value: "india" }]}
                          label="Country"
                          id="country"
                          name="country"
                          isSearchable={true}
                          errMsgContainerClass="ps-2"
                          isRequired={false}
                          labelWidth="7rem"
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="flex flex-wrap my-5">
                      <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10">
                        <div className="flex flex-wrap items-center justify-between">
                          <div className="w-full xl:w-[30%]">
                            <RadioButton
                              disabled={disabled}
                              inputLabelClass="ms-2"
                              onChange={(e) => {
                                setFieldValue("active_from", null);
                                setFieldValue("active_to", null);
                                setFieldValue("inactive_from", null);
                                setFieldValue("inactive_to", null);
                                const { value } = e.target;
                                setFieldValue(
                                  "is_active",
                                  value === "false" ? false : true
                                );
                              }}
                              labelClass="color_9E9E9E d-flex text-sm mb-1 "
                              name="is_active"
                              options={activeOptns}
                              label=""
                            />
                          </div>
                          <div className="w-full xl:w-[60%] mt-5 xl:mt-0">
                            <InputField
                              isDisabled={disabled}
                              errMsgContainerClass="hidden"
                              type="date"
                              name={`${
                                values?.is_active
                                  ? "active_from"
                                  : "inactive_from"
                              }`}
                              placeholder=""
                              label="From"
                              isRequired={false}
                              labelWidth="3rem"
                            />
                            <InputField
                              isDisabled={disabled}
                              containerClass="mt-3"
                              errMsgContainerClass="hidden"
                              type="date"
                              name={`${
                                values?.is_active ? "active_to" : "inactive_to"
                              }`}
                              placeholder=""
                              label="To"
                              isRequired={false}
                              labelWidth="3rem"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {currentTab === "Purchasing" && (
                  <PurchasingTab
                    uomGroupID={product?.item_uom ?? uomGroupID}
                    vendorList={vendorList}
                    itemGeneralData={values}
                    disabled={disabled}
                    product={product}
                    setCurrentTab={setCurrentTab}
                    formValues={formValues}
                    uomList={uomList}
                    productId={productId}
                    setFormValues={setFormValues}
                    uomGroupData={uomGroupData}
                  />
                )}
                {currentTab === "Sales" && (
                  <SalesTab
                    uomGroupID={product?.item_uom ?? uomGroupID}
                    itemGeneralData={values}
                    disabled={disabled}
                    product={product}
                    setCurrentTab={setCurrentTab}
                    formValues={formValues}
                    uomList={uomList}
                    productId={productId}
                    setFormValues={setFormValues}
                    uomGroupData={uomGroupData}
                  />
                )}
                {currentTab === "Inventory" && (
                  <InventoryTab
                    itemGeneralData={values}
                    disabled={disabled}
                    product={product}
                    setCurrentTab={setCurrentTab}
                    formValues={formValues}
                    uomList={uomList}
                    productId={productId}
                    setFormValues={setFormValues}
                  />
                )}
                {currentTab === "Planning" && (
                  <PlanningTab
                    itemGeneralData={values}
                    disabled={disabled}
                    product={product}
                    setCurrentTab={setCurrentTab}
                    formValues={formValues}
                    productId={productId}
                    setFormValues={setFormValues}
                  />
                )}
                {currentTab === "Restrictions" && (
                  <RestrictionsTab
                    itemGeneralData={values}
                    disabled={disabled}
                    product={product}
                    setCurrentTab={setCurrentTab}
                    formValues={formValues}
                    productId={productId}
                    setFormValues={setFormValues}
                  />
                )}
                {currentTab === "Remarks" && (
                  <RemarksTab
                    itemGeneralData={values}
                    disabled={disabled}
                    product={product}
                    setCurrentTab={setCurrentTab}
                    formValues={formValues}
                    productId={productId}
                    setFormValues={setFormValues}
                  />
                )}
                {currentTab === "General" && !disabled && (
                  <div
                    style={{
                      bottom: "0",
                    }}
                    className="flex justify-end px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
                  >
                    <AddButton
                      onclkFn={() => handleShowErrorToast(errors)}
                      // isDisabled={productId ? true : false}
                      prefixIcon={false}
                      text="Save"
                      type="submit"
                    />
                  </div>
                )}
                {disabled && (
                  <div
                    style={{
                      bottom: "0",
                    }}
                    className="flex justify-end px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
                  >
                    <AddButton
                      prefixIcon={false}
                      text="Edit"
                      type="button"
                      onclkFn={() =>
                        navigate(`/edit-product/${product?.item_id}`)
                      }
                    />
                  </div>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ViewEditProduct;

const items = [{ label: "All", value: "all" }];
const itemoptions = [
  { label: "Inventory Item", name: "is_inventory_item", isRequired: false },
  { label: "Purchase Item", name: "is_purchase_item", isRequired: false },
  { label: "Sales Item", name: "is_sales_item", isRequired: false },
];
const itemTabs = [
  "General",
  "Purchasing",
  "Sales",
  "Inventory",
  "Planning",
  "Restrictions",
  "Remarks",
];
export const activeOptns = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
];
export const serialBatchesOptns = [
  { label: "Batches", value: "batches" },
  { label: "Numbers", value: "numbers" },
  { label: "Batches and Numbers", value: "batches_numbers" },
];
