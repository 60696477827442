import React from "react";
import InputField from "../../forms/input-field";

const StoreDrugDlDetailsTab = () => {
  return (
    <div className="w-full 2xl:w-[30%] lg:w-[60%] px-5 xl:px-10">
      <InputField
        isRequired={true}
        type="date"
        name="dl_valid_upto"
        placeholder=""
        label="DL Valid Upto"
        labelWidth="8.5rem"
      />
      <InputField
        isRequired={true}
        type="text"
        name="dl_no_20_20b"
        placeholder=""
        label="DL No. 20/20B"
        labelWidth="8.5rem"
      />
      <InputField
        isRequired={true}
        type="text"
        name="dl_no_21_21b"
        placeholder=""
        label="DL No. 21/21B"
        labelWidth="8.5rem"
      />
      <InputField
        isRequired={true}
        type="text"
        name="dl_no_21c"
        placeholder=""
        label="DL No. 21C"
        labelWidth="8.5rem"
      />
      <InputField
        isRequired={true}
        type="text"
        name="dl_no_21f"
        placeholder=""
        label="DL No. 21F"
        labelWidth="8.5rem"
      />
      <InputField
        isRequired={true}
        type="text"
        name="dl_no_name"
        placeholder=""
        label="DL No. Name"
        labelWidth="8.5rem"
      />
      <InputField
        isRequired={true}
        type="text"
        name="dl_store_sq_ft"
        placeholder=""
        label="Store Area (Sq ft)"
        labelWidth="8.5rem"
      />
    </div>
  );
};

export default StoreDrugDlDetailsTab;

